import React, { useState, useEffect } from 'react'
import ExcelJs from "exceljs";
import {getExportDataEmpRegularShiftUpdate} from '../../actionMethods/actionMethods';
import moment from 'moment';
import ErrorModal from '../../../components/ErrorModal';


//(Employee Bulk Shift Update)
export default function GenerateShiftRegular(){
    let requestBody={};
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    const [errorModal,SetErrorModal]=useState(false);
    const [title,SetTitle]=useState('');
    const [description,SetDescription]=useState('');

    function handleCloseModal(){
        SetErrorModal(false);
    }

    
   
    function generateExcel(){
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id=userDetails.org_id;

        getExportDataEmpRegularShiftUpdate(requestBody).then(res=>{


            if(res.status==200){
        
              const workbook = new ExcelJs.Workbook();
       
        const filename = "EMPLOYEE SHIFT UPDATE.xlsx";
        const sheetNames = ["EMPLOYEE SHIFT UPDATE"];
        
       let sheetData  =[];
       let emp_name = res.data.employee_names;
       let shift = res.data.shift_names;
                

        for(let i =0 ;i<emp_name.length;i++){
            sheetData.push({serial_id:i+1,shift_date:"", emp_name:emp_name[i],shift_update:""})
        }

         
        

        sheetNames.forEach(sheetName => {
            let worksheet = workbook.addWorksheet(sheetName);
            let worksheet2 = workbook.addWorksheet('shift');

            // I believe this needs to be set to show in LibreOffice.

            worksheet2.addTable({
                name: 'shift',
                ref: "A1", 
                headerRow: true,
                totalsRow: false,
                style: {
                  theme: "TableStyleMedium2",
                  showRowStripes: false,
                  width: 200
                },
                columns : [
                  {name:'shift', header: 'shift', key: 'shift'},
                ],
                rows: shift.map((e) => {
                  let arr = [e];      
                  return arr;
                })
              })
              worksheet2.state = 'hidden';            
              const table2 = worksheet2.getTable('shift');            
            table2.commit();


            worksheet.addTable({
                name: sheetName,
                ref: "A1", 
                headerRow: true,
                totalsRow: false,
                style: {
                  theme: "TableStyleMedium2",
                  showRowStripes: false,
                  width: 200
                },
                columns : [
                  {name:'SERIAL ID.', header: 'SERIAL ID.', key: 'serial_id'},
                  {name:'SHIFT DATE',header: 'SHIFT DATE', key: 'shift_date'},
                  {name:'EMPLOYEE NAME',header: 'EMPLOYEE NAME', key: 'emp_name'},
                  {name:'SHIFT UPDATE',header: 'SHIFT UPDATE', key: 'shift_update'},
                ],
                rows: sheetData.map((e) => {
                  let arr = [e.serial_id,e.shift_date,e.emp_name,e.shift_update];      
                  return arr;
                })
              })
              worksheet.state = 'visible';
              const table = worksheet.getTable(sheetName);
              for (let i = 0; i < table.table.columns.length; i++) {
                  worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                  worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "d0cfcf" }
                };
            
      
              }
             
              worksheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength+2;
              });

              sheetData.forEach((element,index) =>{
                worksheet.getCell('D'+(+index+2)).dataValidation = {
    
                  type: "list", allowBlank: true,  formulae: ["shift!$A$2:$A$9999"],
              };
              //worksheet.getCell('C'+(+index+2)).cellFormat().locked(false);
              worksheet.getCell('C'+(+index+2)).locked = true;
              worksheet.getCell('C'+(+index+2)).protection = {
                locked: false,
                lockText: false,
              };
              })

              


              table.commit();

        })

          
        const writeFile = (fileName, content) => {
            const link = document.createElement("a");
            const blob = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = fileName;
            link.href = URL.createObjectURL(blob);
            link.click();
          };
        //const stream = fs.createWriteStream(filename);
        
        workbook.xlsx.writeBuffer().then((buffer) => {
            writeFile(filename, buffer);
        });

            }else{
              SetTitle("Error");
              SetDescription(res.message)
               SetErrorModal(true);
          }

        })
        
    }

    return (
            <>
              <button className='btn btn-transparent btn-twoline' onClick={generateExcel}>
                      <span className='smallerSpan'>Regular Employee</span>
                      <span className='biggerSpan'>Update Shift</span>
              </button>
              <ErrorModal modalError={errorModal} handleCloseModalError={handleCloseModal}   title={title} description={description}  key={'GenerateTagRegilar'} />
            </>
            )
} 