import React,{useState,useEffect} from "react";
import ReactModal from "react-modal";
import Scrollbars from "react-custom-scrollbars";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};
function SucessModal(props){
    const [modalSucess,SetModalSucess] =useState(false);


        useEffect(()=>{
        SetModalSucess(props.modalSucess)
    },[props])
return (
    
    <ReactModal
    isOpen={modalSucess}
    style={customStyles}
    className={props.description&&props.description!=null?'sucessModal with-description':'sucessModal without-description'}
>

<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
<circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit={10} cx="65.1" cy="65.1" r="62.1"/>
<polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6"  strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
</svg>

{props.title}
 <p>{props.description}</p>
    
        <button onClick={()=>props.handleCloseModalSucess()} className="btn btn-close"> Close</button>
</ReactModal>



)
}

export default SucessModal;