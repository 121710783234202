import axios from "axios";

let prefixURL = process.env.REACT_APP_URL

// /api/v1/employee/create_bulk_temporary_employee

export function importDataPlanAttendance(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/employee/create_employee_attendance_plan`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
  }