import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'


export function UpdateShift(requestBody) {    
    return axios.post(prefixURL + `/employee/update_shift` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getProductivityList(session,org_id) {    
    return axios.get(prefixURL + `/employee/get_productivity_category?session=${session}&org_id=${org_id}`).then(res=>res.data).catch(err=>err)
}
