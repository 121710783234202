import axios from "axios";

let prefixURL = process.env.REACT_APP_URL

// /api/v1/employee/create_bulk_temporary_employee
export function importDataTemp(requestBody,file){
    let bodyFormData = new FormData();
    bodyFormData.append('data',JSON.stringify(requestBody));
    bodyFormData.append('file',file);
    return axios({
        method: "post",
        url: prefixURL + `/employee/create_bulk_temporary_employee`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(res=>res.data)
        .catch(err=>err);
 }



 export function importDataEmpTagValidation(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/bulk/bulk_employee_tags_changing_validation`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}

export function importDataEmpTag(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/bulk/bulk_employee_tags_changing`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}


 export function importDataTempValidation(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/employee/daily_employee_validation`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}

export function importDataEmpValidation(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/employee/bulk_shift_history_validation`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}

//cycle count Validation

export function importDataCycleCountValidation(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/bulk/create_bulk_cycle_count_validation`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}


 export function importDataEmp(requestBody,file){
    let bodyFormData = new FormData();
    bodyFormData.append('data',JSON.stringify(requestBody));
    bodyFormData.append('file',file);
    return axios({
        method: "post",
        url: prefixURL + `/employee/bulk_shift_history`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(res=>res.data)
        .catch(err=>err);
 }

 //cycle count Import 
 export function importDataCycleCount(requestBody,file){
  let bodyFormData = new FormData();
  bodyFormData.append('data',JSON.stringify(requestBody));
  bodyFormData.append('file',file);
  return axios({
      method: "post",
      url: prefixURL + `/bulk/create_bulk_cycle_count`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res=>res.data)
      .catch(err=>err);
}

 

 //export function file creation

 //1.(Tags Bulk Update)
export function getExportDataEmpRegularTagUpdate( requestBody) {
  return axios.get(prefixURL + `/bulk/bulk_employee_tags_changing_export?session=${requestBody.session}&org_id=${requestBody.org_id}`)
      .then(res => res.data).catch(err => err)     
}
//(Daily Employee Bulk Update)
export function getExportDataDailyEmpBulkUpdate( requestBody) {
  return axios.get(prefixURL + `/employee/bulk_temporary_employee_export?session=${requestBody.session}&org_id=${requestBody.org_id}`)
      .then(res => res.data).catch(err => err)     
}
//(Employee Bulk Shift Update)
export function getExportDataEmpRegularShiftUpdate( requestBody) {
  return axios.get(prefixURL + `/employee/bulk_shift_history_export?session=${requestBody.session}&org_id=${requestBody.org_id}`)
      .then(res => res.data).catch(err => err)     
}

export function getExportCycleCount( requestBody) {
  return axios.get(prefixURL + `/bulk/create_bulk_cycle_count_export?session=${requestBody.session}&org_id=${requestBody.org_id}`)
      .then(res => res.data).catch(err => err)     
}