import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import '../style/styles.scss'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import AddProductiveType from './AddProductiveType'
import EditProductiveType from './EditProductiveType'
import ProductivityList from './ProductivityList'
import {getRoleDetail} from '../../rolemanagement/actionMethods/actionMethods'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import ReactModal from 'react-modal';
import { getlocationType } from '../../sitemanagement/actionMethods/actionMethods';
import WarningModal from '../../components/WarningModal';
import { removeProductive } from '../actionMethods/actionMethods';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};

function ProductivitManagement(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:"";

  const [permission,SetPermission]=useState([]);
  const [permissionAPICall,SetPermissionAPICall] =useState(false);
  const [modalSucess,SetModalSucess] = useState(false);
  const [modalError,SetModalError]= useState(false);
  const [title,SetTitle]= useState('');
  const [description,SetDescription]=useState('');
  const [prdouctiveData,SetProductiveDate] =useState([]);
  const [locationList,SetLocationList] =useState([])
  const [productiveIDData,SetProductiveIDData]=useState([]);

  const [modalCreate,SetModalCreate]=useState(false);
  const [modalUpdate,SetModalUpdate]=useState(false);
  const [modalDelete,SetModalDelete] = useState(false);
  const [modalWarning,SetModalWarning]= useState(false);
  const [ID,SetID]=useState('');
  const [searchProductive,SetSearchProductive] =useState('');
  
  const [locationType,SetLocationType]=useState([]);

  const [inc,SetInc]=useState(1)

    useEffect(() => {
        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id= org_id
        requestBody.role_id = userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].admin_productive_permissions));
                SetPermissionAPICall(true)
            }
            if(res.status==300){
              localStorage.removeItem('isLoggedInGatewayTraceAdmin')
              localStorage.removeItem('userLoginDetailsTraceAdmin')
              props.history.push(`/login`)        
          }
        }).catch()
        getlocationType(requestBody).then(res=>{
            if(res.status==200){
                SetLocationType(res.data);
            }   
        })


    }, []);

    function Reload(){
        SetInc((prev)=>prev+1);
    }

    function createModalHandlerOpen(data){

    }

    function updateModalHandlerOpen(data){
        SetProductiveIDData(data)
        SetModalUpdate(true)
    }

    function modalSucessHandler(titleShow,descriptionShow){
        SetTitle(titleShow)
        SetDescription(descriptionShow)
        SetModalSucess(true);
        
    }
    function handleCloseModalSucess(){
        SetModalCreate(false);
        SetModalDelete(false);
        SetModalUpdate(false)
        SetModalSucess(false)
        SetInc((prev)=>prev +1);
    
    }
    function handleCloseModalError(){
        SetModalError(false)
    }
    
    function modalErrorHandler(titleShow,descriptionShow){
        SetTitle(titleShow)
        SetDescription(descriptionShow)
        SetModalError(true);
    }
    function handleCloseModalCreate(){
        SetModalCreate(false);
    }
    function handleCloseModalUpdate(){
        SetModalUpdate(false);
    }
    function handleCloseModalDelete(){
        SetModalDelete(false);
    }

    function modalWarningHandler(id,title){
     
        SetID(id)
        SetTitle(<><strong>{title}</strong> Productive Category</>);
        SetModalWarning(true);

    }

    function handleCloseModalWarning(){
        SetModalWarning(false)
    }
    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.productivity_category_id = ID
        requestBody.org_id =org_id
        requestBody.session= session
        removeProductive(requestBody).then(res=>{
            if(res.status==200){
                SetTitle(<h4>{res.message}</h4>);
                SetDescription("Sucessfully deleted");
                SetModalSucess(true);
                SetModalWarning(false);

            }else{
                SetTitle('Fail to Delete');
                SetDescription(res.message);
                SetModalError(true);
            }
        })
    }


    return (

        <div className="main-content side-content pt-0">

        <div className="container-fluid">
            

            {permissionAPICall&&permission.View=='True'?
            
            <div className="inner-body">
        
        
            <div className="page-header">
                    <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={2} className="tagAvailStyle"></Col>
                        <Col lg={6} className="header-btn">
                        <input type="text" className='form-control' placeholder="Search Productive" value={searchProductive} onChange={(e)=>SetSearchProductive(e.target.value) } style={{width:200,display:"inline-block"}} />
                            {permission && permission.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={(e)=>(SetModalCreate(true))} ><PlusIcon/> Add Productive Type</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled btn-delete' disabled><PlusIcon/> Add Productive Type<span className='messageTag'>Access Restricted</span></button>}</Col>
                        </Row>
            </div>
            <div className='br-seperator-row'>
            <ProductivityList 
                Reload={Reload}
                permission={permission}
                updateModalHandlerOpen={updateModalHandlerOpen}
                modalWarningHandler={modalWarningHandler}
                inc={inc}
                searchProductive={searchProductive}
            />        
            </div>
        
            </div>:permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}
        </div>
        
        
        
        
                    <ReactModal
                        isOpen={modalDelete}
                        style={customStyles}
                      //  onRequestClose={handleCloseModal}
                    >
                        <div className='dangerDeleteZone'>
                           <div className='deleteZoneHeader'>
                                {/* <DeleteIcon/> */}
                                 <h2>Delete</h2>
                        </div>
                        <div className='deleteZoneContent'>
                            Are you sure you wan't to delete <br/> <span style={{color:"#c52f2f"}}>Name of delete item</span>
                        </div>
                        <div className='deleteZoneAction'>
                            <button className='btn btn-transparent' onClick={()=>SetModalDelete(false)}>No</button>
                            <button className='btn btn-primary' onClick={()=>SetModalDelete(false)}>Yes, Delete</button>
                        </div>
                        </div>
                    </ReactModal>
        
        
                    <ReactModal
                        isOpen={modalCreate}
                        style={customStyles}
                        //onRequestClose={handleCloseModal}
                        className={'create-form-user'}
                    >
                           
                      <AddProductiveType 
                            handleCloseModal={handleCloseModalCreate}
                            locationList={locationList}
                            modalSucessHandler={modalSucessHandler}
                            modalErrorHandler={modalErrorHandler}
                            Reload={Reload}
                            permission={permission}
                            locationType={locationType}
                        />
                    </ReactModal>
                    <ReactModal
                        isOpen={modalUpdate}
                        style={customStyles}
                        //onRequestClose={handleCloseModal}
                        className={'create-form-user'}
                    >
                      <EditProductiveType 
                            handleCloseModal={handleCloseModalUpdate}
                            updateModalHandlerOpen={updateModalHandlerOpen}
                            productiveIDData={productiveIDData}
                            modalSucessHandler={modalSucessHandler}
                            modalErrorHandler={modalErrorHandler}
                            Reload={Reload}
                            permission={permission}
                            locationType={locationType}
                        />

                    </ReactModal>
                    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />    
                <WarningModal modalWarning={modalWarning} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title} />
        </div>
        
        
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(ProductivitManagement))
