import React from 'react'
import { Row,Col,  Container } from 'react-bootstrap';
import Routes from '../common/routes';
import OrganizationList from '../organizationmanagement/oemsettings/orgsettings';
import OrgList from '../organizationmanagement/components/OrgList';

class RightSideComponents extends React.Component{

    render(){
        let isLoggedInGatewayTraceAdmin = JSON.parse(localStorage.getItem('isLoggedInGatewayTraceAdmin'))
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  if(userDetails.org_type === "oem"){
    return(<>
        {/* <OrganizationList/> */}
        {/* <OrgList/> */}
        <Routes ToggleProfile={this.props.ToggleProfile} firstLoad={this.props.firstLoad} changeFirstLoad={this.props.changeFirstLoad}/> </>
    )

  }else{
        return(
                <Routes ToggleProfile={this.props.ToggleProfile}/> 
        )
    }
    }
 }

export default RightSideComponents