import React, { useState, useEffect,useRef } from 'react';
import {getOrganizationList,removeOrg} from '../actionMethods/actionMethods'
import { Select } from 'antd';
import ReactModal from 'react-modal';
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import {Radio, Button } from 'antd';
import Leftsidebar from '../../components/leftsidebar';
import { useHistory } from 'react-router-dom';
import "../styles/styles.scss"
import Createorg from "../components/create"
import { getsuborganizationlist } from '../../usermanagement/actionMethods/actionMethods';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(8px)',
        zIndex: 9999
      },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        maxWidth: '80%',
        maxHeight: '90%',
        zIndex: 99999,
    backdropFilter: 'none'
    },
	
};
const customStyles1 = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(3px)',
      zIndex: 9999
    },
  content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      maxWidth: '80%',
      maxHeight: '90%',
      zIndex: 99999,
  backdropFilter: 'none'
  },

};
const customStyles2 = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(3px)',
      zIndex: 9999, 
    },
  content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      maxWidth: '80%',
      maxHeight: '90%',
     zIndex: 10000,
  backdropFilter: 'none'
  },

};


function OrganizationList(props){
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  console.log("firstLoad",userDetails)
    const [orgListData,SetOrgListData] =useState([]);
    const [orgListDefaultData,SetOrgListDefaultData] =useState([]);
    const [loader,SetLoader]=useState(true);
    const [apiCall,SetAPICall]=useState(false)
    const [selectedOrg, setSelectedOrg] = useState(parseInt(userDetails.org_id));
    const [modalIsOpen, setModalIsOpen] = useState(userDetails.firstLoad?!userDetails.firstLoad:true);
    const[modalIsOpensub,setModalIsOpensub]=useState(false)
    const [loading, setLoading] = useState(true);
    const [suborglist,setsuborglist] =useState([])
    const [selectsub,Setselectsub] = useState("")
    const [modalCreate,SetModalCreate]=useState(false);
    const [modalEdit,SetModalEdit]=useState(false);
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');
    const [modalSucess,SetModalSucess]= useState(false);
    const [modalError,SetModalError]= useState(false);
const [modalWarning,SetModalWarning]= useState(false);
const [searchorg,SetSearchorg] = useState([]);
const [inc,SetInc]=useState(1);

const [modalUpdate,SetModalUpdate]=useState(false);
const [modalCategoryEdit,SetModalCategoryEdit]= useState(false); 
const [orgDetail,SetorgDetail]=useState([]);
    const { Option } = Select;
    let history =useHistory();
    
    useEffect(()=>{
        let requestBody={}
        requestBody.session=userDetails.session
        requestBody.org_id=userDetails.org_id
        getOrganizationList(requestBody).then(res=>{
            if(res.status==200){
                let data = res.data;
                let index2 = 1;
                let dataFormat =[];
                Object.keys(data).map(function(key){
                    // console.log(dataFormat)
                    dataFormat.push({id:data[key].id,org_name:data[key].name,org_logo:data[key].logo_url})
                    index2++;
                });
                // console.log("Api Response",data,dataFormat)
                SetOrgListData(dataFormat);
                SetOrgListDefaultData(dataFormat)
                SetAPICall(true)
                // localStorage.removeItem('timezonename')
                    SetLoader(false)
            }else{
                SetLoader(false)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)
            }

        }).catch();

    },[props.inc])

    useEffect(()=>{
        let requestBody={}
        requestBody.session=userDetails.session
        requestBody.org_id=userDetails.org_id

        console.log("after change",userDetails);
        localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(userDetails));
        
    },[])


    

const handleOrgChange = (value) => {
    setSelectedOrg(value);
  };


  const handleSubChange = (value) => {
    Setselectsub(value);
  };

  function Orgdata1(){
    
    var LoginDetails ;
    const data ={}
    //console.log(localStorage.getItem('userLoginDetailsTraceAdmin'))
    // LoginDetails = JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin'))
   
     LoginDetails =  localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    //console.log("type",LoginDetails["org_id"])
    LoginDetails["org_id"]= String(selectedOrg);
    LoginDetails["sub_org_id"]=String(selectsub);
    // console.log(orgdata, LoginDetails.type)
     localStorage.removeItem('userLoginDetailsTraceAdmin')
    localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
    setModalIsOpen(false);
    setModalIsOpensub(false)
    history.push('/team-management')
    setTimeout(() => {
        setModalIsOpen(false);
        history.push('/dashboard');
      }, 100);
   
   
}
  function Orgdata(){
    
    var LoginDetails ;
    const data ={}
     LoginDetails =  localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    //console.log("type",LoginDetails["org_id"])
    LoginDetails["org_id"]= String(selectedOrg);
    
    LoginDetails["firstLoad"]= true;
    const selectedOrgData1 = orgListData.find((org) => org.id === selectedOrg);
  
  if (selectedOrgData1) {
    LoginDetails["org_logo"] = selectedOrgData1.org_logo;
  }
    // console.log(orgdata, LoginDetails.type)
     localStorage.removeItem('userLoginDetailsTraceAdmin')
    localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
    const selectedOrgData = orgListData.find((org) => org.id === selectedOrg);
  const selectedSubOrgData = suborglist.find((suborg) => suborg.org_id === selectedOrg);

  if (selectedSubOrgData) {
    setModalIsOpensub(true);
  } else if (selectedOrgData) {
    LoginDetails["sub_org_id"] = "0"

    localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
    setModalIsOpen(false);
    let url = window.location.pathname;
    history.push("/team-management");
    console.log("url",url);
    setTimeout(() => {
      setModalIsOpen(false);
      history.push("/"+url);
    }, 100);
  }
}

useEffect(()=>{
  let requestBody={};
  requestBody.session=userDetails.session;
  requestBody.org_id = selectedOrg;
  getsuborganizationlist(requestBody).then(res=>{
    if(res.status==200){
        let data = res.data;
        let index2 = 1;
        let dataFormat1 =[];
        Object.keys(data).map(function(key){
            // console.log(dataFormat)
            dataFormat1.push({id:data[key].id,org_name:data[key].name,org_id:data[key].org_id})
            index2++;
        });
        // console.log("Api Response",data,dataFormat)
        setsuborglist(dataFormat1);
        SetAPICall(true)
       console.log("Api Response",dataFormat1)
            SetLoader(false)
    }
    if(res.status==300){
        localStorage.removeItem('isLoggedInGatewayTraceAdmin')
        localStorage.removeItem('userLoginDetailsTraceAdmin')
        props.history.push(`/login`)
    }

}).catch();

},[selectedOrg])

function Reload(){
  SetInc((prev)=>prev+1);
}

function modalSucessHandler(titleShow,descriptionShow){
  SetTitle(titleShow)
  SetDescription(descriptionShow)
  SetModalSucess(true);
  
}
function handleCloseModalSucess(){
  SetModalCreate(false);
  SetModalUpdate(false)
  SetModalSucess(false)
  SetModalEdit(false);
  SetInc((prev)=>prev +1);

}
function handleCloseModalError(){
  SetModalError(false)
}

function modalErrorHandler(titleShow,descriptionShow){
  SetTitle(titleShow)
  SetDescription(descriptionShow)
  SetModalError(true);
}
function handleCloseModalCreate(){
  SetModalCreate(false);
}
  return (
    <>  
     <ReactModal
                        isOpen={modalCreate}
                        // style={customStyles2}
                        style={{
                          overlay: {
                            ...customStyles.overlay,
                            zIndex: customStyles.overlay.zIndex + 1,
                          },
                          content: {
                            ...customStyles.content,
                            zIndex: customStyles.content.zIndex + 1,
                          },
                        }}
                        // onRequestClose={handleclosemodal}
                        className={'create-form-user'}
                    >
                           <Createorg
                            
                           handleCloseModal={handleCloseModalCreate}
                           modalSucessHandler={modalSucessHandler}
                           modalErrorHandler={modalErrorHandler}
                           Reload={Reload}
                        />
                    </ReactModal>
    <div style={{width:"30%",marginLeft:"40%"}}>
 {/* //<button onClick={() => setModalIsOpen(true)} className={'btn btn-primary'}>Open Modal</button> */}
      <ReactModal isOpen={modalIsOpen} style={customStyles}>
      <div className='modalHeader'>
    <h4>Select Organization</h4>
    <div className='closeMark' onClick={()=>setModalIsOpen(false)}>x</div>
    </div>
    <div className='modalBody'>
    <div className='field-row'>
    <label>Select Organization*<div className='posRel'><HelpIcon /></div></label>
    <div className="select-container">
        <Select
             value={selectedOrg}
             onChange={handleOrgChange}
              placeholder="Select an organization"
                  //   className={selectedOrg == "" ? "form-control has-error" : "form-control"}
              optionFilterProp="children"
              filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className="custom-select"
                dropdownClassName="custom-select-dropdown"
                
    // dropdownRender={(menu) => (
    //   <div>
    //     {menu}
    //     <Button  type="link" className=''  onClick={(e)=>(SetModalCreate(true))} >Create New</Button>
       
    //   </div>
    // )}
            >
              <Option value="" >Select an Organization</Option>
              {orgListData.map((org) => (
              <Option key={org.id} value={org.id}>
              {org.org_name} 
              </Option>
               ))}
               <Option onClick={(e)=>(SetModalCreate(true))}>Create New</Option>
             </Select>
             </div>
             </div>
             <Button type="primary" onClick={() => Orgdata()} className={'btn btn-primary btn-100'}>
			 Confirm Organization
		 </Button>
             </div>
             </ReactModal>    

             <ReactModal isOpen={modalIsOpensub} style={customStyles1}>
      <div className='modalHeader'>
    <h4>Select Sub Organization</h4>
    <div className='closeMark' onClick={()=>setModalIsOpensub(false)}>x</div>
    </div>
    <div className='modalBody'>
    <div className='field-row'>
    <label>Select Sub Organization*<div className='posRel'><HelpIcon /></div></label>
    <div className="select-container">
        <Select
             value={selectsub}
             onChange={handleSubChange}
              placeholder="Select an Sub organization"
                  //   className={selectedOrg == "" ? "form-control has-error" : "form-control"}
              optionFilterProp="children"
              filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className="custom-select"
                dropdownClassName="custom-select-dropdown"
            >
              <Option value="" >Select an Sub Organization</Option>
              {suborglist.map((org) => (
              <Option key={org.id} value={org.id}>
              {org.org_name}
              </Option>
               ))}
             </Select>
             </div>
             </div>
             <Button type="primary" onClick={() => Orgdata1()} className={'btn btn-primary btn-100'}>
			 Confirm Sub Organization
		 </Button>
             </div>
             </ReactModal>  
             
             <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />    
    </div>
    </>
  );

}


export default OrganizationList;