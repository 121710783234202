import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { Select } from 'antd';
import '../../styles/addEmployee.scss';
import {Radio, Steps, Button, message ,DatePicker,Switch } from 'antd';
import { PropertyKeys } from 'ag-grid-community';
import Scrollbars from 'react-custom-scrollbars';
import {getDepartmentList,getSubDepartmentList} from '../../actionMethods/actionMethods'
import { getLocationList } from '../../../sitemanagement/actionMethods/actionMethods';
import { AddUser} from '../../actionMethods/actionMethods';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as UPIcon} from '../../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CreateShift,getShifttList } from '../../actionMethods/actionMethods';
import moment from 'moment';
import { getProductivityList } from '../../../productivemanagement/actionMethods/actionMethods';


const { Option } = Select;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};



function AddEmployee(props) {
    const [value, setValue] = useState('');
	const { Step } = Steps;
	const [current, setCurrent] = React.useState(0);
	const [historyEnable,SetHistoryEnable]= useState(false);
	const [productive,SetProductive]=useState([]);
	const [nonproductive,SetNonProductive]=useState([]);
	const [lessproductive,SetLessProductive]=useState([]);
	const [firstname,SetFirstName]=useState([])
	const [firstnameError,SetFirstNameError]=useState([])
	const [lastname,SetLastName]=useState('')
	const [lastnameError,SetLastNameError]=useState('')
	const [email,SetEmail]=useState('')
	const [emailError,SetEmailError]=useState('')
	const [password,SetPassword]=useState('')
	const [passwordError,SetPasswordError]=useState('')
	const [description,SetDescription] =useState('');

	const [address,SetAddress]=useState('')
	const [addressError,SetAddressError]=useState('')
	const [emp_id,SetEMPID] =useState('')
	const [emp_idError,SetEMPIDError] =useState('')
	const [nonSelectProductive,SetNonSelectProductive]= useState([])
	const [formatProductiveAPI,SetFormatProductiveAPI] =useState([]);
	const [subDepartment,SetSubDepartment]=useState([]);
	const [subDepartmentID,SetSubDepartmentID]=useState(props.subGroupID?props.subGroupID:'');
	const [shift,SetShift] =useState([]);
	const [shiftID,SetShiftID] =useState([]);
	const [assignedTag,SetAssignedTag]=useState(props.availableTag.length>0?true:false);
    const [fileError,SetFileError] =useState('');
	const [shiftMessage,SetShiftMessage] = useState('');
	const [modalSucessShift,SetModalSucessShift] = useState(false);
	const [empSelected,SetEmpSelected] = useState(''); 

	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""

	useEffect(()=>{
		SetShift(props.shift);


	  },[props.shift])
	const steps = [
		{
		  title: 'User Info',
		  step: '01',
		  content: 'First-content',
		},
		{
		  title: 'Working Detail',
		  step: '02',
		  content: 'Second-content',
		}
	  ];
	
	  const next = () => {
		// let isValidStep=true;
		let isValidStep=true;
		if(current==0){

			//var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;


//name
			if(firstname==''){
				isValidStep=false;
					SetFirstNameError('First name is required');
			}
			else if(firstname.length > 255){
				isValidStep=false;
				isValidStep=false;
				SetFirstNameError('Length of first name should be less than 255 character');
			}else{
				SetFirstNameError('');
			}

			if(description.length> 255){
				isValidStep=false;
				SetDescriptionError('Length of designation should be less than 255 character ');
			}else{
				SetDescriptionError("")
			}
			
			if(isValidStep){
				setCurrent(current + 1);	
			}

			
		}
		else if(current==1){
		if(department_id==''){
				SetDepartmentIDError('Department name is required');
		}else if(emp_id==''){
			SetRoleIDError('')
			SetEMPIDError('Unique Employee ID is required ');
		}else if(emp_id.length > 255){
			SetEMPIDError('Employee ID length should be less than 255 character');
		}else if(shiftID == "" ){
			SetEMPIDError('');
			SetShiftNameError('Shift Time is required')
		}

		else if(shiftToggle || shiftID=='other'){
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")
			SetShiftMessage('Please Create Shift First Before Proceed');
		}
		else{
			SetShiftMessage('');
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")			
			setCurrent(current + 1);
		}		
		}else{
			setCurrent(current + 1);
		}
		
	  };
	
	  const prev = () => {
		setCurrent(current - 1);
	  };
	  
	  function onChangeHistory(value){
		SetHistoryEnable(value)
	  }
	const[locationList,SetlocationList] =useState([])
    const [dropdownOpt,SetDropDownOpt]=useState([])
	const [shiftToggle,SetShiftToggle]=useState(false);
	const [shiftName,SetShiftName]=useState('')
	const [shiftNameError,SetShiftNameError]=useState('')
	const [shiftStartTime,SetStartTime]=useState('')
	const [shiftStartTimeError,SetStartTimeError]=useState('')
	const [shiftEndtTime,SetEndTime]=useState('')
	const [shiftEndtTimeError,SetEndTimeError]=useState('')
	const [department_list,SetDepartMentList] = useState([])
	const [department_id,SetDepartmentID] =useState('');
	const [department_idError,SetDepartmentIDError] =useState('');
	const [role_id,SetRoleID] =useState('');
	const [role_idError,SetRoleIDError] =useState('');
	const [availableTag,SetAvailTag] =useState(props.availableTag?props.availableTag:[]);
	const [selectedTag,SetSelectedtag] = useState('')
	
	const timeVal =[
		"1:00 AM","02:00 AM","03:00 AM","04:00 AM","05:00 AM","06:00 AM","07:00 AM","08:00 AM","09:00 AM","10:00 AM","11:00 AM","12:00 AM",
		"1:00 PM","02:00 PM","03:00 PM","04:00 PM","05:00 PM","06:00 PM","07:00 PM","08:00 PM","09:00 PM","10:00 PM","11:00 PM","12:00 PM",	
	]

	const [previewProfile,SetPreviewProfile] =useState('');
    const [file, setFile] = React.useState("");

	const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [descriptionMessage,SetDescriptionMessage] =useState('');
    const [descriptionError,SetDescriptionError] =useState('');
	const reg = /^\S.*$/;
	
	const [productiveList,SetProductiveList]=useState([])
	const [productiveID,SetProductiveID]=useState('');

	
  
  
	useEffect(()=>{
		let requestBody={}
		requestBody.session = session
		requestBody.org_id = org_id
		requestBody.tag_type= 'personal'

		getLocationList(requestBody).then(res=>{
			if(res.status==200){
				SetlocationList(res.data)
			}
			
		})
		getProductivityList(session,org_id).then(res=>{
			if(res.status==200){
				SetProductiveList(res.data);
			}
		})
	},[])
	
	function onChangeProductivitCat(data){
	
		SetProductiveID(data);
		let preselect = productiveList.find(item=>item.id == data);
		if(preselect){

			let prod = JSON.parse(preselect.productivity);
			let product = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')
            SetProductive(prod[product.id])
            SetLessProductive(prod[lessprod.id])
            SetNonProductive(prod[nonprod.id])
		}
	}

	useEffect(()=>{
		let requestBody ={}
		requestBody.session=session
		requestBody.org_id=org_id
		requestBody.dept_id = props.group_id
	
		SetSubDepartmentID('')
		SetDepartmentID(props.group_id);
				getSubDepartmentList(requestBody).then(res=>{
			if(res.status==200 || res.code==200){
				let item = res.data.filter(item=>item.id== subDepartmentID);
				
				if(item){
					SetSubDepartment(res.data);
				}else{
					SetSubDepartmentID('')
					SetSubDepartment(res.data);
				}

			}
		})

	},[props.group_id])
	useEffect(()=>{

		SetSubDepartmentID(props.subGroupID)

	},[props.subGroupID])

	


	function loadFile(event){

		let arry = event.target.files[0].name.split('.');
		let lastElement = arry[arry.length - 1];
	  
		if(lastElement=='jpeg' || lastElement=='png' || lastElement=='webp' || lastElement=='jpg' ){
			SetPreviewProfile(URL.createObjectURL(event.target.files[0]));
			setFile(event.target.files[0]);    
		}else{
			SetFileError('Only Support JPEG & PNG')
			SetPreviewProfile('');
			setFile('');    
			//setFile(event.target.files[0]);    
			setTimeout(function(){
			SetFileError('')
			},3000)
		}
	
	}
	
	function handleCloseModalSucessShift(){
		SetModalSucessShift(false);
	}

	function CreateShiftHandler(){

		let requestBody2= {};
		requestBody2.session=session
		requestBody2.org_id=org_id
		requestBody2.shift_name= shiftName
		requestBody2.start_time =moment(shiftStartTime).format('HH:mm:ss') 
		requestBody2.end_time = moment(shiftEndtTime).format('HH:mm:ss')
		if(shiftStartTime=='' || shiftEndtTime==''|| shiftName=='' ){
			if(shiftName==''){SetShiftNameError('Shift Name is required')}else{SetShiftNameError('')}
			if(shiftStartTime==''){SetStartTimeError('Shift Start Time is required')}else{SetStartTimeError('')}
			if(shiftEndtTime==''){SetEndTimeError('Shift End Time is required')}else{SetEndTimeError('')}
	
	
		}else{
		CreateShift(requestBody2).then(res=>{
			if(res.status==200){
				props.refreshShift();
				SetShiftID(res.data.shift_id);
				SetShiftName('');

				SetStartTime('');
				SetEndTime('')
				SetShiftToggle(false);
				SetTitle(<h4>Shift is created</h4>)
				SetDescriptionMessage(res.message)
				SetModalSucessShift(true)
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescriptionMessage('Fail to create shift')
				SetModalError(true)

				setCurrent(1);
				SetTitle(res.message);
				SetDescriptionMessage('Shift is not created')
				SetModalError(true)
			}
		})
	}
	  }

    const onSelect = (data) => {

      };
      const onChange = (data) => {
        setValue(data);
			if(data=='other'){

				SetShiftToggle(true)
			}else{
				SetShiftToggle(false)
			}

      };

	const onChangeDepartment = (data)=>{
		SetDepartmentID(data);
		
	}

	const onChangeSubDepartment = (data) =>{
		SetSubDepartmentID(data);
	}
    const onChangeRole =(data)=>{
		if(role_id !== ""){
			SetRoleIDError("")
		}
		SetRoleID(data);	
	}
	  

      function onChangeSelect(value) {
       
      }

	  function onChangeStep(step){
		var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		let validStep=true;
		if(current==0){
			if(firstname==''){
			SetFirstNameError('First name is required');
			validStep=false;
			}else if(firstname.length > 255){
				validStep=false;
				SetFirstNameError('Length of first name should be less than 255 character');
			}else{
				SetFirstNameError('');
			}

			// if(password==''){
			// 	validStep=false;
			// 	SetEmailError('');
			// 	SetPasswordError('Password is required');
			// }else if(password.length> 32){
			// 	validStep=false;
			// 	SetEmailError('');
			// 	SetPasswordError('Length of password should be less than 32 character ');
			// }else{
			// 	SetPasswordError('');
			// }


			if(description.length> 255){
				validStep=false;
				SetPasswordError('');
				SetDescriptionError('Length of designation should be less than 255 character ');
			}else{
				SetDescriptionError('');
				
			}

			if(validStep){
				setCurrent(step);	
			}

		}else{
			setCurrent(step);
		}
		
	  }


	  const onChangeShift = (data) => {
        SetShiftID(data);
			if(data=='other'){

				SetShiftToggle(true)
			}else{
				SetShiftToggle(false)
			}
      };


	  function ChangeRadio(e,val){

		let val2 = parseInt(e.target.value);
	
		if(e.target.checked){
			if(val=="productive"){
				let arr=[...productive];
				arr.push(val2)
				SetProductive(arr)
				
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}
				

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);	
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)	
				}

			}
			else if(val=='lessproductive'){
				let arr=[...lessproductive];
				arr.push(val2)

				SetLessProductive(arr)

				let arr2 = [...productive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetProductive(arr2);
				}	

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}	
				
			}else{
				let arr=[...nonproductive];
				arr.push(val2)

				SetNonProductive(arr)

				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}				
				let arr3 = [...productive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}
			}
		// }else{
		// 	let arr=[...productive];
		// 	let arr2=[...nonproductive];
		// 	let arr3=[...lessproductive];

		// 	if(val=="productive"){
		// 		let index = arr.indexOf(val2)
		// 		if(arr.indexOf(val2)!=-1 && ( arr2.indexOf(val2)!=-1 || arr3.indexOf(val2)!=-1) ){  
		// 			arr.splice(arr.indexOf(val2), 1)
		// 		}   
		// 		SetProductive(arr)
		// 	}
		// 	else if(val=="lessproductive"){
		// 		if(arr3.indexOf(val2)!=-1 && ( arr2.indexOf(val2)!=-1 || arr.indexOf(val2)!=-1) ){  
		// 			SetLessProductive(arr3.splice(arr3.indexOf(val2), 1) )
		// 		}   
		// 	}else{
		// 		if(arr2.indexOf(val2)!=-1 && ( arr3.indexOf(val2)!=-1 || arr.indexOf(val2)!=-1) ){  
		// 			SetNonProductive(arr2.splice(arr2.indexOf(val2), 1))
		// 		}   
		// 	}
		// }
		
	  }
	}

function AddUserHandler(){

	let arr = [];
	let arr2=[];
	var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if(assignedTag===false){

		if(firstname==''){
			SetFirstNameError('First name is required');
		}else if(firstname.length > 255){
			SetFirstNameError('Length of first name should be less than 255 character');
		}
		 if(description.length > 255 ){
			SetDescriptionError('Length of designation should be less than 255 character ')
		}

		if(firstname!='' &&firstname.length <= 255     && description.length<=255 ){
				SetFirstNameError('');
				SetLastNameError('')
				SetPasswordError('');
				SetEmailError('');
				SetDescriptionError('');
				SetRoleIDError('');
		
		let requestBody={};
		
		requestBody.employee_name= firstname+' '+lastname
		requestBody.description = description
		requestBody.org_id= org_id

		/* Empty Pass Old */
		requestBody.department_id=department_id;
		requestBody.sub_org_id = sub_org_id
		requestBody.emp_id=emp_id;
		requestBody.shift = shiftID
		requestBody.session=session
		requestBody.sub_dept_id= subDepartmentID
		requestBody.assign_tag="False"
		/* Empty Pass Old */
		AddUser(requestBody,file).then(res=>{							
			if(res.status==200|| res.code==200){
				SetTitle('Employee is added')
				SetDescriptionMessage('')
				SetModalSucess(true);
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescriptionMessage('Fail to add')
				SetModalError(true);
			}
		})

	}	

	}else{

	
		let requestBody={};
		
		
		requestBody.employee_name= firstname+' '+lastname
		requestBody.description = description
		requestBody.org_id= org_id
		requestBody.department_id=department_id
		requestBody.sub_org_id=sub_org_id
		requestBody.emp_id=emp_id;
		requestBody.shift = shiftID
		requestBody.session=session
		requestBody.sub_dept_id= subDepartmentID;
		requestBody.assign_tag="True"
		//requestBody.tag_serial = selectedTag==''||selectedTag=='Select tag, If need to change'?props.employeeDetail.tag_serial:selectedTag;
		if (selectedTag !== '' && selectedTag !== 'Select a tag') {
			requestBody.tag_serial = selectedTag
		  }

				AddUser(requestBody,file).then(res=>{
									
					if(res.status==200|| res.code==200){
						SetTitle(<h4>Employee is added</h4>)
						SetDescriptionMessage('')
						SetModalSucess(true);
					}else{
						SetTitle(<h4>{res.message}</h4>)
						SetDescriptionMessage('Fail to add')
						SetModalError(true);
					}
				})

	
}


	
	//props.modalOpenEmpSucess();
}

function handleCloseModalSucess(){
	props.Reload();
	props.handleCloseModal()
	SetModalSucess(false)
  }
  function handleCloseModalError(){
	SetModalError(false)
  }

  const validateEmail = (inputEmail) => {
    const validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return validRegex.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    SetEmail(inputValue);

    if (inputValue === '') {
		SetEmailError('');
    } else if (validateEmail(inputValue)) {
		SetEmailError('');
    } else {
		SetEmailError('Invalid email address');
    }
  };

  function prefilledFunction(label){
	
console.log(label);
	let emp = label.obj;
	let name = emp.name.split(" ");
	let first  = name.splice(0,1);
	let last= name.join(" ");
	SetFirstName(first.join(''));
	SetLastName(last);
	SetEMPID(emp.worker_id);	

  }

    return(
        <div>
            <div className='modalHeader'>
                    <h4>Add Employeee</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
                <div className='modalBody'>
					{/* remove || assignedTag===false  */}

				{assignedTag===true || assignedTag===false?<Steps current={current} onChange={onChangeStep}>
					{steps.map(item => (
					<Step key={item.title} title={item.title} />
					))}

				</Steps>:""}
				<select
						placeholder="Select Productive Type"
						optionFilterProp="children"
						onChange={(e,label)=>{onChangeProductivitCat(e.target.value); }}
						value={productiveID}
						className={current==2?'inlast-step form-control activeShow':'inlast-step form-control hide'}
						
					>
						<option value={''}>{'Select'}  </option>
						{productiveList.map(item=>{
							return <option value={item.id} key={"productibity"+item.id}>{item.category_name}  </option>
						})}
						
					</select>
					<form autoComplete='off'>
				{current==0?<div className="steps-content step-user-info">
					<Row>
						<Col lg={12}>
							<div className='field-row'>
									<label>Select Employee*<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
						<Select
							value={empSelected}
							onChange={(val,label)=>{SetEmpSelected(val);prefilledFunction(label)}  }
							allowClear
							showSearch
							filterOption={(input, option) => {
								console.log("input, option",input, option)
								const lowerCaseInput = input.toLowerCase();
								const workerId = option.value.toString().toLowerCase();
								const name = option.children.toLowerCase();
								return workerId.indexOf(lowerCaseInput) >= 0 || name.indexOf(lowerCaseInput) >= 0;
							}}
						>
							<Option value="">Select Employee</Option>
						{props.clientdb.map((el,index)=>{
							return <Option val={el.worker_id} obj={el} key={el.worker_id+index}>{el.name.trim()}</Option>
						})}		

						</Select>

									
							</div>	




						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<div className='field-row'>
									<label>First Name*<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={firstnameError!=''?'form-control has-error':'form-control'} value={firstname} onChange={(e)=>{if(reg.test(e.target.value)){SetFirstName(e.target.value);SetFirstNameError("")}else if(e.target.value==''){SetFirstName(e.target.value)} }  } disabled={empSelected==""} />
									{firstnameError!=''?<div className='errorMessage'>{firstnameError}</div>:""}
							</div>	
						</Col>
						<Col lg={6}>
							<div className='field-row'>
									<label>Last Name <div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={lastnameError!=''?'form-control has-error':'form-control'} value={lastname} onChange={(e)=>{if(reg.test(e.target.value)){SetLastName(e.target.value);}else if(e.target.value==''){SetLastName(e.target.value)} }  } disabled={empSelected==""} />
									{/* {lastnameError!=''?<div className='errorMessage'>{lastnameError}</div>:""} */}
							</div>	
						</Col>
						<Col lg={4}>
							<div className='field-row'>
									<label>Designation</label>
									<input  className='form-control' value={description} onChange={(e)=>{if(reg.test(e.target.value)){SetDescription(e.target.value);}else if(e.target.value==''){SetDescription(e.target.value)} }  } disabled={empSelected==""} />
									
							</div>	
						</Col>

				<Col lg={1} style={{ maxWidth: "125px" }}>
				<div className='field-row'>
					<label style={{ marginBottom: "10px" ,fontSize:13}}>Assign Tag</label>
					<Switch
					style={{ marginTop: "10px" }}
					onChange={(e) => SetAssignedTag(!assignedTag)}
					checked={assignedTag}
					disabled={empSelected==""?true: props.availableTag.length > 0? false : true}

					/>
				</div>
				</Col>


						<Col lg={4}>
							<div className='field-row'>
								<label style={{marginBottom:"10px"}}>Add Profile</label>
                                <input type="file"  id="file" placeholder="browse" accept="image/png, image/gif, image/jpeg" onChange={(e)=>loadFile (e) } disabled={empSelected==""} />
                                <label htmlFor="file" className='fileUploadStyle' disabled={empSelected==""}><span>Browse file</span><small>{file?file.name:""}</small> </label>
								{fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                            </div>
                        </Col>
						{assignedTag && (  
  <Col lg={3}>
    <div className='field-row'>
      <label style={{ marginBottom: "10px" }}>
        Select Tag <strong></strong>
        <div className='posRel'>
          <HelpIcon />
          <span className='helpSmall'>Select a new tag</span>
        </div>
      </label>

      <Select
        placeholder="Select Department"
        optionFilterProp="children"
        onChange={(val) => SetSelectedtag(val)}
        className={department_idError !== '' ? 'form-control has-error' : 'form-control'}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
		showSearch
        defaultValue={selectedTag}
		disabled={empSelected==""}
      >
        <Option value={''} key={"Tag0"}>{'Select a tag'}</Option>
        {availableTag.length > 0 && availableTag !== '' ? (
          availableTag.map((item) => {
            return (
              <Option value={item.serial_num} key={"availTag" + item.serial_num}>
                {item.serial_num}
              </Option>
            );
          })
        ) : (
          <></>
        )}
      </Select>
    </div>
  </Col>
)}
						<Col lg={4}>
							
						 {previewProfile?<div className='previewArea'>
                                <div className='icon-row profile-icon'  >
                                     
                                      <div className='headerIconProfile'><img src={previewProfile} className="avatar-size"/> </div>
                                        <div className='headerIconName'>
                                                 <span className='author-name'>{ firstname!='' &&lastname!=''?<>{firstname} {lastname}</>:'Full Name'} </span>
                                                <span className='author-name author-name-role'>{role_id!=''?<><span>Role</span>{props.roleListData.filter((item)=>item.id == role_id).length> 0?props.roleListData.filter((item)=>item.id == role_id).name:"some role"}</>:<><span>Role</span> Some Role </>}</span>
                                        </div>
                                        <span className='icon-toggle'>
                                            <UPIcon/>
                                        </span>                                        
                                </div>
                            </div>:""}
						</Col>
						
                   


					</Row>						

				</div>:""}
				{current==1?<div className="steps-content">
						<Row>
							<Col lg={6}>
							<div className='field-row'>
                      		  <label>Department*<div className='posRel'><HelpIcon /><span className='helpSmall'>Select Department want to assign for this employee</span></div></label>
								<input type="text" className='form-control' value={props.group_name} disabled="disabled" />
								</div>
							</Col>
							{subDepartment.length>0 ?<Col lg={6}>
							<div className='field-row'>
                      		  <label>Sub Department<div className='posRel'><HelpIcon /><span className='helpSmall'>Select Sub Department if want to assign any</span></div></label>
                                <Select
									placeholder="Select Sub Department"
									optionFilterProp="children"
									onChange={onChangeSubDepartment}
									
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={subDepartmentID}
									className='textCap'
								>
									{subDepartment.map((item)=>{
										return <Option value={item.id} key={"SubDepartment"+item.id}>{item.sub_dept_name.toLowerCase()}</Option>
									})
									}
									
								</Select>
								</div>
							</Col>:''}

					<Col lg={6}>
							<div className='field-row'>
                      		  <label>Employee ID<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
                                <input type="text" className={emp_idError!==''?'form-control has-error':'form-control'} onChange={(e)=>{if(reg.test(e.target.value)){SetEMPID(e.target.value);SetEMPIDError("")}else if(e.target.value==''){SetEMPID(e.target.value)} }  } value={emp_id}  readOnly />
								{emp_idError!=''?<div className='errorMessage'>{emp_idError}</div>:""}
								</div>
							</Col>
													
							<Col lg={6}>
							<div className='field-row'>
							<label>Shift<div className='posRel'><HelpIcon /><span className='helpSmall'>Shift work time of employee is required</span></div></label>
                                <Select
									placeholder="Select Time"
									optionFilterProp="children"
									onChange={onChangeShift}
								
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={shiftID}
								>
									{shift.map(item=>{
										return <Option value={item.id} key={item.shift_name}>{item.shift_name} {  item.start_time.split(':')[0]+':'+item.start_time.split(':')[1] } - {item.end_time.split(':')[0]+':'+item.end_time.split(':')[1]} </Option>
									})}
									
									{/* <Option value={'other'} key={'other'}>Create</Option> */}
								</Select>
								{shiftNameError!=''?<div className='errorMessage'style={{lineHeight:1}}>{shiftNameError}</div>:""}
								</div>
							</Col>	
							<Col lg={6}>
						{/* {userDetails.org_type === "oem" ? (
    <div className='field-row'>
      <label style={{ marginBottom: "10px" }}>Select Sub Organisation</label>
      <Select
        placeholder="Select Sub Organisation"
        optionFilterProp="children"
        // onChange={(val,label)=>{setWorkerType(val);}}
        // className={workerType==''?'form-control has-error':'form-control '}
        // filterOption={(input, option) =>
        // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        // defaultValue={props.productiveIDData.type}
        // value={workerType}
        // key={'lcoationNameMapping'}
      >
        <Option value={"regular_worker"} key={"regular_workerdrope"}>
          {"Sensocore"}
        </Option>
        <Option value={"daily_worker"} key={"regular_workerdrope"}>
          {"traceplus"}
        </Option>
      </Select>
    </div>
  ) :("")} */}
						</Col>
							{shiftToggle?<Col lg={6} className="shiftInResponsive">

							<Row>
							<Col lg={3}>
								<label>Shift Name</label>
								<input type="text" value={shiftName} onChange={(e)=>{if(reg.test(e.target.value)){SetShiftName(e.target.value);}else if(e.target.value==''){SetShiftName(e.target.value)} }  } className='form-control'  />
								{shiftNameError!=''?<div className='errorMessage'style={{lineHeight:1}}>{shiftNameError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy">
								<label>Start Time</label>
								<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker

											value={shiftStartTime}
											onChange={(newValue) => {
												SetStartTime(newValue);
											}}
											ampm={false}
											renderInput={(params) => <TextField {...params} />}

										/>
										</LocalizationProvider>
										{shiftStartTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftStartTimeError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy">
								<label>End Time</label>
								<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker
											value={shiftEndtTime}
											onChange={(newValue) => {
												SetEndTime(newValue);
											}}
											ampm={false}
											renderInput={(params) => <TextField {...params} />}
										/>
										</LocalizationProvider>
										{shiftEndtTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftEndtTimeError}</div>:""}
							</Col>
							<Col lg={3} className="shiftcreatebutton" >
								<div className='btn btn-submit' style={{marginTop:"20px"}} onClick={CreateShiftHandler}>Create Shift</div>
							</Col>
							
						</Row>
						{shiftMessage!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftMessage}</div>:""}
					</Col>:""}
						</Row>
				</div>:""}

				</form>
				<div className="steps-action">
					{/* {assignedTag===false && (
					<Button type="primary" onClick={AddUserHandler} className={'btn btn-primary'}>
						Add Employee
					</Button>
					)} */}
							{/* eleminate assignedTag==false */}
				{current > 0 && ( assignedTag==true || assignedTag==false )  && (
					<Button style={{ margin: '0 8px' }} onClick={() => prev()} className={'btn btn-primary'}>
						Back
					</Button>
					)}

					{current < steps.length - 1&& ( assignedTag==true || assignedTag==false ) && (
					<Button type="primary" onClick={() => next()} className={'btn btn-primary'}>
						Next
					</Button>
					)}
					{current === steps.length - 1 && ( assignedTag==true || assignedTag==false ) && (
					<Button type="primary" onClick={AddUserHandler} className={'btn btn-primary'}>
						Finish
					</Button>
					)}
				</div>
                </div>
				<SucessModal modalSucess={modalSucessShift} handleCloseModalSucess={handleCloseModalSucessShift} title={title} description={descriptionMessage} key="addemployeeShift" />
				<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage}  key={'addEMployee'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}  key={'addEMployeeError'} />

        </div>
    )
}
export default AddEmployee;