import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'
import '../styles/dashboard.scss'





const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '90%'
    },
};

function BoxStats(props) {
        
        return(
            <div className='boxDashboardStats' onClick={()=>props.history.push(props.redirect)}>
                <div  className='headerStats'>
                    <div className='biggerFont' style={{color:props.color!=null?props.color:"#000" }}>{props.count}</div>
                    <div className='smallerFont'>{props.title}</div>
                </div>
                <div className='contentAreaStats'>
                    {props.subStats.map(data=>{
                       return <div className='rowStats' key={"subStats"+data.subtitle}> {data.subtitle} <span className='subCount'>{data.count}</span> </div>
                    })}
                                    </div>
            </div>
        )
}

export default BoxStats;

