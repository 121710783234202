import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import PageList from './PageList';
import { useHistory } from 'react-router-dom';
import { getRoleDetail, removePage,  getPageList, getSubPageList, removeSubPage} from '../actionMethods/actionMethods'
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import AddPage from './AddPage';
import EditPage from './EditPage';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function WebAppPageManagement(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

  const [modalGroup,SetModalGroup]= useState(false);   

  const [modalPage,SetModalPage]= useState(false);   
  const [modalPageEdit,SetModalPageEdit]= useState(false);   
  const [modalAddEmp,SetModalAddEmp]= useState(false);   
  const [modalAddEmpEdit,SetModalAddEmpEdit]= useState(false);   
  const [modalDelete,SetModalDelete]= useState(false);   
  const [modalSubDelete,SetModalSubDelete]= useState(false);   
  const [dataGroup,SetDataGroup] = useState([]);  
  const [searchPage,SetSearchPage] = useState("");
  const [permission,SetPermission]=useState([])
  const [permissionAPICall,SetPermissionAPICall]=useState(false);
  const [deleteID,SetDeleteID] =useState('');
  const [title,SetTitle]=useState('');
  const [description,SetDescription]= useState('');

  const [modalSucess,SetModalSucess]= useState(false);
  const [modalAddPage,SetModalAddPage]= useState(false);
  const [modalError,SetModalError]= useState(false);
  const [inc,setInc]=useState(0);

  const [tyepModal,SetTypeModal]= useState("");
  const [page_id,SetPageID] = useState("");
  const [page_name,SetPageName]= useState("");
  const [sub_page_name,SetSubPageName]= useState("");
  const [sub_page_id,SetSubPageID]= useState("");
  const [version,SetVersion] = useState("");
  const [versionURL,SetVersionUrl] = useState("");
  const [pageAll,SetPageAll] = useState([])
  const [loader,SetLoader] = useState(true);
  useEffect(()=>{
 
   if((userDetails.org_type && userDetails.org_type!=="oem") || !userDetails.firstLoad ) {
        history.push('/dashboard');
   }
  },[])
  useEffect(() => {

        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].role))

                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()

    }, []);
  
    
  useEffect(() => {

        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        let arr = []
        getPageList(requestBody).then(res => {
            if(res.status==200 || res.code ==200){
                

                getSubPageList(requestBody).then(res2=>{
                    if(res2.status==200 || res2.code ==200){
                        
                        let pageParent= res.webpages;
                        let childPages = res2.sub_webpages;
                        

                        for(let i=0;i<pageParent.length;i++){
                            let subCurrent= childPages.filter(el=>el.page_id ==pageParent[i].page_id )
                            arr.push({...pageParent[i],sub_pages:subCurrent})
                            
                        }

                    }

                SetPageAll(arr);
                SetLoader(false);

                })

            }
        })

    }, [inc]);


    function handleCloseModal(){
        SetTypeModal("");
        SetModalPage(false)
        SetModalGroup(false)
        SetModalAddEmp(false)
        SetModalPageEdit(false)
    }
    
    function openAddVersion(name){ 

        SetTypeModal('Version');
        SetPageName(name);
        SetModalAddPage(true);
    }
    function openAddSubPage(name,id){ 
        SetTypeModal('Sub Page');
        SetPageName(name);
        SetPageID(id)
        SetModalAddPage(true);
    }

    function openModalEditPage(data,type){
        SetPageID(data.page_id);
        SetPageName(data.page_name);
        SetVersion(data.version);
        SetVersionUrl(data.url);
        if(type!=""){
            SetTypeModal(type)
        }
        
        SetModalPageEdit(true);
    }
    
    function openModalEditSubPage(data,type){

        SetPageID(data.page_id);
        SetSubPageID(data.sub_page_id)
        SetPageName(data.page_name);
        SetSubPageName(data.sub_page_name)
        SetVersion(data.version);
        SetVersionUrl(data.url);
        if(type!=""){
            SetTypeModal(type)
        }
        
        SetModalPageEdit(true);
    }

    function openAddSubPageVersion(name,id,sub_page_name){

        SetTypeModal('Sub Page Version');
        SetPageName(name);
        SetSubPageName(sub_page_name)
        SetPageID(id)
        SetModalAddPage(true);
    }

    function modalOpenAddEmp(){
        SetModalAddEmp(true)
    }


    function modalOpenPageEdit(){
        SetModalPageEdit(true)
    }

    function modalDeleteModal(title,id){
        SetDeleteID(id);
        SetPageID(id)
        SetTitle(<><strong>{title}</strong> Page</>); 
        SetModalDelete(true)
    }

    function modalDeleteModalSub(title,id){
        SetDeleteID(id);
        SetSubPageID(id)
        SetTitle(<><strong>{title}</strong> Page</>); 
        SetModalDelete(true)
    }

    function modalDeleteSubPage(title,id){

        SetDeleteID(id)
        SetSubPageID(id);
        SetTitle(<><strong>{title}</strong> Page</>)
        SetModalDelete(true);
    }

    

    
    function handleCloseModalWarning(){
        SetModalDelete(false);
        SetPageID("");
        SetPageName("");
        SetSubPageID("");
        SetSubPageName("");
    }

function AddCreateHandler(){

    history.push('/add-Page');
}


    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.page_id=page_id
        requestBody.org_id = userDetails.org_id
        requestBody.session = userDetails.session
        if(sub_page_id!=""){
            handleDeleteConfirmSubPages();
        }else{
        removePage(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                setInc((prev)=>prev +1)
                SetTitle(title);
                SetDescription(res.message?res.message:'');
                SetModalSucess(true);
                SetModalDelete(false);    
                SetPageID('');
                
            }else{
                SetTitle("Failed to delete");
                SetDescription(res.message?res.message:'');
                SetModalError(true);
                //SetModalDelete(false);
            }
            
        })
        }
    

      //  SetModalDelete(false);
    }
    function handleDeleteConfirmSubPages(){
        let requestBody={};
        requestBody.sub_page_id=sub_page_id
        requestBody.org_id = userDetails.org_id
        requestBody.session = userDetails.session
    
        removeSubPage(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                setInc((prev)=>prev +1)
                SetTitle(title);
                SetDescription(res.message?res.message:'');
                SetModalSucess(true);
                SetModalDelete(false);    
                SetSubPageID("")
            }else{
                SetTitle("Failed to delete");
                SetDescription(res.message?res.message:'');
                SetModalError(true);
                //SetModalDelete(false);
            }
            
        })
    }

    function OpenAddPageModal(){
        SetModalAddPage(true)
    }

    function handleCloseModalSucess(){
        setInc((prev)=>prev + 1)
        SetModalSucess(false);
    }
    function handleCloseModalError(){
        SetModalError(false);
    }
    function reload(){
        setInc((prev)=>prev + 1 );
    }
    


    return (
         <div className="main-content side-content pt-0">

<div className="container-fluid">
{permissionAPICall && permission.View=='True'?
    <div className="inner-body">


    <div className="page-header">
            <Row>
            <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                
                <Col lg={6} className="header-btn">
                <input type="text" className='form-control' placeholder="Search Page" value={searchPage} onChange={(e)=>SetSearchPage(e.target.value) } style={{width:200,display:"inline-block"}} />
               
                    {permission && permission.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={OpenAddPageModal}><PlusIcon/> Add Page</button>:<button className='btn btn-large btn-header btn-transparent btn-icon disabled hover-message disabled' disabled><PlusIcon/> Add Page<span className='messageTag'>Access Restricted</span></button>}
                    
                </Col>
                </Row>
    </div>

    <PageList 
        dataGroup={dataGroup}
         modalOpenAddEmp={modalOpenAddEmp}
         modalOpenPageEdit={modalOpenPageEdit}
         modalDeleteModal={modalDeleteModal}
         modalDeleteSubPage={modalDeleteSubPage}
         searchPage={searchPage}
         permission={permission}
         {...props}         
         OpenAddPageModal={OpenAddPageModal}
         inc={inc}
        page_name={page_name}
        openAddSubPage={openAddSubPage}
        openAddSubPageVersion={openAddSubPageVersion}
        openAddVersion={openAddVersion}
        openModalEditPage={openModalEditPage}
        openModalEditSubPage={openModalEditSubPage}
        pageAll={pageAll}
        loader={loader} 
        modalDeleteModalSub={modalDeleteModalSub}
        
        reload={reload}

    />   
    <ReactModal 
        isOpen={modalAddPage}
        style={customStyles2}
    >
        <AddPage 
            handleCloseModal={()=>SetModalAddPage(false)} 
            page_name={page_name} 
            tyepModal={tyepModal}
            SetTypeModal={()=>SetTypeModal} 
            page_id={page_id}
            sub_page_name={sub_page_name}
            reload={reload}
        />
    </ReactModal>

        <ReactModal 
        isOpen={modalPageEdit}
        style={customStyles2}
    >
        <EditPage 
            handleCloseModal={()=>SetModalPageEdit(false)} 
            page_name={page_name} 
            tyepModal={tyepModal}
            SetTypeModal={()=>SetTypeModal} 
            page_id={page_id}
            sub_page_name={sub_page_name}
            version={version}
            versionURL={versionURL}
            reload={reload}
            sub_page_id={sub_page_id}
        />
    </ReactModal>

        

    </div>:permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}   
</div>

           
           


            {/* Modal SUb Page Delete */}
            {/* <ReactModal
                isOpen={modalDeleteSubPage}
                style={customStyles}
             >
                <div className='dangerDeleteZone'>
                   <div className='deleteZoneHeader'>
                 
                         <h2>Delete</h2>
                </div>
                <div className='deleteZoneContent'>
                    Are you sure you wan't to delete <br/> <span style={{color:"#c52f2f"}}>Name of delete item</span>
                </div>
                <div className='deleteZoneAction'>
                    <button className='btn btn-transparent' onClick={()=>SetModalSubDelete(false)}>No</button>
                    <button className='btn btn-primary' onClick={()=>SetModalSubDelete(false)}>Yes, Delete</button>
                </div>
                </div>
            </ReactModal> */}


            <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
            <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
           
           
           

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(WebAppPageManagement))
