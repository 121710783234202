import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withRouter ,useHistory} from "react-router-dom";
import { connect } from 'react-redux';
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import TimezonePicker from '../timezonepicker/TimeZonePicker'
import {CreteOrg, CreteSubOrg} from '../actionMethods/actionMethods'
import {Switch } from 'antd';
import ReactModal from 'react-modal';
import {Radio, Button,Steps,Select } from 'antd';
import moment from 'moment';
// const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

// const urlRegex = /(http(s)?://.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const { Option } = Select;
function Createorg(props) {
	const history = useHistory();
    const [loader,SetLoader] =useState(false);
	const [timezone, setTimezone] = useState('');
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    const { Step } = Steps;
	const [current, setCurrent] = React.useState(0);
  const reg = /^\S.*$/;
	
	const [switchValue, setSwitchValue] = useState("False");
    const[orgname,Setorgname]= useState("");
    const [title,SetTitle] =useState('');
	const [descriptionMessage,SetDescriptionMessage] = useState('')
  const [modalSucess,SetModalSucess] = useState(false);
	const [modalSucessShift,SetModalSucessShift] = useState(false);
	const [modalError,SetModalError] = useState(false);
  const [suborg, setSuborg] = useState(false);
    const[orgdescription,Setorgdescription] =useState("");
    const[orgurl,Setorgurl] = useState("");
    const[suborgname,SetSuborgname]=useState("")
    const[utctimezone,Setutctimezone] =useState("");
    const[utctimezonename,Setutctimezonename] =useState("");
    const [org_name,Setorg_name]=useState('');
    const [descriptionError,SetdescriptionError]=useState('')
    const [logoError,SetlogoError]=useState('')
    const [org_nameError,Setorg_nameError]=useState('');
    const [Start_hourError,Setstart_hourError]=useState('');
    const [End_hourError,Setend_hourError]=useState('');
    const [hoverText, setHoverText] = useState('');
    const [timezoneData, setTimezoneData] = useState({
      utc_hr: '',
      utc_min: '',
      timezone: ''
    });
    localStorage.removeItem('timezonename')
   const[requestorgid,setrequestorgid] =useState("")
   const [startHour, setStartHour] = useState(null);
   const [endHour, setEndHour] = useState(null);
 
   const formatHour = (hour) => {
     return `${hour < 10 ? '0' : ''}${hour}:00:00`;
   };
 
   const handleStartHourChange = (value) => {
     setStartHour(value);
   };
 
   const handleEndHourChange = (value) => {
     setEndHour(value);
   };
	const handleSwitchClick = (props) => {
		// setSwitchValue(prevValue => {
		
		// 	return !prevValue;
		// });
        if(switchValue == "True"){
            setSwitchValue("False")
            return false
        }else{
            setSwitchValue("True")
            return true
        }
	};
  const handleOrgUrlChange = (e) => {
    const inputUrl = e.target.value;

    if (inputUrl === '') {
      Setorgurl(inputUrl);
      SetlogoError('Logo URL is required');
    } else if (!urlRegex.test(inputUrl)) {
      Setorgurl(inputUrl);
      SetlogoError('Invalid Logo URL');
    } else {
      Setorgurl(inputUrl);
      SetlogoError('');
    }
  };
  


  const handleSwitchClick1 = () => {
    setSuborg(!suborg);
  };
    
  // useEffect(() => {
  //   // Define a function to handle updating hoverText
  //   const updateHoverText = (event) => {
  //     // Access the hover text from the global variable
  //     const updatedHoverText = window.hoverText;
  //     if (updatedHoverText) {

  //       console.log("Hover Text:", updatedHoverText,event);
  //       setHoverText(updatedHoverText);
  //     } else {
  //       setHoverText("");
  //       console.log("Hover text not available");
  //     }
  //   };
  //   // Add an event listener for the custom event 'hoverTextChanged'
  //   document.addEventListener('hoverTextChanged', updateHoverText);
  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('hoverTextChanged', updateHoverText);
  //   };
  // }, [])
    useEffect(()=>{
    
                const id2 = setInterval(function() {
                  const timeZone = localStorage.getItem('timeZone') ? JSON.parse(localStorage.getItem('timeZone')) : {};
                  if (timeZone.timezone && timeZone.timezone !== timezoneData.timezone) {
                    const now = moment.utc();
                    const timezone1 = moment(now).tz(timeZone.timezone).format('Z');
                    const timezone = timeZone.timezone;
                    const timezoneHour = String(timezone1).split(':')[0].substring(1);
                    const timezoneMinute = String(timezone1).split(':')[1];
                    setTimezoneData({
                      timezoneMinute: timezoneMinute,
                      timezone: timezone,
                      timezoneHour: timezoneHour,
                    }); 
                  } else {   
                  }
                }, 1000);
            const id =	setInterval(function(){
                var elem = document.querySelectorAll('.timezone-map polygon');
                var elem2 = document.querySelectorAll('.select2-results__option');
                for(var i = 0; i < elem2.length; i++) {
                  elem2[i].addEventListener('click', function(e){
                    //var contactName = e.currentTarget
                });
                  //clearInterval(id);
                }
                for(var i = 0; i < elem.length; i++) {
                    clearInterval(id);
                    //var zone_name1 =  moment.tz.guess();
                    elem[i].addEventListener('click', function(e){
                        //var contactName = e.currentTarget
                        var now = moment.utc();
                        var timezone1 = moment(now).tz(e.currentTarget.dataset.timezone).format('Z');
                        console.log("data-timezone" ,e.currentTarget.dataset,moment.tz(e.currentTarget.dataset.timezone).format('dd-mm-yyyy hh:mm:ss')
                     );
                     let timezone = e.currentTarget.dataset.timezone;
                    // console.log('timezone: ', timezone);
                    //let timezoneHour =  moment.tz(e.currentTarget.dataset.timezone).format('hh');
                    let timezoneHour = String(timezone1).split(':')[0].substring(1);
                    //console.log('timezoneHour: ', timezoneHour);
                    //let timezoneMinute = moment.tz(e.currentTarget.dataset.timezone).format('mm');
                    let timezoneMinute = String(timezone1).split(':')[1];
                    // console.log('timezoneMinute: ', timezoneMinute);
                     setTimezoneData({
                      timezoneMinute: timezoneMinute,
                      timezone: timezone,
                      timezoneHour: timezoneHour,
                    });            
                    }, false);
                }
                //console.log("hello");
            },1000)
            return () => {
                clearInterval(id);	
                clearInterval(id2)
              };
        
          },[timezoneData,timezone])
  
    function AddOrghandler(event){
//console.log(AddOrghandler)
        event.preventDefault();
  
        if(orgname==''|| orgname.trim().length==0){
          Setorg_nameError('org Name is required');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }else if(orgname.length > 255){
          Setorg_nameError('org Name length should be less than 255 character');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }else if(startHour === ""){
          Setstart_hourError("Start hour is required")
        }
        else if(endHour === ""){
          Setend_hourError("End hour is required")
        }
        else if(orgname!=''){
        //   SetLoader(true);
          let requestBody={}
          requestBody.org_name=orgname.trim()
          requestBody.description=orgdescription
          requestBody.logo_url= orgurl
          requestBody.start_hour=startHour;
          requestBody.end_hour=endHour
          // requestBody.utc_hr= utctimezone.split(':')[0].substring(1)
          // requestBody.utc_min= utctimezone.split(':')[1]
          // requestBody.timezone= utctimezonename
          // requestBody.utc_min = String(timezoneData.timezoneMinute);
          // requestBody.timezone = timezoneData.timezone;
          // requestBody.utc_hr = String(timezoneData.timezoneHour);
          if (String(timezoneData.timezoneHour) != ""){
          //  console.log("timezone",timezoneData,timezone)
            //requestBody.utc_hr = timezone.split(' ')[1].split(':')[0].substring(1);
            requestBody.utc_hr = timezoneData.timezoneHour
          }
          else{
            requestBody.utc_hr = String(timezoneData.timezoneHour);
          }

          if (String(timezoneData.timezoneMinute) != "undefined"){
            requestBody.utc_min = String(timezoneData.timezoneMinute);
            }
            else{
              requestBody.utc_min = timezone.split(' ')[1].split(':')[1];
            }

          if ( timezoneData.timezone != ""){
            requestBody.timezone = timezoneData.timezone;
            }
            else{
              requestBody.timezone = utctimezonename;
            }
          requestBody.org_settings= {"multi_session" : switchValue}
          requestBody.session= userDetails.session
          requestBody.org_id = userDetails.org_id
  //console.log(requestBody)
          CreteOrg(requestBody).then(res=>{
           
            //    SetLoader("False");
              if(res.status==200){
                setrequestorgid(res.org_id)
            //  console.log("suborg",res.org_id)
                if (suborg) {
                  let requestBody3={}
                  requestBody3.org_id=res.org_id
                  requestBody3.sub_org_name=suborgname
                  requestBody3.session=session
                  CreteSubOrg(requestBody3).then(res=>{
                    if(res.status==200|| res.code==200){ 
                      props.modalSucessHandler(<h4>Organization is created with Sub-Org</h4>)
                      SetModalSucess(true)
                    }else{
                      SetTitle("Org created Sub-org not created "+String(res.message));
                      SetModalError(true)
                      props. modalErrorHandler(<h4>Org created Sub-org not created</h4>,<h4>{+String(res.message)}</h4>)
                     props.SetModalError(true)
                    }
                  }).catch();
                  }else{
              props.modalSucessHandler(<h4>Organization is created</h4>)
             props.SetModalSucess(true);
            //   SetLoader(true)

                  }
              }else{
            props. modalErrorHandler(<h4>{res.message}</h4>,'Org is not create have some issue')
           props.SetModalError(true)
            // SetLoader(false)
              }
              if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)
             }
              
  
          })
          
        }else{
          SetLoader("False");
          Setorg_nameError('Required');
        }
  
  
  
      }
      const steps = [
		{
		  title: 'Organization Info',
		  step: '01',
		  content: 'First-content',
		},
		{
		  title: 'TimeZone',
		  step: '02',
		  content: 'Second-content',
		},
    {
		  title: 'Working Day',
		  step: '03',
		  content: 'Third-content',
		},
		
	  ];
      
  //     function onChangeStep(step){
	// 	var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	// 	if(current==0){
	// 		if(orgname==''){
	// 		Setorg_nameError('org name is required');
	// 		}else if(orgname.length > 255){
	// 			Setorg_nameError('Length of first name should be less than 255 character');
	// 		}
	// 		else if(orgdescription==''){
	// 			SetdescriptionError('org description is required');
	// 		}
  //     else if(orgurl==''){
	// 			SetlogoError('Logo url  is required');
	// 		}
  //     // else if(password.length> 32){
	// 	// 		SetEmailError('');
	// 	// 		SetPasswordError('Length of password should be less than 32 character ');
	// 	// 	}
	// 	// 	else if(description.length> 255){
	// 	// 		SetPasswordError('');
	// 	// 		SetDescriptionError('Length of designation should be less than 255 character ');
	// 	// 	}
	// 	// 	else{
	// 	// 		setCurrent(step);	
	// 	// 	}

	// 	else{
	// 		setCurrent(step);
	// 	}
		
	//   }

  // }
  function onChangeStep(step) {
    // Assuming you have already declared validRegex and other variables above
  
    if (current === 0) {
      if (orgname === '') {
        Setorg_nameError('org name is required');
      } else if (orgname.length > 255) {
        Setorg_nameError('Length of organization name should be less than 255 characters');
      } else if (orgdescription === '') {
        SetdescriptionError('org description is required');
      } else if (orgurl === '') {
        SetlogoError('Logo URL is required');
      } else if(!urlRegex.test(orgurl)){
        SetlogoError("Invalid logo url")
      }
      
      else {
        setCurrent(step);
      }
    } else {
      setCurrent(step);
    }
  }
  // function getCursor(event){
  //   let x = event.clientX;
  //   let y = event.clientY;
  //   let _position = `X: ${x}<br>Y: ${y}`;
  //   const infoElement = document.getElementById('infoHover');
  // //  infoElement.innerHTML = _position;
  // infoElement.style.opacity="1"
  //   infoElement.style.top = y + "px";
  //   infoElement.style.left = (x + 0.1) + "px";
  //   setTimeout(function(){
  //     infoElement.style.opacity="0"
  //   },3000);

  // }
    return(
        <>
       
        
        {loader ? (<div className="loaderContainer loaderContainerSmall"><img src={LoaderSpinner} /></div>
        ) : (<>
            <div className='modalHeader'>
                    <h4>Create Organization</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
            <div className="modalBody">
            <Steps current={current} onChange={onChangeStep}>
					{steps.map(item => (
					<Step key={item.title} title={item.title} />
					))}

				</Steps>
                {current==0?<div className="steps-content step-user-info">
                <Row>
						<Col lg={6}>
							<div className='field-row'>
              <label>Organization Name</label>
                            <input
                             className={org_nameError!=''?'form-control has-error':'form-control'}
                                type="text"
                                // className="form-control input-field"
                                // onChange={(e)=>{{Setorgname(e.target.value)}}}
                                value={orgname}
                                //onChange={(e)=>{if(reg.test(e.target.value)){Setorgname(e.target.value)}else if(e.target.value==''){Setorgname(e.target.value)} }  }
                                onChange={(e) => {
                                  Setorgname(e.target.value); // Always update orgname
                                  if (e.target.value !== '') {
                                
                                    Setorg_nameError('');
                                  }
                                }}
                            />
                            {org_nameError!=''?<div className='errorMessage'>{org_nameError}</div>:""}
							</div>	
						</Col>
						<Col lg={6}>
							<div className='field-row'>
              <label>Organization Description</label>
                            <input
                                type="text"
                                className={descriptionError!=''?'form-control has-error':'form-control'}
                                // className="form-control input-field"
                                // onChange={(e)=>{{Setorgdescription(e.target.value)}}}
                                value={orgdescription}
                                //onChange={(e)=>{if(reg.test(e.target.value)){Setorgdescription(e.target.value)}else if(e.target.value==''){Setorgdescription(e.target.value)} }  }
                                onChange={(e) => {
                                  Setorgdescription(e.target.value); // Always update orgdescription
                                  if (e.target.value !== '') {
                                    // Clear the error message when the user starts typing
                                    SetdescriptionError('');
                                  }
                                }}
                            />
                            {descriptionError!=''?<div className='errorMessage'>{descriptionError}</div>:""}
							</div>	
						</Col>

						<Col lg={6}>
							<div className='field-row'>
              <label>Organization Logo URL</label>
                            {/* <input
                             className={logoError!=''?'form-control has-error':'form-control'}
                                type="text"
                                // className="form-control input-field"
                                // onChange={(e)=>{{Setorgurl(e.target.value)}}}
                                value={orgurl}
                                onChange={(e)=>{if(reg.test(e.target.value)){Setorgurl(e.target.value)}else if(e.target.value==''){Setorgurl(e.target.value)} }  }  
                            /> */}
                            <input
        className={logoError !== '' ? 'form-control has-error' : 'form-control'}
        type="text"
        value={orgurl}
        onChange={handleOrgUrlChange}
      />
                            {logoError!=''?<div className='errorMessage'>{logoError}</div>:""}
							</div>	
						</Col>

						<Col lg={2}>
							<div className='field-row'>
              <label style={{marginBottom:"5px"}}>Multi Session</label>
                                        <Switch  style={{marginLeft:"20px"}} onClick={handleSwitchClick} value={switchValue} />
							</div>	
						</Col>
            <Col lg={2}>
							<div className='field-row'>
              <label style={{marginBottom:"5px"}}>sub organization</label>
                                        <Switch  style={{marginLeft:"20px"}} onClick={handleSwitchClick1} value={suborg} />
							</div>	
						</Col>
            {suborg && (  
            <Col lg={6}>
							<div className='field-row'>
              <label>Sub Organization Name</label>
                            <input
                             className={'form-control'}
                                type="text"
                                // className="form-control input-field"
                                // onChange={(e)=>{{Setorgurl(e.target.value)}}}
                                value={suborgname}
                                onChange={(e)=>{if(reg.test(e.target.value)){SetSuborgname(e.target.value)}else if(e.target.value==''){SetSuborgname(e.target.value)} }  }  
                            />
							</div>	
						</Col>)}
					</Row>	
                    </div>:""}
                    <div className={current==1?"steps-content":"hide"}style={{display:current==1?"block":"none"}}>
                    {/* <p>Hover Text: {hoverText}</p> */}
                    <div id="infoHover" style={{position:'absolute',width:'auto',background:"rgb(0,0,0,0.7)",color:"#fff",padding:"10px",borderRadius:"5px",marginRight:"30px"}}>{hoverText}</div>
          <div className='timeZoneArea'  style={{height:"450px"}}></div>

      </div>
      {current==2?<div className="steps-content step-user-info">
                <Row>
						<Col lg={6}>
							<div className='field-row'>
              <label>Start Hour</label>
              <Select
        placeholder="Start Hour"
        onChange={handleStartHourChange}
        className={'form-control'}
        value={startHour}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <Option key={i} value={i+":00:00"}>
            {formatHour(i)}
          </Option>
        ))}
      </Select>
      {Start_hourError!=''?<div className='errorMessage'>{Start_hourError}</div>:""}
							</div>	
						</Col>
						<Col lg={6}>
							<div className='field-row'>
              <label>End Hour</label>
              <Select
        placeholder="End Hour"
        onChange={handleEndHourChange}
        className={'form-control'}
        value={endHour}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <Option key={i} value={i+":00:00"}>
            {formatHour(i)}
          </Option>
        ))}
      </Select>
  {End_hourError !=''?<div className='errorMessage'>{End_hourError}</div>:""}
							</div>	
						</Col>


					</Row>	
                    </div>:""}
                <div className="steps-action">
                {/* <button type="submit" className="btn btn-primary"  onClick={AddOrghandler} >Next Step</button> */}

                {/* <button type="submit" className="btn btn-primary btn-50"  onClick={onChangeStep(1)} >Next Step</button> */}
                {current > 0 && (
					<Button style={{ margin: '0 8px' }} onClick={() => setCurrent(prev=>prev-1)} className={'btn btn-primary'}>
						Back
					</Button>
					)}
                    {current < steps.length - 1 &&  (
					<Button type="primary" onClick={() => onChangeStep(current + 1)} className={'btn btn-primary'}>
						Next
					</Button>
					)}

					{current == steps.length -1 &&  (
					<Button type="primary" onClick={(event) => AddOrghandler(event)} className={'btn btn-primary'}>
						Create Organization
					</Button>
					)}
	
                </div>
                
            
            </div> 
            
            </>)}
     
              
    </>
    
    )
}
const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Createorg))