import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../style/styles.scss';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import AddDataElements from './AddDataElements';
import {getRoleDetail} from '../../rolemanagement/actionMethods/actionMethods'
import AddDataElements2 from './AddDataElement2';

function GeoMapManagement(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:"";

  const [permission,SetPermission]=useState([]);
  const[permissionAPICall,SetPermissionAPICall] =useState(false);


    useEffect(() => {
        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id= org_id
        requestBody.role_id = userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].geo_map_admin_permissions));
                console.log("set",JSON.parse(res.data[0].geo_map_admin_permissions))
                SetPermissionAPICall(true)
            }
            if(res.status==300){
              localStorage.removeItem('isLoggedInGatewayTraceAdmin')
              localStorage.removeItem('userLoginDetailsTraceAdmin')
              props.history.push(`/login`)        
          }
        }).catch()

    }, []);




    


    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    {permission.View=='True' && permissionAPICall?<div className="inner-body">


        <AddDataElements/>

    </div>:""}
    {/* <AddDataElements2 permission={permission}/> */}
    {permission.View=='False' && permissionAPICall?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}
</div>

           

           
           
           

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(GeoMapManagement))
