import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreteProductive(requestBody) {
    return axios.post(prefixURL + `/employee/create_productivity_category` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeProductive(requestBody) {
    return axios.post(prefixURL + `/employee/remove_productivity_category` , requestBody).then(res=>res.data).catch(err=>err)
}

export function UpdateProductive(requestBody) {    
    return axios.post(prefixURL + `/employee/update_productivity_category` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getProductivityList(session,org_id,sub_org_id) {    
    return axios.get(prefixURL + `/employee/get_productivity_category?session=${session}&org_id=${org_id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
}

export function getDepartmentList(session,org_id,sub_org_id) {

    return axios.get(prefixURL + `/department/department_list?session=${session}&org_id=${org_id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
}
