import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import  '../../../sitemanagement/styles/style.scss';

import moment from 'moment';
import { Checkbox, Divider,AutoComplete,Select  } from 'antd';
import {ReactComponent as ArrowDown} from  '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../../assets/images/square-plus.svg'
import { getDeviceReport,getAllEmployeeList,getAllLocationList,unAssignLocationTag,unAssignEmployeeTag,assignTag,unAssignTemporaryTag } from '../../actionMethods/actionMethods';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoaderSpinner from  '../../../assets/images/Spinner Loader.gif'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};



function DeviceList(props){
    const { Option } = Select;
    const [defaultDevice,SetDefaultDevice] = useState([])
    const [deviceList,SetDeviceList] =useState([]);
    //const dropdownOpt = ['Personal','Location',"Gateway","Visitor","Asset","Truck"]
    const [dropdownOpt,SetDropDownOpt] = useState([]);
    const [sort,SetSort] = useState('desc');
    const [deviceSearch,SetDeviceSearch] = useState('')
    const [tagType,SetTagType] = useState('');
    const [sortKey,SetSortKey] = useState('');
    const [loader,SetLoader] = useState(true);  
    const [apiCall,SetAPICall] = useState(false);
    const [activeAccord,SetActiveAccord] =useState([]);
    const [WidthContainer,setWidthContainer]=useState('');
    
    const [modalSucess,SetModalSucess] =useState(false);
    const [modalRemoveTag,SetModalRemoveTag] =useState(false);
    const [modalAssignTag,SetModalAssignTag] =useState(false);

    const [modalError,SetModalError] = useState(false);
    const [title,SetTitle]=useState('');
    const [description,SetDescription] =useState('');
    const [tag_serial,SetTagSerial] = useState('');

    const [locationList,SetLocationList]=useState([])
    const [assetList,SetAssetList]=useState([])
    const [visitorList,SetVisitorList]=useState([])
    const [employeeList,SetEmployeeList]=useState([])

    const [assignID,SetAssignID] =useState('')
    const [assignIDError,SetAssignIDError] =useState('')
    const [inc,SetInc]=useState(1);
    const elementRef = useRef(null);   
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:"";

    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }, [elementRef]);

    function handleCloseModalSucess(){
        let increment= inc +1;
        SetInc(increment);
        SetModalAssignTag(false);
        SetModalSucess(false)
    }
    function handleCloseModalError(){
        SetModalError(false);
    }

    useEffect(()=>{
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=org_id
        getDeviceReport(requestBody).then(res=>{

        let arr=[]
        let arr2 = []
        let arr3= []
        
         if(res.status==200|| res.code==200){
            //SetDefaultDevice(res.data)
            for(let i=0;i<res.data.length;i++){
                if(res.data[i].type){
                    res.data[i].tag_type=res.data[i].type;
                }
            }
            for(let i=0;i<res.data.length;i++){
                let element= res.data[i];

                element.assigned = element.loc_name?element.loc_name:element.emp_name
                element.assigned = element.assigned?element.assigned:'' 
                

                if( arr2.indexOf(res.data[i].tag_type ) < 0 ){
                    arr2.push(res.data[i].tag_type)
                    let daF = res.data.filter((item) => {
                        return item.tag_type.toString().toLowerCase().includes(res.data[i].tag_type.toLowerCase());})                         
                        arr3.push({tag_type:res.data[i].tag_type,data:daF,count:daF.length})
                }
                
                else{

                }

                arr.push(element)
            }
            SetDeviceList(arr3) 
            SetDefaultDevice(arr3)
            SetAPICall(true);
            SetLoader(false);
         }else{
            SetLoader(false);
         }   
        })
    },[inc])

    useEffect(()=>{
        let data = [...defaultDevice];
        let arr=[];

        for(let i=0;i<data.length; i++){
            
        if( data[i].tag_type.toLowerCase().includes(deviceSearch.toLowerCase())){
            arr.push(data[i]); 
        }else{

            if(data[i].data.length > 0){
                let dt = data[i].data.filter((item) => {
                    return item.tag_serial.toString().toLowerCase().includes(deviceSearch.toLowerCase()) || 
                        item.tag_serial.toString().toLowerCase().includes(deviceSearch.toLowerCase()) ||                 
                            item.assigned.toString().toLowerCase().includes(deviceSearch.toLowerCase()             
                    ) ;     
            })   
            if(dt.length > 0){
                arr.push({tag_type:data[i].tag_type,data:dt,count:dt.count})
            }
        }
            

        }
    }




    SetDeviceList(arr);
    },[deviceSearch])
    function onChange(val){

    
            let arr = [...defaultDevice];
            arr =  arr.filter((item) => {
                return item.tag_type.toString().toLowerCase().includes(val.toLowerCase());
            
            }) 
            if(val=='Asset'){
                arr =  arr.filter((item) => {
                    return item.tag_type.toString().toLowerCase().includes('truck');
                
                })  

            }


            SetTagType(val)
            SetDeviceList(arr);
    }
    function ChangeSorting(sortVal){
        SetSortKey(sortVal);
        SetSort(sort=='desc'?'asc':'desc')
    }

    useEffect(()=>{
        let data = [...deviceList]
        let arr=[];

        if(sortKey=='tag_type'){

            arr = data.sort((a, b) => {
                a = a.tag_type.toUpperCase()
                b = b.tag_type.toUpperCase()
                return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
            })
            SetDeviceList(arr);
        }
        
        if(sortKey=='tag_count'){
            arr = data.sort(function (x, y) {
                return sort === 'desc' ?y.count - x.count:x.count-y.count;
            });
            SetDeviceList(arr); 
        }
        


    },[sortKey,sort])

    function AssignTagHandlerOpen(data){
      
        SetAssignID('')
        SetAssignIDError('');
        SetTagSerial(data.tag_serial);
        SetTagType(data.tag_type);

        SetModalAssignTag(true);
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id = org_id;
        if(data.tag_type=='location'){
            getAllLocationList(requestBody).then(res=>{
                if(res.status==200){
                    SetLocationList(res.data)
                }
            })
        }
        if(data.tag_type=='personal'){
            requestBody.employee_type = 'employee'
            getAllEmployeeList(requestBody).then(res=>{
                if(res.status==200){
                    SetEmployeeList(res.data)
                }
            })
        }
        if(data.tag_type=='asset'){
            requestBody.employee_type = 'asset'
            getAllEmployeeList(requestBody).then(res=>{
                if(res.status==200){
                    SetEmployeeList(res.data)
                }
            })
        }

    }

    function AssignTagHandler(){

        let requestBody={};
        requestBody.session= session
        requestBody.org_id= org_id
        requestBody.new_tag_serial= tag_serial
        requestBody.type=tagType
        if(assignID!=''){

            SetAssignIDError('');
        
        
        if(tagType=='location'){
            requestBody.location_id=assignID;
        }else if(tagType=='asset'|| tagType=='personal'){
            requestBody.employee_id = assignID
        }

        assignTag(requestBody).then(res=>{
            if(res.status==200){
                SetTitle(<h4>Tag Assign Sucessful</h4>)
                SetDescription(res.message)
                SetModalSucess(true)
                SetModalAssignTag(false)
            }else{
                SetTitle(<h4>{res.message}</h4>)
                SetDescription('fail to assign tag')
                SetModalError(true)
                //SetModalAssignTag(false)
            }
        })


    }else if(tagType==='temporary'){

        assignTag(requestBody).then(res=>{
            if(res.status==200){
                SetTitle(<h4>Tag Assign Sucessful</h4>)
                SetDescription(res.message)
                SetModalSucess(true)
                SetModalAssignTag(false)
            }else{
                SetTitle(<h4>{res.message}</h4>)
                SetDescription('fail to assign tag')
                SetModalError(true)
                //SetModalAssignTag(false)
            }
        })
    }else{

        SetAssignIDError('required');
    }
        


        //SetModalAssignTag(false);
    }

    function RemoveTagHandlerOpen(data){

        
        SetTagSerial(data.tag_serial);
        SetTagType(data.tag_type)  
        
        
        if(data.tag_type=='location'){
            SetAssignID(data.loc_id);    

        }else if(data.tag_type=='personal'|| data.tag_type=='asset'){
            console.log('data in Assign',data)
            SetAssignID(data.employee_id);
        }else{
            SetAssignID("");
        }
        SetModalRemoveTag(true);
    }
    function RemoveTagHandler(){
        let requestBody={};
        requestBody.session = session;
        requestBody.org_id  = userDetails.org_id;

        if(tagType=='location'){

            requestBody.location_id= assignID
            requestBody.existing_tag_serial= tag_serial

            unAssignLocationTag(requestBody).then(res=>{

                if(res.status==200){
                    SetTitle(<h4>Tag is Unassigned</h4>)
                    SetDescription(res.message)
                    SetModalSucess(true);
                    SetModalRemoveTag(false)
                }else{
                    SetTitle(<h4>{res.message}</h4>)
                    SetDescription('Fail to remove tag')
                    SetModalError(true);
                    //SetModalRemoveTag(false)

                }
            })
            
        }
        if(tagType=='personal'|| tagType=='asset'){
            requestBody.existing_tag_serial= tag_serial
            requestBody.employee_id= assignID
            unAssignEmployeeTag(requestBody).then(res=>{

                if(res.status==200){
                    SetTitle(<h4>Tag is Unassigned</h4>)
                    SetDescription(res.message)
                    SetModalSucess(true);
                    SetModalRemoveTag(false)
                }else{
                    SetTitle(<h4>{res.message}</h4>)
                    SetDescription('Fail to remove tag')
                    SetModalError(true);
                    //SetModalRemoveTag(false)
                }
            })


        }

        if(tagType=='temporary'){
            requestBody.existing_tag_serial= tag_serial
            unAssignTemporaryTag(requestBody).then(res=>{

                if(res.status==200){
                    SetTitle(<h4>Tag is Unassigned</h4>)
                    SetDescription(res.message)
                    SetModalSucess(true);
                    SetModalRemoveTag(false)
                }else{
                    SetTitle(<h4>{res.message}</h4>)
                    SetDescription('Fail to remove tag')
                    SetModalError(true);
                    //SetModalRemoveTag(false)
                }
            })

        }
    }


    function EdiButtonRender(params){
        
        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            AssignTagHandlerOpen(params.data)

          }else if(event.target.getAttribute("data-action")=='delete'){
            RemoveTagHandlerOpen(params.data);
          }
        });
    
        /*
        if(params.data.tag_serial =='02CD55B76CBE7B' || params.data.tag_serial=='01CD5E981C0655'){
            eGui.innerHTML=`<div class="element-icon ant-table-cell enable-btn">
                <button class="btn btn-edit btn-smaller btn-icon ${props.permission && props.permission.edit=='True'?'acessEnable':'hover-message disabled'}" data-action="edit" data-el="${params.data.tag_serial}" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Assign Tag <span class='messageTag'>Access Restricted</span></button>   
                 </div>`;
          }
      else */
     
      if(params.data.battery!=='na' && params.data.tag_type=="temporary"){
         eGui.innerHTML=`<div class="element-icon ant-table-cell enable-btn btn_new">
                                <button class="btn btn-edit btn-smaller btn-icon ${props.permission && props.permission.remove=='True'?'acessEnable':'hover-message disabled'}" data-action="delete" data-el="${params.data.tag_serial}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Disassociate Tag <span class='messageTag'>Access Restricted</span></button>
                            </div>`;
      }
     else if(params.value =='active'){
                    eGui.innerHTML=`<div class="element-icon ant-table-cell enable-btn">
                                
                                <button class="btn btn-edit btn-smaller btn-icon ${props.permission && props.permission.remove=='True'?'acessEnable':'hover-message disabled'}" data-action="delete" data-el="${params.data.tag_serial}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Disassociate Tag <span class='messageTag'>Access Restricted</span></button>
                            </div>`;
      }else{
        eGui.innerHTML=`<div class="element-icon ant-table-cell enable-btn">
            <button class="btn btn-edit btn-smaller btn-icon ${props.permission && props.permission.edit=='True'?'acessEnable':'hover-message disabled'}" data-action="edit" data-el="${params.data.tag_serial}" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Assign Tag <span class='messageTag'>Access Restricted</span></button>

             </div>`;
      }
         return eGui;

    }


    function AccordionHandler(val,type='secondary'){
        
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        }   
        else if(type=='primary'){
            SetActiveAccord([val]);
        } 
        else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }
    
    }
    function TextCapRender(params){
        

        var op =document.createElement('div');

        var eGui = document.createElement('div');
            
         eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+params.value+'</div>';
      
         return eGui;


    }

    function TextCapRenderAssigned(params){
        var eGui = document.createElement('div');

        if(params.data.tag_type=='location'){
            eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+params.data.loc_name.toLowerCase()+'</div>';
        }
        else if(params.data.emp_name){
            eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+params.data.emp_name.toLowerCase()+'</div>';
        }else{
            eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">-</div>';
        }
      
         return eGui;
    }
    function TextStatusStyle(params){
        var eGui = document.createElement('div');

        if(params.data.status == 'active'){
            eGui.innerHTML= "<div class='statusHighlight sucess'><span></span>Active</div>";
        }else{
            eGui.innerHTML= "<div class='statusHighlight fail'><span></span>Inactive</div> ";
        }
        return eGui;
    }

    function ShowCardDevice(){
        let arr= []
        let data = [...deviceList]
    
        let index = 1;
        if(data.length >0 ){
        for(let i=0;i<data.length; i++){
            const headerHeight = 50; // Height of the header
  const rowHeight = 50; // Height of each row
  const totalHeight = headerHeight + (rowHeight * data.length);
            //data[key].reported_time
            arr.push(
                <Row key={'contentGroup'+i} className={activeAccord.indexOf(i)!==-1 || deviceSearch.length > 0?'contentGroup active':'contentGroup'}>
                <div className='group-header'> 
                 <Col lg={6} className="align-middle">
                     {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                     {data[i].data.length>0?<span className='accordion-icon' onClick={()=>AccordionHandler(i,'primary')} >
                       {activeAccord.indexOf(i)!==-1?<CircleArrowDown/>:<CircleArrow />}   
                     </span>:""}
                     <span className='align-title-left'>
                     <h4 className='textCap' >{data[i].tag_type.toLowerCase()}</h4>
                    </span>
                 </Col>
                 <Col lg={6} className="align-middle roledataCount" style={{textAlign:"center"}}>{data[i].count}</Col>
                 </div>            
                   {data[i].data.length>0?
                   <div className={activeAccord.indexOf(i)!==-1 || deviceSearch.length > 0?'group-content active':'group-content'} >
                        <div className="ag-theme-alpine" style={{ height: `${totalHeight}px`, width: '100%'}}>
               <AgGridReact
                   rowData={data[i].data}
                   defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    flex:1,
                                    width: ( WidthContainer - 200)/3,   
                                    height:40,
                                    headerHeight:40
                                }}
                >
                    <AgGridColumn 
                        field="tag_serial"
                        headerName={"Tag Serial"}
                        cellRenderer= {(params)=>TextCapRender(params)}
                    ></AgGridColumn>
                        <AgGridColumn 
                        field="tag_type"
                        headerName={"Assigned to"}
                        valueFormatter={(params)=>params.data.tag_type=='location'?params.data.loc_name.toLowerCase():params.data.emp_name!=null?params.data.emp_name.toLowerCase():"-" }
                        cellRenderer= {(params)=>TextCapRenderAssigned(params)}
                    ></AgGridColumn>
                    <AgGridColumn 
                        field="tag_type"
                        headerName={"Tag Type"}
                        cellRenderer= {(params)=>TextCapRender(params)}
                     ></AgGridColumn>

                    <AgGridColumn 
                        field="battery"
                        headerName={"Battery"}
                        
                    ></AgGridColumn>
                    <AgGridColumn   
                        field="status"
                        headerName={"Status"}
                        cellRenderer= {(params)=>TextStatusStyle(params)}
                    ></AgGridColumn>
                    {/* {data[i].tag_type=='personal' || data[i].tag_type=='location' || data[i].tag_type=='asset'? */}
                    <AgGridColumn   
                        field="status"
                        headerName={"Action"}
                        cellRenderer= {(params)=>EdiButtonRender(params)}
                    ></AgGridColumn>
                    {/* :""} */}
               </AgGridReact>
           </div>                 
                 </div>
                 :''}
             </Row>
            )
    index++;
                }
            }else if(apiCall===false){
                
            }
            else{
               arr.push( 
                        <Row className='contentGroup nofound' key={'contentGroup nofound'}>
                            <div className='group-header'>
                                <Col className='align-middle'>
                                    <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                                </Col>
                            </div>
                        </Row>
                        ) 
            }
        return arr;
    }

return(
    
    <div className='roleList deviceManagement'>
        
       <Row className='page-header headerCategory headerGroup page-header ' style={{border:"0px"}}>
           
        <Col lg={6} style={{minHeight:"5px"}}></Col>
        <Col lg={6} style={{padding:"0px",textAlign:"right",float: 'right'}} className={'header-btn'}>
        <input type="text" className='form-control' placeholder="Search ..." value={deviceSearch} onChange={(e)=>SetDeviceSearch(e.target.value) } style={{width:200,display:"inline-block",marginRight:"15px"}}  />
        {/* <Select
                placeholder="Select Tag Type"
                optionFilterProp="children"
                onChange={onChange}
                className="ft-algin-end"
                style={{maxWidth:200}}   
            >
				{dropdownOpt.map((element)=>{
					return (
						<Option value={element} key={element}>{element}</Option>
							)
				})}
            </Select> */}
            </Col>
        </Row>   
        


    <div className='br-seperator-row'>   

        <Row className='headerCategory headerGroup' ref={elementRef}>
            <Col lg={6} className="align-middle" >
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_type')} >Tag Type
                <span className={sortKey=='tag_type'?'filter-icon active':'filter-icon'}>{sortKey=='tag_type'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col> 
            <Col lg={6} className="align-middle" style={{textAlign:"center"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_count')} >Count
                <span className={sortKey=='tag_count'?'filter-icon active':'filter-icon'}>{sortKey=='tag_count'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
                
                </Col>
        </Row>    
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:ShowCardDevice()}

        </div> 
        
        <ReactModal
                isOpen={modalRemoveTag}
                style={customStyles}
                className='modalremove'
                // onRequestClose={handleCloseModal}
                key={'modalRemoveTag'}
              >
                   
              <h2>Are You sure you want to remove tag</h2>
              <p>This action is non-reversible all detail associated to this tag wil be delete permanently</p>
              <button className='btn btn-transparent' style={{width:"120px"}} onClick={RemoveTagHandler}>Yes</button><button className='btn btn-transparent' style={{width:120}} onClick={()=>SetModalRemoveTag(false)}>No, Go Back</button>
            </ReactModal>
            <ReactModal
                isOpen={modalAssignTag}
                style={customStyles}
                className='modalAssign'
                key={'modalAssignTag'}
              >
                <div className='modalHeader'>
                    <h4>Assign Tag</h4>
                    <div className='closeMark' onClick={()=>SetModalAssignTag(false)}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                            <label>Tag Serial</label>
                            <input type="text" className='form-control' value={tag_serial} disabled   />
                    </div>
                    <div className='field-row'>
                        
                    {tagType=="temporary"?"":<label>Assign to</label>}
                    {tagType=='location'?
                     <Select
                        placeholder="Assign to"
                        onChange={(val)=>SetAssignID(val)}
                        className={assignIDError!=''?'form-control has-error':'form-control'}
                      >
                        {locationList.map((element)=>{
                            return (
                                <Option value={element.id} key={"location"+element.id}>{element.name}</Option>
                                    )
                        })}
                    </Select>:""} 
                    {tagType=='asset'||tagType=='personal'?
                     <Select
                        placeholder="Assign to"
                        onChange={(val)=>{SetAssignID(val)}}
                        className={assignIDError!=''?'form-control has-error':'form-control'}
                      >
                        {employeeList.map((element)=>{
                            return (
                                <Option value={element.id} key={tagType+element.employee_id}>{element.name}</Option>
                                    )
                        })}
                    </Select>:""} 
                    </div>

                    <button className='btn btn-primary btn-100' onClick={AssignTagHandler}>Submit</button>    
                </div>
            </ReactModal>    



        <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />

       </div>    
)

}
export default DeviceList;
