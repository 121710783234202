import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { Select } from 'antd';
import '../../styles/addTeam.scss';
import {Checkbox, Button} from 'antd';
import { PropertyKeys } from 'ag-grid-community';
import Scrollbars from 'react-custom-scrollbars';
import { getLocationList } from '../../../sitemanagement/actionMethods/actionMethods';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as UPIcon} from '../../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'
import LoaderSpinner from  '../../../assets/images/Spinner Loader.gif'
import {ReactComponent as ArrowDown} from  '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import { CreateTeam } from '../../actionMethods/actionMethods';
import { toast,ToastContainer } from "react-toastify";
const { Option } = Select;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};



function AddSupervisor(props) {
	const [historyEnable,SetHistoryEnable]= useState(false);
	const [name,SetName]=useState([])
	const [nameError,SetNameError]=useState([])
	const [loader,SetLoader]=useState(true);
	const [activeAccord,SetActiveAccord] =useState([]);
    const [listEmp,SetListEmp]=useState(props.teamlist) 
	 const [listsub,Setlistsub] = useState(props.sub)
	
	const [manualSelected,SetManualSelected] = useState([])

	const [selectedEmp,SetSelectedEmp] =useState([])
	const [selectedEmpError,SetSelectedEmpError] =useState('')
	const [deptSelected,SetDeptSelected]=useState([])
	const [subdeptSelected,SetSubDeptSelected]=useState([])
	const [teamLeaderID,SetTeamLeaderID]=useState('')
	const [teamLeaderError,SetTeamLeaderError]=useState('')

	const [title,SetTitle]=useState('')
	const [description,SetDescription]=useState('')
	const [modalSucess,SetModalSucess]=useState(false)
	const [modalError,SetModalError]=useState(false)

	const [activeTab,SetActiveTab] =useState('regular');
	const [teamData,SetTeamData]= useState([]);

	
	const [searchEmp,SetSearchEmp] =useState('');
	const [searchEmpList,SetSearchEmpList] =useState([]);
	const [searchEmpToggle,SetSearchEmpToggle] =useState(false);

	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""


	function onChangeEmployee(val,dept,subdept,sub){
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	if(val.target.checked ){
		let old= [...selectedEmp];
		old.push(sub)
		old = [...new Set(old)];
		SetSelectedEmp(old);
	
		let checkdept= old.filter(item=>item.team_id  == dept)
		let Allcheckdept= listEmp.filter(item=>item.team_id == dept)
		
		let checksubdept= old.filter(item=>item.team_leader_id === subdept)
		let Allchecksubdept= sub.filter(item=>item.team_leader_id === subdept)
		
		if(checkdept.length==Allcheckdept.length){
			cbox.push(dept)
			SetDeptSelected(cbox)
		}
		if(checksubdept.length==Allchecksubdept.length){
			subcbox.push(subdept)
			SetSubDeptSelected(subcbox)
		}

	}else{
		let allemp = selectedEmp.length>0?selectedEmp:[]
		let index = allemp.findIndex((item)=>item.team_leader_id == sub.team_leader_id);
		if(index > -1){
			let all2 =allemp.splice(index, 1);
			SetSelectedEmp(allemp)
			let manual = allemp.map(item=> {return item.emp_id})
			SetManualSelected(manual)
	
		}
		if(subcbox.indexOf(subdept)> -1){
			//subcbox = subcbox.splice(subcbox.indexOf(subdept),1)		
			//delete subcbox[subcbox.indexOf(subdept)];	
			subcbox.splice(subcbox.indexOf(subdept),1)
			SetSubDeptSelected(subcbox);
		}
		
		if(cbox.indexOf(dept)> -1){
			//delete cbox[cbox.indexOf(dept)];	
			cbox.splice(cbox.indexOf(dept),1)		
			SetDeptSelected(cbox)
		}

	}


}

function onChangeEmployee2(emp){
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	let allemp = selectedEmp.length>0?[...selectedEmp]:[]
	let index = allemp.findIndex((item)=>item.team_leader_id == emp.team_leader_id);
	let index2 = cbox.findIndex((item)=>item == emp.team_id);
	let index3 = subcbox.findIndex((item)=>item == emp.team_leader_id);

		if(index > -1){			
			allemp.splice(index,1);
		}
		SetSelectedEmp(allemp);
			
		if(index2 > -1){
			// delete cbox[index2];
			cbox.splice(index2,1)
			SetDeptSelected(cbox)
		}
		if(index3 > -1){
			//delete subcbox[index3];
			subcbox.splice(index3,1);
			SetSubDeptSelected(subcbox);
		}else{
		}	
}

function AccordionHandler(val,type='secondary'){
	let ActivList = [...activeAccord];
	let index = ActivList.indexOf(val)
	if(index !== -1){  
		ActivList.splice(index, 1);
		SetActiveAccord(ActivList);
	} 
	else if(type=='primary'){
		SetActiveAccord([val]);
	 }    
	else {  
		ActivList.push(val);
		SetActiveAccord(ActivList);
	}	
}


function onChangeDepartmentSelect(val, team_id, team) {
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	
	if (val.target.checked) {
	  SetDeptSelected([...deptSelected, team_id]);
	  let selEmp = [...selectedEmp];
	  SetSubDeptSelected([...subcbox]);
	  
	  selEmp.push(...team);
	  let seen = new Set,
    result = selEmp.filter((value) => !seen.has(value) && seen.add(value));
	  //console.log("First Parent Checked",selEmp,result);
	  SetSelectedEmp(result);
	} else {
		let selEmp = [...selectedEmp];
		cbox.splice(cbox.indexOf(team_id),1);
		SetDeptSelected(cbox);

		/// remove SelectedEMp now
		for(let i=0;i<team.length;i++){
			let index = selEmp.findIndex(item=>item.team_leader_id == team[i].team_leader_id);
			selEmp.splice(index,1);
		}

		SetSelectedEmp(selEmp);

	}
  }
  


const onChangeSubDepartmentSelect = (event, teamId,teamLeaderName) => {
	let checked = event.target.checked;
	
	let selEmp = [...selectedEmp];
	let selDept = [...deptSelected];
	let subcbox = [...subdeptSelected]

	if(checked) {
		selEmp.push(teamLeaderName);
		let lengthofTeam=props.teamlist.find(item=>item.team_id == teamId)?.team.length;
		let lengthofSelectedTeam=selEmp.filter(item=>item.team_id == teamId).length;
	
		SetSelectedEmp([...selEmp]);
		if(lengthofSelectedTeam == lengthofTeam  && selDept.indexOf(teamId) === -1 ){
			selDept.push(teamId);
		}

	} else {
		let index = selEmp.findIndex(item=>item.team_leader_id == teamLeaderName.team_leader_id);
		selEmp.splice(index,1)
		selDept.splice(selDept.indexOf(teamId),1);
	}
	SetSelectedEmp(selEmp);
	SetDeptSelected(selDept);

  };

function AddTeamHandler(){
	// if(name==''){
	// 	SetNameError('Field is Required')
	// }
	if(teamLeaderID==''){
		SetTeamLeaderError('Field is Required')
	}
	if(selectedEmp.length==0){
		SetSelectedEmpError('Employee Selection is Required for Create Team')
	}
	if(teamLeaderID!='' &&selectedEmp.length> 0){
		SetSelectedEmpError('')
		SetSelectedEmpError('')
		let requestBody={};
		// requestBody.team_name = name
		// requestBody.team_leader_user_id= teamLeaderID.toString()
		requestBody.supervisor_user_id = teamLeaderID.toString()
		requestBody.team_leaders_list = {team_leaders_list : selectedEmp.map((item)=> {if(item){return item.team_leader_id} })}
		requestBody.org_id= org_id
		requestBody.session = userDetails.session
		requestBody.sub_org_id=sub_org_id
	

		CreateTeam(requestBody).then(res=>{
			if(res.status==200){
				SetTitle(<h4>Supervisor is created</h4>)
				
				SetDescription(res.message)

				props.Reload();
				SetModalSucess(true);
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescription('Failed to create team')
				SetModalError(true)
			}
		}).catch(err=>{
			toast.error("TA:71 something went wrong in creating team")
		})
	}                                                                                       
}

	function ShowGroupCardList(data){
		let arr=[]
		
	let data2 = [...data]
		if(data2.length > 0){

			for(let i=0;i<data2.length;i++){
			
			arr.push( 
				<Row className={activeAccord.indexOf(i+1)!==-1 ?'contentGroup active':'contentGroup'} key={'department'+data2[i].name.toLowerCase()}>
				<div className='group-header'> 
				<Col lg={10} className="align-middle md-1">
					<span className='accordion-icon' onClick={() => AccordionHandler(data2[i].name)}>
					{activeAccord.indexOf(data2[i].name) !== -1 ? <CircleArrowDown /> : <CircleArrow />}
					</span>
					<span className='align-title-left'>
					<div className={[...selectedEmp].filter((item) => item.team_id == data2[i].team_id).length > 0 ? 'inline partialSelected' : 'inline'}>
						<Checkbox checked={deptSelected.indexOf(data2[i].team_id) > -1} onChange={(event) => onChangeDepartmentSelect(event, data2[i].team_id,  data2[i].team)} />
					</div>
					<div className='inline'><h4 className='textCap'>{data2[i].name.toLowerCase()}</h4></div>
					</span>
				</Col>
				<Col lg={2} className="align-middle" style={{textAlign:"center"}}>{data2[i].team_id}</Col>
				</div>
				<div className={activeAccord.indexOf(data2[i].name) !== -1 ? 'group-content group-sub-content active' : 'group-content group-sub-content'}>
				{data2[i].team.map((leaderName, index) => (
					<Row className={`contentGroup ${activeAccord.indexOf(leaderName.team_leader_name) !== -1 ? 'contentGroup contentGroupSubCat active' : 'group-content group-sub-content'} mb-2`} style={{width:"96%",marginLeft:"3%"}} key={`SubDepart${leaderName}_${index}`}>
					<div className='group-header group-header-subCat'>
						<span className='align-with-title' style={{width:"10px"}}></span>
						<Col lg={10} className="align-middle ">
						<span className='align-title-left'>
							{/* <div className={[...selectedEmp].filter((item) => item.dept_id == data2[i].team_leader_id[index]).length > 0 ? 'inline partialSelected' : 'inline'}>
							<Checkbox checked={deptSelected.indexOf(data2[i].team_id) > -1 || subdeptSelected.indexOf(data2[i].team_leader_id[index])> -1 } onChange={(event) => onChangeSubDepartmentSelect(event, data2[i].team_id,data2[i].team_leader_id[index],data2[i].team_leader_name[index])}/>
							</div> */}
							{console.log("here is  some logic",[...selectedEmp],deptSelected)}
				<div className={[...selectedEmp].filter((item) => item.team_leader_id == leaderName.team_leader_id).length > 0 ? 'inline partialSelected' : 'inline'}>
				<Checkbox checked={deptSelected.indexOf(data2[i].team_id) > -1 || selectedEmp.find(item=>item.team_leader_id == leaderName.team_leader_id)  } onChange={(event) => onChangeSubDepartmentSelect(event, data2[i].team_id,leaderName)}/>
				{/* <Checkbox checked={selectedEmp.some(emp => emp.team_leader_id === leaderName.team_leader_id && deptSelected.indexOf(data2[i].team_id) > -1)} onChange={(event) => onChangeSubDepartmentSelect(event, data2[i].team_id, leaderName)} /> */}

				</div>

							<div className='inline'><h4 >{leaderName.team_leader_name.toLowerCase()}</h4></div>  
						</span>
						</Col>
						<Col lg={2} className="align-middle" style={{textAlign:"center"}}>{leaderName.team_leader_id}</Col>
					</div>
					</Row>
				))}
				</div>
				</Row>

				)
			}
		}else{
			arr.push( 
				<Row className='contentGroup nofound'>
					<div className='group-header'>
						<Col className='align-middle'>
							<h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
						</Col>
					</div>
				</Row>
				) 
		}
		return arr;

	}


function ShowGroupCardListEmployee(data){
	if(data.length>0){
		let arr=[];
		for(let i=0;i<data.length;i++){
			let item=data[i];
			arr.push(
				<div className='employeee' key={"employeedTop"+item.team_leader_id+i}>
					<span>{item.team_leader_name}</span> 
					<div className='removeEmp' onClick={()=>onChangeEmployee2(item)}>x</div>
					
				</div>
			)
		}
		return arr;	
	}
}



useEffect(() => {
	if (searchEmp.length > 0) {
	  let list = [...props.teamlist];
	  let filter = list.filter((item) =>
		item.team_leader_name.some((name) =>
		  name.toLowerCase().includes(searchEmp.toLowerCase().trim())
		)
	  );
	  SetSearchEmpList(filter);
	  
	  SetSearchEmpToggle(true);
	}
  }, [searchEmp]);
  
  

	return (<>
		<div className='policyTypeTeam'>
		<ToastContainer
                    autoClose={3000}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnFocusLoss={false}
                    pauseOnHover={false}
                    width={"500px"}
                    toastStyle={{ backgroundColor: "#fff" }}    
                />
			<div className='headerAreaTeam'>
				<h4>Add Superviosr</h4>
					<div className='SearchArea'>

						<input type="placeholder"className='searchFieldheaderRight form-control' placeholder='Search Teamleader...' value={searchEmp} onChange={(e)=>SetSearchEmp(e.target.value)}/>
						
				{searchEmpToggle?
  <div className='Search'>
    <div className='searchCLickCLose' onClick={()=>{SetSearchEmpToggle(false);SetSearchEmp('');}}></div>
    <div style={{zIndex:999,position:"relative"}}>
      <Scrollbars style={{maxHeight:"200px",height:searchEmpList.length>0? searchEmpList.length *22:20}}>
        {searchEmpList.length> 0? searchEmpList.map(item=>{
          return (
            <>
              {item.team.map(name => (
                <div className='emplistSearch'>
                  <Checkbox checked={selectedEmp.findIndex(el=> el.team_leader_id==name.team_leader_id) > -1} onChange={(event)=>onChangeEmployee(event,name.team_id,name.team_leader_id,name)} />
                  {name.team_leader_name}
                </div>
              ))}
            </>
          )
        }):<div className='emplistSearch emplistSearchNoResult'>No Team Leader found</div>}
      </Scrollbars>
    </div>
  </div>
  :""} 				
				</div>	
				 <button className='btn btn-close' onClick={()=>props.handleCloseModal()}>x</button></div>
			<div className='bodyAreaTeam'>
			<Row>
				<Col lg={7}>

					<div className='form-field'>
					<div className='leftDiv100'>
						<label>Select Supervisor <span className='required'>*</span></label>
						<Select
							placeholder="Select User"
							optionFilterProp="children"
							onChange={(val)=>SetTeamLeaderID(val)}
							className={'form-control'}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							showSearch
							value={teamLeaderID}

							//defaultValue={'Resarch & Development'}
						>								
								{props.teamleaderList.map(element=>{
									return (<Option value={element.user_id} key={element.user_name+"sub"+element.user_name}>{element.user_name.toLowerCase()}</Option>)

								})}	
								</Select>
							{teamLeaderError!=''?<div className='error'>{teamLeaderError}</div>:""}
					</div>
					
					</div>
					<div className='form-field'>
						{/* <div className='divHead'><div className={activeTab=='regular'?'listHead active':'listHead'} onClick={()=>SetActiveTab('regular') }>Regular Worker</div> <div className={activeTab=='daily_worker'?'listHead active':'listHead disable'} >Daily Worker</div> </div> */}
						<div className='divHead'><label>Select Team Leader<span className='required'> *</span></label></div>
						<Scrollbars style={{height:"360px"}}>
						{ShowGroupCardList(props.teamlist)}	
						</Scrollbars>
					</div>
				</Col>

				<Col lg={5}>
					<div className='listSelectedEMp' style={{background:"#f9f4f4",height:"460px"}}>
					{selectedEmpError!=''?<div className='error'>{selectedEmpError}</div>:""}
					<Scrollbars style={{height:"420px"}}>
						{ShowGroupCardListEmployee(selectedEmp)}
						</Scrollbars>
					</div>
				</Col>
			</Row>
			</div>
			<div className='footerAreaTeam'>

				<button type="submit"  className='btn btn-submit' style={{width:"200px"}} onClick={AddTeamHandler}>Add Team</button>
			</div>


	
		</div>
		<SucessModal modalSucess={modalSucess} handleCloseModalSucess={()=>{SetModalSucess(false);props.handleCloseModal();}} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={()=>SetModalError(false)}   title={title} description={description}  />
	</>)
    
    
}
export default AddSupervisor;