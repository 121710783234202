import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'
import '../../dashboard/styles/dashboard.scss'
import '../styles/styles.scss'

import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';

import {
        importDataTemp,
        importDataEmp,
        importDataEmpValidation,
        importDataTempValidation,
        importDataEmpTagValidation,
        importDataEmpTag,
        importDataCycleCount,
        importDataCycleCountValidation
      } from '../actionMethods/actionMethods';
      
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'

import {ReactComponent as FileDownload} from '../../assets/images/file-excel.svg'
import {ReactComponent as FileImport} from '../../assets/images/file-import.svg'
import {ReactComponent as FileExport} from '../../assets/images/file-export.svg'
import ExcelTemplate from '../../assets/working_data_template.xlsm'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
import ExcelJs from "exceljs";
import moment from 'moment';
import GenerateRegisterDaily from './Excel/GenerateRegisterDaily';
import GenerateShiftRegular from './Excel/GenerateShiftRegular';
import GenerateTagRegular from './Excel/GenerateTagRegular';
import GenerateCycleCount from './Excel/GenerateCycleCount';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};

function MassUpload(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [loader,Setloader]= useState(true);
    const [modalImportTemp,SetModalImportTemp]=useState(false);
    const [modalImportEmp,SetModalImportEmp]=useState(false);
    const [modalImportEmpTag,SetModalImportEmpTag]=useState(false);
    const [file, setFile] = React.useState("");
    const [fileError,SetFileError] =useState('');
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')
    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [inc,SetInc]=useState(0)
    const [dailyWorkerEnable,SetDailyWorkerEnable]=useState(false)
    const [empImportEnable,SetEmpImportEnable]=useState(false)
    const [empImportTagEnable,SetEmpImportTagEnable]=useState(false)
    const [errorMessage,SetErrorMessage] =useState([]);

    const [modalImportCycle,SetModalImportCycle] = useState(false);
    const [cycleImportEnable,SetCycleImportEnable] = useState(false);


    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
              
              let perm = JSON.parse(res.data[0].bulk_permissions)
                  perm.View = res.data[0].bulk_management_page;
                SetPermission(perm)
                SetPermissionAPICall(true)
                Setloader(false);
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()
        
    }, []);


    function handleCloseModalSucess(){
      SetModalImportTemp(false);
      SetModalImportEmp(false);
      SetModalSucess(false);
      // setFile([])
      SetFileError('')
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }
    






    useEffect(()=>{
    if(inc==6){
        Setloader(false);
    }
    },[inc])

    function loadFile(event){

        let arry = event.target.files[0].name.split('.');
        let lastElement = arry[arry.length - 1];
        SetDailyWorkerEnable(false);           
        if(lastElement=='xls' || lastElement=='xlsx' || lastElement=='xlsm' ){    
            setFile(event.target.files[0]);    
        }else{
            SetFileError('Only Support Excel file')
            setFile('');    
            setTimeout(function(){
               SetFileError('')
            },3000)
        }
    
    }
    
    
  function importFileHandlerTempValidation(){


    if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
      let requestBody={}
      SetLoaderImport(true);

      requestBody.session= session
      requestBody.org_id= userDetails.org_id

      importDataTempValidation(requestBody,file).then(res=>{
        if(res.status==200){
           
            SetTitle(<h4>File is Validated</h4>);
            SetDescription(res.message)
            //Set(true);
            SetDailyWorkerEnable(true)
            SetModalSucess(true)            
            
        }else{



          let arr=[]; 
          let data = res.data?res.data:[];

          let dataSubDepartment= data['department and sub department name not present']?data['department and sub department name not present']:[]
       
              Object.keys(dataSubDepartment).forEach((key) => {
          arr.push(<li className='empValidation' key={"empValidationsubdepartment"+key}><strong>{dataSubDepartment[key] }:</strong> {key} </li>) 
              }); 
          
      
          let dataDepartment= data['department name not present']?data['department name not present']:[]
          
              Object.keys(dataDepartment).forEach((key) => {
          arr.push(<li className='empValidation' key={"empValidationdepartment"+key}><strong>{key}:</strong> {dataDepartment[key]} </li>) 
              }); 
          
      
          let dataDepartmentProductivity= data['productivity category validation id not present']?data['productivity category validation id not present']:[]
          
             Object.keys(dataDepartmentProductivity).forEach((key) => {
          arr.push(<li className='empValidation' key={"empValidationDepartmentProd"+key}><strong>{key}:</strong> {dataDepartmentProductivity[key] } </li>) 
              });            
          
      
          let dataDepartmentShift= data['shift_validation id not present']?data['shift_validation id not present']:[]
          
              Object.keys(dataDepartmentShift).forEach((key) => {
          arr.push(<li className='empValidation' key={"empValidationDepartmentShift"+key}><strong>{key}:</strong> {dataDepartmentShift[key]} </li>) 
              }); 
          
          let dataTagSerial= data['tag serial not present']?data['tag serial not present']:[]
          
              Object.keys(dataTagSerial).forEach((key) => {
          arr.push(<li className='empValidation' key={"empValidationtagSerial"+key}><strong>{key}:</strong> {dataTagSerial[key]} </li>) 
              });
          
              
          let empNotExist= data['employee ids not present for this tag serial']?data['employee ids not present for this tag serial']:[]
              Object.keys(empNotExist).forEach((key) => {
                arr.push(<li className='empValidation' key={"empValidationtagSerial"+key}><strong>{key}:</strong> {empNotExist[key]} </li>) 
            });
            

            

          // let deptNameNotExist= data['department name not present']?data['department name not present']:[]
          //   Object.keys(deptNameNotExist).forEach((key) => {
          //     arr.push(<li className='empValidation' key={"empValidationnameNotExist"+key}><strong>{key}:</strong> {deptNameNotExist[key]} </li>) 
          // });
            
          SetErrorMessage(arr);
          SetTitle("Fail");                
           SetDescription('File have some error')
           SetModalError(true);          
          SetModalError(true)
        }
        SetLoaderImport(false);
      })

    }else{
      SetFileError('File is required')
    }

  }


  //This is for regular employee shift validation
  function importFileHandlerEmpValidation(){
  


    if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
      let requestBody={}
      SetLoaderImport(true);

      requestBody.session= session
      requestBody.org_id= userDetails.org_id

      importDataEmpValidation(requestBody,file).then(res=>{
        if(res.status==200){
           
            SetTitle(<h4>File is Validated</h4>);
            SetDescription(res.message)
            SetEmpImportEnable(true);
            SetModalSucess(true)            
        }else{
          
      let data =res.data?res.data:[];

      let arr=[];  
      let empNotExist = data.employee_names_not_entered_list?data.employee_names_not_entered_list:{}
      let shiftNotExist = data.shift_names_not_entered_list?data.shift_names_not_entered_list:{}
      let dateNotExist = data.date_not_entered_list?data.date_not_entered_list:{}

      Object.keys(empNotExist).forEach((key) => {
       
        arr.push(<li className='empValidation' key={"empValidationEmpnoExist"+empNotExist[key]}><strong>{key}:</strong> {empNotExist[key]} </li>) 
      }); 

      Object.keys(shiftNotExist).forEach((key) => {
        arr.push(<li className='empValidation' key={"empValidationShiftNotExist"+key}><strong>{key}:</strong> {shiftNotExist[key]} </li>) 
      }); 
      
      Object.keys(dateNotExist).forEach((key) => {
        arr.push(<li className='empValidation' key={"empValidationDateNotExist"+key}><strong>{key}:</strong> {dateNotExist[key]} </li>) 
      }); 
      
      SetErrorMessage(arr);
      SetTitle("Fail");
        SetModalError(true)
          SetDescription('File have some issue')
          SetModalError(true)
        }
        SetLoaderImport(false);
      })

    }else{
      SetFileError('File is required')
    }

  }

  function importFileHandlerEmpTagValidation(){
   

          //     let data =res.data;
     

    if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
      let requestBody={}
      SetLoaderImport(true);
      
      requestBody.session= session
      requestBody.org_id= userDetails.org_id

      importDataEmpTagValidation(requestBody,file).then(res=>{
        if(res.status==200){
           
            SetTitle(<h4>File is Validated</h4>);
            SetDescription(res.message)
            SetEmpImportEnable(true);
           // SetEmpImportTagEnable(true)
            SetModalSucess(true)            
        }else{
        
          let arr=[]; 
          let data=res.data?res.data:[]
           
          Object.keys(data).forEach((key) => {
            arr.push(<li className='empValidation' key={"empValidation"+key}><strong>{key}:</strong> {data[key]} </li>)
          });     
          SetErrorMessage(arr);
          SetTitle("Fail");
          SetDescription('File have some issue')
          SetModalError(true) 
        }

        SetLoaderImport(false);
      })

    }else{
      SetFileError('File is required')
    }
  }

  //Cycle Count Validation
  function importFileHandlerCycleCountValidation(){
   

          //     let data =res.data;
     

    if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
      let requestBody={}
      SetLoaderImport(true);
      
      requestBody.session= session
      requestBody.org_id= userDetails.org_id

      importDataCycleCountValidation(requestBody,file).then(res=>{
        if(res.status==200){
           
            SetTitle(<h4>File is Validated</h4>);
            SetDescription(res.message)
            SetCycleImportEnable(true)
            SetEmpImportEnable(true);
            SetModalSucess(true)            
        }else{
        
          let arr=[]; 
          let data=res.data?res.data[0]:[]
          
          Object.keys(data).forEach((key) => {
          
            arr.push(<li className='empValidation' key={"empValidation"+key}><strong>{key}:</strong> {data[key]} </li>)
          });     
          SetErrorMessage(arr);
          SetTitle("Fail");
          SetDescription('File have some issue')
          SetModalError(true) 
        }

        SetLoaderImport(false);
      })

    }else{
      SetFileError('File is required')
    }
  }

    function importFileHandlerTemp(){


      
      if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
        let requestBody={}
        SetLoaderImport(true);       
        requestBody.session= session
        requestBody.org_id= userDetails.org_id

        importDataTemp(requestBody,file).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

      }else{
        SetFileError('File is required')
      }

    }


    function importFileHandlerEmp(){
      

      if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
        SetLoaderImport(true);
        let requestBody={}
       
        requestBody.session= session
        requestBody.org_id= userDetails.org_id


        importDataEmp(requestBody,file).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

      }else{
        SetFileError('File is required')
      }

    }


    function importFileHandlerEmpTag(){
      

      if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
        SetLoaderImport(true);
        let requestBody={}
       
        requestBody.session= session
        requestBody.org_id= userDetails.org_id


        importDataEmpTag(requestBody,file).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

      }else{
        SetFileError('File is required')
      }

    }

    //cycle count Handler Import
    function importFileHandlerCycleCount(){
      
      if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
        SetLoaderImport(true);
        let requestBody={}
       
        requestBody.session= session
        requestBody.org_id= userDetails.org_id

      

        importDataCycleCount(requestBody,file).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

      }else{
        SetFileError('File is required')
      }

    }



    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">




        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
            permission.View=='True' && permissionAPICall?<>
        <div className='page-header'>
          <Row className='box-stat-rows' >
              <div className='btn-rightPush'>
                <span>Demo Template</span>  
                <GenerateCycleCount date={moment()}/>
                {permission.daily_employee_bulk=="True"?<GenerateRegisterDaily/>:""}
                {permission.bulk_shift=="True"?<GenerateShiftRegular/>:""}
                {permission.employee_tag_changing_bulk=="True"?<GenerateTagRegular/>:""}
              </div>
          </Row>
         </div>
        <Row className='box-stat-rows' style={{marginTop:30}}>

        


                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:
          
                <>
                {permission.bulk_shift=="True"?<div className="box-space-bottom box-space-bottom-2 col-lg-4"><div className="boxDashboardStats" style={{cursor:"pointer"}} onClick={()=>{SetModalImportEmp(true);setFile([]);SetFileError('')}}><div className="headerStats"><div className="biggerFont" ><FileImport/></div><div className="smallerFont">Mass Update Regular <br/> Employee Shift</div></div></div></div>:""}
                {permission.daily_employee_bulk=="True"?<div className="box-space-bottom box-space-bottom-2 col-lg-4"><div className="boxDashboardStats" style={{cursor:"pointer"}} onClick={()=>{SetModalImportTemp(true);setFile([]);SetFileError('')}}><div className="headerStats"><div className="biggerFont" ><FileImport/></div><div className="smallerFont">Mass Register Daily <br/> Worker</div></div><div className="noteArea"><span>Note:</span>  Registration of daily worker</div></div></div>:""}
                {permission.employee_tag_changing_bulk=="True"?<div className="box-space-bottom box-space-bottom-2 col-lg-4"><div className="boxDashboardStats" style={{cursor:"pointer"}} onClick={()=>{SetModalImportEmpTag(true);setFile([]);SetFileError('')}}><div className="headerStats"><div className="biggerFont" ><FileImport/></div><div className="smallerFont">Mass Update Regular <br/> Employee Tag</div></div></div></div>:""}
                <div className="box-space-bottom box-space-bottom-2 col-lg-4">
                  <div className="boxDashboardStats" style={{cursor:"pointer"}} onClick={()=>{SetModalImportCycle(true);setFile([]);SetFileError('')}}>
                      <div className="headerStats">
                        <div className="biggerFont" >
                          <FileImport/></div><div className="smallerFont">Mass Update <br/> Cycle Count</div></div></div></div>
                </>
                }

    
        </Row></>:<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>
        
      
      }


    </div>
</div>
{/* Mass Register Daily Worker */}
        <ReactModal
                isOpen={modalImportTemp}
                style={customStyles}
                key={'mapLocationTemp'}
              //  onRequestClose={handleCloseModal}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Register Daily Workers</h4>
                    <div className='closeMark' onClick={()=>{SetModalImportTemp(false);setFile([]);SetFileError('')} }>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'> Select file you want to import</span></div></label>
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e) } />
                            <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>

                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                       </div>
                      <span className='noteArea'></span> 
                      {!dailyWorkerEnable?loaderImport?<button className='btn btn-primary btn-100'  ><img src={Loader} />Validating file...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerTempValidation} >Validate file</button>:""}
                    {dailyWorkerEnable?loaderImport?<button className='btn btn-primary btn-100' onClick={importFileHandlerTemp} ><img src={Loader} />Importing...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerTemp} > Start Import</button>:"" }  
                </div>
        </div>
            </ReactModal>

{/* Mass Update Shift */}
            <ReactModal
                isOpen={modalImportEmp}
                style={customStyles}
                key={'mapLocation'}
              //  onRequestClose={handleCloseModal}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Update Regular Employee Shift</h4>
                    <div className='closeMark' onClick={()=>{SetModalImportEmp(false);setFile([]);SetFileError('')}}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'>Select File you want to import</span></div></label>
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e) } />
                            <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                       </div>
                       <span className='noteArea'><strong>Note:</strong> Update of Shift</span> 
                      
                       {!empImportEnable?loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} />Validating file...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmpValidation} >Validate file</button>:""}
                    {empImportEnable?loaderImport?<button className='btn btn-primary btn-100'  ><img src={Loader} />Importing...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmp} > Start Import</button>:"" }  
                    {/* {loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} /></button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmp} > Start Import</button>  }   */}
                </div>
        </div>
            </ReactModal>

{/* Update Regular Employee Tag */}
            <ReactModal
                isOpen={modalImportEmpTag}
                style={customStyles}
                key={'mapLocationEmployeeTag'}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Update Regular Employee Tag</h4>
                    <div className='closeMark' onClick={()=>{SetModalImportEmpTag(false);setFile([]);SetFileError('')}}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'>Select file you want to import</span></div></label>
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e) } />
                            <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                       </div>
                       <span className='noteArea'><strong>Note:</strong> Update of Tag</span> 
                      
                       {!empImportTagEnable?loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} />Validating file...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmpTagValidation} >Validate file</button>:""}
                    {empImportTagEnable?loaderImport?<button className='btn btn-primary btn-100'  ><img src={Loader} />Importing...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmpTag} > Start Import</button>:"" }  
                    {/* {loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} /></button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmp} > Start Import</button>  }   */}
                </div>
        </div>
            </ReactModal>

{/* Mass Update Cycle Count */}
<ReactModal
                isOpen={modalImportCycle}
                style={customStyles}
                key={'mapDailyCycleCount'}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Update Cycle Count</h4>
                    <div className='closeMark' onClick={()=>{SetCycleImportEnable(false);SetModalImportCycle(false);setFile([]);SetFileError('')}}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'>Select file you want to import</span></div></label>
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e) } />
                            <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                       </div>
                     
                     {!cycleImportEnable?loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} />Validating file...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerCycleCountValidation} >Validate file</button>:""}
                    {cycleImportEnable?loaderImport?<button className='btn btn-primary btn-100'  ><img src={Loader} />Importing...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerCycleCount} > Start Import</button>:"" }  
                    {/* {loaderImport?<button className='btn btn-primary btn-100' ><img src={Loader} /></button>:<button className='btn btn-primary btn-100' onClick={importFileHandlerEmp} > Start ImportimportFileHandlerCycleCount</button>  }   */}
                </div>
        </div>
            </ReactModal>
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal errorMessage={errorMessage} modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(MassUpload))
