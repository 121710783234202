import React from 'react'
import { Redirect, Route, withRouter ,useHistory} from 'react-router-dom'



const PrivateRoute = ({component: Component, ...rest }) => {

    let isLoggedInGatewayTraceAdmin = localStorage.getItem('isLoggedInGatewayTraceAdmin')
    let userDetails= localStorage.getItem('userLoginDetailsTraceAdmin');
    let history = useHistory();
    if(userDetails=='loggedIn'){
        localStorage.removeItem('isLoggedInGatewayTraceAdmin');
        localStorage.removeItem('userLoginDetailsTraceAdmin');
        history.push(`/login`);      
    }
    
    return (
        <Route onEnter={() => window.analytics()} {...rest} render={props =>
          
          (
          
            isLoggedInGatewayTraceAdmin && isLoggedInGatewayTraceAdmin == 'true' && userDetails!='loggedIn' ?   (
                <Component {...props} {...rest}/>
              ) : (
                <Redirect to={{
                  pathname: '/login',
                }}/>
              )
          
        )}/>
    )
}

export default (PrivateRoute) 