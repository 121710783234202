import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'

import AddTeam from './Team/AddTeam';
import EditTeam from './Team/EditTeam'
import TeamList from './Team/TeamList'
import {getRoleList} from '../../rolemanagement/actionMethods/actionMethods';

import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { getlocationType } from '../../sitemanagement/actionMethods/actionMethods';
import { getTeamDetail,getTeamLeaderList, getsuborganizationlist, removeTeam } from '../actionMethods/actionMethods';
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};
const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '1200px',
        maxHeight: '90%'
    },
};



function TeamMananagement(props) {

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:""
  let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""
  const [inc,SetInc]=useState(1)
 const [permission,SetPermission] =useState([]);
  const [permissionAPICall,SetPermissionAPICall] =useState(false);
  const [permissionDepartment,SetPermissionDepartment]=useState([])
  const [searchTeam,SetSearchTeam]=useState([])

  const [locationType,SetLocationType] =useState([]);
  const [teamModal,SetTeamModal] =useState(false);
  const [teamModalEdit,SetTeamModalEdit] =useState(false);
  const [GroupListDataDailyWorker,SetGroupListDataDailyWorker] = useState([])
  const [GroupListData,SetGroupListData] = useState([])
  const [emp,SetEmp] = useState([])
  const [sub,Setsub] = useState([])
  const [teamID,SetTeamID] =useState('');
  const [teamleaderList,SetTeamLeaderList] =useState([]);
  const [teamleaderList1,SetTeamLeaderList1] =useState([]);
  const [teamleaderID,SetTeamLeaderID] =useState([]);
  const [deleteID,SetDeleteID] =useState('');
  const [title,SetTitle]=useState('');
  const [modalDelete,SetModalDelete]= useState(false); 
  const [description,SetDescription]= useState('');
    const[suborglist,Setsuborglist] =useState([])
  const [modalSucess,SetModalSucess]= useState(false);
  const [modalError,SetModalError]= useState(false);

  useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
    }, [inc]);

    useEffect(()=>{

        let requestBody={}
        requestBody.session=session
        requestBody.org_id= org_id
        // if(String(org_id)=="30"){
    requestBody.role_id=userDetails.role
        // }else{
        //     requestBody.role_id=23
        // }
        
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200|| res.code==200){
                
                SetPermissionDepartment(JSON.parse(res.data[0].teams));
                SetPermission(res.data);
                SetPermissionAPICall(true);
            }
            if(res.status == 300 ){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }

        }).catch();

    },[])

    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id    
            requestBody.sub_org_id=sub_org_id

        getTeamLeaderList(requestBody).then(res=>{
            // console.log("res",res)
            if(res.status==200){
                SetTeamLeaderList(res.data);

            }

        })
    }
    },[permission])

    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id    

        getsuborganizationlist(requestBody).then(res=>{
         
            if(res.status==200){
                Setsuborglist(res.data);

            }

        })
    }
    },[permission])

    function Reload(){
        SetInc(inc+1);
    }  

    function handleCloseModal(){
 
    }

    function handleCloseModalEditTeam(){
        SetTeamModalEdit(false)
     }

    function modalOpenAddTeam(){
        SetTeamModal(true)
    }

    function modalOpenAddTeamEdit(id,teamID,arr){
        SetTeamLeaderID(teamID);
        SetTeamID(id);
        SetTeamModalEdit(true)
        SetTeamLeaderList1(arr)
        
    }

    // function getAllSubDepartments(arr) {
    //     const subDepartments = [];
    //     console.log("subdepartments",arr)
    //     arr.forEach(obj => {
    //       const childArr = obj.child;
        
    //       if(childArr){
    //         childArr.forEach(childObj => {

    //             const { sub_department, sub_dept_id, data } = childObj;
    //             const newSubDepartment = { sub_department, sub_dept_id, data };
    //             subDepartments.push(newSubDepartment);
    //           });
    //       }

    //     });
        
    //     return subDepartments;
    //   }
    function getAllSubDepartments(arr) {
        const subDepartments = [];
        
        arr.forEach(obj => {
          const childArr = obj.child;
      
          if (childArr) {
            childArr.forEach(childObj => {
              const { sub_department, sub_dept_id, data } = childObj;
              const newSubDepartment = { sub_department, sub_dept_id, data };
      
              // Add parent department information to the sub-department
              newSubDepartment.dept_id = obj.dept_id;
              newSubDepartment.dept_name = obj.name;
      
              subDepartments.push(newSubDepartment);
            //   console.log("subdepartments", subDepartments);
            });
          }
        });
      
        return subDepartments;
      }
      
      function modalDeleteModal(id){
        SetDeleteID(id)     
        SetModalDelete(true)
    }
    function handleCloseModalWarning(){
        SetModalDelete(false);
    }
    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.team_id=deleteID
        requestBody.org_id = userDetails.org_id
        requestBody.session = userDetails.session
        removeTeam(requestBody).then(res=>{
            if(res.status==200){
                SetInc((prev)=>prev +1)
                SetTitle(title);
                // SetDescription(res.message?res.message:'');
                SetDescription("Team is Deleted")
                
                SetModalSucess(true);
                SetModalDelete(false);    
                
            }else{
                SetTitle("Failed to delete");
                SetDescription(res.message?res.message:'');
                SetModalError(true);
                //SetModalDelete(false);
            }
        })
      //  SetModalDelete(false);
    }
    function handleCloseModalSucess(){
        SetModalSucess(false);
    }
    function handleCloseModalError(){
        SetModalError(false);
    }
    function defaultListData(arr,empList){
        SetGroupListData(arr);
        
        const subDepartments = getAllSubDepartments(arr);
        Setsub(subDepartments)
        SetEmp(empList)
    }

    function defaultListDataDailyWorker(arr,empList){
        SetGroupListDataDailyWorker(arr);
        let empl=[...emp]
        empl.push(...empList)
        SetEmp(empl)
    }

    function Reload(){
        let inc2 = inc+1
        SetInc(inc2);
    }
    function tabshift(){
    props.history.push('/supervisor-management')  
    }
    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
<div className="inner-body"></div>
    {permissionAPICall?
    
    <div className="inner-body">
<Row>
    <Col lg={8}>

 </Col>
 <Col lg={4}>

 </Col>
 </Row>
    <div className="page-header" >
       
            <Row>

                <Col lg={6}>
                <Button className='btn btn-large btn-header  active'>Team Management</Button>
                <Button className='btn btn-large btn-header btn-transparent' onClick={()=>tabshift()}>Supervisor Management</Button>
                </Col>
                
                <Col lg={6} className="header-btn">
                    <input type="text" className='form-control' placeholder="Search Team" value={searchTeam} onChange={(e)=>SetSearchTeam(e.target.value) } style={{width:200,display:"inline-block"}} />
                    {permissionDepartment && permissionDepartment.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={()=>SetTeamModal(true)} ><PlusIcon/> Add Team</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled btn-delete' disabled><PlusIcon/> Add Team <span className='messageTag'>Access Restricted</span></button>}
                    </Col>
                </Row>
    </div>

    <TeamList 
         permission={permissionDepartment}
         searchTeam ={searchTeam}
         modalOpenAddTeam={modalOpenAddTeam}
         defaultListData={defaultListData}
         modalOpenAddTeamEdit={modalOpenAddTeamEdit}
         defaultListDataDailyWorker={defaultListDataDailyWorker}
         inc={inc}
         Reload={Reload}
         modalDeleteModal={modalDeleteModal}
    /> 
          
    <ReactModal
        isOpen={teamModal}
        style={customStyles2}

        >
            
        <AddTeam handleCloseModal={()=>SetTeamModal(false)}  GroupListData={GroupListData} emp={emp} sub={sub}  Reload={Reload} teamleaderList={teamleaderList} GroupListDataDailyWorker={GroupListDataDailyWorker} suborglist={suborglist}/>
    </ReactModal>    

    <ReactModal
        isOpen={teamModalEdit}
        style={customStyles2}

        >
            
        <EditTeam handleCloseModal={()=>SetTeamModalEdit(false)}  Reload={Reload}  GroupListData={GroupListData} emp={emp} sub={sub} teamID={teamID} teamleaderList={teamleaderList} teamleaderList1={teamleaderList1} teamleaderID={teamleaderID}  GroupListDataDailyWorker={GroupListDataDailyWorker}/>
    </ReactModal>    

    <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
     <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />

    </div>:permissionAPICall && permissionDepartment.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}
</div>

              


</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(TeamMananagement))
