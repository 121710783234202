import logo from './logo.svg';
import { BrowserRouter } from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import './App.css';
import './assets/styles/aside.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/common.scss'
import './assets/styles/responsive.css'
import LeftSideBar from './components/leftsidebar';
import RightSideComponents from './components/rightsideComponents';
import TopHeader from './components/topHeader';
import ReactModal from 'react-modal';
import React, {  useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getRoleDetail } from './rolemanagement/actionMethods/actionMethods';
import "react-datepicker/dist/react-datepicker.css";

function App(props) {

  let isLoggedInGatewayTraceAdmin = JSON.parse(localStorage.getItem('isLoggedInGatewayTraceAdmin'))
  let history =useHistory();
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
const [firstLoad,SetFirstLoad]=useState(null);
  const [permission,SetPermission] =useState([])
  useEffect(()=>{
      if(isLoggedInGatewayTraceAdmin){

        let check= userDetails.firstLoad? userDetails.firstLoad:false;
        SetFirstLoad(check);
      
       let requestBody={}
        requestBody.session=userDetails.session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
              res.data[0]["org_type"]=userDetails.org_type
                SetPermission(res.data[0])
               
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()
      }
  },[isLoggedInGatewayTraceAdmin])

  ReactModal.setAppElement('*'); 
  const [menuActive,SetMenuActive]=useState(false);
  const [activeStateProfile,SetActiveStateProfile] =useState(false)

  function ToggleProfile(){

    SetActiveStateProfile(false);
  }
  function ToggledMenu(){
    if(menuActive){
      
      document.body.classList.add('main-sidebar-show')
      
    }else{
      document.body.classList.remove('main-sidebar-show')
    }
    SetMenuActive(!menuActive)
    
  }

function changeFirstLoad(firstloadVar = false){
  
  userDetails.firstLoad = firstloadVar;
  localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(userDetails));
  
  SetFirstLoad(firstloadVar);
}

  return (
    
      <div className="mainAppDiv">
    
      {
        isLoggedInGatewayTraceAdmin ? 
        <><LeftSideBar firstLoad={firstLoad} changeFirstLoad={changeFirstLoad} menuActive={menuActive} permission={permission}  ToggledMenu={ToggledMenu} ToggleProfile={ToggleProfile}  /> <TopHeader ToggleProfile={ToggleProfile} activeStateProfile={activeStateProfile} menuActive={menuActive} ToggledMenu={ToggledMenu} /></>: ''
      }
        <RightSideComponents firstLoad={firstLoad} changeFirstLoad={changeFirstLoad} ToggleProfile={ToggleProfile} />
      </div>
    
  );
}



export default withRouter(App);
