import React, { useState, useEffect,useRef } from 'react'
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import  '../styles/style.scss';
import moment from 'moment';
import {Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as EyeIcon} from '../../assets/images/eye.svg'
import { Select } from 'antd';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {getRoleList,removeRole} from '../actionMethods/actionMethods'
import LoaderSpinner from '../../assets/images/Spinner Loader.gif'
import Pages from './pages.json'
import {ReactComponent as AddSubIcon} from '../../assets/images/plus-octagon.svg'

const { Option } = Select;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function PageList(props){
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [modalEdit,SetModalEdit]=useState(false);
    const[WidthContainer,setWidthContainer]=useState('');
    const [sort,SetSort]=useState('');
    const[sortKey,SetSortKey] =useState('');  
    const [pageListData,SetPageListData] =useState([]);

    const [pageListDefaultData,SetPageListDefaultData] =useState([]);
    const [loader,SetLoader]=useState(false);
    const elementRef = useRef(null);   
    const [searchPage,SetSearchPage]=useState(props.searchPage);
    const [permission,SetPermission]=useState(props.permission)
    const [apiCall,SetAPICall]=useState(true)
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    

    useEffect(()=>{



        if(props.pageAll && props.pageAll.length>0){
            const groupedData = {};

// Iterate through the original data
props.pageAll.forEach(entry => {
  // Check if the page_name is already in the grouped data
  if (groupedData[entry.page_name]) {
    // If it exists, push the version to the existing array
    groupedData[entry.page_name].versionList.push({page_id:entry.page_id,page_name:entry.page_name,url:entry.url,version:entry.version});
  } else {
    // If it doesn't exist, create a new entry with the version as an array
    let subFIlter = entry.sub_pages?entry.sub_pages:[];
    let subAr=[]
    subFIlter.forEach(subElm=>{
        
        if (subAr[subElm.sub_page_name]) {
            
            subAr[subElm.sub_page_name].versionList.push({page_id:subElm.page_id,sub_page_id:subElm.sub_page_id,page_name:subElm.page_name,sub_page_name:subElm.sub_page_name,url:subElm.sub_web_page_url,version:subElm.sub_webpage_version});

        }else{
            
            subAr[subElm.sub_page_name] = {
                created_at: subElm.created_at,
                page_id: subElm.page_id,
                page_name: subElm.page_name,
                sub_page_name:subElm.sub_page_name,
                sub_page_id:subElm.sub_page_id,
                url: subElm.sub_web_page_url,
                version:subElm.sub_webpage_version,
                versionList: [{page_id:subElm.page_id,sub_page_id:subElm.sub_page_id,page_name:subElm.page_name,sub_page_name:subElm.sub_page_name,url:subElm.sub_web_page_url,version:subElm.sub_webpage_version}]
            };

        }
        
    })
 



    const result2 = Object.values(subAr);

    groupedData[entry.page_name] = {
      created_at: entry.created_at,
      page_id: entry.page_id,
      page_name: entry.page_name,
      url: entry.url,
      version:entry.version,
      versionList: [{page_id:entry.page_id,page_name:entry.page_name,url:entry.url,version:entry.version}],
      sub_pages:result2
    };
  }
});

// Convert the grouped data object back to an array
const result = Object.values(groupedData);


SetPageListData(result)
SetPageListDefaultData(result)
            
        }
    },[props.pageAll])
    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
 
    }, [elementRef]);
    useEffect(()=>{

        SetPermission(props.permission);
    },[props.permission])


    useEffect(()=>{
      let pg= [...pageListDefaultData];
      let arr=[]
      for(let i =0;i<pg.length;i++){

        if(pg[i].page_name.toLowerCase().trim().includes(props.searchPage.toLowerCase().trim())){
          arr.push(pg[i]);
        }else{
          let sub= pg[i].sub_pages.filter(el=>el.page_name.toLowerCase().trim().includes(props.searchPage.toLowerCase().trim()));
          
          if(sub.length> 0){
            let single=pg[i];
            single.sub_pages = sub;
            arr.push(single);
          }
        }
      }
      SetPageListData(arr);

    },[props.searchPage])

    useEffect(()=>{
        let requestBody={}
        requestBody.session=userDetails.session
        requestBody.org_id=userDetails.org_id


    },[props.inc])

    useEffect(()=>{
        if(props.loader && props.loader===false){
        let data = [...pageListDefaultData];
 
        let arr=  data.filter((item) => {
                     return item.page_name.toString().toLowerCase().includes(props.searchPage.toLowerCase());
                    })
                          
        SetPageListData(arr);           

        }


    },[props.searchPage])

    // useEffect(() => {
    //     let data = [...pageListDefaultData]
    //     let arr=[];
    //     if(sortKey=='page_name'){
    //         arr = data.sort((a, b) => {
    //             a = a.page_name.toUpperCase()
    //             b = b.page_name.toUpperCase()
    //             return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
    //         })
    //     }
    //     if(sortKey=='id'){
    //         arr = data.sort(function (x, y) {
    //             return sort === 'desc' ?y.total - x.total:x.total-y.total;
    //         });
    //     }


    //     SetPageListData(arr);

    // },[sort,sortKey])
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

    


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val,type='secondary'){
        
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
     }  
      else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }
        

    }


    function editHandlerAlert(id){
        SetModalEdit(true);
    }
    function openSubPages(name,id){
    
        props.openAddSubPage(name,id)
    }

function ShowCardListPage(data){
    let arr= []

    let index = 1;
    if(data.length >0 ){
    for(let i=0;i<data.length; i++){
        //data[key].reported_time
        arr.push(
            <Row key={'contentGroup'+i} className={activeAccord.indexOf(i)!==-1?'contentGroup active':'contentGroup'}>
            <div className='group-header'> 
             <Col lg={3} className="align-middle">
                 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                 {data[i].sub_pages.length>0?<span className='accordion-icon' onClick={()=>AccordionHandler(i,'primary')} >
                   {activeAccord.indexOf(i)!==-1 || props.searchPage.length >0?<CircleArrowDown/>:<CircleArrow />}   
                 </span>:""}
                 <span className='align-title-left'>
                    <h4 className='textCap' >{data[i].page_name.toLowerCase()}</h4>
                 </span>
               
             </Col>
             <Col lg={3} className="align-middle">
                 <div className='inlineVersion'>
  {data[i].versionList &&
    data[i].versionList.map((item)=> (
      <div className='versionInlineList' key={item.page_id}>
        {item.version}
        <span className='tootlipVersionImage'>
                        <div className="posAbsoluteBtn" style={{textAlign:"right"}}>
        {permission && permission.edit=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.openModalEditPage(data[i],"Version")}><EditIcon/></button>:
              <button className='btn btn-smaller btn-icon disabled hover-message'><EditIcon/><span className='messageTag'>Access Restricted</span></button>} 
            {permission && permission.remove=='True'?<button className='btn btn-smaller btn-icon btn-delete' onClick={()=>props.modalDeleteModal(data[i].page_name+" V"+item.version,item.page_id)}><DeleteIcon/></button>:<button className='btn btn-smaller btn-icon disabled hover-message btn-delete' disabled><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}
            </div>

          <img src={item.url.startsWith("http")?item.url:"https://traceplus.ai/wp-content/uploads/2023/12/dummy_750x750_ffffff_cccccc.png"} alt={`Version ${item.page_id}`} />
        </span>
      </div>
    ))}
</div>
             </Col>
             
             <Col lg={6} className="align-middle">
              {props.permission.edit=="True" &&
                    <button className='btn btn-smaller btn-icon' title="Add Sub Pages" onClick={()=>openSubPages(data[i].page_name,data[i].page_id)}><AddSubIcon/><span></span></button>
                }
                {props.permission.edit=="True" &&
                    <button className='btn btn-smaller btn-icon' title="Add Version" onClick={()=>props.openAddVersion(data[i].page_name)}><AddIcon/><span></span></button>
                }
              {permission && permission.edit=='True'?<button className='btn btn-smaller btn-icon' title="Edit" onClick={()=>props.openModalEditPage(data[i])}><EditIcon/><span></span></button>:
              <button className='btn btn-smaller btn-icon disabled hover-message'><EditIcon/><span> </span><span className='messageTag'>Access Restricted</span></button>} 
              {permission && permission.remove=='True'?<button className='btn btn-smaller btn-icon btn-delete' title="Delete" onClick={()=>props.modalDeleteModal(data[i].page_name,data[i].page_id)}><DeleteIcon/><span></span></button>:<button className='btn btn-smaller btn-icon disabled hover-message btn-delete' disabled><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}
                            </Col>
             </div>            
               {data[i].sub_pages.length>0?
               <div className={activeAccord.indexOf(i)!==-1 || props.searchPage.length >0?'group-content active':'group-content'} >
                      {
                        data[i].sub_pages.map(el=>
                            {

                            return( 
                            
                            <Row className='headerGroup headerGroupSubPages'>

            <Col lg={3} className="align-middle">
               <span class="align-title-left"><h4 class="textCap">{el.sub_page_name.toLowerCase()}</h4></span>         
            </Col>
            <Col lg={3} className="align-middle">
               
                                 <div className='inlineVersion'>
                                    {el.versionList &&
    el.versionList.map((item)=> (
      <div className='versionInlineList' key={"sub_page"+item.sub_page_id}>
        {item.version}
        <span className='tootlipVersionImage'>
                        <div className="posAbsoluteBtn" style={{textAlign:"right"}}>
        {permission && permission.edit=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.openModalEditSubPage(item,"Sub Page Version")}><EditIcon/></button>:
              <button className='btn btn-smaller btn-icon disabled hover-message'><EditIcon/><span className='messageTag'>Access Restricted</span></button>} 
            {permission && permission.remove=='True'?<button className='btn btn-smaller btn-icon btn-delete' onClick={()=>props.modalDeleteModalSub(item.sub_page_name+" V"+item.version,item.sub_page_id)}><DeleteIcon/></button>:<button className='btn btn-smaller btn-icon disabled hover-message btn-delete' disabled><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}
            </div>

          <img src={item.url.startsWith("http")?item.url:"https://traceplus.ai/wp-content/uploads/2023/12/dummy_750x750_ffffff_cccccc.png"} alt={`Version ${item.sub_page_id}`} />
        </span>
      </div>
    ))}

  {/* {el.version &&
    Object.entries(el.version).map(([versionNumber, versionUrl]) => (
      <div className='versionInlineList' key={versionNumber}>
        {versionNumber}
        <span className='tootlipVersionImage'>
          <img src={versionUrl || "https://traceplus.ai/wp-content/uploads/2023/12/dummy_750x750_ffffff_cccccc.png"} alt={`Version ${versionNumber}`} />
        </span>
      </div>
    ))} */}



    
</div>
        
            </Col>
            <Col lg={6} className="align-middle" style={{textAlign:"right"}}>
                {props.permission.edit=="True" &&
                    <button className='btn btn-smaller btn-icon' title="Add Sub Version" onClick={()=>props.openAddSubPageVersion(data[i].page_name,el.sub_page_id,el.sub_page_name)}><AddIcon/><span></span></button>
                }
{props.permission.edit=="True" &&
                    <button className='btn btn-smaller btn-icon' title="Edit Sub Pages" onClick={()=>props.openModalEditSubPage(el,"Sub Page Version")}  ><EditIcon/><span></span></button>
                }
              
              {permission && permission.remove=='True'?<button className='btn btn-smaller btn-icon btn-delete' title="Delete" onClick={()=>{props.modalDeleteSubPage(el.sub_page_name,el.sub_page_id)}}><DeleteIcon/><span></span></button>:<button className='btn btn-smaller btn-icon disabled hover-message btn-delete' disabled><DeleteIcon/><span></span><span className='messageTag'>Access Restricted</span></button>}

            </Col>
        </Row> 
) 
                            
                        })
                      }
  
                      
             </div>
             :''}
         </Row>
        )
index++;
            }
        }else if(apiCall===false){
            
        }
        else{

           arr.push( 
                    <Row className='contentGroup nofound' key={'contentGroup nofound'}>
                        <div className='group-header'>
                            <Col className='align-middle'>
                                <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                            </Col>
                        </div>
                    </Row>
                    ) 
        }
    return arr;
}

  
    return(

        <div className='roleList'>

            <div className='br-seperator-row'>                
        <Row className='headerGroup' ref={elementRef}>
            <Col lg={3} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('page_name')}>Page Name
                <span className={sortKey=='page_name'?'filter-icon active':'filter-icon'} >{sortKey=='page_name'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
            <Col lg={3} className="align-middle" style={{textAlign:"left"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('id')} >Version
                <span className={sortKey=='id'?'filter-icon active':'filter-icon'}>{sortKey=='id'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col>
            <Col lg={6} className="align-middle role-management-act">Action</Col>
        </Row> 
        {props.loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
        
        ShowCardListPage(pageListData)}
            

        </div>
        </div>
    )
    

}
export default PageList;