import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'
/*----CloneUser --- */
export function CreateDepartment(requestBody) {
    
    return axios.post(prefixURL + `/department/create_department` , requestBody).then(res=>res.data).catch(err=>err)
}

export function CreateShift(requestBody) {
    return axios.post(prefixURL + `/employee/create_shift` , requestBody).then(res=>res.data).catch(err=>err)
}
export function getShifttList(requestBody) {
    return axios.get(prefixURL + `/employee/get_shift_details?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}


export function getDepartmentList(requestBody) {

    return axios.get(prefixURL + `/department/department_list?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}

export function getAvailableTag(requestBody) {

    return axios.get(prefixURL + `/tags/get_available_tags?session=${requestBody.session}&org_id=${requestBody.org_id}&tag_type=${requestBody.tag_type}`).then(res=>res.data).catch(err=>err)
}

export function getDepartmentDetail(requestBody) {
  
    return axios.get(prefixURL + `/department/view_department?session=${requestBody.session}&org_id=${requestBody.org_id}&department_id=${requestBody.role_id}`).then(res=>res.data).catch(err=>err)

}

export function getEmployeeDetail(requestBody) {
  
    return axios.get(prefixURL + `/employee/get_employee_list?session=${requestBody.session}&org_id=${requestBody.org_id}&flag=${requestBody.flag}`).then(res=>res.data).catch(err=>err)

}




export function getSubDepartmentList(requestBody) {
  
    return axios.get(prefixURL + `/department/get_sub_department_list?session=${requestBody.session}&org_id=${requestBody.org_id}&dept_id=${requestBody.dept_id}`).then(res=>res.data).catch(err=>err)

}


export function editDepartment(requestBody) {
    
    return axios.post(prefixURL + `/department/edit_department` , requestBody).then(res=>res.data).catch(err=>err)
}
export function editSubDepartment(requestBody) {
    
    return axios.post(prefixURL + `/department/edit_sub_department` , requestBody).then(res=>res.data).catch(err=>err)
}

export function CreateSubDepartment(requestBody) {
    
    return axios.post(prefixURL + `/department/create_sub_department` , requestBody).then(res=>res.data).catch(err=>err)
}

export function CreateAsset(requestBody,file){
   // return axios.post(prefixURL + `/user/add_user` , requestBody).then(res=>res.data).catch(err=>err)

   let bodyFormData = new FormData();
   bodyFormData.append('data',JSON.stringify(requestBody));
   bodyFormData.append('file',file);
   return axios({
       method: "post",
       url: prefixURL + `/asset/create_asset`,
       data: bodyFormData,
       headers: { "Content-Type": "multipart/form-data" },
     })
       .then(res=>res.data)
       .catch(err=>err);
}
export function UpdateAsset(requestBody,file){

    let bodyFormData = new FormData();
    bodyFormData.append('data',JSON.stringify(requestBody));
    bodyFormData.append('file',file);
    //return axios.post(prefixURL + `/user/update_user` , requestBody).then(res=>res.data).catch(err=>err)
    return axios({
        method: "post",
        url: prefixURL + `/asset/update_asset`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(res=>res.data)
        .catch(err=>err);
}

export function getAssetInfo(requestBody){
    return axios.get(prefixURL+`/asset/get_asset_details?org_id=${requestBody.org_id}&user_id=${requestBody.user_id}&session=${requestBody.session}`)
}

/*----CloneUser --- */
export function getAssetList(requestBody){
    return axios.get(prefixURL+`/asset/get_asset_list?org_id=${requestBody.org_id}&flag=${requestBody.flag}&session=${requestBody.session}`)
}


