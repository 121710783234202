import React from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


function CommonDatePicker(props) {

    //let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date((new Date().setDate(new Date().getDate() - 1)));
    function handleDateSelect(date) {
        localStorage.setItem('selectedDate', date)
        props.handleSelectDate(date)
    }

    return (
        <React.Fragment>
            
            <DatePicker
                selected={date}
                onChange={d => handleDateSelect(d)}
                dateFormat={'MMM dd'}
                isClearable={false}
                maxDate={new Date()}
                minDate={moment().subtract(29, 'days').toDate()}
                placeholderText={'Select Date'}
            />
        </React.Fragment>
    )
}

export default (CommonDatePicker)