import React, { useState, useEffect } from 'react'
import { Container, Row, Col ,Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'

import AddTeam from './Team/AddSuperviosr';
import EditTeam from './Team/EditSuperviosr'
import TeamList from './Team/SuperviosrList'
import {getRoleList} from '../../rolemanagement/actionMethods/actionMethods';

import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { getlocationType } from '../../sitemanagement/actionMethods/actionMethods';
import { getSupervisorList, getTeamDetail,getTeamLeaderList,getTeamList, removeSuperviosr } from '../actionMethods/actionMethods';
import AddSupervisor from './Team/AddSuperviosr';
import EditSupervisor from './Team/EditSuperviosr';
import SuperviosrList from './Team/SuperviosrList';
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};
const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '1200px',
        maxHeight: '90%'
    },
};



function SupervisorMananagement(props) {

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:""
    let sub_org_id=userDetails.sub_org_id?userDetails.sub_org_id:""
  const [inc,SetInc]=useState(1)
 const [permission,SetPermission] =useState([]);
  const [permissionAPICall,SetPermissionAPICall] =useState(false);
  const [permissionDepartment,SetPermissionDepartment]=useState([])
  const [searchTeam,SetSearchTeam]=useState([])

  const [locationType,SetLocationType] =useState([]);
  const [teamModal,SetTeamModal] =useState(false);
  const [teamModalEdit,SetTeamModalEdit] =useState(false);
  const [GroupListDataDailyWorker,SetGroupListDataDailyWorker] = useState([])
  const [GroupListData,SetGroupListData] = useState([])
  const [emp,SetEmp] = useState([])
  const [sub,Setsub] = useState([])
  const [teamID,SetTeamID] =useState('');
  const [teamleaderList,SetTeamLeaderList] =useState([]);
  const [teamleaderID,SetTeamLeaderID] =useState([]);
  const[teamlist,SetTeamList] = useState([]);
  const[superlist,SetsuperList] = useState([]);
  const[supervisordetails,Setsupervisordetails] = useState([]);
  const [deleteID,SetDeleteID] =useState('');
  const [title,SetTitle]=useState('');
  const [modalDelete,SetModalDelete]= useState(false); 
  const [description,SetDescription]= useState('');

  const [modalSucess,SetModalSucess]= useState(false);
  const [modalError,SetModalError]= useState(false);

  useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
    }, [inc]);

    useEffect(()=>{

        let requestBody={}
        requestBody.session=session
        requestBody.org_id= org_id
        requestBody.role_id=userDetails.role
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200|| res.code==200){
                
                SetPermissionDepartment(JSON.parse(res.data[0].teams));
                SetPermission(res.data);
                SetPermissionAPICall(true);
            }
            if(res.status == 300 ){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }

        }).catch();

    },[])

    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id    
            requestBody.sub_org_id=sub_org_id
        getTeamLeaderList(requestBody).then(res=>{
            if(res.status==200){
                
                SetTeamLeaderList(res.data);
            }

        })
    }
    },[permission])

    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id  
            requestBody.sub_org_id =sub_org_id  
        getSupervisorList(requestBody).then(res => {
            let supervisorList = [];
            if (res.status === 200) {
              for (let supervisorName in res.data) {
                let supervisorData = res.data[supervisorName];
                let supervisorObj = {
                  supervisor_name: supervisorName,
                  supervisor_id: supervisorData.supervisor_id,
                  team_leaders: [],
                  employee_count: 0 
                };
                for (let leaderName in supervisorData) {
                  if (leaderName !== "supervisor_id") {
                    let teamLeaderData = supervisorData[leaderName];
                    let teamLeaderObj = {
                      team_leader_name: leaderName,
                      sub_departments: [],
                      employee_count: 0 
                    };
                    for (let subDepartmentName in teamLeaderData) {
                      if (subDepartmentName !== "team_leader_id") {
                        let employeesList = teamLeaderData[subDepartmentName].employees_list;
                        let subDepartmentObj = {
                          sub_department_name: subDepartmentName,
                          employees: employeesList
                        };
                        teamLeaderObj.sub_departments.push(subDepartmentObj);
                        teamLeaderObj.employee_count += employeesList.length; 
                        supervisorObj.employee_count += employeesList.length; 
                      }
                    }
                    supervisorObj.team_leaders.push(teamLeaderObj);
                  }
                }
                supervisorList.push(supervisorObj);
              }
            } else {
              // handle error
            }
            SetsuperList(supervisorList);
          }); 
    }
    },[permission])
    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id    
            requestBody.sub_org_id=sub_org_id
        getTeamList(requestBody).then(res=>{
            if(res.status==200){
               
                let data= res.data;
                
                for(let i=0;i<data.length;i++){
                    //now the loop is for team leader name with team id
                    data[i].team=[]
                    for(let j=0;j<data[i].team_leader_id.length;j++){
                        data[i].team.push({team_leader_id:data[i].team_leader_id[j],team_leader_name:data[i].team_leader_name[j],team_id:data[i].team_id})
                    }

                }
                SetTeamList(data);
            }

        })
    }
    },[permission])



    function Reload(){
        SetInc(inc+1);
    }  

    function handleCloseModal(){
 
    }

    function handleCloseModalEditTeam(){
        SetTeamModalEdit(false)
     }

    function modalOpenAddTeam(){
        SetTeamModal(true)
    }
    function modalDeleteModal(data){
        SetDeleteID(data.supervisor_id)     
        SetModalDelete(true)
    }
    function handleCloseModalWarning(){
        SetModalDelete(false);
    }
    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.supervisor_id=deleteID
        requestBody.org_id = userDetails.org_id
        // requestBody.sub_org_id=sub_org_id
        requestBody.session = userDetails.session
        removeSuperviosr(requestBody).then(res=>{
            if(res.status==200){
                SetInc((prev)=>prev +1)
                SetTitle(title);
                SetDescription("Suscessfully Deleted");
                SetModalSucess(true);
                SetModalDelete(false);    
                
            }else{
                SetTitle("Failed to delete");
                SetDescription(res.message?res.message:'');
                SetModalError(true);
                //SetModalDelete(false);
            }
        })
      //  SetModalDelete(false);
    }
    function modalOpenAddTeamEdit(data){
        // SetTeamLeaderID(data.);
         SetTeamID(data.supervisor_id);
        Setsupervisordetails(data)
        SetTeamModalEdit(true)
    }

    function getAllSubDepartments(arr) {
        const subDepartments = [];
        
        arr.forEach(obj => {
          const childArr = obj.child;
          
          childArr.forEach(childObj => {
            const { sub_department, sub_dept_id, data } = childObj;
            const newSubDepartment = { sub_department, sub_dept_id, data };
            
            subDepartments.push(newSubDepartment);
          });
        });
        
        return subDepartments;
      }
      


    function defaultListData(arr,empList){
        SetGroupListData(arr);
      
        const subDepartments = getAllSubDepartments(arr);
        Setsub(subDepartments)
        SetEmp(empList)
    }

    function defaultListDataDailyWorker(arr,empList){
        SetGroupListDataDailyWorker(arr);
        let empl=[...emp]
        empl.push(...empList)
        SetEmp(empl)
    }

    function Reload(){
        let inc2 = inc+1
        SetInc(inc2);
    }
    function handleCloseModalSucess(){
        SetModalSucess(false);
    }
    function handleCloseModalError(){
        SetModalError(false);
    }
    function tabshift(){
        props.history.push('/team-management')  
        }
    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
<div className="inner-body"></div>
    {permissionAPICall?
    <div className="inner-body">
    {/* <Row >
    <Col lg={8}>
    <Button className='btn btn-large btn-header btn-transparent active' >Supervisor Mangement</Button>
    <Button className='btn btn-large btn-header btn-transparent'onClick={()=>tabshift()} >Team Management</Button>
    </Col>
    <Col lg={4}>
    </Col>
    </Row> */}
    <div className="page-header" >
            <Row >
                <Col lg={6}>
                <Button className='btn btn-large btn-header btn-transparent' style={{marginLeft:0}}onClick={()=>tabshift()} >Team Management</Button>
                <Button className='btn btn-large btn-header btn-transparent active' >Supervisor Mangement</Button>
                </Col>
                <Col lg={6} className="header-btn">
                    <input type="text" className='form-control' placeholder="Search Supervisor" value={searchTeam} onChange={(e)=>SetSearchTeam(e.target.value) } style={{width:200,display:"inline-block"}} />
                    {permissionDepartment && permissionDepartment.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={()=>SetTeamModal(true)} ><PlusIcon/> Add Supervisor</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled btn-delete' disabled><PlusIcon/> Add Team <span className='messageTag'>Access Restricted</span></button>}
                    </Col>
                </Row>
    </div>
    <SuperviosrList
         permission={permissionDepartment}
         searchTeam ={searchTeam}
         modalOpenAddTeam={modalOpenAddTeam}
         defaultListData={defaultListData}
         modalOpenAddTeamEdit={modalOpenAddTeamEdit}
         defaultListDataDailyWorker={defaultListDataDailyWorker}
         inc={inc}
         Reload={Reload}
         modalDeleteModal={modalDeleteModal}
    /> 
       
    <ReactModal
        isOpen={teamModal}
        style={customStyles2}
        >
            
        <AddSupervisor handleCloseModal={()=>SetTeamModal(false)}  GroupListData={GroupListData} emp={emp} sub={sub}  Reload={Reload} teamleaderList={teamleaderList} teamlist={teamlist} GroupListDataDailyWorker={GroupListDataDailyWorker} />
    </ReactModal>    

    <ReactModal
        isOpen={teamModalEdit}
        style={customStyles2}
        >   
        <EditSupervisor handleCloseModal={()=>SetTeamModalEdit(false)}  supervisordetails={supervisordetails} Reload={Reload}  GroupListData={GroupListData} emp={emp} sub={sub} teamID={teamID} teamleaderList={teamleaderList} teamleaderID={teamleaderID} teamlist={teamlist} />
    </ReactModal>    

    <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
     <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
           
    </div>:permissionAPICall && permissionDepartment.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}
</div>

              


</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(SupervisorMananagement))
