import React,{useEffect, useState,useRef} from "react";
import {getEmployeeDetail} from '../../../usermanagement/actionMethods/actionMethods'
import WarningModal from "../../../components/WarningModal";
import SucessModal from "../../../components/SucessModal";
import ErrorModal from "../../../components/ErrorModal";
import {ReactComponent as ArrowDown} from  '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../../assets/images/square-plus.svg'
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import LoaderSpinner from '../../../assets/images/Spinner Loader.gif'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Container, Row, Col } from 'react-bootstrap';
import {ReactComponent as GroupIcon} from '../../../assets/images/hexagon-plus.svg'
import Avatar from '../../../assets/images/avatar.png'
import ReactModal from "react-modal";
import AddTeam from "./AddSuperviosr";
import { getSupervisorList, getTeamList } from "../../actionMethods/actionMethods";
import EditTeam from "./EditSuperviosr";

export default function SuperviosrList(props){

    const [team,SetTeamList] = useState([]);

    const[departmentlist,Setdepartmentlist]=useState([])
 	const [activeAccord,SetActiveAccord] =useState([]);
    const [emp,SetEmp] = useState([])
	const [loader,SetLoader] =useState(true);
    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  

	const [modalSucess,SetModalSucess]=useState(false);
	const [modalError,SetModalError]=useState(false);
	const [modalWarning,SetModalWarning]=useState(false);

	const [title,SetTitle] = useState("");
	const [description,SetDescription]= useState("");
	const [defaultTeam,SetDefaultTeam]= useState([]);
    
	const elementRef = useRef(null);   
    const [WidthContainer,setWidthContainer]=useState('');


	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""
    let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""
useEffect(()=>{
   
    let requestBody={};
    requestBody.session=session
    requestBody.org_id=org_id
    requestBody.sub_org_id =sub_org_id
    // getSupervisorList(requestBody).then(res => {
    //     let supervisorList = [];
    //     if (res.status === 200) {
    //       for (let supervisorName in res.data) {
    //         let supervisorData = res.data[supervisorName];
    //         let supervisorObj = {
    //           supervisor_name: supervisorName,
    //           supervisor_id: supervisorData.supervisor_id,
    //           team_leaders: [],
    //           employee_count: 0 
    //         };
    //         for (let leaderName in supervisorData) {
    //           if (leaderName !== "supervisor_id") {
    //             let teamLeaderData = supervisorData[leaderName];
    //             let teamLeaderObj = {
    //               team_leader_name: leaderName,
    //               sub_departments: [],
    //               employee_count: 0 
    //             };
    //             for (let subDepartmentName in teamLeaderData) {
    //               if (subDepartmentName !== "team_leader_id") {
    //                 let employeesList = teamLeaderData[subDepartmentName].employees_list;
    //                 let subDepartmentObj = {
    //                   sub_department_name: subDepartmentName,
    //                   employees: employeesList
    //                 };
    //                 teamLeaderObj.sub_departments.push(subDepartmentObj);
    //                 teamLeaderObj.employee_count += employeesList.length; 
    //                 supervisorObj.employee_count += employeesList.length; 
    //               }
    //             }
    //             supervisorObj.team_leaders.push(teamLeaderObj);
    //           }
    //         }
    //         supervisorList.push(supervisorObj);
    //       }
    //     } else {
    //       // handle error
    //     }
    //     SetTeamList(supervisorList);
    //     console.log("supervisorList", supervisorList);
    //     SetDefaultTeam(supervisorList);
    //     SetLoader(false);
    //   }); 
    getSupervisorList(requestBody).then(res => {
      let supervisorList = [];
      if (res.status === 200) {
        for (let supervisorName in res.data) {
          let supervisorData = res.data[supervisorName];
          let supervisorObj = {
            supervisor_name: supervisorName,
            supervisor_user_id: supervisorData.supervisor_user_id,
            supervisor_id: supervisorData.supervisor_id,
            team_leader_count: 0,
            team_leaders: []
          };
    
          for (let leaderName in supervisorData) {
            if (leaderName !== "supervisor_id" && leaderName !== "supervisor_user_id") {
              let teamLeaderData = supervisorData[leaderName];
              let teamLeaderObj = {
                team_leader_name: leaderName,
                team_leader_id: teamLeaderData.team_leader_id,
                sub_departments: []
              };
    
              for (let subDepartmentName in teamLeaderData) {
                if (subDepartmentName !== "team_leader_id") {
                  let employeesList = teamLeaderData[subDepartmentName].team_id;
                  let subDepartmentObj = {
                    sub_department_name: subDepartmentName,
                    team_id: employeesList
                  };
                  teamLeaderObj.sub_departments.push(subDepartmentObj);
                }
              }
              supervisorObj.team_leaders.push(teamLeaderObj);
              supervisorObj.team_leader_count++;
            }
          }
          supervisorList.push(supervisorObj);
        }
      } else {
        // handle error
      }
      SetTeamList(supervisorList);
      
      SetDefaultTeam(supervisorList);
      SetLoader(false);
    });
    
},[props.inc])
	
useEffect(()=>{
    let teamAll=[...defaultTeam];
    let filter = teamAll.filter(item=>item.supervisor_name.toLowerCase().trim().includes(props.searchTeam.trim().toLowerCase()))
    SetTeamList(filter)
},[props.searchTeam])
    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }, [elementRef]);

	useEffect(()=>{
        let arr= [...team]
        if(sortKey == 'name'){
                arr = arr.sort((a, b) => {
                    a = a.supervisor_name.toUpperCase()
                    b = b.supervisor_name.toUpperCase()
                    return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                })
        }
        if(sortKey=='tag_count'){
            arr = arr.sort(function (x, y) {
                return sort === 'desc' ?y.team_leader_count - x.team_leader_count:x.team_leader_count-y.team_leader_count;
            });
        }
        SetTeamList(arr)

    },[sort,sortKey])



	function AccordionHandler(val,type='secondary'){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
         }    
        else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }        
    }

	function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }

	function DisplayNameProfile(params){
        if(params){
            var op =document.createElement('div');
            var eGui = document.createElement('div');      
             eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+
                                '<span class="profileTable"><img src='+Avatar+' />'+params.toLowerCase()+'</span>'+''+                            
                               '</div>';
        }else{

        }
         return eGui;
    }

	function  AGData(name,filter,type){

        //console.log("this darta ",filter)
if(filter){

	   let arr=  <div className="ag-theme-alpine" style={{height:(60 + ( 40* filter.length + 1 ) ), width: '100%'}}>
		 <AgGridReact
		   rowData={filter}
		   defaultColDef={{
				sortable: true,
				resizable: true,
				width: type? ( WidthContainer - 120)/4:( WidthContainer - 80)/4,
				height:40,
		   }}
		 >

			 <AgGridColumn 
				field="name"
				headerName={"Employee Name"}    
				//valueFormatter={(params)=><>{params.value}</>  }
				// cellRenderer= {(params)=>console.log("params",params)}
            cellRenderer={(params) => DisplayNameProfile(params.data.name)}      
			></AgGridColumn>
            <AgGridColumn 
				field="worker_id"
				headerName={"Emp ID"}    
				//valueFormatter={(params)=><><img src={Avatar} />{params.value}</>  }	
			></AgGridColumn>

			 <AgGridColumn 
				field="emp_email"
				headerName={"Email"}
				
			></AgGridColumn>
			<AgGridColumn 
				field="tag_serial"
				headerName={"Tag Serial"}
			></AgGridColumn>

	   </AgGridReact>     
	   </div>
 
	   return arr;
        }
	 }
	 
	function ShowGroupCardList(data){
        let arr=[]

        if(data.length > 0){
    
            for(let i=0;i<data.length;i++){

            arr.push( 
        <Row className={activeAccord.indexOf(data[i].supervisor_name) !== -1 ? 'contentGroup active' : 'contentGroup'} key={'departmentTeam' + data[i].supervisor_name.toLowerCase()}>
        <div className='group-header'>
          <Col lg={4} className="align-middle">
            <span className='accordion-icon' onClick={() => AccordionHandler(data[i].supervisor_name)}>
              {activeAccord.indexOf(data[i].supervisor_name) !== -1 ? <CircleArrowDown /> : <CircleArrow />}
            </span>
            <span className='align-title-left'>
              <h4 className='textCap'>{data[i].supervisor_name.toLowerCase()}</h4>
            </span>
          </Col>
  
          <Col lg={3} className="align-middle" style={{ textAlign: "center" }}>
            {data[i].team_leader_count}
            </Col>
          <Col lg={7} className="align-middle userManagementLast" style={{ minWidth: "310px" }}>

          {props.permission.edit=="True"?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenAddTeamEdit(data[i])}><EditIcon/><span>Edit</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><EditIcon/><span>Edit</span><span className='messageTag'>Access Restricted</span></button>}
           / {props.permission.remove=="True"?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalDeleteModal(data[i])} ><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon btn-icon disabled hover-message' ><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}
          </Col>
        </div>
        <div className={activeAccord.indexOf(data[i].supervisor_name) !== -1 ? 'group-content group-sub-content active' : 'group-content group-sub-content'}>
          {data[i].team_leaders.map((leader) => (
            //<Row className={activeAccord.indexOf(leader.team_leader_name) !== -1 ? 'contentGroup active' : 'contentGroup ',} key={'leaderTeam' + leader.team_leader_name.toLowerCase()}>
                <Row className={`contentGroup ${activeAccord.indexOf(leader.team_leader_name) !== -1 ? 'contentGroup active' : 'contentGroup'} mb-2`} key={'leaderTeam' + leader.team_leader_name.toLowerCase()}>
              <div className='group-header'>
                <Col lg={4} className="align-middle mt-1">
                  {/* <span className='accordion-icon' onClick={() => AccordionHandler(leader.team_leader_name)}>
                    {activeAccord.indexOf(leader.team_leader_name) !== -1 ? <CircleArrowDown /> : <CircleArrow />}
                  </span> */}
                  <span className='align-title-left'>
                    <h4 style={{ marginLeft: 10 }}>{leader.team_leader_name.toLowerCase()}</h4>
                  </span>
                </Col>
              </div>
              <div className={activeAccord.indexOf(leader.team_leader_name) !== -1 ? 'group-content group-sub-content active' : 'group-content group-sub-content'}>
                {leader.sub_departments.map((sub_department) => (
                  <Row className={activeAccord.indexOf(sub_department.sub_department_name) !== -1 ? 'contentGroup active' : 'contentGroup'} key={'subdepartmentTeam' + sub_department.sub_department_name.toLowerCase()}>
                    <div className='group-header'>
                      <Col lg={4} className="align-middle">
                        <span className='accordion-icon' onClick={() => AccordionHandler(sub_department.sub_department_name)}>
                          {activeAccord.indexOf(sub_department.sub_department_name) !== -1 ? <CircleArrowDown /> : <CircleArrow />}
                        </span>
                        <span className='align-title-left'>
                          <h4 style={{ marginLeft: 10 }}>{sub_department.sub_department_name.toLowerCase()}</h4>
                        </span>
                      </Col>
                    </div>
                    <div className={activeAccord.indexOf(sub_department.sub_department_name) !== -1 ? 'group-content group-sub-content active' : 'group-content group-sub-content'}>
                      {AGData(leader.team_leader_name, sub_department.employees)}
                    </div>
                  </Row>
                ))}
              </div>
            </Row>
          ))}
        </div>
      </Row>
  
            )
            }
        }else{
            arr.push( 
                <Row className='contentGroup nofound' key={"noDataTEam"}>
                    <div className='group-header'>
                        <Col className='align-middle'>
                            <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                        </Col>
                    </div>
                </Row>
                ) 
        }

        return arr;

    }

    return(<>
          <div className='roleList' ref={elementRef}>
            <div className='br-seperator-row'>
                 
        <Row className='headerGroup' >
            
            <Col lg={4} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('name')}>Team
                <span className={sortKey=='group'?'filter-icon active':'filter-icon'} >{sortKey=='group'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
            
            <Col lg={3} className="align-middle" style={{textAlign:"center"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_count')} >TeamLeader Count
                <span className={sortKey=='tag_count'?'filter-icon active':'filter-icon'}>{sortKey=='tag_count'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col>
            
            <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>Action</Col>
        </Row>    
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:ShowGroupCardList(team)} 
        	{/* <WarningModal modalWarning={modalWarning} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title} /> */}
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={()=>SetModalSucess(false)} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={()=>SetModalError(false)}   title={title} description={description}  />
      
           
        </div>

        </div>
        
    </>)
}