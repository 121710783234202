import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Checkbox } from 'antd';

//import 'antd/dist/antd.css'; 

import '../styles/login.scss'

import { emailPattern } from '../../common/utilities';
import { userLogin, forgotPassword } from '../actionMethods/actionMethods';

import traceplusLogo from '../../assets/traceplusImages/trace_logo.png'
import showPasswordEyeIcon from '../../assets/traceplusImages/show_password.png'


import infiniteLoader from '../../assets/images/infinite_loader.gif'
import LoginLeftGraphic from '../../assets/images/OBJECTS.png'


function LoginComponent(props) {

    const [emailID, updateEmailID] = useState('')
    const [password, updatePassword] = useState('')

    const [isEmailValid, updateIsEmailValid] = useState(true)
    
    const [isPasswordEmpty, updateIsPasswordEmpty] = useState(false)

    const [isForgotPasswordView, updateIsForgotPasswordView] = useState(false)

    const [somethingWentWrongFlag, updateSomethingWrongWentFlag] = useState(false)
    const [errorMessage, updateErrorMessage] = useState('')

    const [successMessage, updateSucessMessage] = useState('')

    const [isLoading, updateIsLoading] = useState(false)

    const reg = /^\S.*$/; 
    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
      }  
    

    function handleSubmit(event) {
        event.preventDefault()

        if (emailID == '') {
            updateIsEmailValid(false)
        }

        if (password == '') {
            updateIsPasswordEmpty(true)
        }


        if (emailID && password) {
            let isValid = emailPattern.test(emailID)
            //testing emaiil
            if (isValid) {
                updateIsLoading(true)
                updateIsPasswordEmpty(false)
                updateIsEmailValid(true)
                updateErrorMessage('')

                let requestBody = {}
                requestBody.username = emailID
                requestBody.password = password
                let authToken =randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
                requestBody.token = authToken
                userLogin(requestBody).then(res=>{
                    if(res.status==200){
                        let ar = res;
                        delete ar['status'];
                        if(ar.org_type == "oem"){
                            ar.firstLoad=true
                            localStorage.setItem('userLoginDetailsTraceAdmin', JSON.stringify(ar));
                            localStorage.setItem('isLoggedInGatewayTraceAdmin', true);
                            localStorage.setItem('tokenAuthAdmin', authToken)
                            props.history.push('/organization-management')
                        }else{
                            localStorage.setItem('userLoginDetailsTraceAdmin', JSON.stringify(ar));
                            localStorage.setItem('isLoggedInGatewayTraceAdmin', true);
                            localStorage.setItem('tokenAuthAdmin', authToken)
                            props.history.push('/dashboard')
                        }
                         
                        updateIsLoading(false)
                    }else{
                            updateErrorMessage(res.message)
                            updateSomethingWrongWentFlag(true)
                            updateIsLoading(false)

                            setTimeout(() => {
                                updateSomethingWrongWentFlag(false)
                            }, 3000);


                    }

                })
                    
        


                // userLogin(requestBody).then(res => {
                //     updateIsLoading(false)
                //     if (res && res.status >= 200 && res.status <= 299) {
                //         if (res.data && res.data.status == 200) {
                //             localStorage.setItem('userLoginDetailsTraceAdmin', JSON.stringify(res.data))
                //             localStorage.setItem('isLoggedInGatewayTraceAdmin', true)
                //             props.history.push('/gateway-status')
                //         }
                //         else {
                //             updateSomethingWrongWentFlag(true)
                //             updateErrorMessage(res.data.message)

                //             setTimeout(() => {
                //                 updateSomethingWrongWentFlag(false)
                //             }, 3000);
                //         }
                //     }
                // }).catch(err => {
                //     updateSomethingWrongWentFlag(true)
                //     updateErrorMessage('Username and password do not match')
                // })

            }else{
                updateIsEmailValid(false)
            }








        }

        
    }

    //localStorage.setItem('isLoggedInGatewayTraceAdmin','true');
    //localStorage.setItem('userLoginDetailsTraceAdmin','loggedIn');

    function toggleForgotPasswordView(flag) {
        updateIsForgotPasswordView(flag)
        updateEmailID('')
        updatePassword('')
        updateIsPasswordEmpty(false)
        updateIsEmailValid(true)
        updateErrorMessage('')
        updateSucessMessage('')
    }


    function togglePasswordTypeChange() {
        if (document.getElementById('password')) {
            document.getElementById('password').type == 'text' ? document.getElementById('password').type = "password" : document.getElementById('password').type = 'text'
        }
    }
    
    function handleEmailID(value) {
        if(value==' '&& emailID.length==0){
            updateEmailID(value)
        }else if(reg.test(value)){  
            updateEmailID(value)
        }else if(value.length==0){
            updateEmailID(value)
        }
        
    }

    function handlePassword(value) {
        updatePassword(value)
        updateIsPasswordEmpty(false)
    }

    let isLoggedInGatewayTraceAdmin = JSON.parse(localStorage.getItem('isLoggedInGatewayTraceAdmin'))

    if (isLoggedInGatewayTraceAdmin) {
        return (
            <div>
                {props.history.push('/dashboard')}
            </div>
        )
    }

    else {

        return (
            <div className="loginComponentMainDiv">
                <Container className='container-login'>
                <div className="logoDiv">
                    <img src={traceplusLogo} />
                </div>
                <div className="firstRowDiv">

                </div>

                <div className="secondRowDiv">

                </div>

                <div className="loginFormWithLogoDiv container">

                <div className='leftformDiv'>
                    <div className='top-graphic'><img src={LoginLeftGraphic} /></div>
                    <div className='bottom-graphic'></div>
                    
                </div>


                            <div className="loginFormMainDiv ">
                                {
                                    !isForgotPasswordView ?

                                        <React.Fragment>

                                            <div className="loginText">Log In</div>
                                            <div className="loginForm">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="eachElement">
                                                        <label>Email</label>
                                                        <input maxLength="150" type="text" name="email" value={emailID} onChange={(e) => handleEmailID(e.target.value)} placeholder="Email" />

                                                        {
                                                            !isEmailValid ? <div className="dangerColor">Please Enter Valid Email.</div> : ''
                                                        }
                                                    </div>

                                                    <div className="eachElement">
                                                        <label>Password</label>
                                                        <input maxLength="150" id="password" type="password" name="password" value={password}
                                                            onChange={(e) => handlePassword(e.target.value)} placeholder="Password" />
                                                        <img src={showPasswordEyeIcon} onClick={togglePasswordTypeChange} />

                                                        {
                                                            isPasswordEmpty ? <div className="dangerColor">Password is required.</div> : ''
                                                        }
                                                    </div>


                                                    {
                                                        isLoading ?
                                                            <img src={infiniteLoader} /> :
                                                            <button type="submit" className="loginFormButton">Log In</button>

                                                    }

                                                    {
                                                        somethingWentWrongFlag ?

                                                            <div className="dangerColor text-center">{errorMessage}</div> : ''
                                                    }

                                                </form>

                                            </div>
                                        </React.Fragment> : ""

                                         }
                            </div>

                </div>
                </Container>
            </div>
        )

    }
}


export default connect(null, {  })(withRouter(LoginComponent))