import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../dashboard/components';
import LoginComponent from '../login/components/login';
import UserManagement from '../usermanagement/components/index';
import SiteManangement from '../sitemanagement/components/index';
import RoleManagement from '../rolemanagement/components/index';
import AddRole from '../rolemanagement/components/AddRole';
import EditRole from '../rolemanagement/components/EditRole';
import PrivateRoute from './privateRoute'
import RoleDetail from '../rolemanagement/components/detail/index'
import DeviceManagement from '../devicemanagment/components/index'
import AssetMananagement from '../assetmanagement/components/index'
import GeoMapManagement from '../geomapmanagement/components/index';
import SupervisorManagement from "../supervisormanagement/components/index"
import ProductivitManagement from '../productivemanagement/components/index'
import ShiftManagement from '../shiftmanagement/components/index'
import MassUpload from '../massupload/components/index'
import TeamMananagement from '../teammanagement/components/index';
import AddDataElements2 from '../geomapmanagement/components/AddDataElement2'
import OrganizationManagement from '../organizationmanagement/components/index'
import UserSetting from '../organizationmanagement/components/usersettings';
import CycleCount from '../cyclecountmanagement/components/index'
// import CreatePolicy from '../policyManagement/components/list/createPolicy';
import FacilityCounter from '../facilityCounter/components/index'
import Holiday from '../holiday/components/index'
import DeviceMonitor from '../deviceMonitor/componenets';
import WebAppPageManagement from '../webappPageManagement/components/index'
import ClientAppManagement from '../clientappmanagement/components/index'

import ClientDB from '../clientdb/components/index'
import ProcessManagement from '../process/components/index'
import PlanImport from '../planImport/components/index'

const Routes = (props) => {
    
    return(
     <Switch>
        <Route exact path='/login' component={LoginComponent}  />
        <PrivateRoute exact path='/add-element-2' component={AddDataElements2}{...props} />
        <PrivateRoute exact path='/dashboard' component={Dashboard}{...props} />
        <PrivateRoute exact path='/user-management' component={UserManagement} {...props} />
        <PrivateRoute exact path='/site-management' component={SiteManangement} {...props}/>
        <PrivateRoute exact path='/role-management' component={RoleManagement} {...props}/>
        <PrivateRoute exact path='/device-management' component={DeviceManagement} {...props}/>
        <PrivateRoute exact path='/device-monitor' component={DeviceMonitor} {...props}/>
        <PrivateRoute exact path='/add-role' component={AddRole} {...props}/>
        <PrivateRoute exact path='/user-setting' component={UserSetting} {...props}/>
        <PrivateRoute exact path='/edit-role/:id' component={EditRole} {...props}/>
        <PrivateRoute exact path='/role-detail/:id' component={RoleDetail} {...props}/>
        <PrivateRoute exact path='/asset-management/' component={AssetMananagement} {...props}/>
        <PrivateRoute exact path='/geo-map-management' component={GeoMapManagement} {...props}/>
        <PrivateRoute exact path='/productive-management' component={ProductivitManagement} {...props}/>
        <PrivateRoute exact path='/shift-management' component={ShiftManagement} {...props}/>
        <PrivateRoute exact path='/mass-upload' component={MassUpload} {...props}/>
        <PrivateRoute exact path='/plan-import' component={PlanImport} {...props}/>
        <PrivateRoute exact path='/team-management' component={TeamMananagement} {...props}/>
        <PrivateRoute exact path='/organization-management' component={OrganizationManagement} {...props}/>
        <PrivateRoute exact path='/supervisor-management' component={SupervisorManagement} {...props}/>
        <PrivateRoute exact path='/cycle-count-management' component={CycleCount} {...props}/>
        <PrivateRoute exact path='/facility-counter' component={FacilityCounter} {...props}/>
        <PrivateRoute exact path='/holiday' component={Holiday} {...props}/>
        <PrivateRoute exact path='/webapp-page' component={WebAppPageManagement} {...props}/>
        <PrivateRoute exact path='/client-app-management' component={ClientAppManagement} {...props}/>
        
        <PrivateRoute exact path='/employee-management' component={ClientDB} {...props}/>
        <PrivateRoute exact path='/process-management' component={ProcessManagement} {...props}/>
        <PrivateRoute exact path='/' component={Dashboard} {...props}/>
     </Switch>)
}

export default Routes
