import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withRouter ,useHistory} from "react-router-dom";
import { connect } from 'react-redux';
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import TimezonePicker from '../timezonepicker/TimeZonePicker'
import {CreteOrg} from '../actionMethods/actionMethods'
import {Switch } from 'antd';
import ReactModal from 'react-modal';
import {Radio, Button,Steps } from 'antd';
import moment from 'moment';
import '../styles/settingstyle.scss'
import {getEmpDetail} from '../actionMethods/actionMethods'
	import { Select } from 'antd';



function AddSetting(props) {
	const history = useHistory();
    const [loader,SetLoader] =useState(false);
	const [timezone, setTimezone] = useState('');
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = props.orgid
    
	const [current, setCurrent] = React.useState(0);
  const reg = /^\S.*$/;


const { Option } = Select;
	const [switchValue, setSwitchValue] = useState("False");
    const[orgname,Setorgname]= useState("");
    const[orgdescription,Setorgdescription] =useState("");
    const[orgurl,Setorgurl] = useState("");
  
    const [org_name,Setorg_name]=useState('');
    const [descriptionError,SetdescriptionError]=useState('')
    const [logoError,SetlogoError]=useState('')
    const [org_nameError,Setorg_nameError]=useState('');
    const [names, setNames] = useState([]);
    const [data, setData] = useState({});

	const handleSwitchClick = (props) => {
		
        if(switchValue == "True"){
            setSwitchValue("False")
            return false
        }else{
            setSwitchValue("True")
            return true
        }
	};

  
    useEffect(() => {
      const requestBody = {
        session: session,
        org_id: org_id,
        flag: 'employee'
      };
  
      getEmpDetail(requestBody).then((res) => {
        let arr = [];
        if (res.status === 200) {
          let data = res.data;
        //   let employeeList = data.employee_list;
        //   let userList = data.user_list;
        //   for (let employee of employeeList) {
        //     let user = userList.find((u) => u.id === employee.user_id);
        //     if (user) {
        //       let name = user.name;
        //       arr.push(name);
        //     }
        let userList = data.user_list;
        for (let user of userList) {
          let name = user.name;
          arr.push(name);
        
          }
          setNames(arr);
          SetLoader(false);
        }
      });
    }, []);
  
    
  
    // const handleChange = (event) => {
    //   const value = event.target.value;
    //   Setorgname(value);
    // };
    const handleChange = (value) => {
      // Handle the selected value
    
      // Update the state or perform any other actions
      Setorgname(value);
    };

  
    function AddOrghandler(event){

        event.preventDefault();
        if(orgname==''|| orgname.trim().length==0){
          Setorg_nameError('org Name is required');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }else if(orgname.length > 255){
          Setorg_nameError('org Name length should be less than 255 character');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }
        else if(orgname!=''){
      
          let requestBody={}
          requestBody.org_name=orgname.trim()
          requestBody.description=orgdescription
          requestBody.logo_url= orgurl
        
          requestBody.org_settings= {"multi_session" : switchValue}
          requestBody.session= userDetails.session
          requestBody.org_id = userDetails.org_id
          
          CreteOrg(requestBody).then(res=>{
            //    SetLoader("False");
              if(res.status==200){
                props.modalSucessHandler(<h4>Organization is created</h4>,res.message)
             props. SetModalSucess(true);
            //   SetLoader(true)
             
              }else{
            props. modalErrorHandler(<h4>{res.message}</h4>,'Org is not create have some issue')
           props.SetModalError(true)
            // SetLoader(false)
              }
              if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)
             }
              
  
          }).catch();
        
        }else{
          SetLoader("False");
          Setorg_nameError('Required');
        }
  
  
  
      }
     

      function onChangeStep(step){
		var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if(current==0){
			if(orgname==''){
			Setorg_nameError('org name is required');
			}else if(orgname.length > 255){
				Setorg_nameError('Length of first name should be less than 255 character');
			}
	// 		else if(orgdescription==''){
	// 			SetdescriptionError('org description is required');
	// 		}
    //   else if(orgurl==''){
	// 			SetlogoError('Logo url  is required');
	// 		}
      
	  }

  }
    return(
        <>
       
        
        {loader ? (<div className="loaderContainer loaderContainerSmall"><img src={LoaderSpinner} /></div>
        ) : (<>
            <div className='modalHeader'>
                    <h4>Create User Settings</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
            <div className="modalBody">
                    <div className='field-row'>
                    <label>User Name</label>
        {/* <input
          className={org_nameError !== '' ? 'form-control has-error' : 'form-control'}
          type='text'
          value={orgname}
          onChange={handleChange}
          list='names-list'
        />
        
        <datalist id='names-list' >
          {names.map((name, index) => (
            <option key={index} value={name} className='emplistSearch1'/>
          ))}
        </datalist> */}
        <Select
  className={org_nameError !== '' ? 'form-control has-error' : 'form-control'}
  value={orgname}
  onSelect={handleChange}
  showSearch
  placeholder="Select an option"
  optionFilterProp="children"
  filterOption={(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
>
  {names.map((name, index) => (
    <Option key={index} value={name} className='emplistSearch1'>
      {name}
    </Option>
  ))}
</Select>
        {org_nameError !== '' ? <div className='errorMessage'>{org_nameError}</div> : ''}
    
                        </div>
                        {/* <div className="input-item">
                            <label>User Email</label>
                            <input
                                type="text"
                                className={descriptionError!=''?'form-control has-error':'form-control'}
                               
                                value={orgdescription}
                                onChange={(e)=>{if(reg.test(e.target.value)){Setorgdescription(e.target.value)}else if(e.target.value==''){Setorgdescription(e.target.value)} }  }
                            />
                            {descriptionError!=''?<div className='errorMessage'>{descriptionError}</div>:""}
                       </div> */}
                       <div className="input-item" >
                           <label style={{ marginRight: '20px' }}>User Settings :</label>
                        <div style={{ display: 'flex', alignItems: 'center',  }}>
                      <div style={{ marginRight: '30px',marginLeft:"85px",marginTop:"10px" }}>
                    <label style={{ marginBottom: '5px' }}>Admin</label>
                       <Switch onClick={handleSwitchClick} value={switchValue}  style={{ marginBottom: '20px' }}/>
                        </div>
                          <div style={{ marginRight: '20px' ,marginTop:"10px"}}>
                           <label style={{ marginBottom: '5px' }}>webapp</label>
                          <Switch onClick={handleSwitchClick} value={switchValue} style={{ marginBottom: '20px' }} />
                               </div>
                                <div style={{ marginRight: '30px',marginTop:"10px" }}>
                             <label style={{ marginBottom: '5px' }}>Mobile</label>
                          <Switch onClick={handleSwitchClick} value={switchValue} style={{ marginBottom: '20px' }}/>
                            </div>
                           </div>    
                           
                    </div>
                   
                

                
                <div className="steps-action">
					<Button type="primary" onClick={(event) => AddOrghandler(event)} className={'btn btn-primary btn-100'}>
						Create user Settings
					</Button>
                </div>
                
            
            </div> 
            
            </>)}
     
              
    </>
    
    )
}
const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(AddSetting))