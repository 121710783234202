import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreatePage(requestBody) {    
    return axios.post(prefixURL + `/clientapp/add_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}

export function CreateSubPage(requestBody) {    
    return axios.post(prefixURL + `/clientapp/add_sub_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}


export function removePage(requestBody) {
    return axios.post(prefixURL + `/clientapp/delete_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeSubPage(requestBody) {
    return axios.post(prefixURL + `/clientapp/delete_sub_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getPageList(requestBody) { 
    return axios.get(prefixURL + `/clientapp/get_webpages?session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
export function getSubPageList(requestBody) { 
    return axios.get(prefixURL + `/clientapp/get_sub_webpages_list?session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
export function getRoleDetail(requestBody) {
  
    return axios.get(prefixURL + `/admin/view_role_info?session=${requestBody.session}&org_id=${requestBody.org_id}&role_id=${requestBody.role_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
export function updatePage(requestBody) {
    
    return axios.post(prefixURL + `/clientapp/edit_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}
export function updateSubPage(requestBody) {
    
    return axios.post(prefixURL + `/clientapp/edit_sub_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}