import React, { useState, useEffect } from 'react'
import ExcelJs from "exceljs";
import {getExportDataEmpRegularTagUpdate} from '../../actionMethods/actionMethods';
import ErrorModal from '../../../components/ErrorModal';

//(Tags Bulk Update)
export default function GenerateTagRegular(){
    let requestBody={};
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    const [errorModal,SetErrorModal]=useState(false);
    const [title,SetTitle]=useState('');
    const [description,SetDescription]=useState('');

    function handleCloseModal(){
        SetErrorModal(false);
    }


    function numToSSColumnLetter(num) {
      let columnLetter = "",
        t;
    
      while (num > 0) {
        t = (num - 1) % 26;
        columnLetter = String.fromCharCode(65 + t) + columnLetter;
        num = (num - t) / 26 | 0;
      }
      return columnLetter || undefined;
    }
    function numberToLetters(num) {
      let letters = ''
      while (num >= 0) {
          letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
          num = Math.floor(num / 26) - 1
      }
      return letters
    }
    

    function generateExcel(){
        
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id=userDetails.org_id;

        getExportDataEmpRegularTagUpdate(requestBody).then(res=>{
           
            if(res.status==200){
    
                
                const workbook = new ExcelJs.Workbook();
                const filename = "Tags Bulk Update.xlsx";
                const sheetNames = ["Tags Bulk Update"];
                
               let sheetData  =[];
               let emp_name = res.data.active_serial_numbers;
               
                        
    
        
                 
                
        
                sheetNames.forEach(sheetName => {
                    let worksheet = workbook.addWorksheet(sheetName);
                            
        
                    worksheet.addTable({
                        name: sheetName,
                        ref: "A1", 
                        headerRow: true,
                        totalsRow: false,
                        style: {
                          theme: "TableStyleMedium2",
                          showRowStripes: false,
                          width: 200
                        },
                        columns : [
                          {name:'SERIAL ID.', header: 'SERIAL ID.', key: 'serial_id'},
                          {name:'EXISTING SERIAL NUMBER',header: 'EXISTING SERIAL NUMBER', key: 'existing_serial'},
                          {name:'EMPLOYEE NAME',header: 'EMPLOYEE NAME', key: 'new_serial'},
                          {name:'NEW SERIAL NUMBER',header: 'NEW SERIAL NUMBER', key: 'new_serial'},
                        ],
                        rows: emp_name.map((e,index) => {
                          let arr = [index+1,e,''];      
                          return arr;
                        })
                      })
                      worksheet.state = 'visible';
                      const table = worksheet.getTable(sheetName);
                      for (let i = 0; i < table.table.columns.length; i++) {
                          worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                          worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                          type: "pattern",
                          pattern: "solid",
                          fgColor: { argb: "d0cfcf" }
                        };
                    
              
                      }
                     
                      worksheet.columns.forEach(column => {
                        const lengths = column.values.map(v => v.toString().length);
                        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                        column.width = maxLength+2;
                      });
                  
        
        
                      table.commit();
        
                })
        
                  
                const writeFile = (fileName, content) => {
                    const link = document.createElement("a");
                    const blob = new Blob([content], {
                      type: "application/vnd.ms-excel;charset=utf-8;"
                    });
                    link.download = fileName;
                    link.href = URL.createObjectURL(blob);
                    link.click();
                  };
                //const stream = fs.createWriteStream(filename);
                
                workbook.xlsx.writeBuffer().then((buffer) => {
                    writeFile(filename, buffer);
                });



            }else{
              SetTitle("Error");
              SetDescription(res.message)
              SetErrorModal(true);
            }
        });
    }
    return (
        <>
            <button className='btn btn-transparent btn-twoline' onClick={generateExcel}>
                <span className='smallerSpan'>Regular Employee</span>
                <span className='biggerSpan'>Update Tag</span>
            </button>
            <ErrorModal modalError={errorModal} handleCloseModalError={handleCloseModal}   title={title} description={description}  key={'GenerateTagRegilar'} />
            
            </>
            )
} 