import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/Category.scss';

import   '../../../usermanagement/styles/addEmployee.scss';

import {addLocation} from '../../actionMethods/actionMethods'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as HelpIcon} from '../../../assets/images/circle-info.svg'


// import { AutoComplete } from 'antd';
import { Select,Button ,Steps  } from 'antd';

const { Option } = Select;

function onChange(value) {

}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};



function AddLocation(props) {
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:""

  const [availableTag,SetAvailTags] =useState(props.availableTag?props.availableTag:[]);
	const [selectedTag,SetSelectedtag] = useState('')
    const [value, setValue] = useState('');
    const [name,SetName]=useState('');
    const [nameError,SetNameError]=useState('');
    const [description,SetDescription]=useState('');
    const [descriptionError,SetDescriptionError]=useState('');
    const [category_id,SetCategoryID] = useState(props.categoryID?props.categoryID:'');
    const [category_idError,SetCategoryIDError] = useState('');
    const [capacity,SetCapacity] =useState('');
    const [capacityError,SetCapacityError] =useState('');
    const [area_size,SetAreaSize] =useState('');
    const [area_sizeError,SetAreaSizeError] =useState('');
    const [loc_type,SetLocType] =useState(1);
    const [loc_typeError,SetLocTypeError] =useState('');
    const [type,SetType] =useState('location')
    const [availTag,SetAvailTag] =useState([]);
    const [tag_serial,SetTagSerial] =useState('')

    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [descriptionMessage,SetDescriptionMessage] =useState('');
    const [locationType,SetlocationType]=useState([]);
    const reg = /^\S.*$/;


  const { Step } = Steps;
  const [current, setCurrent] = React.useState(0);



    const [dropdownOpt,SetDropDownOpt]=useState([])

    useEffect(()=>{
      SetDropDownOpt(props.dataCategory)
    },[props.dataCategory])
  useEffect(()=>{

    SetlocationType(props.locationType)
    },[props.locationType])


    const onSelect = (data) => {

      };
      const onChange = (data) => {
        setValue(data);
      };
      const onChangeCat=(data)=>{
         SetCategoryID(data);
      }
      const onChangeProductive = (data)=>{
         SetLocType(data);
      }
      const onSearch = (searchText) => {
         
      };

      function onChangeSelect(value) {

      }
      function onChangeStep(step){

          if(current==0){

          }else{
            setCurrent(step);
          }

        }

        function AddLocationHandler(){

          if(name==''){
            SetNameError('Location Name is required') 
          }
          if(name.length > 255){
            SetNameError('Location Name length should be less than 255 character') 
          }
            if(category_id==''){
              SetCategoryIDError('Category is required')
            }
            if(description==''){
              SetDescriptionError('Description is required');
            }
            if(description.length > 255){
              SetDescriptionError('Description length should be less than 255 character')
            }
            if(capacity == ''){
              SetCapacityError('Capacity is required')
            }
            else if(!capacity.toString().match(/^\d+/)){
              SetCapacityError('Only Numeric Value allowed  && value should in range 0.1 - 999999')
            }
            else if(capacity.toString().includes('.')){
              SetCapacityError("Float Value doesn't allow")
            }
            else if(capacity< 1 || capacity > 999999 ){
              SetCapacityError('Capacity should be greater than 1 and  less than 999999')
            }
            else if(capacity.toString().length>= 6){
              SetCapacityError('Capacity should be less than 999999')
            }else{
              SetCapacityError('')
            }
            if(area_size==''){
              
              SetAreaSizeError('Area Size is required')
            }else if(!area_size.toString().match(/^\d+/)){
              SetAreaSizeError('Only Numeric Value allowed  && value should in range of 0.1 - 999999')
            }else if(area_size<= 0.1 && area_size>= 999999){
              SetAreaSizeError('Area should be greater than 0.1 sq.m   and  less than 999999 sq.m')
            }else if(area_size.toString().length >= 6){
              SetAreaSizeError('Area Size should be less than 999999 sq.m')
            }

            if(loc_type==''){
              SetLocType('Location Type is required')
            }
            
            if(name!=''&& name.length <= 255 && category_id!==''&&description!=''&& description.length <=255  && capacity!='' && !capacity.toString().includes('.') && capacity.toString().length<=  6 && capacity >= 1 && capacity <= 999999    && area_size!=''&& area_size >= 0.1 && area_size <= 999999 && area_size.toString().length<=6  && loc_type!=''){
                  SetNameError('') 
                  SetCategoryIDError('')
                  SetDescriptionError('')
                  SetCapacityError('')
                  SetAreaSizeError('')
                  SetLocTypeError('')
                  let requestBody={}
                  requestBody.name=name
                  requestBody.description=description
                  requestBody.org_id= org_id
                  requestBody.category_id= props.categoryID
                  requestBody.capacity=capacity
                  requestBody.area_size=area_size
                  // requestBody.loc_type=loc_type
                  if (selectedTag !== '' && selectedTag !== 'Select a tag') {
                    requestBody.tag_serial = selectedTag
                    }
                  requestBody.type=type
                  requestBody.session=session
                  addLocation(requestBody).then(res=>{
                  
                    if(res.status==200 || res.code==200){
                      SetTitle(<h4>Location is added</h4>)
                      SetDescriptionMessage('')
                      SetModalSucess(true)            
                    }else{
                      SetTitle(<h4>{res.message}</h4>)
                      SetDescriptionMessage('Fail to add location')
                      SetModalError(true)            
                    }

                  })



            }


        }
        function handleCloseModalSucess(){
          SetModalSucess(false)
          props.handleCloseModal();
          props.Reload();
        }
        function handleCloseModalError(){
          SetModalError(false) 
        }
    return(
        <div>
            <div className='modalHeader modalHeader'>
                    <h4>Add Location</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
                <div className='modalBody modalBody-AddLocation'>

                  
				<div className="steps-content step-user-info">
					<Row>
          <Col lg={6}>
                  
                  <div className='field-row'>
                      <label>Location Name* <div className='posRel'><HelpIcon /> <span className='helpSmall'>Max length 255 character</span></div></label>
                     
                      <input type="text" placeholder="Enter Location Name " className={nameError!=''?'form-control has-error':'form-control'} value={name}  onChange={(e)=>{if(reg.test(e.target.value)){SetName(e.target.value);SetNameError("")}else if(e.target.value==''){SetName(e.target.value)} }  } />           
                      {nameError!=''?<div className='errorMessage'>{nameError}</div>:""}
                  </div>
              </Col>  
              <Col lg={6}>
                    <div className='field-row'>
                        <label>Category* <div className='posRel'><HelpIcon /> <span className='helpSmall'>Max length 255 character</span></div></label>
                     
            <input type="text" className='form-control' value={props.cat_name} readOnly disabled />
            {category_idError!=''?<div className='errorMessage'>{category_idError}</div>:""}
            </div>

                    </Col>
           <Col lg={6}>
							<div className='field-row'>
									<label>Description<div className='posRel'><HelpIcon /> <span className='helpSmall'>Location Description</span></div></label>        
									<input type="text"  className={descriptionError!=''?'form-control has-error':'form-control'} value={description}  onChange={(e)=>{if(reg.test(e.target.value)){SetDescription(e.target.value);SetDescriptionError("")}else if(e.target.value==''){SetDescription(e.target.value)} }  }  />
                  {descriptionError!=''?<div className='errorMessage'>{descriptionError}</div>:""}
							</div>	
						</Col>
            <Col lg={6}>
        <div className='field-row'>
          <label>Capacity<div className='posRel'><HelpIcon /> <span className='helpSmall'>No of employee min 1 and Max 999999</span></div></label> 
          
              <input type="text" className={capacityError!=''?'form-control has-error':'form-control'} value={capacity}  onChange={(e)=>{if(reg.test(e.target.value)){SetCapacity(e.target.value);SetCapacityError("")}else if(e.target.value==''){SetCapacity(e.target.value)} }  }  />
              {capacityError!=''?<div className='errorMessage'>{capacityError}</div>:""}
                </div>
        </Col>
        <Col lg={6}>
    <div className='field-row'>
        <label>Area Size <div className='posRel'><HelpIcon /> <span className='helpSmall'>Area size Min 0.1 and Max 999999 sq.m</span></div></label> 
        <input type="text" className={area_sizeError!=''?'form-control has-error':'form-control'} value={area_size} onChange={(e)=>{if(reg.test(e.target.value)){SetAreaSize(e.target.value);SetAreaSizeError("")}else if(e.target.value==''){SetAreaSize(e.target.value)} }  }  />
        {area_sizeError!=''?<div className='errorMessage'>{area_sizeError}</div>:""}
     </div>
  </Col>
  <Col lg={6}>
    <div className='field-row'>
      <label>
        Select Tag <strong></strong>
        <div className='posRel'>
          <HelpIcon />
          <span className='helpSmall'>Select a new tag</span>
        </div>
      </label>

      <Select
        placeholder="Select Department"
        optionFilterProp="children"
        onChange={(val) => SetSelectedtag(val)}
        className={'form-control'}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        defaultValue={selectedTag}
      >
        <Option value={''} key={"Tag0"}>{'Select a tag'}</Option>
        {availableTag.length > 0 && availableTag !== '' ? (
          availableTag.map((item) => {
            return (
              <Option value={item.serial_num} key={"availTag" + item.serial_num}>
                {item.serial_num}
              </Option>
            );
          })
        ) : (
          <></>
        )}
      </Select>
    </div>
  </Col>
  {/* <Col lg={6}>
  <div className='field-row'>
    <label>Location type <div className='posRel'><HelpIcon /><span className='helpSmall'>Location Type for this current location (eg productive,non productive)</span></div></label> 
 
    <Select
                showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={onChangeProductive}
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
               
                defaultValue={loc_type}
                className={loc_typeError!=''?'form-control has-error':'form-control'}
            >
              {locationType.map((item)=>{
                return <Option value={item.id} key={"locationType"+item.id}>{item.loc_type}</Option>
              })}
										    </Select>
            {loc_typeError!=''?<div className='errorMessage'>{loc_typeError}</div>:""} 
        </div>
  </Col> */}
					</Row>						

				</div>
  <div className="steps-action">
					<Button type="primary" onClick={ AddLocationHandler} className={'btn btn-primary'} >
						Finish
					</Button>
				</div>
                </div>              
                <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage} />
	            	<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}  />

        </div>
    )
}
export default AddLocation;