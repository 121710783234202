import axios from "axios";

let prefixURL = process.env.REACT_APP_URL

export function userInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/user_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}

export function roleInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/role_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     

}
export function groupInfo( requestBody,sub_org_id) {
    return axios.get(prefixURL + `/dashboard/group_info?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)     
}
export function categoryInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/category_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}

export function locationInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/location_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}
export function deviceInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/device_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}
export function exportData( requestBody) {
    return axios.get(prefixURL + `/bulk/bulk_data_list?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}

// export function importData( requestBody) {
//     return axios.get(prefixURL + `/bulk/upload_bulk_data`,requestBody)
//         .then(res => res.data).catch(err => err)     
// }

export function importData(requestBody,file){
    let bodyFormData = new FormData();
    bodyFormData.append('data',JSON.stringify(requestBody));
    bodyFormData.append('file',file);
    return axios({
        method: "post",
        url: prefixURL + `/bulk/upload_bulk_data`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(res=>res.data)
        .catch(err=>err);
 }