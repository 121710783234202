import axios from "axios";

let prefixURL = process.env.REACT_APP_URL
let prefixURL2 = process.env.REACT_APP_URL_2

//https://api.traceplus.ai/trace/api/v1/get_cycle_count_plan?session=8f72ea326b2b4239956beeba29b11a8c&org_id=28&sub_org_id=0&date=2023-06-09
export function getHolidayList(requestBody){
    return axios.get(prefixURL + `/organization/get_holidays_list?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}`)
        .then(res => res.data).catch(err => err)     
} 

export function createHoliday(requestBody){
    return axios.post(prefixURL + `/organization/create_holiday`,requestBody)
        .then(res => res.data).catch(err => err)     
}

export function editHoliday(requestBody){
    return axios.post(prefixURL + `/organization/edit_holiday`,requestBody)
        .then(res => res.data).catch(err => err)     
}
