import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../style/styles.scss';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import {ReactComponent as UPIcon} from  '../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import Scrollbars from 'react-custom-scrollbars';
import {Radio, Button } from 'antd';
import { getLocationList } from '../../sitemanagement/actionMethods/actionMethods';
import '../../assetmanagement/styles/addEmployee.scss';
import {UpdateProductive,getDepartmentList} from '../actionMethods/actionMethods'
import { Select } from 'antd';

function EditProductiveType(props){
    const [loader,SetLoader] =useState(true);
    const [name,SetName]=useState('');
    const [nameError,SetNameError]=useState('')
	const { Option } = Select;
    const reg = /^\S.*$/;
    const [locationList,SetlocationList]=useState([]);
    const [productive,SetProductive]=useState([]);
	const [nonproductive,SetNonProductive]=useState([]);
	const [lessproductive,SetLessProductive]=useState([]);
    const [nonSelectProductive,SetNonSelectProductive]= useState([])
	const [department_list,SetDepartMentList] = useState([])
	const[deptid,setDeptid] =useState([])
	const [searchLocation,setSearchLocation] = useState("");
	const [listLocation,SetListLocation]= useState([]);
	const[deptname,setDeptname] =useState("")

	const timeoutRef = useRef(null);

	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""

    useEffect(()=>{
		let requestBody={}
		requestBody.session = session
		requestBody.org_id = org_id
		requestBody.tag_type= 'personal'
		getDepartmentList(requestBody.session,requestBody.org_id,requestBody.sub_org_id).then(res=>{
			if(res.status==200){
				SetDepartMentList(res.data)
				
			}
		})
		getLocationList(requestBody).then(res=>{
			if(res.status==200){
				SetlocationList(res.data)
				SetListLocation(res.data);
				SetLoader(false)
			}
			
		})

        
	},[])

    useEffect(()=>{
        
        if(props.productiveIDData){
            SetName(props.productiveIDData.category_name)
            let prod = JSON.parse(props.productiveIDData.productivity);
		
			let product = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')
	
            SetProductive(prod[product.id])
			SetNonProductive(prod[nonprod.id])
            SetLessProductive(prod[lessprod.id])

        }
        
    },[props.productiveIDData])

		  function searchHandler(val){
		let list =[...locationList].filter(el=>el.location_name.toLowerCase().includes(val.trim().toLowerCase()));
		SetListLocation(list);
		setSearchLocation(val);
	  }

    function ChangeRadio(e,val){
		let val2 = parseInt(e.target.value);
		if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
				searchHandler("");
		}, 4000);

		if(e.target.checked){
			if(val=="productive"){
				let arr=[...productive];
				arr.push(val2)
				SetProductive(arr)
				
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}
			
				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);	
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)	
				}

			}
			else if(val=='lessproductive'){
				let arr=[...lessproductive];
				arr.push(val2)
				SetLessProductive(arr)

				let arr2 = [...productive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetProductive(arr2);
				}	

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}	
				
			}else{
				let arr=[...nonproductive];
				arr.push(val2)
				
				SetNonProductive(arr)
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}				
				let arr3 = [...productive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}
			}
	  }
	}

    function AddProductiveHandler(){

        let arr = [];
        let arr2=[];
    
    
        
        for(let i =0 ;i<locationList.length;i++){
            let locid=locationList[i].id;
            if(productive.indexOf(locationList[i].id)> -1 || nonproductive.indexOf(locationList[i].id)> -1 || lessproductive.indexOf(locationList[i].id)> -1 ){
                        
            }else{				
                arr.push(locationList[i].id);
            }
        }
        SetNonSelectProductive(arr);
    
        if(name==''){
            SetNameError('Required name of productive type')	
        }else if(name.length > 255){
            SetNameError('Length of Name should be less than 255 character')	
        }
        if(arr.length>0){
    
        }else{
            let requestBody={}
            requestBody.category_type =name;
            requestBody.org_id= org_id
            requestBody.session= session;
			//requestBody.department_id=deptid
            //requestBody.productivity={"1":productive,"2":lessproductive,'3':nonproductive};
			let prod = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')
			requestBody.productivity={[prod.id]:productive,[lessprod.id]:lessproductive,[nonprod.id]:nonproductive};
			requestBody.sub_org_id = sub_org_id
            requestBody.productivity_id = props.productiveIDData.id
                        
            UpdateProductive(requestBody).then(res=>{
                if(res.status==200|| res.code==200){
                    props.modalSucessHandler(<h4>Productive Type Updated</h4>,res.message)
                }else{
                    props.modalErrorHandler(<h4>{res.message}</h4>,'Productive Type is not  updated')
                }
            })
        
        }
    
    

    }


	
	function department(val) {
		setDeptid(val);
		const selectedOption = department_list.find((item) => item.id === val);
		setDeptname(selectedOption.name);
	  }
	  




    return(<div>
                   <div className='modalHeader'>
                    <h4>Edit Productivity Type</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
				{loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: 
				<div className='modalBody'>
				
				<div className="steps-content step-user-info">
					<Row>
						<Col lg={12}>
							<div className='field-row'>
									<label>Name*<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={nameError!=''?'form-control has-error':'form-control'} value={name} onChange={(e)=>{if(reg.test(e.target.value)){SetName(e.target.value);}else if(e.target.value==''){SetName(e.target.value)} }  } />
									{nameError!=''?<div className='errorMessage'>{nameError}</div>:""}
							</div>	
						</Col>
						{/* <Col lg={6}>
						<div className='field-row'>
						 <label>Department*<div className='posRel'><HelpIcon /></div></label>
						 <Select
								 placeholder={deptname ? deptname : "Select Sub Department"}
									// optionFilterProp="children"
									onChange={(val,label)=>{department(val)}}
									// onChange={department(val)}
									value={deptname}
									 className='textCap'
								>
									{department_list.map((item)=>{
										return <Option value={item.id} key={item.id}>{item.name}</Option>
									})
									}
									
								</Select>
				 </div>	
						</Col> */}

					</Row>							
				
            <Row className='header-table-2 header-table-3'>
						<Col lg={3} className='label-disp label-check' style={{textAlign:"left"}}><input type="searchLocation" value={searchLocation} placeholder='Location Name' onChange={(e)=>searchHandler(e.target.value)} className='inlineTitle' /></Col>
						<Col lg={3} className='label-check'>Productive</Col>
						<Col lg={3} className='label-check'>Less Productive</Col>
						<Col lg={3} className='label-check'>Non Productive</Col>
                   </Row>
					<Scrollbars style={{maxHeight:300,height:300}}>
						<div className={'accord-role active'} >
							{listLocation.map((item)=>{
									return (
										<Row key={"departmentID"+item.id} className= {nonSelectProductive.indexOf(item.id)> -1?'cotnent-table error-highlight':'cotnent-table'}>
										<Col lg={3} className='label-disp textCap'>{item.location_name.toLowerCase()}</Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={productive.indexOf(item.id)!=-1?true:false} onChange={(e)=>ChangeRadio(e,'productive')}></Radio></Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={lessproductive.indexOf(item.id)!=-1?true:false}  onChange={(e)=>ChangeRadio(e,'lessproductive')}  ></Radio></Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={nonproductive.indexOf(item.id)!=-1?true:false} onChange={(e)=>ChangeRadio(e,'nonproductive')}></Radio></Col>					  
									</Row>   
									)
							})
							}						
						</div>
					</Scrollbars>						
				</div>
                
				<div className="steps-action">
					
					<Button type="primary" onClick={AddProductiveHandler} className={'btn btn-primary'}>
						Update Productive Type
					</Button>
						</div>
    
                </div>}
    </div>

    )
}


const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(EditProductiveType))