


import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../style/styles.scss';
import moment from 'moment';
import { Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useHistory } from 'react-router-dom';
import LoaderSpinner from '../../assets/images/Spinner Loader.gif'

import {ReactComponent as GroupIcon} from '../../assets/images/hexagon-plus.svg'
import Avatar from '../../assets/images/avatar.png'
import WarningModal from '../../components/WarningModal'; 
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { getProcessList, removeProcess } from '../actionMethods/actionMethods';

function ProcessList(props){
    
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [WidthContainer,setWidthContainer]=useState('');
    const [loader,SetLoader]=useState(true);
    const elementRef = useRef(null);   
    const [ProcessListData,SetProcessListData] = useState([])
    const [DefaultProcessListData,SetDefaultProcessListData] = useState([])
    const [listEmp,SetListEmp]=useState([])
    const [listEmpDefault,SetListEmpDefault]=useState([])
    const [parentGroup,SetParentGroup]=useState([])
    const [availableTag,SetAvailTag]=useState(props.availableTag?props.availableTag:[])   
    const [modalDelete,SetModalDelete] =useState(false); 
    const [title,SetTitle]=useState('')
    const [description,SetDescription]=useState('')
    const [ID,SetID] =useState('')
    const [type,SetType] =useState('')
    const [modalSucess,SetModalSucess] =useState(false);
    const [modalError,SetModalError] = useState(false);



    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""
    
    
    const [permissionProcess,SetPermissionProcess]=useState([]);

    //let org_id = 23
    useEffect(()=>{
        console.log("props.permission[0]",props.permission)
        if(props.permission!=null && props.permission){
            let dept= props.permission;
            SetPermissionProcess(dept);
        }

    },[props.permission])

    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }, [elementRef]);

    useEffect(()=>{

        let data = [...DefaultProcessListData]
 
        let arr= []
        let allemp = [];
        let parentUniq= []
        for(let i=0;i<data.length;i++){
            //child exist
           if(data[i].process_name.toString().toLowerCase().includes(props.searchProcess.toString().toLowerCase())){
            
            arr.push(data[i]);
           }
        }
 
        SetProcessListData(arr);
   
    },[props.searchProcess])


    useEffect(() => {
        let requestBody={};
        if(props.functionType.length > 0 ){
                    requestBody.session=session
        requestBody.org_id=org_id
    
        requestBody.flag='employee'
        requestBody.org_id=org_id

        getProcessList(userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{
            if(res.status===200){
                console.log("res",res);
                let data= res.data;
                for(let i=0;i<data.length;i++){
                    data[i].functions = JSON.parse(data[i].functions).sub_department_ids;
                }
                console.log("res process",data)
                SetProcessListData(data);
                SetDefaultProcessListData(res.data);
                SetLoader(false)
            }
        })

        }


        

    }, [props.inc,props.functionType]);

    function DisplayNameProfile(params){
        if(params.value){

        }else{

        }
            

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
         eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+
                            '<span class="profileTable"><img src='+Avatar+' />'+params.value.toLowerCase()+'</span>'+''+
                            
                           '</div>';
      
         return eGui;

    }

    // (data[i].process_name,data[i].functions,props.functionType)
    function  AGData(name,func,subDept){

       let  filter= [];
        let data = 
       
        filter = func.map(el=> subDept.find(item=>item.sub_department_id == el) )
        console.log("filter",func,filter,subDept);
       

      let arr=  <div className="ag-theme-alpine" style={{height:(60 + ( 40* filter.length + 1 ) ), width: '100%'}}>
        <AgGridReact
          rowData={filter}
          defaultColDef={{
               sortable: true,
               resizable: true,
               width: type? ( WidthContainer - 120):( WidthContainer - 80),
               height:40,
          }}
        >
            <AgGridColumn 
               field="category_name"
               headerName={"Function Name"}    
               //valueFormatter={(params)=><><img src={Avatar} />{params.value}</>  }
            //    cellRenderer= {(params)=>DisplayNameProfile(params)}
           ></AgGridColumn>

      </AgGridReact>     
      </div>

      return arr;
    }
    
function SucessMessage(){
    SetModalSucess(true);
}
function ErrorMessage(){
    SetModalError(true);
}

    function  handleDeleteConfirm(){


        let requestBody={};
        requestBody.org_id= org_id
        requestBody.sub_org_id= userDetails.sub_org_id;
        requestBody.process_id = ID
        requestBody.session= session
        removeProcess(requestBody).then(res=>{

            if(res.status==200|| res.code==200){
                SetModalDelete(false);
                    props.modalSucessHandler(<h4>Process is Deleted</h4>,res.message)
            }else{
                    props.modalErrorHandler(<h4>{res.message}</h4>,'Failed to Delete Process')
            }
        })

        console.log("ID",ID);


    }
    function handleCloseModalWarning(){
        SetModalDelete(false);
    }
function ShowGroupCardList(data){


        let arr=[]
        if(data.length > 0){
    
            for(let i=0;i<data.length;i++){

            arr.push( 
            <Row className={activeAccord.indexOf(i+1)!==-1 || props.searchProcess.length > 0?'contentGroup active':'contentGroup'} key={'department'+i+data[i].process_name.toLowerCase()}>
            <div className='group-header'> 
             <Col lg={4} className="align-middle">
                 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className='accordion-icon' onClick={()=>AccordionHandler(i+1,'primary')} >
                   {activeAccord.indexOf(i+1)!==-1 || props.searchProcess.length > 0 ?<CircleArrowDown/>:<CircleArrow />}     
                 </span>

                 

                 <span className='align-title-left'>
                 <h4 className='textCap'>{data[i].process_name.toLowerCase()}</h4>
                     {/* <div className='sub-stats'><small>Active Sub Department : <strong>{data[i].child?data[i].child.length:0}</strong> Inactive Sub Department <strong>0</strong></small></div> */}
                 </span>
             </Col>
             
             <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>
                    
                    {permissionProcess && permissionProcess.edit=='True'?<button className='btn btn-smaller btn-icon'onClick={()=>props.modalOpenGroupEdit(data[i])}><EditIcon/><span>Edit</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled' disabled><EditIcon/><span>Edit</span><span className='messageTag'>Access Restricted</span></button>}
                    /{permissionProcess && permissionProcess.remove=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>{SetType('Department');SetID(data[i].id);SetTitle(<><strong>{data[i].process_name}</strong> Process</>);SetModalDelete(true) }} ><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled btn-delete' disabled ><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}</Col>
             </div>
             
        <div ref={elementRef} className={activeAccord.indexOf(i+1)!==-1|| props.searchProcess.length > 0?'group-content active':'group-content'}>
             {AGData(data[i].process_name,data[i].functions,props.functionType) }
             </div>



             

         </Row>



            )
            }
        }else{
            arr.push( 
                <Row className='contentGroup nofound' key="noFound">
                    <div className='group-header'>
                        <Col className='align-middle'>
                            <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                        </Col>
                    </div>
                </Row>
                ) 
        }

        return arr;

    }
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  


    useEffect(()=>{
        let arr= [...DefaultProcessListData]
       

        if(sortKey=='group'){
                
                arr = arr.sort((a, b) => {
                    a = a.process_name.toUpperCase()
                    b = b.process_name.toUpperCase()
                    return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                })
        }
        if(sortKey=='tag_count'){
            arr = arr.sort(function (x, y) {
                return sort === 'desc' ?y.count - x.count:x.count-y.count;
            });
   

        }
        SetProcessListData(arr)

    },[sort,sortKey])


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val,type='secondary'){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
         }    
        else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }


        
        
    }

    function HeaderEmpSubTab(){
        return (
            <Row className='subHeaderEmptTable'>
                <Col lg={3}>Employee Name <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Role <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Tag Serial <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Action</Col>
            </Row>
        )
    }

    function editHandlerAlert(data){

        props.modalOpenEditEmployee(data);
    }

    function deleteHandlerAler(data){
        SetType('User');
        SetID(data.emp_id);
        SetTitle(<><strong>{data.emp_name}</strong>  User</>);
        SetModalDelete(true); 
      
    }

    return(

        <div className='roleList' ref={elementRef}>
                        <div className='br-seperator-row'>   
        <Row className='headerGroup' >
            
            <Col lg={4} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('group')}>Process Name
                <span className={sortKey=='group'?'filter-icon active':'filter-icon'} >{sortKey=='group'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
                        
            <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>Action</Col>
        </Row>    
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:ShowGroupCardList(ProcessListData)} 
        <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title} />
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={()=>SetModalSucess(false)} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={()=>SetModalError(false)}   title={title} description={description}  />
        </div>

        </div>
        
    )
    

}
export default ProcessList;