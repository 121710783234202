import React from 'react'
import { useHistory } from 'react-router-dom'

export function CommonHeading(props){
    let history= useHistory();
return(
        <div className="page-header">
            <div>
                <h2 className="main-content-title tx-24 mg-b-5">{props.MainTitle}</h2>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" onClick={()=>history.push(props.redirect)}><a href="#">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                </ol>

            </div>


        </div>)
    
}