import React, { useState, useEffect } from 'react'
import ExcelJs from "exceljs";

import {getExportDataDailyEmpBulkUpdate} from '../../actionMethods/actionMethods';
import ErrorModal from '../../../components/ErrorModal';

//(Daily Employee Bulk Update)      


export default function GenerateRegisterDaily(){

  const [errorModal,SetErrorModal]=useState(false);
  const [title,SetTitle]=useState('');
  const [description,SetDescription]=useState('');

  function handleCloseModal(){
      SetErrorModal(false);
  }


  //getting alphabet of excel column
  function numToSSColumnLetter(num) {
    let columnLetter = "",
      t;
  
    while (num > 0) {
      t = (num - 1) % 26;
      columnLetter = String.fromCharCode(65 + t) + columnLetter;
      num = (num - t) / 26 | 0;
    }
    return columnLetter || undefined;
  }
  

  function slugify(string) {  
    return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
  }


    let requestBody={};
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    function generateExcel(){
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id=userDetails.org_id;

        getExportDataDailyEmpBulkUpdate(requestBody).then(res=>{
            if(res.status==200){
             
                const workbook = new ExcelJs.Workbook();
       
        const filename = "DAILY-EMPLOYEE-UPDATE.xlsx";
        const sheetNames = ["DAILY EMPLOYEE UPDATE"];
        
       let sheetData  =[];
       let product_cat = res.data.productivity_category_names;
       let dept_name = res.data.department_names;
       let serial_numbers = res.data.serial_numbers;
       let sub_dept= res.data.sub_department_names;
       let shift = res.data.shift_names;
       
       let dataShift =[];

       

       for (let i = 0; i < 1000000; i++) {
        if ( i >= product_cat.length && i >=dept_name.length && i >= sub_dept.length && i >=shift.length) {
           
             break;
         }else{
            
            dataShift.push({
                cat:product_cat[i]?product_cat[i].trim():"",
                dept:dept_name[i]?dept_name[i].trim():"",
                shift:shift[i]?shift[i].trim():""
             })
         }
      }

        for(let i =0 ;i<serial_numbers.length;i++){
            sheetData.push({serial_id:i+1,tag_serial:serial_numbers[i],emp_name:"",dept:"",sub_dept:"",shift:"",cat:"",checkin:""});
        }

         
        

        sheetNames.forEach(sheetName => {
            let worksheet = workbook.addWorksheet(sheetName);
            let worksheet2 = workbook.addWorksheet('shift');
            let worksheet3 = workbook.addWorksheet('dropdown');

            worksheet3.addTable({
                name: 'dropdown',
                ref: "A1", 
                headerRow: true,
                totalsRow: false,
                style: {
                  theme: "TableStyleMedium2",
                  showRowStripes: false,
                  width: 200
                },
                columns : [
                  {name:'Category', header: 'Category', key: 'cat'},
                  {name:'Department', header: 'Department', key: 'department'},
                  {name:'Shift', header: 'Shift', key: 'shift'},         
                ],
                rows: dataShift.map((e) => {
                  let arr = [e.cat,e.dept,e.shift];      
                  return arr;
                })
              })
              worksheet3.state = 'hidden';            
              const table3 = worksheet3.getTable('dropdown');            
              table3.commit();

       
            //formatting value for department as header and sub department as value;




       
       
             for(let j=0;j<dept_name.length;j++){
             
                let singlePush=[];
                
                singlePush.push(dept_name[j].trim());
                
                
                let sub = sub_dept.filter(item=>item.department_name == dept_name[j]);
                
                
                for(let jj=0;jj<sub.length;jj++){
                  
                  
                    singlePush.push(sub[jj].sub_department_name.trim())
                }
               
                worksheet2.getColumn(j+1).values = singlePush;
                
                    
                }
             
             
              worksheet2.state = 'hidden';    

            //First Sheet
            worksheet.addTable({
                name: sheetName,
                ref: "A1", 
                headerRow: true,
                totalsRow: false,
                style: {
                  theme: "TableStyleMedium2",
                  showRowStripes: false,
                  width: 200
                },

                columns : [
                  {name:'SERIAL ID.', header: 'SERIAL ID.', key: 'serial_id'},
                  {name:'SERIAL NUMBER', header: 'SERIAL NUMBER', key: 'tag_serial'},
                  {name:'NAME',header: 'NAME', key: 'emp_name'},
                  {name:'DEPARTMENT NAME',header: 'DEPARTMENT NAME', key: 'dept'},
                  {name:'SUB DEPARTMENT NAME',header: 'SUB DEPARTMENT NAME', key: 'sub_dept'},
                  {name:'SHIFT NAME',header: 'SHIFT NAME', key: 'shift'},
                  {name:'PRODUCTIVITY CATEGORY NAME',header: 'PRODUCTIVITY CATEGORY NAME', key: 'cat'},
                  {name:'CHECKIN DATE',header: 'CHECKIN DATE', key: 'checkin'},
                ],
               
                rows: sheetData.map((e) => {
                  let arr = [e.serial_id,e.tag_serial,e.emp_name,e.dept,e.sub_dept,e.shift,e.cat,e.checkin];      
                  return arr;
                })
              })
              worksheet.state = 'visible';
              const table = worksheet.getTable(sheetName);
              for (let i = 0; i < table.table.columns.length; i++) {
                  worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                  worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "d0cfcf" }
                };
            
      
              }
             
              worksheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength+2;
              });


              sheetData.forEach((element,index) =>{
              worksheet.getCell('D'+(+index+2)).dataValidation = {
                  type: "list", allowBlank: true,  formulae: ["shift!$A$1:$AZZ$1"],
              };
              worksheet.getCell('E'+(+index+2)).dataValidation = {
                  type: "list", allowBlank: true,  
                  //formulae: ["OFFSET(shift!$A1, 1, MATCH($D$"+(index+2)+", shift!$A$1:$ZZ$50, 0)  - 1,COUNTA(OFFSET(shift!$A1, 1, MATCH($D$"+(index+2)+", shift!$A$1:$ZZ$50, 0)  - 1, 50, 1)), 1)"],
                  formulae:["OFFSET(shift!$A$1, 1, MATCH(D"+(index+2)+", shift!$A$1:$ZZ$1, 0)  - 1,COUNTA(OFFSET(shift!$A$1, 1, MATCH(D"+(index+2)+", shift!$A$1:$ZZ$1, 0)  - 1, 1000, 1)), 1)"]
              };

              //OFFSET(shift!$A1, 1, MATCH($D$2, shift!$A$1:$ZZ$50, 0)  - 1,COUNTA(OFFSET(shift!$A1, 1, MATCH($D$2, shift!$A$3:$ZZ$50, 0)  - 1, 50, 1)), 1)
              worksheet.getCell('F'+(+index+2)).dataValidation = {
                type: "list", allowBlank: true,  formulae: ["dropdown!$C$2:$C$"+shift.length+""],
              };

              worksheet.getCell('G'+(+index+2)).dataValidation = {
                type: "list", allowBlank: true,  formulae: ["dropdown!$A$2:$A$"+product_cat.length+""],
              };
              
              })

            
              table.commit();

        })

          
        const writeFile = (fileName, content) => {
            const link = document.createElement("a");
            const blob = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = fileName;
            link.href = URL.createObjectURL(blob);
            link.click();
          };
        //const stream = fs.createWriteStream(filename);
        
        workbook.xlsx.writeBuffer().then((buffer) => {
            writeFile(filename, buffer);
        });

            }else{
              SetTitle("Error");
              SetDescription(res.message)
              SetErrorModal(true);
          }

        })
        
    }


    return ( <>
              <button className='btn btn-transparent btn-twoline' onClick={generateExcel}>
                        <span className='smallerSpan'>Daily Employee</span>
                        <span className='biggerSpan'>Register Worker</span>
                </button>
              <ErrorModal modalError={errorModal} handleCloseModalError={handleCloseModal}   title={title} description={description}  key={'GenerateTagRegilar'} />
            </>   
            )
} 
