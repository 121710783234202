import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import  '../styles/style.scss';
import moment from 'moment';
import {Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as MinusIcon} from '../../assets/images/square-minus.svg'
import {ReactComponent as Back} from '../../assets/images/angle-left.svg'
import LoaderSpinner from '../../assets/images/Spinner Loader.gif'
import { Select, Radio,Switch } from 'antd';
import {CreteRole} from '../actionMethods/actionMethods'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {ReactComponent as HelpIcon} from '../../assets/images/circle-info.svg'
const customStyle = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      width: '25rem',
      height: '13rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}

function AddRole(props){
    const { Option } = Select;
    const defaultChecked = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];
  
    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    const [checkedList, setCheckedList] = React.useState(defaultChecked);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    //const [activeAccord,SetActiveAccord] =useState([]);
    let count100 = [];
    for(let i= 1;i<101;i++){
      count100.push(i);
    }
    const [activeAccord,SetActiveAccord]=useState(count100)
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };
      

      
    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  
    const [size, setSize] = React.useState('default');
    const [role_name,Setrole_name]=useState('');
    const [role_nameError,Setrole_nameError]=useState('');
    
    const [user_management,Setuser_management]=useState("True");
    const [site_management,Setsite_management] =useState("True")
    const [device_management,Setdevice_management] =useState("True")
    const [group_management,Setgroup_management] =useState("True")
    const [role_management,Setrole_management] =useState("True")
    const [asset_management,Setasset_management] =useState("True")
    const [visitor,Setvisitor] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [employee,Setemployee] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [device,Setdevice] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [asset,Setasset] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [group,Setgroup] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [role,Setrole] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [category,Setcategory] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [location,Setlocation] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [dashboard,Setdashboard] =useState({"View": "True", "Bulk_import": "False", "Bulk_export":"False"})
    const [dashboard_management,Setdashboard_management]=useState("True")
    const [webapp_dashboard,Setwebapp_dashboard] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [webapp_sitem_anagement,Setwebapp_sitem_anagement] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [webapp_manpower_management,Setwebapp_manpower_management] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [webapp_visitor_management,Setwebapp_visitor_management] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [webapp_policy_management,Setwebapp_policy_management] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [webapp_productivity_management,Setwebapp_productivity_management] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [web_app_cycles,Setweb_app_cycles] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})

    const [UserAll,SetUserALL] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [SiteAll,SetSiteAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [DeviceAll,SetDeviceAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [GroupAll,SetGroupAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [RoleAll,SetRoleAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [AssetAll,SetAssetAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [productive_management,Setproductive_management] = useState('True');
    const [productivity,Setproductivity] = useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"});
    
    const [WebAll,SetWebAll] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
    const [loader,SetLoader]=useState("False");
    const [redirectTrue,SetRedirectTrue] = useState(false)  
    const [showResetModal,SetResetModal]=useState(false);
    const [mobile_app,Setmobile_app] = useState({"policy": "False", "visitor": "False", "default":"False"})
    const [geo_map_web_all,Setgeo_map_web_all]=useState("True");
    const [geo_map_admin_all,Setgeo_map_admin_all]=useState("True");
    const [geo_map_web,Setgeo_map_web]=useState({"facility_tracker":"True","employee_tracker":"True"});
    const [geo_map_admin,Setgeo_map_admin]=useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"});
  
    const [team_management,SetTeamManagement] =useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"});
    const [teamAll,SetTeamAll] =useState("True");

    const [reportAll,SetReportAll] =useState("True");
   

    const [report,Setreport]=useState({"View":"True","export":"True"});
    const [cycle_count_permissions,Setcycle_count_permissions]=useState({"View":"True","export":"True"});
    const [bulk_permissions,Setbulk_permissions]=useState({"bulk_shift": "True", "daily_employee_bulk": "True", "employee_tag_changing_bulk": "True", "cycles_count_bulk": "True"});
    const [reportLocation,SetReportLocation]= useState({"View":"True","export":"True"})
    const [reportWorkingHour,SetReportWorkingHour]= useState({"View":"True","export":"True"})
    const [reportCycleCount,SetReportCycleCount]= useState({"View":"True","export":"True"})

    const [webapp_dashboard_condition,Setwebapp_dashboard_condition] =useState("True");
    const [webapp_site_condition,Setwebapp_site_condition] =useState("True");
    const [webapp_manpower_condition,Setwebapp_manpower_condition] =useState("True");
    const [webapp_visitor_condition,Setwebapp_visitor_condition] =useState("True");
    const [webapp_policy_condition,Setwebapp_policy_condition] =useState("True");
    const [web_app_cycle_count_condition,Setweb_app_cycle_count_condition] =useState("True");
    const [webapp_productivity_condition,Setwebapp_productivity_condition] =useState("True");

    const [adminapp_shift_condition,Setadminapp_shift_condition] =useState("True");
    const [adminapp_shift_permissions,Setadminapp_shift_permissions]= useState({"View": "True", "edit": "False", "Create":"False", "remove":"False"});
    
    const [adminapp_mass_condition,Setadminapp_mass_condition] =useState("True");
    const [adminapp_mass_permissions,Setadminapp_mass_permissions]= useState({"bulk_shift": "False", "daily_employee_bulk": "False", "employee_tag_changing_bulk": "False","cycles_count_bulk": "False"});

    const reg = /^\S.*$/;
    useEffect(()=>{
      props.ToggleProfile();
    },[])

    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
        }   
        else {  
            ActivList.push(val);
        }
        SetActiveAccord(ActivList);
    }

    function ResetHandler(){

      Setrole_name('');
      Setrole_nameError('');
      Setuser_management("True");
      Setsite_management("True")
      Setdevice_management("True")
      Setgroup_management("True")
      Setrole_management("True")
      Setproductive_management("True")
      Setvisitor({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setemployee({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setdevice({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setgroup({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setrole({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setasset({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setcategory({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setlocation({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      Setdashboard({"View": "True", "Bulk_import": "False", "Bulk_export":"False"})
      Setproductivity({"View": "True", "edit": "False", "Create":"False", "remove":"False"})

      Setwebapp_dashboard({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      Setwebapp_sitem_anagement({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      Setwebapp_manpower_management({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      Setwebapp_visitor_management({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      Setwebapp_policy_management({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      Setwebapp_productivity_management({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
      SetUserALL({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      SetSiteAll({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      SetDeviceAll({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      SetGroupAll({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      SetRoleAll({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      SetWebAll({"View": "True", "edit": "False", "Create":"False", "remove":"False"})
      
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
      if(redirectTrue){
        props.history.push(`/role-management`);
      }
      SetResetModal(false);
      
    }


    function handleCloseModalSucess(){
      SetModalSucess(false)
      props.history.push(`/role-management`)    
    }
    function handleCloseModalError(){
      SetModalError(false) 
    }


    function AddRolehandler(event){

      event.preventDefault();

      if(role_name==''|| role_name.trim().length==0){
        Setrole_nameError('Role Name is required');
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
      }else if(role_name.length > 255){
        Setrole_nameError('Role Name length should be less than 255 character');
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
      }
      else if(role_name!=''){
        SetLoader("True");
        let requestBody={}
        requestBody.role_name=role_name.trim()
        requestBody.user_management=user_management
        requestBody.site_management= site_management
        requestBody.device_management= device_management
        requestBody.dashboard_management= dashboard_management
        requestBody.dashboard= dashboard
        requestBody.group_management= "True"
        requestBody.role_management= role_management
        requestBody.asset_management= asset_management
        //webapp productivity
        requestBody.productive_management=productive_management
        requestBody.productivity = productivity
        requestBody.visitor= visitor
        
        requestBody.asset= asset
        requestBody.employee= employee
        requestBody.device= device
        requestBody.group= group
        requestBody.role= role
        requestBody.category= category
        requestBody.location= location
        requestBody.mobile_app=mobile_app
        requestBody.report= reportAll

        
        requestBody.geo_map_permissions = geo_map_web
        requestBody.location_permissions = reportLocation
        requestBody.working_hour_permissions = reportWorkingHour

        requestBody.geo_map_admin= geo_map_admin_all
        requestBody.geo_map= geo_map_web_all
        requestBody.geo_map_admin_permissions= geo_map_admin

        requestBody.team_management= teamAll
        requestBody.team_permissions= team_management



/*
        requestBody.webapp_dashboard= {"View": "True", "edit": "True", "Create": "True", "remove": "True"}
        requestBody.webapp_site_management= {"View": "True", "edit": "True", "Create": "True", "remove": "True"}
        requestBody.webapp_manpower_management= {"View": "True", "edit": "True", "Create": "True", "remove": "True"}
        requestBody.webapp_visitor_management= {"View": "True", "edit": "True", "Create": "True", "remove": "True"}
        requestBody.webapp_policy_management= {"View": "True", "edit": "True", "Create": "True", "remove": "True"}
        requestBody.webapp_productivity_management =webapp_productivity_management; 
  */
        requestBody.webapp_health_management= webapp_dashboard;
        requestBody.webapp_site_management= webapp_sitem_anagement;
        requestBody.webapp_manpower_management= webapp_manpower_management;
        requestBody.webapp_visitor_management= webapp_visitor_management;
        requestBody.webapp_policy_management= webapp_policy_management;
        
        //web app productiviity
        requestBody.productivity_management = webapp_productivity_condition;
        requestBody.webapp_productivity_management =webapp_productivity_management; 


        requestBody.geo_map_permissions = geo_map_web
        requestBody.location_permissions = reportLocation
        requestBody.working_hour_permissions = reportWorkingHour

        //condition page
        requestBody.webapp_health_management_page = webapp_dashboard_condition
        requestBody.webapp_site_management_page = webapp_site_condition
        requestBody.webapp_manpower_management_page = webapp_manpower_condition
        requestBody.webapp_visitor_management_page = webapp_visitor_condition
        requestBody.webapp_policy_management_page = webapp_policy_condition
        
        //permission for admin app mass upload and shift management
        requestBody.shift_management_page = adminapp_shift_condition
        requestBody.bulk_management_page = adminapp_mass_condition
        requestBody.shift_permissions = adminapp_shift_permissions
        requestBody.bulk_permissions = adminapp_mass_permissions

        requestBody.web_app_cycle_count_page = web_app_cycle_count_condition
        requestBody.cycle_count_permissions = reportCycleCount
        requestBody.web_app_cycles = web_app_cycles

        requestBody.session= userDetails.session
        requestBody.org_id = userDetails.org_id

        CreteRole(requestBody).then(res=>{
         
             SetLoader("False");
            if(res.status==200){
              SetTitle(<h4>Role is created</h4>)  
              SetModalSucess(true);
              ResetHandler();
            }else{
              SetTitle(<h4>{res.message}</h4>)
              SetDescription('Role is not create have some issue');
              SetModalError(true)
            }
            if(res.status==300){
              localStorage.removeItem('isLoggedInGatewayTraceAdmin')
              localStorage.removeItem('userLoginDetailsTraceAdmin')
              props.history.push(`/login`)
           }
            

        }).catch();

      }else{
        SetLoader("False");
        Setrole_nameError('Required');
      }



    }

  
    
    function HandlerCheck(e,param,type){  
      let val = [UserAll];
      if(e){

        if(param == 'user'){
          val =[UserAll];
          val[0][type]="True"
          SetUserALL((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setgroup((prev) => ({ ...prev, [`${type}`]: "True" }));
          Setvisitor((prev) => ({ ...prev, [`${type}`]: "True" }));
          Setemployee((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        if(param == 'dashboard'){
          Setdashboard((prev) => ({ ...prev, [`${type}`]: "True" }))
        }
        
        if (param == 'webapp'){     
          SetWebAll((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_dashboard((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_manpower_management((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_sitem_anagement((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_productivity_management((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_policy_management((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setwebapp_visitor_management((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setweb_app_cycles((prev) => ({ ...prev, [`${type}`]: "True" }))
        }
        if(param=='site'){
          val =[SiteAll];
          val[0][type]="True"
          SetSiteAll((prev) => ({ ...prev, [`${type}`]: "True" })); 
          Setcategory((prev) => ({ ...prev, [`${type}`]: "True" }));
          Setlocation((prev) => ({ ...prev, [`${type}`]: "True" }));
        }else if(param=='device'){
          val =[DeviceAll];
          val[0][type]="True"
          SetDeviceAll((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setdevice((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='group'){
          val =[GroupAll];
          val[0][type]="True"
          SetGroupAll((prev) => ({ ...prev, [`${type}`]: "True"}))
          Setgroup((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='role'){
          val =[GroupAll];
          val[0][type]="True"
          SetRoleAll((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setrole((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='asset'){
          val =[AssetAll];
          val[0][type]="True"
          SetAssetAll((prev) => ({ ...prev, [`${type}`]: "True" }))
          Setasset((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='productivity'){
          Setproductivity((prev) => ({ ...prev, [`${type}`]: "True" }));
        }

        else if(param=='mobile'){
          Setmobile_app((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='geomap_web'){
          Setgeo_map_web((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param=='geomap_admin'){
          Setgeo_map_admin((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param == 'report'){
          Setreport((prev) => ({ ...prev, [`${type}`]: "True" }));
          SetReportLocation((prev) => ({ ...prev, [`${type}`]: "True" }));
          SetReportWorkingHour((prev) => ({ ...prev, [`${type}`]: "True" }));
          SetReportCycleCount((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param == 'shift_management'){
          Setadminapp_shift_permissions((prev) => ({ ...prev, [`${type}`]: "True" }));
        }
        else if(param =='mass_upload'){
          Setadminapp_mass_permissions((prev) => ({ ...prev, [`${type}`]: "True" }));
            }
        else if(param =='team_management'){
              SetTeamManagement((prev) => ({ ...prev, [`${type}`]: "True" }));
         }
    
        
        
      }else{
        if(param=='user'){
          val =[UserAll];
          val[0][type]="False"
          SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setgroup((prev) => ({ ...prev, [`${type}`]: "False" }));
          Setvisitor((prev) => ({ ...prev, [`${type}`]: "False" }));
          Setemployee((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        if(param == 'dashboard'){
          Setdashboard((prev) => ({ ...prev, [`${type}`]: "False" }))
        }
        
        if (param == 'webapp'){     
          SetWebAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_dashboard((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_manpower_management((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_sitem_anagement((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_productivity_management((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_policy_management((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setwebapp_visitor_management((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setweb_app_cycles((prev) => ({ ...prev, [`${type}`]: "False" }))
        }

        if(param=='site'){
          val =[SiteAll];
          val[0][type]="False"
          SetSiteAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setcategory((prev) => ({ ...prev, [`${type}`]: "False" }));
          Setlocation((prev) => ({ ...prev, [`${type}`]: "False" }));
          
        }else if(param=='device'){
          val =[DeviceAll];
          val[0][type]="True"
          SetDeviceAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setdevice((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='group'){
          val =[GroupAll];
          val[0][type]="True"
          SetGroupAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setgroup((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='role'){
          val =[GroupAll];
          //val[0][type]="True"
          SetRoleAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setrole((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='asset'){
          val =[AssetAll];
          SetAssetAll((prev) => ({ ...prev, [`${type}`]: "False" }))
          Setasset((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='productivity'){
          Setproductivity((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='mobile'){
          Setmobile_app((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='geomap_web'){
          Setgeo_map_web((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param=='geomap_admin'){
          Setgeo_map_admin((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param == 'report'){
          Setreport((prev) => ({ ...prev, [`${type}`]: "False" }));
          SetReportLocation((prev) => ({ ...prev, [`${type}`]: "False" }));
          SetReportWorkingHour((prev) => ({ ...prev, [`${type}`]: "False" }));
          SetReportCycleCount((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param == 'shift_management'){
          Setadminapp_shift_permissions((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param =='mass_upload'){
          Setadminapp_mass_permissions((prev) => ({ ...prev, [`${type}`]: "False" }));
        }
        else if(param =='team_management'){
          SetTeamManagement((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      }

  }

  function ChangeCheckBox(e,param,type,label){
   
    if(e.target.checked){
      if(label=='group' && param=='user' ){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));
        Setgroup((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='visitor'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setvisitor((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='employee'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setemployee((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='category' && param=='user'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setcategory((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='category' && param=='site'){
        SetSiteAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setcategory((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='location' && param=='site' ){
        SetSiteAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setlocation((prev) => ({ ...prev, [`${type}`]: "True" }));
      }      
      else if(label=='device' ){
        SetDeviceAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setdevice((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='group' & param=='group' ){
        SetGroupAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setgroup((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='role'  ){
        SetRoleAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setrole((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='asset'  ){
        SetAssetAll((prev) => ({ ...prev, [`${type}`]: "True" }));  
        Setasset((prev) => ({ ...prev, [`${type}`]: "True" }));
      }
      else if(label=='productivity'){
        Setproductivity((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label=='geomap_admin'){
        Setgeo_map_admin((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label=='webapp_dashboard'){
        Setwebapp_dashboard((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label=='webapp_manpower_management'){
        Setwebapp_manpower_management((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'webapp_site_management'){
        Setwebapp_sitem_anagement((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'webapp_visitor_management'){
      Setwebapp_visitor_management((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'webapp_policy_management'){
        Setwebapp_policy_management((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'webapp_productivity_management'){
        Setwebapp_productivity_management((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'report_location'){
        
        SetReportLocation((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'report_working'){
        SetReportWorkingHour((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'shift_management'){
        Setadminapp_shift_permissions((prev) => ({ ...prev, [`${type}`]: "True" }));  
      } 
      else if(label == 'team_management'){
        SetTeamManagement((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }   
      else if(label == 'team_management'){
        SetTeamManagement((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }  
      else if(label == 'web_app_cycles'){
        Setweb_app_cycles((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }
      else if(label == 'report_cycle_count'){
        SetReportCycleCount((prev) => ({ ...prev, [`${type}`]: "True" }));  
      }  
      

    }else{
      if(label=='group' && param=='user'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));
        Setgroup((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='visitor'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));
        Setvisitor((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='employee'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setemployee((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='category' && param=='user'){
        SetUserALL((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setcategory((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='category' && param=='site'){
        SetSiteAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setcategory((prev) => ({ ...prev, [`${type}`]: "False" }));
      }else if(label=='location' && param=='site'){
        SetSiteAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setlocation((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='device' ){
        SetDeviceAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setdevice((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='group' & param=='group' ){
        SetGroupAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setgroup((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='role'  ){
        SetRoleAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setrole((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='asset'){
        SetAssetAll((prev) => ({ ...prev, [`${type}`]: "False" }));  
        Setasset((prev) => ({ ...prev, [`${type}`]: "False" }));
      }
      else if(label=='productivity'){
        Setproductivity((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label=='geomap_admin'){
        Setgeo_map_admin((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label=='webapp_dashboard'){
        Setwebapp_dashboard((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label=='webapp_manpower_management('){
        Setwebapp_manpower_management((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'webapp_site_management'){
        Setwebapp_sitem_anagement((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'webapp_visitor_management'){
      Setwebapp_visitor_management((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'webapp_policy_management'){
        Setwebapp_policy_management((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'webapp_productivity_management'){
        Setwebapp_productivity_management((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'report_location'){
        Setreport((prev) => ({ ...prev, [`${type}`]: "False" }))
        SetReportLocation((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'report_working'){
        Setreport((prev) => ({ ...prev, [`${type}`]: "False" }))
        SetReportWorkingHour((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }
      else if(label == 'shift_management'){
        Setadminapp_shift_permissions((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }      
      else if(label == 'team_management'){
        SetTeamManagement((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }  
      else if(label == 'web_app_cycles'){
        Setweb_app_cycles((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }  
      else if(label == 'report_cycle_count'){
        SetReportCycleCount((prev) => ({ ...prev, [`${type}`]: "False" }));  
      }  
      
    }


  }

  function ChangeMainModule(e,param,accord){

    if(!e){
     
      let ActivList = [...activeAccord];
      let index = ActivList.indexOf(accord)
      if(index !== -1){  
          ActivList.splice(index, 1);
      }   
  
      SetActiveAccord(ActivList);
  
      if(param=='user'){
        SetUserALL({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setuser_management('False')
        Setvisitor({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setemployee({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setgroup({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      if(param=='dashboard'){
       Setdashboard_management("False")
       Setdashboard({"View": "False", "Bulk_import": "False", "Bulk_export":"False"})
      }
      if(param=='site'){
        Setsite_management('False')
        SetSiteAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setcategory({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setlocation({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      if(param=='device'){
        
        Setdevice_management('False')
        SetDeviceAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setdevice({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      if(param=='productive'){
        Setproductive_management('False')
        Setproductivity({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      if(param=='geomap_web'){
        Setgeo_map_web({"facility_tracker":"False","employee_tracker":"False"})
        Setgeo_map_web_all("False")
      }

      if(param =='role'){
        Setrole_management('False')
        
        SetRoleAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setrole({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      if(param=='asset'){
        Setasset_management('False')
        SetAssetAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setasset({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }

      if(param=='geomap_admin'){
        Setgeo_map_admin_all('False')       
        Setgeo_map_admin({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      
      if(param=='report'){
        SetReportAll('False')       
        Setreport({"View": "False", "export": "False"})
        SetReportLocation({"View": "False", "export": "False"})
        SetReportWorkingHour({"View": "False", "export": "False"})
        SetReportCycleCount({"View": "False", "export": "False"})
      }
      if(param=='web_app_cycle_count_condition'){
        Setweb_app_cycle_count_condition("False");
       
        Setweb_app_cycles({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
      }
      
      if(param =='dashboard_condition'){
        Setwebapp_dashboard({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_dashboard_condition("False")
      }
      if(param =='site_condition'){
        Setwebapp_sitem_anagement({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_site_condition("False")
      }
      if(param =='manpower_condition'){
        Setwebapp_manpower_management({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_manpower_condition("False")
      }
      if(param =='visitor_condition'){
        Setwebapp_visitor_management({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_visitor_condition("False")
      }
      if(param =='policy_condition'){
        Setwebapp_policy_management({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_policy_condition("False")
      }
      if(param =='productivity_condition'){
        Setwebapp_productivity_management({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetWebAll({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setwebapp_productivity_condition("False")
      }
      if(param =='shift_management'){
        Setadminapp_shift_permissions({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        Setadminapp_shift_condition("False")
      }
      if(param =='mass_upload'){
        Setadminapp_mass_permissions({"bulk_shift": "False", "daily_employee_bulk": "False", "employee_tag_changing_bulk": "False","cycles_count_bulk": "False"})
        Setadminapp_mass_condition("False")
      }
      if(param=='team_management'){
        SetTeamManagement({"View": "False", "edit": "False", "Create":"False", "remove":"False"})
        SetTeamAll("False")
      }
      
    }else{
      
      let ActivList = [...activeAccord];
      let index = ActivList.indexOf(accord)
      if(index !== -1){  
          //ActivList.splice(index, 1);
      }else{
        ActivList.push(accord);
      }   
  
      SetActiveAccord(ActivList);
  
      if(param=='user'){
        Setuser_management('True')
      }
      if(param=='dashboard'){
        Setdashboard_management("True")
       }
      if(param=='site'){
        Setsite_management('True')
      }
      if(param=='device'){
        Setdevice_management('True')
      }
      if(param=='productive'){
        Setproductive_management('True')
      }
      
      if(param =='role'){
        Setrole_management('True')
      }
      if(param=='asset'){
        Setasset_management('True')
      }
      if(param=='geomap_web'){
        Setgeo_map_web_all("True")
      }
      if(param=='geomap_admin'){
        Setgeo_map_admin_all('True')       

      }
      if(param=='report'){
        SetReportAll('True')       
      }
      if(param =='dashboard_condition'){
        Setwebapp_dashboard_condition("True")
      }
      if(param =='site_condition'){
        Setwebapp_site_condition("True")
      }
      if(param =='manpower_condition'){
        Setwebapp_manpower_condition("True")
      }
      if(param =='policy_condition'){
        Setwebapp_policy_condition("True")
      }
      if(param =='productivity_condition'){
        Setwebapp_productivity_condition("True")
      }
      if(param =='visitor_condition'){
        Setwebapp_visitor_condition("True")
      }
      if(param =='shift_management'){
        Setadminapp_shift_condition("True")
      }
      if(param =='mass_upload'){
        
        Setadminapp_mass_condition("True")
      }
      if(param=='web_app_cycle_count_condition'){
        Setweb_app_cycle_count_condition("True");
       
      }
      if(param=='team_management'){
       
        SetTeamAll("True")
      }      
    }
  }

 

    return(
      
      <div className="main-content side-content pt-0">

      <div className="container-fluid">
          <div className="inner-body">
      
          <div className="page-header">
                  <Row>
                      <Col lg={6}>
                      </Col>
                      <Col lg={2}></Col>
                      <Col lg={4} style={{textAlign:"right"}}>
                      <button onClick={()=>{SetResetModal(true);SetRedirectTrue(true)}} className="btn btn-transparent btn-icon " style={{marginRight:"10px"}}><Back className='svgSmall'/> Go Back</button>
                      </Col>
                      </Row>
          </div>
      
        
            <form onSubmit={AddRolehandler} autocomplete="off" className={loader=="True"?"loader-form":"form-relative"} >
       
            <div className='formsArea'>
            <div className='br-seperator-row'>                
          
              <Row>
                <Col lg={12}>
                <div className='field-row'>
                <label>Role Name <div className='posRel'><HelpIcon /><span className='helpSmall'>Name of new role</span></div></label>
                    <input type="text" className={role_nameError!=''?'form-control error':'form-control'} value={role_name} onChange={(e)=>{if(reg.test(e.target.value)){Setrole_name(e.target.value);Setrole_nameError("")}else if(e.target.value==''){Setrole_name(e.target.value)} }  }  />
                    {role_nameError!=''?<div className='errorMessage'>{role_nameError}</div>:""}
                </div>

                </Col>
              </Row>

              </div>               
                

              <div className='br-seperator-row'>              
                <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Trace Admin System <span class="switchRight">Permission</span></Col>
                    <Col lg={2} className='label-check'>View</Col>
                    <Col lg={2} className='label-check'>Create</Col>
                    <Col lg={2} className='label-check'>Edit</Col>
                    <Col lg={2} className='label-check'>Remove</Col>
                  </Row>
                <div className={activeAccord.indexOf(1)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>User Management <span class="switchRight"><Switch checkedChildren="Enable" unCheckedChildren="Disable" onChange={(e)=>ChangeMainModule(e,'user',1)} checked={user_management=='True'? true:false}/></span></Col>
                    <Col lg={2} className='label-check'><Switch disabled={user_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'user','View')} checked={UserAll.View=="True" ||(group.View=="True" && visitor.View=="True" && employee.View=="True")? true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={user_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'user','Create','group')} checked={UserAll.Create=="True" ||(group.Create=="True"&& visitor.Create=="True" && employee.Create=="True")? true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={user_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'user','edit')} checked={UserAll.edit=="True"|| (group.edit=="True"&& visitor.edit=="True" && employee.edit=="True")?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={user_management=='False'? true:false}  onChange={(e)=>HandlerCheck(e,'user','remove')} checked={UserAll.remove=="True" || (group.remove=="True"&& visitor.remove=="True" && employee.remove=="True") ?true:false}/></Col>
                  </Row>
                    	<span className='icon-right' onClick={()=>AccordionHandler(1)} >
                       { activeAccord.indexOf(1)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span>
                </div>
<div className={activeAccord.indexOf(1)!==-1?'accord-role active':'accord-role'} >
                    
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'>Department</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.View=="True" ||group.View=="True"} onChange={(e)=>ChangeCheckBox(e,'user','View','group') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.Create=="True" ||group.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'user','Create','group') }  ></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.edit=="True" || group.edit=="True"} onChange={(e)=>ChangeCheckBox(e,'user','edit','group') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.remove=="True" ||group.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'user','remove','group') }></Checkbox></Col>
                  </Row> 
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'> Visitor</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false} checked={UserAll.Create=="True" ||visitor.View=="True"} onChange={(e)=>ChangeCheckBox(e,'user','View','visitor') } ></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false} checked={UserAll.Create=="True" ||visitor.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'user','Create','visitor') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false} checked={UserAll.edit=="True" ||visitor.edit=="True"} onChange={(e)=>ChangeCheckBox(e,'user','edit','visitor') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.remove=="True" ||visitor.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'user','remove','visitor') } ></Checkbox></Col>
                  </Row> 
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'>Employee</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.View=="True" ||employee.View=="True"} onChange={(e)=>ChangeCheckBox(e,'user','View','employee') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.Create=="True" ||employee.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'user','Create','employee') } ></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.edit=="True" ||employee.edit=="True"} onChange={(e)=>ChangeCheckBox(e,'user','edit','employee') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={user_management=='False'? true:false}  checked={UserAll.remove=="True" ||employee.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'user','remove','employee') }></Checkbox></Col>
                  </Row> 
                 </div>  

                 <div  className={activeAccord.indexOf(2)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                 <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Site Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'site',2)} checked={site_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                    <Col lg={2} className='label-check'><Switch disabled={site_management=='False'? true:false}  defaultChecked onChange={(e)=>HandlerCheck(e,'site','View')}  checked={SiteAll.View=="True" || (category.View=="True" &&  location.View=="True" )?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={site_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'site','Create')}  checked={SiteAll.Create=="True" || (category.Create=="True" &&  location.Create=="True" )?true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={site_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'site','edit')} checked={SiteAll.edit=="True"|| (category.edit=="True" &&  location.edit=="True" )?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={site_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'site','remove')} checked={SiteAll.remove=="True"|| (category.remove=="True" &&  location.remove=="True" )?true:false} /></Col>

                  </Row>
                    	<span className='icon-right' onClick={()=>AccordionHandler(2)}>
                       { activeAccord.indexOf(2)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span>
                </div>
<div className={activeAccord.indexOf(2)!==-1?'accord-role active':'accord-role'} >
                    
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'>Category</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.View=="True"  || category.View=="True" } onChange={(e)=>ChangeCheckBox(e,'site','View','category')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.Create=="True"  || category.Create=="True" } onChange={(e)=>ChangeCheckBox(e,'site','Create','category')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false} checked={SiteAll.edit=="True"  || category.edit=="True" } onChange={(e)=>ChangeCheckBox(e,'site','edit','category')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.remove=="True"  || category.remove=="True" } onChange={(e)=>ChangeCheckBox(e,'site','remove','category')}></Checkbox></Col>
                  </Row> 
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'> Location </Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.View=="True"  || location.View=="True" } onChange={(e)=>ChangeCheckBox(e,'site','View','location')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.Create=="True"  || location.Create=="True" } onChange={(e)=>ChangeCheckBox(e,'site','Create','location')} ></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false}  checked={SiteAll.edit=="True"  || location.edit=="True" } onChange={(e)=>ChangeCheckBox(e,'site','edit','location')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={site_management=='False'? true:false} checked={SiteAll.remove=="True"  || location.remove=="True" } onChange={(e)=>ChangeCheckBox(e,'site','remove','location')}></Checkbox></Col>
                  </Row> 
                 </div> 

                 <div  className={activeAccord.indexOf(3)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                 <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Device Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'device',3)} checked={device_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                    <Col lg={2} className='label-check'><Switch disabled={device_management=='False'? true:false}  onChange={(e)=>HandlerCheck(e,'device','View')} checked={DeviceAll.View=="True" || (device.View=="True") ?true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={device_management=='False'? true:false || DeviceAll.View=="False" || (device.View=="False") ?true:false} onChange={(e)=>HandlerCheck(e,'device','Create')}  checked={DeviceAll.Create=="True" || (device.Create=="True") ?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={device_management=='False'? true:false || DeviceAll.View=="False" || (device.View=="False") ?true:false} onChange={(e)=>HandlerCheck(e,'device','edit')} checked={DeviceAll.edit=="True"|| (device.edit=="True")?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={device_management=='False'? true:false || DeviceAll.View=="False" || (device.View=="False") ?true:false} onChange={(e)=>HandlerCheck(e,'device','remove')} checked={DeviceAll.remove=="True"|| (device.remove=="True")?true:false } /></Col>
                  </Row> 

                    	<span className='icon-right' onClick={()=>AccordionHandler(3)}>
                       { activeAccord.indexOf(3)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span>
                </div>
              <div className={activeAccord.indexOf(3)!==-1?'accord-role active':'accord-role'} >
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'>Device</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={device_management=='False'? true:false}  checked={DeviceAll.View=="True"  || device.View=="True" } onChange={(e)=>ChangeCheckBox(e,'device','View','device') }></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={device_management=='False'? true:false}  checked={DeviceAll.Create=="True"  || device.Create=="True" } onChange={(e)=>ChangeCheckBox(e,'device','Create','device')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={device_management=='False'? true:false} checked={DeviceAll.edit=="True"  || device.edit=="True" } onChange={(e)=>ChangeCheckBox(e,'device','edit','device')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={device_management=='False'? true:false}  checked={DeviceAll.remove=="True"  || device.remove=="True" } onChange={(e)=>ChangeCheckBox(e,'device','remove','device')}></Checkbox></Col>
                  </Row> 
                 </div> 

                 {/* <div  className={activeAccord.indexOf(9)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                      <Row className='header-table-2'>
                         <Col lg={4} className='label-disp'>Asset Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'asset',9)} checked={asset_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                         <Col lg={2} className='label-check'><Switch disabled={asset_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'asset','View')} checked={AssetAll.View=="True" || (asset.View=="True") ?true:false}/></Col>
                         <Col lg={2} className='label-check'><Switch disabled={asset_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'asset','Create')} checked={AssetAll.Create=="True" || (asset.Create=="True") ?true:false} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={asset_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'asset','edit')} checked={AssetAll.edit=="True"|| (asset.edit=="True")?true:false} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={asset_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'asset','remove')} checked={AssetAll.remove=="True"|| (asset.remove=="True")?true:false } /></Col>
     
                       </Row> 
                           <span className='icon-right' onClick={()=>AccordionHandler(9)}>
                            { activeAccord.indexOf(9)!==-1?<MinusIcon/>:<AddIcon/>} 
                           </span>
                     </div>
                   <div className={activeAccord.indexOf(9)!==-1?'accord-role active':'accord-role'} >
                          <Row className='cotnent-table'>
                            <Col lg={4} className='label-disp'>Asset</Col>
                            <Col lg={2} className='label-check'><Checkbox disabled={asset_management=='False'? true:false}  checked={AssetAll.View=="True"  || asset.View=="True" } onChange={(e)=>ChangeCheckBox(e,'asset','View','asset')}></Checkbox></Col>
                            <Col lg={2} className='label-check'><Checkbox disabled={asset_management=='False'? true:false}  checked={AssetAll.Create=="True"  || asset.Create=="True" } onChange={(e)=>ChangeCheckBox(e,'asset','Create','asset')}></Checkbox></Col>
                            <Col lg={2} className='label-check'><Checkbox disabled={asset_management=='False'? true:false} checked={AssetAll.edit=="True"  || asset.edit=="True" } onChange={(e)=>ChangeCheckBox(e,'asset','edit','asset')}></Checkbox></Col>
                            <Col lg={2} className='label-check'><Checkbox disabled={asset_management=='False'? true:false}  checked={AssetAll.remove=="True"  || asset.remove=="True" } onChange={(e)=>ChangeCheckBox(e,'asset','remove','asset')}></Checkbox></Col>
                          </Row> 
                      </div>  */}

                 <div  className={activeAccord.indexOf(5)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                 <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Role Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'role',5)} checked={role_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                    <Col lg={2} className='label-check'><Switch disabled={role_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'role','View')} checked={RoleAll.View=="True"||  role.View=="True"?true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={role_management=='False'? true:false}  onChange={(e)=>HandlerCheck(e,'role','Create')} checked={RoleAll.Create=="True"||  role.Create=="True"?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={role_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'role','edit')}  checked={RoleAll.edit=="True"||  role.edit=="True"?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={role_management=='False'? true:false} onChange={(e)=>HandlerCheck(e,'role','remove')}  checked={RoleAll.remove=="True" ||  role.remove=="True"?true:false} /></Col>
                  </Row>  

                    	<span className='icon-right' onClick={()=>AccordionHandler(5)}>
                       { activeAccord.indexOf(5)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span>
                </div>
                
                <div className={activeAccord.indexOf(5)!==-1?'accord-role active':'accord-role'} >
                  <Row className='cotnent-table'>
                    <Col lg={4} className='label-disp'>Role</Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={role_management=='False'? true:false}  checked={RoleAll.View=="True" || role.View=="True"} onChange={(e)=>ChangeCheckBox(e,'role','View','role')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={role_management=='False'? true:false}   checked={RoleAll.Create=="True" || role.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'role','Create','role')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={role_management=='False'? true:false}  checked={RoleAll.edit=="True" || role.edit =="True"} onChange={(e)=>ChangeCheckBox(e,'role','edit','role')}></Checkbox></Col>
                    <Col lg={2} className='label-check'><Checkbox disabled={role_management=='False'? true:false} checked={RoleAll.remove=="True" || role.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'role','remove','role')}></Checkbox></Col>
                  </Row> 
                 </div> 


                 <div  className={activeAccord.indexOf(10)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                      <Row className='header-table-2'>
                         <Col lg={4} className='label-disp'>Productive Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'productive',10)} checked={productive_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                         <Col lg={2} className='label-check'><Switch disabled={productive_management=='False'? true:false} checked={productivity.View=="True"}  onChange={(e)=>HandlerCheck(e,'productivity','View')} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={productive_management=='False'? true:false} checked={ productivity.Create=="True"} onChange={(e)=>HandlerCheck(e,'productivity','Create')}/></Col>
                         <Col lg={2} className='label-check'><Switch disabled={productive_management=='False'? true:false}  checked={productivity.edit =="True"} onChange={(e)=>HandlerCheck(e,'productivity','edit')} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={productive_management=='False'? true:false} checked={productivity.remove=="True"} onChange={(e)=>HandlerCheck(e,'productivity','remove')} /></Col>
                       </Row>  
     
                           <span className='icon-right' onClick={()=>AccordionHandler(10)}>
                            { activeAccord.indexOf(10)!==-1?<MinusIcon/>:<AddIcon/>} 
                           </span>
                     </div>

                     
                     <div className={activeAccord.indexOf(10)!==-1?'accord-role  accord-productivity active':'accord-role accord-productivity'} >
                       <Row className='cotnent-table'>
                         <Col lg={4} className='label-disp'>Productivity</Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={productive_management=='False'? true:false}  checked={productivity.View=="True"} onChange={(e)=>ChangeCheckBox(e,'productivity','View','productivity')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={productive_management=='False'? true:false}  checked={ productivity.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'productivity','Create','productivity')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={productive_management=='False'? true:false}  checked={productivity.edit =="True"} onChange={(e)=>ChangeCheckBox(e,'productivity','edit','productivity')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={productive_management=='False'? true:false} checked={productivity.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'productivity','remove','productivity')}></Checkbox></Col>
                       </Row> 
                      </div>


                      <div  className={activeAccord.indexOf(20)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                      <Row className='header-table-2'>
                         <Col lg={4} className='label-disp'>Shift Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'shift_management',20)} checked={adminapp_shift_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                         <Col lg={2} className='label-check'><Switch disabled={adminapp_shift_condition=='False'? true:false} checked={adminapp_shift_permissions.View=="True"}  onChange={(e)=>HandlerCheck(e,'shift_management','View')} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={adminapp_shift_condition=='False'? true:false} checked={ adminapp_shift_permissions.Create=="True"} onChange={(e)=>HandlerCheck(e,'shift_management','Create')}/></Col>
                         <Col lg={2} className='label-check'><Switch disabled={adminapp_shift_condition=='False'? true:false}  checked={adminapp_shift_permissions.edit =="True"} onChange={(e)=>HandlerCheck(e,'shift_management','edit')} /></Col>
                         <Col lg={2} className='label-check'><Switch disabled={adminapp_shift_condition=='False'? true:false} checked={adminapp_shift_permissions.remove=="True"} onChange={(e)=>HandlerCheck(e,'shift_management','remove')} /></Col>
                       </Row>  
     
                           <span className='icon-right' onClick={()=>AccordionHandler(20)}>
                            { activeAccord.indexOf(20)!==-1?<MinusIcon/>:<AddIcon/>} 
                           </span>
                     </div>

                     
                     <div className={activeAccord.indexOf(20)!==-1?'accord-role  accord-productivity active':'accord-role accord-productivity'} >
                       <Row className='cotnent-table'>
                         <Col lg={4} className='label-disp'>Shift Management</Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={adminapp_shift_condition=='False'? true:false}  checked={adminapp_shift_permissions.View=="True"} onChange={(e)=>ChangeCheckBox(e,'shift_management','View','shift_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={adminapp_shift_condition=='False'? true:false}  checked={adminapp_shift_permissions.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'shift_management','Create','shift_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={adminapp_shift_condition=='False'? true:false}  checked={adminapp_shift_permissions.edit =="True"} onChange={(e)=>ChangeCheckBox(e,'shift_management','edit','shift_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={adminapp_shift_condition=='False'? true:false}  checked={adminapp_shift_permissions.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'shift_management','remove','shift_management')}></Checkbox></Col>
                       </Row> 
                      </div>     




                      <div  className={activeAccord.indexOf(14)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                      <Row className='header-table-2'>
                         <Col lg={4} className='label-disp'>Geo Map<span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'geomap_admin',14)} checked={geo_map_admin_all=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                         <Col lg={2} className='label-check'><Switch checked={geo_map_admin.View=='True'? true:false} disabled={geo_map_admin_all=='False'}  onChange={(e)=>HandlerCheck(e,'geomap_admin','View')} /></Col>
                         <Col lg={2} className='label-check'><Switch checked={geo_map_admin.Create=='True'? true:false} disabled={geo_map_admin_all=='False'} onChange={(e)=>HandlerCheck(e,'geomap_admin','Create')}/></Col>
                         <Col lg={2} className='label-check'><Switch checked={geo_map_admin.edit=='True'? true:false}  disabled={geo_map_admin_all=='False'} onChange={(e)=>HandlerCheck(e,'geomap_admin','edit')} /></Col>
                         <Col lg={2} className='label-check'><Switch checked={geo_map_admin.remove=='True'? true:false} disabled={geo_map_admin_all=='False'} onChange={(e)=>HandlerCheck(e,'geomap_admin','remove')} /></Col>
                       </Row>  
     
                           <span className='icon-right' onClick={()=>AccordionHandler(14)}>
                            { activeAccord.indexOf(14)!==-1?<MinusIcon/>:<AddIcon/>} 
                           </span>
                     </div>

                     <div className={activeAccord.indexOf(14)!==-1?'accord-role  accord-productivity active':'accord-role accord-productivity'} >
                       <Row className='cotnent-table'>
                         <Col lg={4} className='label-disp'>Geo Map Admin</Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={geo_map_admin_all=='False'? true:false}  checked={geo_map_admin.View=="True"} onChange={(e)=>ChangeCheckBox(e,'geomap_admin','View','geomap_admin')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={geo_map_admin_all=='False'? true:false}  checked={ geo_map_admin.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'geomap_admin','Create','geomap_admin')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={geo_map_admin_all=='False'? true:false}  checked={geo_map_admin.edit =="True"} onChange={(e)=>ChangeCheckBox(e,'geomap_admin','edit','geomap_admin')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={geo_map_admin_all=='False'? true:false} checked={geo_map_admin.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'geomap_admin','remove','geomap_admin')}></Checkbox></Col>
                       </Row> 
                      </div>


                      <div  className={activeAccord.indexOf(25)!==-1?'bg-background-accord active':'bg-background-accord'}>
                  		
                      <Row className='header-table-2'>
                         <Col lg={4} className='label-disp'>Team Management<span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'team_management',25)} checked={teamAll=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                         <Col lg={2} className='label-check'><Switch checked={team_management.View=='True'? true:false} disabled={teamAll=='False'}  onChange={(e)=>HandlerCheck(e,'team_management','View')} /></Col>
                         <Col lg={2} className='label-check'><Switch checked={team_management.Create=='True'? true:false} disabled={teamAll=='False'} onChange={(e)=>HandlerCheck(e,'team_management','Create')}/></Col>
                         <Col lg={2} className='label-check'><Switch checked={team_management.edit=='True'? true:false}  disabled={teamAll=='False'} onChange={(e)=>HandlerCheck(e,'team_management','edit')} /></Col>
                         <Col lg={2} className='label-check'><Switch checked={team_management.remove=='True'? true:false} disabled={teamAll=='False'} onChange={(e)=>HandlerCheck(e,'team_management','remove')} /></Col>
                       </Row>  
     
                           <span className='icon-right' onClick={()=>AccordionHandler(25)}>
                            { activeAccord.indexOf(25)!==-1?<MinusIcon/>:<AddIcon/>} 
                           </span>
                     </div>

                     <div className={activeAccord.indexOf(25)!==-1?'accord-role  accord-productivity active':'accord-role accord-productivity'} >
                       <Row className='cotnent-table'>
                         <Col lg={4} className='label-disp'>Team Management</Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={teamAll=='False'? true:false}  checked={team_management.View=="True"} onChange={(e)=>ChangeCheckBox(e,'team_management','View','team_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={teamAll=='False'? true:false}  checked={ team_management.Create=="True"} onChange={(e)=>ChangeCheckBox(e,'team_management','Create','team_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={teamAll=='False'? true:false}  checked={team_management.edit =="True"} onChange={(e)=>ChangeCheckBox(e,'team_management','edit','team_management')}></Checkbox></Col>
                         <Col lg={2} className='label-check'><Checkbox disabled={teamAll=='False'? true:false} checked={team_management.remove=="True"} onChange={(e)=>ChangeCheckBox(e,'team_management','remove','team_management')}></Checkbox></Col>
                       </Row> 
                      </div>


                 </div>
                 <div className='br-seperator-row'>              
                 <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Admin Dashboard <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'dashboard',11)} checked={dashboard_management=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"></Switch></span></Col>
                    <Col lg={3} className='label-check'>View</Col>
                    <Col lg={2} className='label-check'>Import</Col>
                    <Col lg={3} className='label-check'>Export</Col>
                  </Row>
                  <div  className={activeAccord.indexOf(11)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Dashboard</Col>
                    <Col lg={3} className='label-check'><Switch disabled={dashboard_management =="False"?true:false} onChange={(e)=>HandlerCheck(e,'dashboard','View')} checked={dashboard.View=="True"?true:false}  /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={dashboard_management =="False"?true:false} onChange={(e)=>HandlerCheck(e,'dashboard','Bulk_import')} checked={dashboard.Bulk_import=="True"?true:false} /></Col>
                    <Col lg={3} className='label-check'><Switch disabled={dashboard_management =="False"?true:false} onChange={(e)=>HandlerCheck(e,'dashboard','Bulk_export')} checked={dashboard.Bulk_export=="True"?true:false} /></Col>
                    
                  </Row>
              
                </div>
              </div>
              
              {/*Mass upload Management Page  */}
              <div className='br-seperator-row'>              
                 <Row className='header-table-2 header-table-3 header-table-4'>
                    <Col lg={4} className='label-disp'>Mass Upload<span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'mass_upload',11)} checked={adminapp_mass_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"></Switch></span></Col>
                    <Col lg={2} className='label-check'><span className="smallFont">Regular Employee</span><span className='biggerFont'>Update Shift</span> </Col>
                    <Col lg={2} className='label-check'><span className="smallFont">Daily Employee</span><span className='biggerFont'>Register Worker</span></Col>
                    <Col lg={2} className='label-check'><span className="smallFont">Regular Employee</span><span className='biggerFont'>Update Tag</span></Col>
                    <Col lg={2} className='label-check'><span className="smallFont"></span><span className='biggerFont'>Cycle Count</span></Col>
                  </Row>
                  <div  className={activeAccord.indexOf(11)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Mass Upload</Col>
                    <Col lg={2} className='label-check'><Switch disabled={adminapp_mass_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'mass_upload','bulk_shift')} checked={adminapp_mass_permissions.bulk_shift=="True"?true:false}  /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={adminapp_mass_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'mass_upload','daily_employee_bulk')} checked={adminapp_mass_permissions.daily_employee_bulk=="True"?true:false} /></Col>
                    <Col lg={2} className='label-check'><Switch disabled={adminapp_mass_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'mass_upload','employee_tag_changing_bulk')} checked={adminapp_mass_permissions.employee_tag_changing_bulk=="True"?true:false} /></Col>                    
                    <Col lg={2} className='label-check'><Switch disabled={adminapp_mass_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'mass_upload','cycles_count_bulk')} checked={adminapp_mass_permissions.cycles_count_bulk=="True"?true:false} /></Col>                    
                  </Row>
                </div>
              </div>




                <div className='br-seperator-row'>              
                 <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Mobile APP</Col>
                    <Col lg={3} className='label-check'>Manager</Col>
                    <Col lg={2} className='label-check'>Admin</Col>
                    <Col lg={3} className='label-check'>Employee</Col>
                  </Row>
                  <div  className={activeAccord.indexOf(12)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Mobile App</Col>
                    <Col lg={3} className='label-check'><Switch onChange={(e)=>HandlerCheck(e,'mobile','policy')} checked={mobile_app.policy=="True"?true:false}  /></Col>
                    <Col lg={2} className='label-check'><Switch onChange={(e)=>HandlerCheck(e,'mobile','visitor')} checked={mobile_app.visitor=="True"?true:false} /></Col>
                    <Col lg={3} className='label-check'><Switch onChange={(e)=>HandlerCheck(e,'mobile','default')} checked={mobile_app.default=="True"?true:false} /></Col>
                  
                  </Row>
                    	{/* <span className='icon-right' onClick={()=>AccordionHandler(12)} >
                       { activeAccord.indexOf(12)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span> */}
                </div>
              </div>


              <div className='br-seperator-row'>              
                 <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Geo Map Web App <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'geomap_web',10)} checked={geo_map_web_all=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable" /></span></Col>
                    <Col lg={4} className='label-check'>Employee Tracker</Col>
                    <Col lg={4} className='label-check'>Facilities Tracker</Col>
                  </Row>
                  <div  className={activeAccord.indexOf(13)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Geo Map</Col>
                    <Col lg={4} className='label-check'><Switch onChange={(e)=>HandlerCheck(e,'geomap_web','employee_tracker')} checked={geo_map_web.employee_tracker=="True"?true:false}  disabled={geo_map_web_all=="False"}  /></Col>
                    <Col lg={4} className='label-check'><Switch onChange={(e)=>HandlerCheck(e,'geomap_web','facility_tracker')} checked={geo_map_web.facility_tracker=="True"?true:false} disabled={geo_map_web_all=="False"}  /></Col>                    
                  </Row>
                    	{/* <span className='icon-right' onClick={()=>AccordionHandler(13)} >
                       { activeAccord.indexOf(13)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span> */}
                </div>
              </div>



              <div className='br-seperator-row'>              
                 <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Report<span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'report',15)} checked={reportAll=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable" /></span> 
                    	{/* <span className='icon-right' >
                       { activeAccord.indexOf(15)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span> */}
                    </Col>
                    <Col lg={4} className='label-check'>View <Switch onChange={(e)=>HandlerCheck(e,'report','View')} checked={report.View=="True" || (reportLocation.View=="True" &&reportWorkingHour.View=="True"&& reportCycleCount.View=="True")}  disabled={reportAll =="False"?true:false} /></Col>
                    <Col lg={4} className='label-check'>Export <Switch onChange={(e)=>HandlerCheck(e,'report','export')} checked={report.export=="True"|| (reportLocation.export=="True" &&reportWorkingHour.export=="True"&& reportCycleCount.View=="True")}  disabled={reportAll =="False"?true:false} /></Col>
                  </Row>
                  <div  className={activeAccord.indexOf(15)!==-1?'bg-background-accord active':'bg-background-accord'}>                    
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Location</Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}  checked={report.View=="True" || reportLocation.View=="True"} onChange={(e)=>ChangeCheckBox(e,'report','View','report_location')} ></Checkbox></Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}   checked={report.export=="True" || reportLocation.export=="True"} onChange={(e)=>ChangeCheckBox(e,'report','export','report_location')}></Checkbox></Col>     
                    </Row> 
                  </div>

                  <div  className={activeAccord.indexOf(15)!==-1?'bg-background-accord active':'bg-background-accord'}>                    
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Working hour</Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}  checked={report.View=="True" || reportWorkingHour.View=="True"} onChange={(e)=>ChangeCheckBox(e,'report','View','report_working')}></Checkbox></Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}   checked={report.export=="True" || reportWorkingHour.export=="True"} onChange={(e)=>ChangeCheckBox(e,'report','export','report_working')}></Checkbox></Col>   
                    </Row> 
                  </div>

                  <div  className={activeAccord.indexOf(15)!==-1?'bg-background-accord active':'bg-background-accord'}>                    
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Cycle Count</Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}  checked={report.View=="True" || reportCycleCount.View=="True"} onChange={(e)=>ChangeCheckBox(e,'report','View','report_cycle_count')}></Checkbox></Col>
                      <Col lg={4} className='label-check'><Checkbox disabled={reportAll =="False"?true:false}   checked={report.export=="True" || reportCycleCount.export=="True"} onChange={(e)=>ChangeCheckBox(e,'report','export','report_cycle_count')}></Checkbox></Col>   
                    </Row> 
                  </div>


              </div>


              
                 
                <div className='br-seperator-row'>
                <Row className='header-table-2 header-table-3'>
                    <Col lg={4} className='label-disp'>Web APP</Col>
                    <Col lg={2} className='label-check'>View</Col>
                    <Col lg={2} className='label-check'>Create</Col>
                    <Col lg={2} className='label-check'>Edit</Col>
                    <Col lg={2} className='label-check'>Remove</Col>
                  </Row>
                <div  className={activeAccord.indexOf(17)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
                    <Row className='header-table-2'>
                    <Col lg={4} className='label-disp'>Module</Col>
                    <Col lg={2} className='label-check'><Switch disabled={webapp_dashboard_condition=="False" ||webapp_manpower_condition=="False"|| webapp_site_condition=="False"|| webapp_visitor_condition=="False"||webapp_productivity_condition=="False"|| webapp_policy_condition=="False" || web_app_cycle_count_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'webapp','View')} checked={WebAll.View=="True" ||(webapp_dashboard.View=="True" && webapp_sitem_anagement.View=="True" && webapp_productivity_management.View=="True"&& webapp_manpower_management.View=="True"&&webapp_visitor_management.View=="True" && webapp_policy_management.View=="True"&&web_app_cycles.View=="True" )? true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={webapp_dashboard_condition=="False" ||webapp_manpower_condition=="False"|| webapp_site_condition=="False"|| webapp_visitor_condition=="False"||webapp_productivity_condition=="False"|| webapp_policy_condition=="False" || web_app_cycle_count_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'webapp','Create')} checked={WebAll.Create=="True" ||(webapp_dashboard.Create=="True" && webapp_sitem_anagement.Create=="True" && webapp_productivity_management.Create=="True"&& webapp_manpower_management.Create=="True"&&webapp_visitor_management.Create=="True" && webapp_policy_management.Create=="True"&&web_app_cycles.Create=="True"  )? true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={webapp_dashboard_condition=="False" ||webapp_manpower_condition=="False"|| webapp_site_condition=="False"|| webapp_visitor_condition=="False"||webapp_productivity_condition=="False"|| webapp_policy_condition=="False" || web_app_cycle_count_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'webapp','edit')} checked={WebAll.edit=="True" ||(webapp_dashboard.edit=="True" && webapp_sitem_anagement.edit=="True" && webapp_productivity_management.edit=="True"&& webapp_manpower_management.edit=="True"&&webapp_visitor_management.edit=="True" && webapp_policy_management.edit=="True"&&web_app_cycles.edit=="True"  )? true:false}/></Col>
                    <Col lg={2} className='label-check'><Switch disabled={webapp_dashboard_condition=="False" ||webapp_manpower_condition=="False"|| webapp_site_condition=="False"|| webapp_visitor_condition=="False"||webapp_productivity_condition=="False"|| webapp_policy_condition=="False" || web_app_cycle_count_condition =="False"?true:false} onChange={(e)=>HandlerCheck(e,'webapp','remove')} checked={WebAll.remove=="True" ||(webapp_dashboard.remove=="True" && webapp_sitem_anagement.remove=="True" && webapp_productivity_management.remove=="True"&& webapp_manpower_management.remove=="True"&&webapp_visitor_management.remove=="True" && webapp_policy_management.remove=="True"&&web_app_cycles.remove=="True"  )? true:false}/></Col>
                  </Row>
                    	<span className='icon-right' onClick={()=>AccordionHandler(17)} >
                       { activeAccord.indexOf(17)!==-1?<MinusIcon/>:<AddIcon/>} 
                      </span>
                </div>
                <div className={activeAccord.indexOf(17)!==-1?'accord-role active':'accord-role'} >
                    
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Dashboard <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'dashboard_condition',5)} checked={webapp_dashboard_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_dashboard_condition=='False'} checked={WebAll.View=="True" || webapp_dashboard.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_dashboard')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_dashboard_condition=='False'} checked={WebAll.Create=="True" || webapp_dashboard.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_dashboard')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_dashboard_condition=='False'} checked={WebAll.edit=="True" || webapp_dashboard.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_dashboard')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_dashboard_condition=='False'} checked={WebAll.remove=="True" || webapp_dashboard.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_dashboard')}  ></Checkbox></Col>
                    </Row> 
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'> Site Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'site_condition',5)} checked={webapp_site_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_site_condition=='False'} checked={WebAll.View=="True" || webapp_sitem_anagement.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_site_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_site_condition=='False'} checked={WebAll.Create=="True" || webapp_sitem_anagement.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_site_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_site_condition=='False'} checked={WebAll.edit=="True" || webapp_sitem_anagement.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_site_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_site_condition=='False'} checked={WebAll.remove=="True" || webapp_sitem_anagement.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_site_management')}  ></Checkbox></Col>
                    </Row> 
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Manpower Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'manpower_condition',5)} checked={webapp_manpower_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_manpower_condition=='False'}  checked={WebAll.View=="True" || webapp_manpower_management.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_manpower_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_manpower_condition=='False'} checked={WebAll.Create=="True" || webapp_manpower_management.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_manpower_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_manpower_condition=='False'} checked={WebAll.edit=="True" || webapp_manpower_management.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_manpower_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_manpower_condition=='False'} checked={WebAll.remove=="True" || webapp_manpower_management.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_manpower_management')}  ></Checkbox></Col>
                    </Row> 
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Visitor Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'visitor_condition',5)} checked={webapp_visitor_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_visitor_condition=='False'} checked={WebAll.View=="True" || webapp_visitor_management.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_visitor_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_visitor_condition=='False'}checked={WebAll.Create=="True" || webapp_visitor_management.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_visitor_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_visitor_condition=='False'}checked={WebAll.edit=="True" || webapp_visitor_management.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_visitor_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_visitor_condition=='False'}checked={WebAll.remove=="True" || webapp_visitor_management.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_visitor_management')}  ></Checkbox></Col>
                    </Row>
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Policy Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'policy_condition',5)} checked={webapp_policy_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_policy_condition=='False'} checked={WebAll.View=="True" || webapp_policy_management.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_policy_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_policy_condition=='False'}checked={WebAll.Create=="True" || webapp_policy_management.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_policy_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_policy_condition=='False'}checked={WebAll.edit=="True" || webapp_policy_management.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_policy_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_policy_condition=='False'}checked={WebAll.remove=="True" || webapp_policy_management.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_policy_management')}  ></Checkbox></Col>
                    </Row>
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Productivity Management <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'productivity_condition',5)} checked={webapp_productivity_condition=='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_productivity_condition=='False'}checked={WebAll.View=="True" || webapp_productivity_management.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','webapp_productivity_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_productivity_condition=='False'}checked={WebAll.Create=="True" || webapp_productivity_management.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','webapp_productivity_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_productivity_condition=='False'}checked={WebAll.edit=="True" || webapp_productivity_management.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','webapp_productivity_management')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={webapp_productivity_condition=='False'}checked={WebAll.remove=="True" || webapp_productivity_management.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','webapp_productivity_management')}  ></Checkbox></Col>
                    </Row>
                    <Row className='cotnent-table'>
                      <Col lg={4} className='label-disp'>Cycle Count <span class="switchRight"><Switch  onChange={(e)=>ChangeMainModule(e,'web_app_cycle_count_condition',5)} checked={web_app_cycle_count_condition =='True'? true:false} checkedChildren="Enable" unCheckedChildren="Disable"/></span></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={web_app_cycle_count_condition =='False'}checked={WebAll.View=="True" || web_app_cycles.View=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','View','web_app_cycles')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={web_app_cycle_count_condition =='False'}checked={WebAll.Create=="True" || web_app_cycles.Create=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','Create','web_app_cycles')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={web_app_cycle_count_condition =='False'}checked={WebAll.edit=="True" || web_app_cycles.edit=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','edit','web_app_cycles')}  ></Checkbox></Col>
                      <Col lg={2} className='label-check'><Checkbox disabled={web_app_cycle_count_condition =='False'}checked={WebAll.remove=="True" || web_app_cycles.remove=='True'} onChange={(e)=>ChangeCheckBox(e,'webapp','remove','web_app_cycles')}  ></Checkbox></Col>
                    </Row>
                   </div>  



</div>




                 <div className='field-row' style={{margin:"20px 0px 40px",textAlign:'right'}}>
                          <button type="reset" className='btn btn-submit btn-transparent btn-mar-10' onClick={()=>SetResetModal(true)}>Reset</button>
                          <button type="submit" className='btn btn-submit btn-transparent'>Submit</button>
                </div>
                </div>
                <ReactModal
                isOpen={showResetModal}
                style={customStyle}
            >
                <div className='logoutModal'>
                    <div className="logoutTitle">Reset Form{redirectTrue?', and go back':''} </div>
                    <div className='logoutDesc'>Are you sure you want to {redirectTrue?'go back':'reset?'} all changes done will be lost</div>

                    <div className='actionButtons'>
                        <span className='btnText borderRight' onClick={()=>{SetResetModal(false);SetRedirectTrue(false)}}>No</span>
                        <span className='btnText okBtn' onClick={ResetHandler}>Yes,  {redirectTrue?'Go back':'Reset'}</span>
                    </div>
                </div>
            </ReactModal>

                
                {loader=="True"?<div className='loaderArea'><img src={LoaderSpinner}/></div>:""}
                <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
            </form>

            </div>
</div>
</div>    
         
    )
    

}
export default AddRole;