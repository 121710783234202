import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreteOrg(requestBody) {
    
    return axios.post(prefixURL + `/org/create_organization` , requestBody).then(res=>res.data).catch(err=>err)
}

export function CreteSubOrg(requestBody) {
    
    return axios.post(prefixURL + `/org/create_sub_organization` , requestBody).then(res=>res.data).catch(err=>err)
}
export function EditOrg(requestBody) {
    
    return axios.post(prefixURL + `/org/update_organization` , requestBody).then(res=>res.data).catch(err=>err)
}
export function removeOrganization(requestBody) {
    return axios.post(prefixURL + `/org/remove_organization` , requestBody).then(res=>res.data).catch(err=>err)
}

// export function removeRole(requestBody) {
//     return axios.post(prefixURL + `/admin/remove_role` , requestBody).then(res=>res.data).catch(err=>err)
// }

export function getOrganizationList(requestBody) {
  
    return axios.get(prefixURL + `/org/list_organizations?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
// export function getRoleDetail(requestBody) {
  
//     return axios.get(prefixURL + `/admin/view_role_info?session=${requestBody.session}&org_id=${requestBody.org_id}&role_id=${requestBody.role_id}`).then(res=>res.data).catch(err=>err)
//     //return axios(config).then(res=>res.data).catch(err=>err);
// }
export function getorgDetail(requestBody) {
    return axios.get(prefixURL + `/org/get_organization_details?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err) 
}
export function getOemOrgDetail(requestBody) {
    
    return axios.get(prefixURL + `/dashboard/device_info_oem?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err) 
}
export function getEmpDetail(requestBody) {
    
    return axios.get(prefixURL + `/user/get_list?session=${requestBody.session}&org_id=${requestBody.org_id}&flag=${requestBody.flag}`).then(res=>res.data).catch(err=>err)
   
}