import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'


import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';

import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { getCategoryList } from '../../sitemanagement/actionMethods/actionMethods';
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'

import ExcelTemplate from '../../assets/working_data_template.xlsm'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import ExcelJs from "exceljs";
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
import '../styles/styles.scss'
import DatePicker from "react-datepicker";
import { read, utils, writeFile } from 'xlsx';
import {importDataCycleCount,importDataCycleCountValidation} from '../../massupload/actionMethods/actionMethods';
import CycleWarningModal from '../../components/CycleWarningModal';
import { getCycleCount } from '../actionMethods/actionMethods';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};

function CycleCount(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let sub_org_id =userDetails.sub_org_id?userDetails.sub_org_id:""
  const [file,setFile] =useState('')
    const hour= [
        {hour:7,time:'07:00'},
        {hour:8,time:'08:00'},
        {hour:9,time:'09:00'},
        {hour:10,time:'10:00'},
        {hour:11,time:'11:00'},
        {hour:12,time:'12:00'},
        {hour:13,time:'13:00'},
        {hour:14,time:'14:00'},
        {hour:15,time:'15:00'},
        {hour:16,time:'16:00'},
        {hour:17,time:'17:00'},
        {hour:18,time:'18:00'},
        {hour:19,time:'19:00'},
        {hour:20,time:'20:00'},
        {hour:21,time:'21:00'},
        {hour:22,time:'22:00'},
        {hour:23,time:'23:00'},
        {hour:0,time:'00:00'},
        {hour:1,time:'01:00'},
        {hour:2,time:'02:00'},
        {hour:3,time:'03:00'},
        {hour:4,time:'04:00'},
        {hour:5,time:'05:00'},
        {hour:6,time:'06:00'}
    ]


    const [currentLocation,SetCurrentLocation]=useState(null)

    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [inc,SetInc]=useState(0);
    const [loader,Setloader]= useState(true);
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')
    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [loaderExport,SetLoaderExport] =useState(false);
    const [categoryList,SetCategoryList] = useState([])
    const [scrollWidth,SetScrollWidth] = useState(0)
    const [date,SetDate] = useState(new Date())
    const [allDataFormat,SetAllDataFormat] =useState([]);
    const [locationList,SetLocationList] =useState([]);
    const [modalWarning,SetModalWarning] = useState(false);
    const [transform,SetTransform]=useState(0);

    const [modalImport,SetModalImport] =useState(false);
    const [importType,SetImportType] =useState('');
    const [importDate,SetImportDate] = useState('');
        const [fileError,SetFileError] =useState('');    


    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].dashboard))
                //Setloader(false);
                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()
        
    }, []);


    function handleCloseModalSucess(){
    
      SetModalSucess(false);
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }
    useEffect(()=>{
        let requestBody={};
      
        requestBody.session=userDetails.session;
        requestBody.org_id= userDetails.org_id;

        getCategoryList(requestBody).then(res=>{
            if(res.status==200){
                res.data = res.data.filter(item=>item.location.length > 0);
                let cat = res.data;
                let locLength = []
                for(let i =0;i<cat.length;i++){
                    if(cat[i].location.length> 0){
                        locLength.push(...cat[i].location)
                    }
                    
                }
                SetLocationList(locLength);

                let arrray=[]
                for(let i=0;i<24;i++){
                    let single ={}
                    let incDate=[0,1,2,3,4,5,6]
                    if(incDate.indexOf(i)> -1){
                            single.date=moment(date).add('days',1).format('YYYY-MM-DD');
                            
                            single.dateSort = moment(date).add('days',1).set({hour:i,minute:0,second:0,millisecond:0});
                            
                        }else{
                            single.date=moment(date).format('YYYY-MM-DD');
                            single.dateSort = moment(date).set({hour:i,minute:0,second:0,millisecond:0});
                            
                        }
                        single.time = moment().set({hour:i,minute:0}).format('HH:mm')
                    for(let j=0;j<locLength.length;j++){
                        single[slugify(locLength[j].location_name)]=0
                    }

                    arrray.push(single);
                }
                arrray.sort((a,b)=>{
                    return new Date(a.dateSort) - new Date(b.dateSort);
                })
                SetAllDataFormat(arrray);
                SetScrollWidth(locLength.length*86);
                SetCategoryList(res.data)
                Setloader(false);
            }

        })
    },[])
    

    const [statsData,SetStatsData]= useState(["#000000",'#3fb995','#000000',"#ef835c","#fbcf31","#f16b95"]);

    useEffect(()=>{
    if(inc==6){
        Setloader(false);
    }
    },[inc])

    
     function slugify(string) {  
    return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
  }

  async function importPreviewHandler(file){
 
    let emp=[];
            SetModalImport(false);

    SetLoaderImport(true);
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                     //utils.sheet_add_json(ws, movies, { origin: 'A2', skipHeader: true });
                    const rows = utils.sheet_to_csv(wb.Sheets[sheets[0]]);

                   SetAllDataFormat(parseDataHandler(rows));
                   setTimeout(function(){
                        SetLoaderImport(false);
                   },2000)

                   // setMovies(rows)
                }
            }
            reader.readAsArrayBuffer(file);

}

function parseDataHandler(rows){
    let loc = [...locationList]
    let arr=[];
    let dataExist=[];
          const data = rows.split("\n");
          let exitLoop=false;
          for(let i=2;i<data.length;i++){
            

            var row = data[i].split(",");

            if(!exitLoop){
                if(row[0]=="Total Manpower"){
                    exitLoop =true
                }else{
           let single={}
           if(moment(row[0]).toDate() >= moment().set({hour:0,minute:0,second:0}) ){
                SetDate(moment(row[0]).toDate());        
                single.date= moment(row[0]).toDate();
            single.dateSort = moment(row[0]).set({hour:parseInt(row[1]),minute:0,second:0,millisecond:0});    
           }else{
            SetTitle(<>Can't Import cycle count by old date <br/> Data will be import by <span style={{color:"#a42d20"}}> current date</span></>);
                SetModalWarning(true);
            let incDate=[0,1,2,3,4,5,6]
            if(incDate.indexOf(parseInt(row[1])) > -1){
                single.date= moment().add('day',1).set({hour:parseInt(row[1]),minute:0,second:0,millisecond:0}).toDate();
                single.dateSort = moment().add('day',1).set({hour:parseInt(row[1]),minute:0,second:0,millisecond:0});
                
            }else{
                let checkOldBigger = arr[arr.length - 1];
                if(checkOldBigger){
                    single.date= moment(checkOldBigger.dateSort).add('hour',1).toDate();
                    single.dateSort = moment(checkOldBigger.dateSort).add('hour',1);
                }else{
                    single.date= moment().set({hour:parseInt(row[1]),minute:0,second:0,millisecond:0}).toDate();
                    single.dateSort = moment().set({hour:parseInt(row[1]),minute:0,second:0,millisecond:0});

                }
             }
            
           }
            
            single.time= row[1];
            
           
            for(let i= 0;i<loc.length;i++){
                let index= data[1].split(",").indexOf(loc[i].location_name);
                if(index > -1){

                    single[slugify(loc[i].location_name)] = row[index]?parseInt(row[index]):0;
                }else{
                    single[slugify(loc[i].location_name)] = 0
                }
            }

               arr.push(single);
                }
    

            }
             

          }
          
    return arr

}
function generateExcel(){
    const workbook = new ExcelJs.Workbook();
 const filename = "Export.xlsx";
 const sheetNames = ["Sheet1"];
 sheetNames.forEach(sheetName => {
 let worksheet = workbook.addWorksheet(sheetName);
 let firstRow=["",""]
 let secondRow =["Date","Hour"];
 SetLoaderImport(true);
for(let i=0;i<categoryList.length;i++){
    firstRow.push(categoryList[i].name);
    for(let j=0;j<categoryList[i].location.length;j++){
        if(j!=0){
            firstRow.push("");
        }
        secondRow.push(categoryList[i].location[j].location_name);
    }
}
firstRow.push("Total Manpower per hour");
secondRow.push("");
worksheet.addRow(firstRow);
worksheet.addRow(secondRow);
let totalManPower=["Total Manpower",""];
let totalAll = 0;
for(let i=0;i<allDataFormat.length;i++){
    let single=[];
    single.push(moment(allDataFormat[i].date).set({hour:0,minute:0,second:0}).format("YYYY-MM-DD") )
    
    single.push(allDataFormat[i].time+":00")
    for(let j=0;j<locationList.length;j++){
        if(i==0){
            totalManPower.push(allDataFormat.reduce((old,current)=>old + current[slugify(locationList[j].location_name)],0));
        }
        single.push(allDataFormat[i][slugify(locationList[j].location_name)])
    }
    let total= 0

    for (const key in allDataFormat[i]) {
        if(key!='date' && key!="time" && key!="dateSort"){
            total = total + parseInt(allDataFormat[i][key]);
            totalAll = totalAll+ parseInt(allDataFormat[i][key]);
        }
    }
    single.push(total);
    worksheet.addRow(single);

}
totalManPower.push(totalAll);
worksheet.addRow(totalManPower);
worksheet.addRow([totalAll,""]);

	worksheet.columns.forEach(column => {
	  const lengths = column.values.map(v => v.toString().length);
	  const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
	  column.width = maxLength+2;
	});

	//table.commit();
          });
    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",

    });
             link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
    blob.name = 'export.xlsx';
    blob.lastModified = new Date();
    const myFile = new File([blob], 'export.xlsx', {
            type: "application/vnd.ms-excel;charset=utf-8;",
            lastModifiedDate:new Date(),            
        });
   
        let requestBody={}
       
        requestBody.session= session
        requestBody.org_id= userDetails.org_id      

        importDataCycleCount(requestBody,myFile).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

};
    
   


          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(filename, buffer);
          });
}
function savedCycleCountData(val,index,slug){
    let data=[...allDataFormat];
    if(val.toString().length==0){
        data[index][slug]= val;
        
        SetAllDataFormat(data);
    }
    else if(Number.isInteger(parseInt(val))){
        data[index][slug]= parseInt(val);

        SetAllDataFormat(data);
    }else{

    }
    
}

const  listenScrollEvent = event => {
   
    SetTransform(event.target.scrollLeft)
    
  };


    function loadFile(event){

        let arry = event.target.files[0].name.split('.');
        let lastElement = arry[arry.length - 1];
        // SetDailyWorkerEnable(false);           
        if(lastElement=='xls' || lastElement=='xlsx' || lastElement=='xlsm' ){    
            setFile(event.target.files[0]);    
        }else{
            SetFileError('Only Support Excel file')
            setFile('');    
            setTimeout(function(){
               SetFileError('')
            },3000)
        }
    
    }

    // function getCycleCountData (){
    //     SetModalImport(false);
    //     SetLoaderImport(true);
    //     let requestBody={}
    //     requestBody.org_id= userDetails.org_id
    //     requestBody.sub_org_id = userDetails.sub_org_id
    //     requestBody.session= session
    //     requestBody.date = moment(importDate).format('YYYY-MM-DD');
    //     let arr= []
    //     let allData=[...allDataFormat];
    //     let loc = [...locationList];

    //     getCycleCount(requestBody).then(res=>{
    //         if(res.status==200){
    //             let data= res.data
    //             let newDt= [0,1,2,3,4,5,6];
    //             let dt = moment(importDate).format('YYYY-MM-DD');
    //             for(let i=0;i<hour.length ;i++){
    //                 if(newDt.indexOf(hour[i].hour)> -1){
    //                   dt =   moment(importDate).add('day',1).format('YYYY-MM-DD');
    //                 }
    //                 let single = {}
    //                 single.date = dt
    //                 single.time = hour[i].time
    //                 //single.dateSort= moment(dt).set({hour:hour[i].hour,minute:0})
    //                 for(let j=0;j<loc.length;j++){
    //                     let checkLoc = res.data[loc[j].tag_serial]
                        
    //                     if(checkLoc){
    //                         let nowCheckHour = res.data[loc[j].tag_serial].find(item=>item.hour == hour[i].time && item.reported_date == dt)
    //                         if(nowCheckHour){
    //                             single[slugify(loc[j].location_name)] =   nowCheckHour.cycle_count
    //                         }else{
    //                             single[slugify(loc[j].location_name)] =   0
    //                         }
    //                     }else{
    //                       single[slugify(loc[j].location_name)] = 0  
    //                     }
                       
    //                 }
    //                 arr.push(single);
    //             }
               
    //             SetAllDataFormat(arr);


                
                
    //         }
  

            
    //         SetLoaderImport(false);
    //     })
    // }
    function getCycleCountData() {
        SetModalImport(false);
        SetLoaderImport(true);
        let requestBody = {};
        requestBody.org_id = userDetails.org_id;
        requestBody.sub_org_id = userDetails.sub_org_id;
        requestBody.session = session;
        requestBody.date = moment(importDate).format('YYYY-MM-DD');
        let arr = [];
        let allData = [...allDataFormat];
        let loc = [...locationList];
      
        getCycleCount(requestBody).then((res) => {
          if (res.status == 200) {
            let data = res.data;
            let newDt = [0, 1, 2, 3, 4, 5, 6];
            let dt = moment(importDate).format('YYYY-MM-DD');
            for (let i = 0; i < hour.length; i++) {
              if (newDt.indexOf(hour[i].hour) > -1) {
                dt = moment(importDate).add('day', 1).format('YYYY-MM-DD');
              }
              let single = {};
              single.date = dt;
              single.time = hour[i].time;
              //single.dateSort= moment(dt).set({hour:hour[i].hour,minute:0})
              for (let j = 0; j < loc.length; j++) {
                let checkLoc = res.data[loc[j].tag_serial];
      
                if (checkLoc) {
                  let nowCheckHour = res.data[loc[j].tag_serial].find(
                    (item) => item.hour == hour[i].time && item.reported_date == dt
                  );
                  if (nowCheckHour) {
                    single[slugify(loc[j].location_name)] = nowCheckHour.cycle_count;
                  } else {
                    single[slugify(loc[j].location_name)] = 0;
                  }
                } else {
                  single[slugify(loc[j].location_name)] = 0;
                }
              }
              arr.push(single);
            }
      
            SetAllDataFormat(arr);
          }
          SetLoaderImport(false);
        });
      }
      

    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">

        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: allDataFormat.length === 0 ? (
 <Row className='contentGroup nofound' key={"noDataTEam"}>
 <div className='group-header'>
     <Col className='align-middle'>
         <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
     </Col>
 </div>
</Row>
) :
        <> 
         {permissionAPICall && permission.View=='True'?<>
        <div className="page-header">
                    <Row>
                
                        <Col lg={4}>Cycle Count
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6} className="header-btn">
                            <div style={{display:"inline-block"}}>
                                     <DatePicker
                                        selected={date}
                                        onChange={d => SetDate(d)}
                                        dateFormat={'MMM dd'}
                                        isClearable={false}
                                        minDate={new Date()}
                                        placeholderText={'Select Date'}
                                     />                             
                             </div>
                            <div style={{display:"inline-block"}}><label for="file" className='btn BtnImport' onClick={()=>SetModalImport(true)}>Import</label> </div>
                        </Col>
                    
                    </Row>
                </div> 
                
                 <div>
                    <div className="br-seperator-row">

                        {loaderImport?<div className='ModalHighLight' style={{position:"absolute"}}><img src={LoaderSpinner}/></div>:""}
                        <div className='displayINlineLEftTableFull'>
                            <div className='nestedDIfficultTable nestedDIfficultTableHeader'>
                        <div className='leftDiv'>
                                <div className={"listTable"}>
                                    <div className='catName'></div>
                                    <div className='locationList'><div className='locationRepeat'>Date</div></div>
                                </div>
                                <div className={"listTable"}>
                                    <div className='catName'></div>
                                    <div className='locationList'><div className='locationRepeat'>Hour</div></div>
                                </div>    
                        </div>
                        <div className='rightDiv' style={{transform:`translateX(-${transform}px)`,minWidth:scrollWidth}}>
                                {                        
                            categoryList.map((item,index)=>{
                                return (
                                     <div className={"listTable listTable"+index} style={{minWidth:85*item.location.length}}>
                                        <div className={`catName catName${index}`}>{item.name}</div>
                                        <div className={'locationList locationList'+index}>{item.location.map(el=>{
                                            return <div className='locationRepeat'>{el.location_name}</div>
                                        })}</div>
                                    </div>
                                )
                            })
                        }
                        </div>
                            </div>
                            </div>


<div className='displayINlineLEftTable'>
                            {  [...allDataFormat].map( (i,index) => {
                              //let index = [...allDataFormat].findIndex(item=> item.time == i.time )
 
                              return( 
                                 <div className={`nestedDIfficultTable nestedDIfficultTableSmall nestedDIfficultTableSmall-${i}`}>
                                <div className={"listTable"} style={{minWidth:85,width:85}}>
                                    <div className='locationList locationListDate'>
                                        <div className='locationRepeat'>{moment(i.date).format('DD-MM-YYYY')}</div>
                                    </div>
                                </div>
                                <div className={"listTable"} style={{minWidth:85,width:85}}>
                                    <div className='locationList locationListHour'>
                                        <div className='locationRepeat'>{i.time}</div>
                                    </div>
                                </div>
                                </div>
                                ) 
                              })
                            }
                        </div>
                        

                        <div className='parentScroll' onScroll={listenScrollEvent}>
                        <div className='childScroll'   style={{width:scrollWidth}}>
                        
                        {
                            [...allDataFormat].map( (i,index) => {

                              //let index = [...allDataFormat].findIndex(item=> item.time == i.time )
 
                              return(  <div className={`nestedDIfficultTable nestedDIfficultTableSmall nestedDIfficultTableSmall-${i}`}>
                                {/* <div className={"listTable"} style={{minWidth:85,width:85}}>
                                    <div className='locationList locationListDate'>
                                        <div className='locationRepeat'>{moment(i.date).format('DD-MM-YYYY')}</div>
                                    </div>
                                </div>
                                <div className={"listTable"} style={{minWidth:85,width:85}}>
                                    <div className='locationList locationListHour'>
                                        <div className='locationRepeat'>{i.time}</div>
                                    </div>
                                </div> */}

                               { categoryList.map((item)=>{
                            
                                return (
                                    item.location.map(e=>{
                                    
                                    return <div className={"listTable listTable"+index} style={{minWidth:85,width:85}}>
                                        <div className={allDataFormat[index] && allDataFormat[index][slugify(e.location_name)].toString().length > 0 && allDataFormat[index][slugify(e.location_name)] > 0?'locationList changesinLocation locationList'+index:'locationList locationList'+index}>
                                            <div className='locationRepeat'><input type="text" value={allDataFormat[index] && allDataFormat[index][slugify(e.location_name)].toString().length > 0?allDataFormat[index][slugify(e.location_name)]:""} onChange={(event)=>savedCycleCountData(event.target.value,index,slugify(e.location_name))} className='form-control' /></div>
                                        </div>
                                        </div>
                                   })     
                                   )
                                    })

                            }
                             </div>
                            
                            )
                            })
                            
                    }
                    </div>
                    </div>
                    <div  className='submitArea' style={{textAlign:"right",marginTop:20}}>
                        <button className='btn btn-pink' onClick={()=>generateExcel()}>Submit</button>
                    </div>
                    </div>

                 </div>


        <ReactModal
                isOpen={modalImport}
                style={customStyles}
                key={'mapLocationTemp'}
              //  onRequestClose={handleCloseModal}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Import Cycle Count</h4>
                    <div className='closeMark' onClick={()=>{SetModalImport(false);} }>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                         <label>Import By</label>
                        <div className='importBY'>
                            <button className={importType=='file'?'btn btn-select btn-primary btnActive firstButton':'btn btn-select btn-primary btn-non-selected firstButton'} onClick={()=>SetImportType('file')}>File</button>
                            <button className={importType=='date'?'btn btn-select btn-primary btnActive SecondButton':'btn btn-select btn-primary btn-non-selected SecondButton'} onClick={()=>SetImportType('date')}>Date</button>
                        </div>
                    </div>
                    {importType=='file'? <>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'> Select file you want to import</span></div></label>
                     <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                                                 {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e)}  style={{display:"none"}} />                          
                       </div>
                                             <button className='btn btn-primary btn-100' onClick={()=>importPreviewHandler(file)}>Import</button>                </>:""}
                     {importType=='date'?<>
                     <div className='field-row field-row-modalDate'>
                     <label>Date<div ><small><span className='helpSmall'> Select Date from which you want to import</span></small></div></label>
                            <DatePicker
                                selected={importDate}
                                onChange={d => SetImportDate(d)}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                maxDate={new Date()}
                                placeholderText={'Select Date'}
                                />   

                       </div>
                         <button className='btn btn-primary btn-100' onClick={()=>getCycleCountData()}>Import</button>                
                       </>:""
                     }  
                      <span className='noteArea'></span> 
                      
                </div>
        </div>
            </ReactModal>    
    
       
            </>:"" }
 
                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}

    
        </>}


    </div>
</div>

        <CycleWarningModal modalWarning={modalWarning} handleDeleteConfirm={()=>SetModalWarning(false)} handleCloseModalWarning={()=>SetModalWarning(false)} title={title} />
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(CycleCount))
