import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreateMapping(requestBody) {
    
    return axios.post(prefixURL + `/clientapp/create_mapping_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}
export function UpdateMapping(requestBody) {
    
    return axios.post(prefixURL + `/clientapp/edit_mapping_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}
export function RemoveMapping(requestBody) {
    return axios.post(prefixURL + `/clientapp/remove_mapping_webpage` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getRoleDetail(requestBody) {  
    return axios.get(prefixURL + `/admin/view_role_info?session=${requestBody.session}&org_id=${requestBody.org_id}&role_id=${requestBody.role_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}

export function getOrgWebpages(requestBody) {  
    return axios.get(prefixURL + `/clientapp/get_org_webpages?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
