import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'
/*----CloneUser --- */

export function CreateTeam(requestBody) {
    return axios.post(prefixURL + `/department/create_teams` , requestBody).then(res=>res.data).catch(err=>err)
}

export function UpdateTeam(requestBody) {
    return axios.post(prefixURL + `/department/edit_teams` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getEmployeeDetail(requestBody) {  
    return axios.get(prefixURL + `/employee/get_employee_list?session=${requestBody.session}&org_id=${requestBody.org_id}&flag=${requestBody.flag}&sub_org_id=${requestBody.sub_org_id}`).then(res=>res.data).catch(err=>err)
}

export function getTeamDetail(requestBody) { 
    return axios.get(prefixURL + `/user/get_team_details?session=${requestBody.session}&org_id=${requestBody.org_id}&team_id=${requestBody.team_id}&sub_org_id=${requestBody.sub_org_id}`).then(res=>res.data).catch(err=>err)
}

export function getTeamList(requestBody) { 
    return axios.get(prefixURL + `/user/get_team_list?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}`).then(res=>res.data).catch(err=>err)
}
export function getTeamLeaderList(requestBody) { 
    return axios.get(prefixURL + `/user/get_team_leader_list?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}`).then(res=>res.data).catch(err=>err)
}
export function removeTeam(requestBody) {
    return axios.post(prefixURL + `/team/remove_team` , requestBody).then(res=>res.data).catch(err=>err)
}
export function getsuborganizationlist(requestBody) { 
    return axios.get(prefixURL + `/org/list_sub_organizations?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}





