import axios from "axios";
let prefixURL = process.env.REACT_APP_URL
let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function getLocationList(requestBody) {
    
    return axios.get(prefixURL + `/location/get_locations?org_id=${requestBody.org_id}&session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
}

export function getlocationDetail(requestBody) {
    return axios.get(prefixURL + `/location/view_locations?org_id=${requestBody.org_id}&session=${requestBody.session}&location_id=${requestBody.location_id}`).then(res=>res.data).catch(err=>err)
}

export function getlocationType(requestBody) {
    return axios.get(prefixURL + `/location/get_location_type?session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
}



export function addLocation(requestBody){ 
    return axios.post(prefixURL + `/location/add_location`,requestBody).then(res=>res.data).catch(err=>err)
}

export function removeLocation(requestBody){ 
    return axios.post(prefixURL + `/location/remove_location`,requestBody).then(res=>res.data).catch(err=>err)
}



export function addCategory(requestBody){ 
    return axios.post(prefixURL + `/category/create_category`,requestBody).then(res=>res.data).catch(err=>err)
}

export function removeCategory(requestBody){ 
    return axios.post(prefixURL + `/category/remove_category`,requestBody).then(res=>res.data).catch(err=>err)
}
export function editLocation(requestBody){ 
    return axios.post(prefixURL + `/location/edit_location`,requestBody).then(res=>res.data).catch(err=>err)
}

export function getCategoryList(requestBody) {
    
    return axios.get(prefixURL + `/category/list_category?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}

export function updateCategory(requestBody){ 
    return axios.post(prefixURL + `/category/edit_category`,requestBody).then(res=>res.data).catch(err=>err)
}