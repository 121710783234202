import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/Category.scss';
import { AutoComplete } from 'antd';
import {addCategory} from '../../actionMethods/actionMethods'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};



function CreateCategory(props) {
	const [modalSucess,SetModalSucess] = useState(false);
	const [modalError,SetModalError] = useState(false);
	const [title,SetTitle] =useState('');
	const [description,SetDescription] = useState('')
    const [catName, setCatName] = useState('');
	const [catNameError, setCatNameError] = useState('');
	const reg = /^\S.*$/;
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""

	function AddCategoryHandler(){

		if(catName==''){
			setCatNameError('Category name is required');
		}else if(catName.length > 255){
			setCatNameError('Category name length should be less than 255 character');
		}else{
			setCatNameError('');
			
			let requestBody ={}
			requestBody.session=session
			requestBody.category_name= catName
			requestBody.org_id= org_id
			
			addCategory(requestBody).then(res=>{
				if(res.status==200){
					//props.handleCloseModal();
					SetTitle(<h4><strong>{catName}</strong> category is created</h4>);
					SetModalSucess(true)

				}else{


					SetTitle(<h4>{res.message}</h4>);
					SetDescription(<><strong>{catName}</strong> category is not created</>)
					
					SetModalError(true)
				}
			})

		}
	}
      
	function handleCloseModalSucess(){
		SetModalSucess(false)
		props.handleCloseModal()
		props.Reload();
	}
	function handleCloseModalError(){
		SetModalError(false)
		props.handleCloseModal()
	}

    return(

<>        <div>
            <div className='modalHeader'>
                    <h4>Add Category</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                        <label>Category Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Category Name Max length 255 character</span></div></label>
                        <input type="text"  className={catNameError!=''?'form-control has-error':'form-control'} value={catName} onChange={(e)=>{if(reg.test(e.target.value)){setCatName(e.target.value); }else if(e.target.value==''){setCatName(e.target.value) } }  } />                
						{catNameError!=''?<div className='errorMessage'>{catNameError}</div>:""}
                    </div>
                    <button className='btn btn-primary btn-100' onClick={AddCategoryHandler} >Add Category</button>    
                </div>
        </div>
		<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
		</>
    )
}
export default CreateCategory;