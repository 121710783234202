// import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
// import { sidebarMenuItems } from '../common/menuItems';
// import { getTranslatedText } from '../common/utilities';
// import { ReactComponent as LogoutIcon } from '../assets/images/arrow-right-from-bracket.svg';

// class Aside extends React.Component {
//   getActiveTab = (eachMenuItem) => {
//     let className = '';
//     if (this.props.location.pathname.includes(eachMenuItem.paths)) {
//       className = 'nav-item active';
//     } else if (
//       (this.props.location.pathname.includes('role-detail') ||
//         this.props.location.pathname.includes('edit-role') ||
//         this.props.location.pathname.includes('add-role') ||
//         this.props.location.pathname.includes('role-management')) &&
//       eachMenuItem.paths === '/role-management'
//     ) {
//       className = 'nav-item active';
//     } else {
//       className = 'nav-item';
//     }
//     return className;
//   };

//   logoutHandler = () => {
//     localStorage.removeItem('');
//     localStorage.removeItem('userLoginDetailsTraceAdmin');
//     localStorage.removeItem('isLoggedInGatewayTraceAdmin');
//     this.props.history.push('/login');
//   };

//   showMenuItems = () => {
//     let arr = [];
//     let organization = this.props.permission.org_type === 'oem' ? 'True' : 'False';
//     const isOrganizationManagementSelected = this.props.location.pathname === '/organization-management';
    
//     for (let index = 0; index < sidebarMenuItems.length; index++) {
//       const element = sidebarMenuItems[index];

//       if (isOrganizationManagementSelected) {
//         if (element.title === 'Org Management' && organization === 'True') {
//           const content = (
//             <div className={element.submenu && element.submenu.length > 0 ? 'menu-has-child' : 'menudefault'}>
//               <Link to={element.paths} className="nav-link" onClick={() => this.props.ToggledMenu()}>
//                 <span className="shape1"></span>
//                 <span className="shape2"></span>
//                 <span className="sidemenu-icon">
//                   {this.getActiveTab(element) === 'nav-item' ? <element.icon /> : <element.activeicon />}
//                 </span>
//                 <span className={this.getActiveTab(element)}>{getTranslatedText(element.title)}</span>
//               </Link>
//             </div>
//           );

//           arr.push(<li className={element.status === 'inactive' ? 'disabled-li' : this.getActiveTab(element)} key={index}>{content}</li>);
//         }
//       } else {
//         if (element.title !== 'Org Management') {
//           const content = (
//             <div className={element.submenu && element.submenu.length > 0 ? 'menu-has-child' : 'menudefault'}>
//               <Link to={element.paths} className="nav-link" onClick={() => this.props.ToggledMenu()}>
//                 <span className="shape1"></span>
//                 <span className="shape2"></span>
//                 <span className="sidemenu-icon">
//                   {this.getActiveTab(element) === 'nav-item' ? <element.icon /> : <element.activeicon />}
//                 </span>
//                 <span className={this.getActiveTab(element)}>{getTranslatedText(element.title)}</span>
//               </Link>
//             </div>
//           );

//           arr.push(<li className={element.status === 'inactive' ? 'disabled-li' : this.getActiveTab(element)} key={index}>{content}</li>);
//         }
//       }
//     }
//     return arr;
//   };

//   render() {
//     return (
//       <div className="main-sidebar-body">
//         <ul className="nav">{this.showMenuItems()}</ul>
//         {/* <div className='logout

//                 {/* <div className='logout-btn'>
//                     <button onClick={()=>this.logoutHandler()} className='btn btn-icon'><LogoutIcon/> Logout </button>
//                 </div> */}
//             </div>
//         )
//     }
// }

// export default withRouter(Aside)
import React from 'react';
import { Link, withRouter } from 'react-router-dom'

import { sidebarMenuItems } from '../common/menuItems';
import { getTranslatedText } from '../common/utilities';
import {ReactComponent as LogoutIcon} from '../assets/images/arrow-right-from-bracket.svg'


class Aside extends React.Component {

    
    getActiveTab = (eachMenuItem) => {


       
        
        let className = ''
       
        if (this.props.location.pathname.includes(eachMenuItem.paths) ) {
            className = 'nav-item active'

        }else if( (this.props.location.pathname.includes('role-detail') 
                || this.props.location.pathname.includes('edit-role') 
                ||  this.props.location.pathname.includes('add-role') ||
                  this.props.location.pathname.includes('role-management') ) && eachMenuItem.paths=='/role-management' ){
            className = 'nav-item active'
        }
        else if((this.props.location.pathname.includes('supervisor-management')||this.props.location.pathname.includes('team-management') ) && eachMenuItem.paths=='/team-management' ){
            //  className = '/team-management active'
            className = 'nav-item active'
        }
        
        else{
            className = 'nav-item'
        }

        return className
    }

    logoutHandler= ()=> {
        localStorage.removeItem('')
        localStorage.removeItem('userLoginDetailsTraceAdmin');
        localStorage.removeItem('isLoggedInGatewayTraceAdmin');
        this.props.history.push('/login')        
    }

    showMenuItems = () => {
        let license = ""
        let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
        let arr = []
       
        let dashboard = this.props.permission.dashboard_management ?this.props.permission.dashboard_management:"False"
        let role = this.props.permission.role_management  ?this.props.permission.role_management:"False"
        let user = this.props.permission.user_management  ?this.props.permission.user_management:"False"
        let asset = this.props.permission.asset_management?this.props.permission.asset_management:"False"
        let productive = this.props.permission.admin_productive_management?this.props.permission.admin_productive_management:"False"
        let site = this.props.permission.site_management?this.props.permission.site_management:"False"
        let geomap = this.props.permission.geo_map_admin?this.props.permission.geo_map_admin:"False"
        let mass = this.props.permission.bulk_management_page?this.props.permission.bulk_management_page:"False"
        let device = this.props.permission.device_management?this.props.permission.bulk_management_page:"False"
        let shift = this.props.permission.shift_management_page?this.props.permission.shift_management_page:"False"
        let team = this.props.permission.team_management?this.props.permission.team_management:"False"
        let supervisor = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False"
        // let organization = this.props.permission.org_type === "oem" ? "True" : "False";
        let organization = this.props.permission.org_type === "oem"  ? "True" : "False";
        let cycleCount = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False"
        let facilityCounter = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False";
        let holiday = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False";
        let clietApp = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False";
        let clietDB = this.props.permission.dashboard_management?this.props.permission.dashboard_management:"False";

        for (let index = 0; index < sidebarMenuItems.length; index++) {
            const element = sidebarMenuItems[index];

            if(this.props.firstLoad == true){
                if(
                    element.title =='Org Management' && organization =="True"
                    || element.title =='Web App Page Management' && organization =="True" 
                    || element.title =='Client App Management' && organization =="True" 
                
                ){
                    const  content =  <div className={element.submenu && element.submenu.length >0?"menu-has-child":"menudefault"}>
                    <Link to={element.paths} className="nav-link" onClick={()=>this.props.ToggledMenu() } >
                        <span className="shape1"></span><span className="shape2"></span>
                           <span className='sidemenu-icon'>{this.getActiveTab(element)=='nav-item'?<element.icon />:<element.activeicon />}</span>
                            <span className={this.getActiveTab(element)}>
                                {getTranslatedText(element.title)}        
                            </span>
                        </Link>
                    </div>
        
                
                        arr.push(
                            <li className={element.status=='inactive'?"disabled-li":element.submenu && element.submenu.length >0?"parentNav "+this.getActiveTab(element):this.getActiveTab(element)}  key={index}>{content}</li>
                        )
        
                }

            }

           else if( (element.title =='Dashboard' && dashboard =="True") ||
                (element.title =='Role Management' && role =="True") ||
                (element.title =='User Management' && user =="True") ||
                (element.title =='Asset Management' && asset =="True") ||
                (element.title =='Productive Management' && productive =="True") ||
                (element.title =='Site Management' && site =="True") ||
                (element.title =='Device Management' && device =="True") ||
                (element.title =='Geo Map Management' && geomap =="True") || 
                (element.title =='Plan Import') ||
                (element.title =='Shift Management' && shift =="True") ||
                (element.title =='Team Management' && team =="True") ||
                // (element.title =='Org Management' && organization =="True") ||
                (element.title =='Supervisor Management' && supervisor =="True") ||
                (element.title =='Cycle Count' && supervisor =="True") ||
                (element.title =='Facility Counter' && facilityCounter =="True") || 
                (element.title =='Holiday Management' && holiday =="True") ||
                (element.title =='Device Monitor' && holiday =="True") ||
                (element.title == 'Registered Workers' && holiday=="True" ) ||
                (element.title == 'Process Management' && holiday=="True" ) || 
                (element.title == 'Mass Upload' && mass=="True" )
                 )    {

                
                 const  content =  <div className={element.submenu && element.submenu.length >0?"menu-has-child":"menudefault"}>
            <Link to={element.paths} className="nav-link" onClick={()=>this.props.ToggledMenu() } >
                <span className="shape1"></span><span className="shape2"></span>
                   <span className='sidemenu-icon'>{this.getActiveTab(element)=='nav-item'?<element.icon />:<element.activeicon />}</span>
                    <span className={this.getActiveTab(element)}>
                        {getTranslatedText(element.title)}
                    </span>
                </Link>
            </div>

        
                arr.push(
                    <li className={element.status=='inactive'?"disabled-li":element.submenu && element.submenu.length >0?"parentNav "+this.getActiveTab(element):this.getActiveTab(element)}  key={index}>{content}</li>
                )
            }

        }
        return arr
            }


     
    render() {
        return (
            <div className='main-sidebar-body'>
                <ul className='nav'>
                    {this.showMenuItems()}
                </ul>
                {/* <div className='logout-btn'>
                    <button onClick={()=>this.logoutHandler()} className='btn btn-icon'><LogoutIcon/> Logout </button>
                </div> */}
            </div>
        )
    }
}

export default withRouter(Aside)










