import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../style/styles.scss';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import {ReactComponent as UPIcon} from  '../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import Scrollbars from 'react-custom-scrollbars';
import {Checkbox, Button } from 'antd';

import '../../assetmanagement/styles/addEmployee.scss';
import {UpdateProcess} from '../actionMethods/actionMethods'
import { Select } from 'antd';

function EditProcess(props){
    const [loader,SetLoader] =useState(true);
    const [name,SetName]=useState('');
    const [nameError,SetNameError]=useState('')
	const { Option } = Select;
    const reg = /^\S.*$/;
    const [processID,SetProcessID]= useState('');
	const[processName,setprocessName] =useState("")
	const [selectedFunction,SetSelectedFunction]= useState([]);
	const [functionError,SetfunctionError]=useState('')


	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""


    useEffect(()=>{
        
        if(props.ProcessIDData){
            setprocessName(props.ProcessIDData.process_name)
            SetSelectedFunction(props.ProcessIDData.functions.map(el=>parseInt(el)));
			console.log("function",props.ProcessIDData.functions)
			SetProcessID(props.ProcessIDData.id);
			SetLoader(false);
        }
        
    },[props.ProcessIDData])


    function EditProcessHandler(){

            let requestBody={}      
			console.log("Function",selectedFunction.length)
			if(processName.length === 0){
				SetNameError("This field is required")
			}else if(processName.length > 255){
				SetNameError("Field Length should be less than 255")
			}else if(selectedFunction.length === 0){
				SetNameError('');
					SetfunctionError("Atleast one function should be selected");
			}else{
				console.log('validated else');	
				requestBody.org_id =org_id
				requestBody.session= userDetails.session
				requestBody.sub_org_id = userDetails.sub_org_id
				requestBody.process_name= processName
				requestBody.functions = {sub_department_ids : selectedFunction}
				requestBody.process_id= processID;
			
				UpdateProcess(requestBody).then(res=>{
					if(res.status==200|| res.code==200){
						props.modalSucessHandler(<h4>Process is Updated</h4>,res.message)
					}else{
						props.modalErrorHandler(<h4>{res.message}</h4>,'Failed to Update Process')
					}
				})

			}
        
        }


function changeCheckBox(e,val){
	console.log(e);
	let list = [...selectedFunction];
	if(e){
		list.push(val);
	}else{
		let index =list.indexOf(val);
		list.splice(index,1);
	}
	SetSelectedFunction(list)
}




    return(
			<div>
                   <div className='modalHeader'>
                    <h4>Edit Process</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
				{loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: 
				<div className='modalBody'>
				
				<div className="steps-content step-user-info">
					<Row>
						<Col lg={6}>
							<div className='field-row'>
									<label>Name*<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={nameError!=''?'form-control has-error':'form-control'} value={processName} onChange={(e)=>{if(reg.test(e.target.value)){setprocessName(e.target.value);SetNameError("")}else if(e.target.value==''){setprocessName(e.target.value)} }  } />
									{nameError!=''?<div className='errorMessage'>{nameError}</div>:""}
							</div>	
							<div className='field-row' style={{marginBottom:0}}>
								<label>Function*</label>
								{functionError?<div className='functionError'>{functionError}</div>:""}
								<div className='bodyAreaTeam'>
									
								<Scrollbars style={{height:"275px"}}>
								{props.functionType.map((el,index)=>{
									return <Row className='contentGroup' key={"selection"+index+el.sub_department_id}><div className='group-header'><Col lg={12}><Checkbox checked={selectedFunction.indexOf(el.sub_department_id) > -1} onChange={(e)=>changeCheckBox(e.target.checked,el.sub_department_id)}>{el.category_name}</Checkbox></Col></div></Row>
								})}
								</Scrollbars>		 
								</div>
							</div>


						</Col>
						<Col lg={6}>
							<div className="listSelectedEMp" style={{background:"#f9f4f4"}} >
								<h6 style={{textAlign:"left"}} >Selected Function</h6>
								
								<Scrollbars style={{height:300}}>
									{selectedFunction.map((el,index)=>{
										return <div className="employeee" key={"remove"+index+el}><span>{props.functionType.find(item=>item.sub_department_id === el)?.category_name}</span><div className="removeEmp"onClick={()=> changeCheckBox(false,el) }>x</div></div>
									})}
									

								</Scrollbars>
								
							</div>

						</Col>

					</Row>							
				
					
				</div>
                
				<div className="steps-action">
					
					<Button type="primary" onClick={EditProcessHandler} className={'btn btn-primary'}>
						Edit Process
					</Button>
						</div>
    
                </div>}
    </div>
    )
}


const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(EditProcess))