import axios from "axios";

let prefixURL = process.env.REACT_APP_URL
let prefixURL2 = process.env.REACT_APP_URL_2

export function userInfo( requestBody) {
    return axios.get(prefixURL + `/dashboard/user_info?session=${requestBody.session}&org_id=${requestBody.org_id}`)
        .then(res => res.data).catch(err => err)     
}
//https://api.traceplus.ai/trace/api/v1/get_cycle_count_plan?session=8f72ea326b2b4239956beeba29b11a8c&org_id=28&sub_org_id=0&date=2023-06-09
export function getCycleCount(requestBody){
    return axios.get(prefixURL2 + `/get_cycle_count_plan?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}&date=${requestBody.date}`)
        .then(res => res.data).catch(err => err)     
} 
