import React, { useState, useEffect } from 'react'
import ExcelJs from "exceljs";

import {getExportCycleCount} from '../../actionMethods/actionMethods';
import ErrorModal from '../../../components/ErrorModal';
import moment from 'moment';
//(Daily Employee Bulk Update)      


export default function GenerateCycleCount(props){

  const [errorModal,SetErrorModal]=useState(false);
  const [title,SetTitle]=useState('');
  const [description,SetDescription]=useState('');

  function handleCloseModal(){
      SetErrorModal(false);
  }


  function numToSSColumnLetter(num) {
    let columnLetter = "",
      t;
  
    while (num > 0) {
      t = (num - 1) % 26;
      columnLetter = String.fromCharCode(65 + t) + columnLetter;
      num = (num - t) / 26 | 0;
    }
    return columnLetter || undefined;
  }
  function numberToLetters(num) {
    let letters = ''
    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }
    return letters
  }

  //getting alphabet of excel column
  function numToSSColumnLetter(num) {
    let columnLetter = "",
      t;
  
    while (num > 0) {
      t = (num - 1) % 26;
      columnLetter = String.fromCharCode(65 + t) + columnLetter;
      num = (num - t) / 26 | 0;
    }
    return columnLetter || undefined;
  }



  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';


  function generateExcel(){
      let requestBody={}
      requestBody.session=session;
      requestBody.org_id=userDetails.org_id;

      getExportCycleCount(requestBody).then(res=>{
          if(res.status==200){
          
            const workbook = new ExcelJs.Workbook();     
            let sheetName =moment(props.date).format("DD MMM YY");
            let headerName= "Report"
            const filename = "CycleCount"+"-"+moment(props.date).format("MMM DD")+".xlsx";

            let loc= res.data.locations_names


            let sheet = workbook.addWorksheet('Cycle Count');

            let rows1=["Planned Cycle Count"];
            let lengthofAllRow = 2 + loc.length;
            let rows2=["Date","Hour",...loc];
            sheet.addRow(rows1).fill={
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "d0cfcf" }
            }
            sheet.addRow(rows2).font={
                bold:true
              };
            sheet.mergeCells('A1:'+numberToLetters(lengthofAllRow)+(1)); 
            sheet.columns.forEach(column => {
                if(column){

                  const lengths = column.values.map(v => v?v.toString().length:0);
                  const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                  column.width = maxLength + 2;
                }
                
              });
              //sheet.getRow(1).alignment = { vertical: 'middle', wrapText: true,alignment:'center',align:'center', };
            sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true,font : {      
                bold:true
              } };
              sheet.getRow(1).font={
                bold:true
              }
            
            let emptyValLoc = loc.map(item=>{return ""})
            sheet.addRow([moment(props.date).format("YYYY-MM-DD"),"00:00"]);
            for(let i=1;i<24;i++){
                sheet.addRow(["",i<10?"0"+i+":00":i+":00"]);
            }
            
            sheet.mergeCells('A3:A26');
            sheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            const writeFile = (fileName, content) => {
                const link = document.createElement("a");
                const blob = new Blob([content], {
                  type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = fileName;
                link.href = URL.createObjectURL(blob);
                link.click();
              };
              //const stream = fs.createWriteStream(filename);
            
              workbook.xlsx.writeBuffer().then((buffer) => {
                  writeFile(filename, buffer);
              });

          }else{
            SetTitle("Error");
            SetDescription(res.message)
            SetErrorModal(true);
        }

      })
      
  }






  return (<>
    <button className='btn btn-transparent btn-twoline' onClick={generateExcel}>
              <span className='smallerSpan'>Export</span>
              <span className='biggerSpan'>Cycle Count</span>
      </button>
    <ErrorModal modalError={errorModal} handleCloseModalError={handleCloseModal}   title={title} description={description}  key={'GenerateTagRegilar'} />
  </>)
}