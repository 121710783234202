import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withRouter ,useHistory} from "react-router-dom";
import { connect } from 'react-redux';
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import TimezonePicker from '../timezonepicker/TimeZonePicker'
import {CreteOrg, CreteSubOrg} from '../actionMethods/actionMethods'
import {Switch } from 'antd';
import ReactModal from 'react-modal';
import {Radio, Button,Steps } from 'antd';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'

function Createsuborg(props) {
	const history = useHistory();
    const [loader,SetLoader] =useState(false);
	const [timezone, setTimezone] = useState('');
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    const { Step } = Steps;
	const [current, setCurrent] = React.useState(0);
  const reg = /^\S.*$/;
	
    const [title,SetTitle] =useState('');
	const [descriptionMessage,SetDescriptionMessage] = useState('')
  const [modalSucess,SetModalSucess] = useState(false);
	const [modalError,SetModalError] = useState(false);
    const[suborgname,SetSuborgname]=useState("")
	const [suborg_nameError,SetsuborgnameError]= useState('');
    const [description,SetDescription] =useState('');
    function handleCloseModalSucess(){
		props.Reload();
		props.handleCloseModal()		
		SetModalSucess(false)
	  }
	  function handleCloseModalError(){
		SetModalError(false)
	  }
  
    function SubmitHandlerGroup(){
		if(suborgname==''){	
			SetsuborgnameError('Sub Organization Name is required')
		}else if(suborgname.length > 255){
			SetsuborgnameError('Sub Organization Name length should be less than 255 character');
		}
		else{	
			let requestBody={}
            requestBody.session=session
            requestBody.org_id=String(props.selectedorg)
            requestBody.sub_org_name=suborgname
            SetLoader(true);
            CreteSubOrg(requestBody).then(res=>{
					SetLoader(false)
					if(res.status==200){
						SetTitle(<h4>Sub Organisation is created</h4>)						
						SetDescription('')
						SetModalSucess(true)                    
                	}else{
						SetTitle(<h4>{res.message}</h4>)						
						SetDescription('Sub Organization is not created')
						
						SetModalError(true)                    
					}
				// if(res.status==300){
				// 	localStorage.removeItem('isLoggedInGatewayTraceAdmin')
				// 	localStorage.removeItem('userLoginDetailsTraceAdmin')
				// 	props.history.push(`/login`)
				// }
				
				})

		  }
	  }
   
     
      
  
    return(
        <div>
        <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
        <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
        
        {loader?<div className='loaderContainer loaderContainerSmall'><img src={LoaderSpinner}/></div>:<>
        <div className='modalHeader'>
                <h4>Add Sub Organization</h4>
                <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
            </div>
            <div className='modalBody'>
                <div className='field-row'>
                    <label style={{marginBottom:"10px"}}>Sub Organization Name<div className='posRel'><HelpIcon /><span className='helpSmall'>Max length 255  character</span></div></label>
                    <input
                             className={'form-control'}
                                type="text"
                                // className="form-control input-field"
                                // onChange={(e)=>{{Setorgurl(e.target.value)}}}
                                value={suborgname}
                                onChange={(e)=>{if(reg.test(e.target.value)){SetSuborgname(e.target.value)}else if(e.target.value==''){SetSuborgname(e.target.value)} }  }  
                            />
                    {suborg_nameError!=''?<div className='errorMessage'>{suborg_nameError}</div>:""}
                </div>
                <button className='btn btn-primary btn-100' onClick={SubmitHandlerGroup}>Add Sub Organization</button>    
            </div>
            </> }
    </div>
)}
const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Createsuborg))