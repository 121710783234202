import React, {
    useState,
    useCallback,
    useReducer,
    useEffect,
    Fragment
  } from 'react'
  import { Group } from '@mantine/core'
  import { useMediaQuery } from '@mantine/hooks'
  import Chance from 'chance'
  import {
    append,
    compose,
    isEmpty,
    map,
    reject,
    intersperse,
    evolve,
    max,
    props
  } from 'ramda'
  import { Select,Button   } from 'antd';
  import { Container, Row, Col } from 'react-bootstrap';  
  import Viewer from './Viewer';
  import ReactModal from 'react-modal';
  import { getLocationList } from '../../sitemanagement/actionMethods/actionMethods'
  import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
  import  '../../sitemanagement/styles/Category.scss';
  import { getLocationCordinate, CreteGeoMap,CreteGeoMapLocation } from '../actionMethods/actionMethods'
  import SucessModal from '../../components/SucessModal'
  import ErrorModal from '../../components/ErrorModal'
  import LoaderSpinner from '../../assets/images/Spinner Loader.gif'
  import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
  import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'
  import { removeMapLocation} from '../actionMethods/actionMethods'
  import {getRoleDetail} from '../../rolemanagement/actionMethods/actionMethods'
  import CMGManualWarehouse from '../../assets/cmg_manual.json'
  import CMGBulkyWarehouse from '../../assets/cmg_bulky.json'



  const INITIAL_MODE = '3d'
  
  const chance = new Chance()
  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};



  const AddDataElements = () => {
    const isMobile = useMediaQuery('(max-width: 600px)')
    const { Option } = Select;  
    const [space, setSpace] = useState()
    const [task, setTask] = useState('drawIcon')
    const [mode, setMode] = useState(INITIAL_MODE)
    const [showTooltip, setShowTooltip] = useState(false);
    const [editingId, setEditingId] = useState()
    const [locationName,SetLocationName]=useState();
    const [locationID,SetLocationID] =useState('');
    const [locationRemoveID,SetLocationRemoveID] =useState('');
    const [locationNameError,SetLocationNameError]=useState('');
    const [locationList,SetLocationList]=useState([]);
    const [modalAdd,SetModalAdd] =useState(false);
    const [modalDelete,SetModalDelete] =useState(false);
    const [modalAddSpace,SetModalAddSpace] =useState(false);
    const [currentArray,SetCurrentArray]=useState([])
    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false);
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let sub_org_id = userDetails.sub_org_id!=null?userDetails.sub_org_id:0;
    let org_id = userDetails.org_id?userDetails.org_id:""
    const[permission,SetPermission]=useState([])
    const [mappedLocation,SetMappedLocation] =useState([]);
    const [spaceID,SetSpaceID] = useState('');
    const [spaceIDCreate,SetSpaceIDCreate] = useState('');
    const [spaceIDError,SetSpaceIDError] = useState('');
    const [defaultCordinate,SetDefaultCordinate] =useState([]); 
    const [title, SetTitle]=useState('');
    const [description,SetDescription] = useState('');
    const [loader,SetLoader] = useState(true);
    const onReady = useCallback(space => setSpace(space), [])
  
    const onModeChange = useCallback(setMode, [])
  
    const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
      mode
    ])
    const autoElevation = map(
      evolve({ position: { elevation: noElevationIn2D } })
    )
    const [points, dispatchPoint] = useReducer((points, action) => {

    
      switch (action.type) {
        case 'add':
          return [...points, action.point]
        case 'update':
          return points.map(pt =>
            pt.id === action.id ? { ...pt, ...action.updates } : pt
          )
        case 'remove':
          return reject(r => r.id === action.id)(points)
        default:

      }
      
    },[])
    useEffect(() => {
      let requestBody={}
      requestBody.session=session;
      requestBody.org_id= org_id
      requestBody.role_id = userDetails.role;
      getRoleDetail(requestBody).then(res=>{
          if(res.status==200 || res.code==200){
              SetPermission(JSON.parse(res.data[0].geo_map_admin_permissions));
              console.log("set",JSON.parse(res.data[0].geo_map_admin_permissions))
          }
          
          if(res.status==300){
            localStorage.removeItem('isLoggedInGatewayTraceAdmin')
            localStorage.removeItem('userLoginDetailsTraceAdmin')
            props.history.push(`/login`)        
        }
      }).catch()

  }, []);

    useEffect(()=>{
      
    },[editingId])
    useEffect(()=>{
      let requestBody={}
      requestBody.session=session;
      requestBody.org_id=org_id
      getLocationList(requestBody).then(res=>{
        if(res.status=200 || res.code == 200){
          SetLocationList(res.data);
        }
      }).catch();
    },[])



    const [icons, dispatchIcon] = useReducer((icons, action) => {

      switch (action.type) {
        case 'add':
          return [...icons, action.icon]
        case 'update':
          return icons.map(pt =>
            pt.id === action.id ? { ...pt, ...action.updates } : pt
          )
        case 'remove':
          return reject(r => r.id === action.id)(icons)
        default:
          
      }
    }, [])
    
  const [polylines, dispatchPolyline] = useReducer((polylines, action) => {
    switch (action.type) {
      case 'addPolyline':

        return append(
          {
            id: action.id,
            name:
              chance.letter({ casing: 'upper' }) +
              chance.integer({ min: 1, max: 9 }),
            coordinates: []
          },
          polylines
        )
      case 'addCoordinate':
        return polylines.map(r =>
          r.id === action.id
            ? { ...r, coordinates: [...r.coordinates, action.coordinate] }
            : r
        )
      case 'updateCoordinates':
        return polylines.map(r =>
          r.id === action.id ? { ...r, coordinates: action.coordinates } : r
        )
      case 'removePolyline':
        return reject(r => r.id === action.id)(polylines)
      default:
        console.error(`Unknown action type ${action.type}`)
    }
  }, [])

  
  
    useEffect(()=>{

      let requestBody={}
      requestBody.session = session;
      requestBody.org_id=org_id;
      let geoLocation=[]
      getLocationCordinate(requestBody).then(res=>{
        if(res.status==200){
              SetSpaceID(res.data.space_id)  
                let data = res.data.location
  
                    geoLocation = res.data.location
                  // }          
          SetDefaultCordinate(geoLocation)
        }
        SetLoader(false);
      })
      
    },[])

   useEffect(()=>{
    if (!space) {
      return
    }

    let data = [...defaultCordinate]
    if(data.length>0){
      for (let i = 0;i<data.length;i++){
        
        
        let arr={};
        arr.type='add'
        arr.icon={}
        arr.icon.id=data[i].unique_loc_id;
        arr.icon.position= data[i].position
        arr.icon.name = data[i].name
        arr.location_id = data[i].location_id

        dispatchIcon(arr);

        //SetMappedLocation(data[i].name)
        //SetMappedLocation((prev) =>  {...prev,  })
        SetMappedLocation(oldArray => [...oldArray, data[i].name.toLowerCase()]);

      }

    }
    
  },[defaultCordinate,spaceID,space])
 


  function handleCloseModalSucess(){
    SetModalAddSpace(false);
    SetModalSucess(false);
    SetModalDelete(false);
    SetLocationID('');
    SetLocationName('');
    SetLocationRemoveID('')
  }
  function handleCloseModalError(){
    SetModalError(false);

  }
    
  
    // memoize so Viewer render once only (wrapped in memo)
    
  
    // switch picking mode
  function addGeoMap(){
    if(spaceIDCreate==''){
      SetSpaceIDError('Required Space ID For Geo map')
    } else{
      let requestBody={}
      requestBody.session= session;
      requestBody.org_id =org_id;
      requestBody.space_id=spaceIDCreate
      CreteGeoMap(requestBody).then(res=>{

        if(res.status == 200){
          SetTitle(<h4>{res.message}</h4>);
          SetDescription('Geo Map is Setup');
          SetModalSucess(true)
          SetSpaceID(spaceIDCreate);
        }else{
          SetTitle(res.message);
          SetDescription('Geo Map Failed');
          SetModalError(true)
        }

      })
    }   
  }
function addCoordinateMap(){
  
  let cord = currentArray
  cord.icon.name = locationName;
  
  let mapLoc = mappedLocation;
  mapLoc.push(locationName);

  let requestBody ={}
  requestBody.location_id= locationID
  requestBody.org_id= org_id
  requestBody.unique_loc_id =currentArray.icon.id;
  requestBody.position = currentArray.icon.position
  requestBody.session = session

  CreteGeoMapLocation(requestBody).then(res=>{

    if(res.status==200){

      SetTitle(<h4>{res.message}</h4>);
      SetDescription("Location is Mapped");
      SetModalSucess(true)
      SetMappedLocation(mapLoc);

      SetLocationName('')
      SetLocationID('')
      SetCurrentArray([])
      cord.location_id= locationID
      dispatchIcon(cord);
      SetModalAdd(false);

    }else{
      SetTitle(<h4>{res.message}</h4>);
      SetDescription("Location is Mapped");
      SetModalError(true)
    }

  })
  




}

    useEffect(() => {
      if (!space) {
        return
      }

      if (task === 'drawPoint') {
        space.enablePickingMode({
          onPick: ({ coordinates }) => {

            dispatchPoint({
              type: 'add',
              point: {
                id: chance.guid(),
                name:
                  chance.letter({ casing: 'upper' }) +
                  chance.integer({ min: 1, max: 9 }),
                position: coordinates
              }
            })
          }
        })
      } else if (task === 'drawIcon') {

       // SetModalAdd(true);
 
        space.enablePickingMode({
          onPick: ({ coordinates }) => {
            SetModalAdd(true);
            SetCurrentArray({
              type: 'add',
              icon: {
                id: chance.guid(),
                position: coordinates,
                name:'',
              }
            })
            //dispatchIcon()
          }
        })
      }else if (task === 'drawPolyline') {
      space.enablePickingMode({
        onPick: ({ coordinates }) => {
          dispatchPolyline({
            type: 'addCoordinate',
            id: editingId,
            coordinate: coordinates
          })
        }
      })
    } else {
        space.disablePickingMode()
      }
    }, [space, editingId, task])
  
    // render elements
    useEffect(() => {
      if (!space) {
        return
      }

      space.addDataLayer({
        id: 'points',
        type: 'point',
        data: autoElevation(points),
        diameter: 0.5,
        anchor: 'bottom',
        tooltip: d => d.name,
        onDrop: ({ data, position }) =>
          dispatchPoint({
            type: 'update',
            id: data.id,
            updates: { position }
          })
      })
    }, [space, points, autoElevation])

    
    useEffect(() => {
      if (!space) {
        return
      }
      space.addDataLayer({
        id: 'icons',
        type: 'icon',
        data: map(evolve({ position: { elevation: max(0.25) } }))(
          autoElevation(icons)
        ),
        icon: {
          url: '/img/logo.png',
          width: 180,
          height: 180
        },
        width: 0.5,
        tooltip: d => d.name,
        onDrop: ({ data, position }) =>
          dispatchIcon({
            type: 'update',
            id: data.id,
            updates: { position }
          })
      })


    }, [space, icons, autoElevation])
  
       useEffect(() => {
    if (!space) {
      return
    }
    space.addDataLayer({
      id: 'polylines',
      type: 'polyline',
      data: compose(
        map(
          evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        ),
        reject(p => isEmpty(p.coordinates))
      )(polylines),
      scale: 0.2,
      tooltip: d => d.name,
      onDrop: ({ data, coordinates }) =>
        dispatchPolyline({
          type: 'updateCoordinates',
          id: data.id,
          coordinates
        })
    })
    console.log("polyline corrdinates",polylines)
  }, [space, polylines, noElevationIn2D])

     
function removeLocation(command,id,name,obj){

  let mapLoc= mappedLocation;
  let index = mapLoc.indexOf(name)

  
  let locID= defaultCordinate.find(item=>item.name==name && item.unique_loc_id == id)?defaultCordinate.find(item=>item.name==name && item.unique_loc_id == id).location_id:''
  SetLocationID(locID)  
  SetLocationName(name)
  SetLocationRemoveID(id);
  SetModalDelete(true);

  if(index > -1){
    mapLoc.splice(index,1);
    SetMappedLocation(mapLoc)
  }
  //dispatchIcon({ type: 'remove', id: id })
  
}
function removeMapHandler(){

  //dispatchIcon({ type: 'remove', id: locationRemoveID })
  let requestBody ={}
  requestBody.location_id =locationID;
  requestBody.session = session
  requestBody.org_id =org_id

  removeMapLocation(requestBody).then(res=>{
    if(res.status===200){
      SetTitle(<h4>{locationName} is remove from map</h4>)
      SetDescription(res.message);
      SetModalSucess(true);
      dispatchIcon({ type: 'remove', id: locationRemoveID })
    }else{
      SetTitle(<h4>{locationName} Fail to delete</h4>)
      SetModalError(true)
    }
  })

}


  
    return (
      
      <div>
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
      <div className='br-seperator-row'>
      <Group align='flex-start'>
        {spaceID? 
        <div style={{ width: isMobile ? '100%' : '100%' }}>
        
          
          <Viewer
            mode={INITIAL_MODE}
            onReady={onReady}
            onModeChange={onModeChange}
            spaceID={spaceID}
          />
          
        </div>
        :"" }
        
         

            
      </Group>
      {spaceID==''  ? (

<div className="page-header">
<Row>
  <Col lg={6}>
  
  </Col>
  <Col lg={2}></Col>
  <Col lg={4} style={{textAlign:"right"}}>
       <button onClick={()=>{SetModalAddSpace(true)}} className="btn btn-transparent" style={{marginRight:"10px"}}>Add Geo Map</button>
  </Col>
  </Row>
</div>


) : (
  space?
<Group
      direction='column'
      style={{ width: isMobile ? '100%' : '100%' }}
      >
      <p>👉 Click "Add" and draw elements on the floor plan</p>


      <h4>Add Location</h4>
      <span style={{ marginTop: 0 }}>
      {compose(
      intersperse(''),
      map(p => (
      <Fragment key={p.id}>
      
      <li className="inlineStyleListLocation"
      
    
      ><span>{p.name}{' '}</span>
      {permission && permission.remove=='True'?<button className='btn-transparent btn-delete'><span className="removebdr"  onClick={() =>removeLocation('remove',p.id,p.name,p)}><DeleteIcon/></span></button>:
      // <button className='btn-transparent btn-delete disabled' ><span class="removebdr"><DeleteIcon/></span></button>
      <button
      className={`btn-transparent btn-delete ${showTooltip ? 'tooltip-active' : ''}`}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <span className="removebdr" >
        <DeleteIcon />
      </span>
      {showTooltip && (
        <div className="tooltip">
          Access Restricted
        </div>
      )}
    </button>
      }
       {/* <button className='btn-transparent btn-delete'><span class="removebdr"  onClick={() =>removeLocation('remove',p.id,p.name,p)}><DeleteIcon/></span></button> */}
{/* {permission && permission.remove =="True" ?(<button className='btn-transparent btn-delete'><span class="removebdr"  onClick={() =>removeLocation('remove',p.id,p.name,p)}><DeleteIcon/></span></button>):("")} */}
      </li>
      </Fragment>
      ))
      )(icons)}
      
      {task !== 'drawIcon' ? (
      <button
      className="btn btn-transparent"
      onClick={() => {
      setTask('drawIcon')
      }}
      >
      Add
      </button>
      ) : (
      <button
      className="btn btn-transparent"
      onClick={() => {
      setTask('idle')
      }}
      >
      Done
      </button>
      )}
      </span>
</Group>:""
)}

         <h4>Polylines</h4>
          <span style={{ marginTop: 0 }}>
            {compose(
              intersperse(' - '),
              map(p => (
                <Fragment key={p.id}>
                  {p.name}{' '}
                  <button
                    onClick={() =>
                      dispatchPolyline({ type: 'removePolyline', id: p.id })
                    }
                  >
                    x
                  </button>
                </Fragment>
              ))
            )(polylines)}
            {' - '}
            <button className='btn btn-primary'
              onClick={() => {
                const id = chance.guid()
                dispatchPolyline({ type: 'addPolyline', id })
                setEditingId(id)
                setTask('drawPolyline')
              }}
            >
              Add
            </button>
            {task === 'drawPolyline' && (
              <Fragment>
                {' '}
                <button className='btn btn-primary'
                  onClick={() => {
                    setEditingId()
                    setTask('idle')
                  }}
                >
                  Done
                </button>
              </Fragment>
            )}
          </span>

</div>}

<ReactModal
                isOpen={modalAddSpace}
                style={customStyles}
                key={'CreateSpace1'}
              //  onRequestClose={handleCloseModal}
            >
                    <div>
            <div className='modalHeader'>
                    <h4>Add Geo Map</h4>
                    <div className='closeMark' onClick={()=>SetModalAddSpace(false)}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Space ID* <div className='posRel'><HelpIcon /><span className='helpSmall'>Please select Location Name to map</span></div></label>
                        <input type="text" className={spaceIDError!==''?'form-control has-error':'form-control'} value={spaceIDCreate} onChange={(e)=>SetSpaceIDCreate(e.target.value)} />
						{spaceIDError!=''?<div className='errorMessage'>{spaceIDError}</div>:""}
                    </div>
                    <button className='btn btn-primary btn-100' onClick={addGeoMap} >Add Geo Map</button>    
                </div>
        </div>
            </ReactModal>
            

            <ReactModal
                isOpen={modalDelete}
                style={customStyles}
                key={'CreateSpace2'}
              //  onRequestClose={handleCloseModal}
            >
                    <div>
           
                <div className='modalBody modalBodyDelete'>
                   <p>Are You sure wan't to delete <br/><strong>{locationName}</strong></p>
                   <div className='dleeteBtn'>
                      <button className='btn btn-primary btn-100' onClick={removeMapHandler} >Yes Delete</button>    
                      <button className='btn btn-primary btn-100 btn-deletee' onClick={()=>SetModalDelete(false)} >No</button>    
                    </div>
                </div>
        </div>
            </ReactModal>
     

      {permission&& permission.Create == "True" ? (<ReactModal
                isOpen={modalAdd}
                style={customStyles}
                key={'mapLocation'}
              //  onRequestClose={handleCloseModal}
            >
                    <div>
            <div className='modalHeader'>
                    <h4>Map Lcoation</h4>
                    <div className='closeMark' onClick={()=>SetModalAdd(false)}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Location Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Please select Location Name to map</span></div></label>
                     {spaceID && modalAdd && space?<Select
                            placeholder="Select Location"
                            optionFilterProp="children"
                            onChange={(val,label)=>{SetLocationName(label.children);SetLocationID(label.value);   }}
                            className={locationNameError==''?'form-control':'form-control has-error'}
                            filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            defaultValue={locationName}
                            key={'lcoationNameMapping'}
                        >
                            {locationList.map((item)=>{
                              
                              if(mappedLocation.indexOf(item.location_name.toLowerCase()) > -1){
                                return <Option value={item.id} key={"Department"+item.location_name} disabled >{item.location_name.toLowerCase()}</Option>
                              }else{
                                return <Option value={item.id} key={"Department"+item.location_name}>{item.location_name.toLowerCase()}</Option>
                              }
                                
                            })
                            }
                        </Select>:""}                        

						{locationNameError!=''?<div className='errorMessage'>{locationNameError}</div>:""}
                    </div>
                    <button className='btn btn-primary btn-100' onClick={addCoordinateMap} >Map Location</button>    
                </div>
        </div>
            </ReactModal>):("")}

            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />

      </div>
                          
    )
  }
  
  export default AddDataElements