/* global smplr */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

import useSmplrJs from  '../hooks/useSmplrJs';

const Viewer = memo(({ mode, onReady, onModeChange,spaceID }) => {
  useSmplrJs({ onLoad })

  function onLoad () {
    if(spaceID){   
      
      
      const space = new smplr.Space({
        // spaceId: 'edb2ebaa-47ea-4e54-af0d-cf543328bdb0',
        spaceId: spaceID,
        spaceToken: 'X',
        containerId: 'smplr-container',
        clientToken: 'pub_4561dd6966994e328d56234e3999422e',
      })
      space.startViewer({
        preview: true,
        mode,
        allowModeChange: true,
        onModeChange,
        onReady: () => onReady(space),
        onError: error => console.error('Could not start viewer', error)
      })
  }
  
  }

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '60%'
      }}
    >
      <div
        id='smplr-container'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'whitesmoke'
        }}
      />
    </div>
  )
})

Viewer.propTypes = {
  mode: PropTypes.string.isRequired,
  onReady: PropTypes.func.isRequired,
  onModeChange: PropTypes.func
}

export default Viewer