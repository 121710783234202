import React, { Component } from 'react';
import {ReactComponent as Clock} from '../assets/images/clock.svg'

class LiveTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: new Date()
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.updateTime(),
      1000 // Update the time every second
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  updateTime() {
    this.setState({
      currentTime: new Date()
    });
  }

  render() {
    const { currentTime } = this.state;
    const timeString = currentTime.toLocaleTimeString();

    return (
      <div>
        <div className='clockWIthTIme'><Clock/> {timeString}</div>
      </div>
    );
  }
}

export default LiveTime;
