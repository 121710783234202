import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreteRole(requestBody) {
    
    return axios.post(prefixURL + `/admin/create_role` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeRole(requestBody) {
    return axios.post(prefixURL + `/admin/remove_role` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getRoleList(requestBody) {
  
    return axios.get(prefixURL + `/admin/get_role_list?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
export function getRoleDetail(requestBody) {
  
    return axios.get(prefixURL + `/admin/view_role_info?session=${requestBody.session}&org_id=${requestBody.org_id}&role_id=${requestBody.role_id}`).then(res=>res.data).catch(err=>err)
    //return axios(config).then(res=>res.data).catch(err=>err);
}
export function editRole(requestBody) {
    
    return axios.post(prefixURL + `/admin/edit_role` , requestBody).then(res=>res.data).catch(err=>err)
}
