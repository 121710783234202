import React,{useState,useEffect} from "react";
import ReactModal from "react-modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function CycleWarningModal(props){
    const [modalWarning,SetModalWarning] =useState(false);
    useEffect(()=>{
        SetModalWarning(props.modalWarning)
    },[props])
return (


           <ReactModal
                isOpen={modalWarning}
                style={customStyles}
             >
                <div className='dangerDeleteZone'>
                   <div className='deleteZoneHeader'>
                        
                         <h2>Warning</h2>
                </div>
                <div className='deleteZoneContent'>
                    <span className="deleteHighlightName">{props.title}</span>
                </div>
                <div className='deleteZoneAction'>
                   
                    <button className='btn btn-primary' onClick={()=>props.handleDeleteConfirm()}>Okay</button>
                </div>
                </div>
            </ReactModal>



)
}

export default CycleWarningModal;