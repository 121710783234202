import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { CreateDepartment } from '../../actionMethods/actionMethods';
import LoaderSpinner from '../../../assets/images/Spinner Loader.gif'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};


function CreateGroup(props) {
    const [value, setValue] = useState('');
	const [group_name,SetGroupName]= useState('');
	const [group_nameError,SetGroupNameError]= useState('');
    
	const [loader,SetLoader]=useState(false)

	const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');
	const reg = /^\S.*$/;
	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
  
	function handleCloseModalSucess(){
		props.Reload();
		props.handleCloseModal()		
		SetModalSucess(false)
	  }
	  function handleCloseModalError(){
		SetModalError(false)
	  }
  
  
      function SubmitHandlerGroup(){
		if(group_name==''){	
			SetGroupNameError('Department Name is required')
		}else if(group_name.length > 255){
			SetGroupNameError('Department Name length should be less than 255 character');
		}
		else{	
			let requestBody={}
            requestBody.session=session
            requestBody.org_id=org_id
            requestBody.department_name=group_name
            SetLoader(true);
				CreateDepartment(requestBody).then(res=>{
					SetLoader(false)
					if(res.status==200){
						SetTitle(<h4>Department is created</h4>)						
						SetDescription('')
						SetModalSucess(true)                    
                	}else{
						SetTitle(<h4>{res.message}</h4>)						
						SetDescription('Department is not created')
						
						SetModalError(true)                    
					}
				if(res.status==300){
					localStorage.removeItem('isLoggedInGatewayTraceAdmin')
					localStorage.removeItem('userLoginDetailsTraceAdmin')
					props.history.push(`/login`)
				}
				
				})

		  }
	  }

    return(

        <div>
			<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
            
			{loader?<div className='loaderContainer loaderContainerSmall'><img src={LoaderSpinner}/></div>:<>
            <div className='modalHeader'>
                    <h4>Add Department</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                        <label>Department Name<div className='posRel'><HelpIcon /><span className='helpSmall'>Max length 255  character</span></div></label>
                        <input type="text" className={group_nameError!=''?'form-control error':'form-control'} value={group_name} onChange={(e)=>{if(reg.test(e.target.value)){SetGroupName(e.target.value);}else if(e.target.value==''){SetGroupName(e.target.value)} }  } / >
                        {group_nameError!=''?<div className='errorMessage'>{group_nameError}</div>:""}
                    </div>
                    <button className='btn btn-primary btn-100' onClick={SubmitHandlerGroup}>Add Department</button>    
                </div>
				</> }
        </div>
    )
}
export default CreateGroup;