import React, { useState, useEffect } from 'react'
import { Container, Row, Col,Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../style/styles.scss';
import moment from 'moment'
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'
import '../../assetmanagement/styles/addEmployee.scss';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CreateShift } from '../../usermanagement/actionMethods/actionMethods';
import {UpdateShift} from '../actionMethods/actionMethods'




function EditShift(props){
    const [loader,SetLoader] =useState(false);
    const [name,SetName]=useState('');
    const [nameError,SetNameError]=useState('')
    const reg = /^\S.*$/;

	const [shiftName,SetShiftName]=useState('')
	const [shiftNameError,SetShiftNameError]=useState('')
	const [shiftStartTime,SetStartTime]=useState('')
	const [shiftStartTimeError,SetStartTimeError]=useState('')
	const [shiftEndtTime,SetEndTime]=useState('')
	const [shiftEndtTimeError,SetEndTimeError]=useState('')
	const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [descriptionMessage,SetDescriptionMessage] =useState('');
    const [descriptionError,SetDescriptionError] =useState('');


	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""

	let date =new Date();
	 
	useEffect(() => {
		console.log(props.shiftIDData);

		if(props.shiftIDData && props.shiftIDData.shift_name && props.shiftIDData.start_time && props.shiftIDData.end_time){
			console.log(props.shiftIDData,props.shiftIDData.start_time.substr(0, 5),props.shiftIDData.end_time.substr(0, 5));
			SetShiftName( props.shiftIDData.shift_name)
			//SetStartTime(props.shiftIDData.start_time.substr(0, 5))
		     let timeStartSplit =props.shiftIDData.start_time.split(':') 		
		     let timeEndSplit =props.shiftIDData.end_time.split(':') 		
			// console.log(timeStartSplit);
			
			SetStartTime(new Date().setHours(timeStartSplit[0] , timeStartSplit[1],timeStartSplit[2]) )
			SetEndTime(new Date().setHours(timeEndSplit[0] , timeEndSplit[1],timeEndSplit[2]))
			
		}

	},[props.shiftIDData])

	function CreateShiftHandler(){

		let requestBody2= {};
		requestBody2.session=session
		requestBody2.org_id=org_id;
		requestBody2.shift_name= shiftName
		requestBody2.start_time =moment(shiftStartTime).format('HH:mm:ss') 
		requestBody2.end_time = moment(shiftEndtTime).format('HH:mm:ss')
		requestBody2.shift_id= props.shiftIDData.id;
		if(shiftStartTime=='' || shiftEndtTime==''|| shiftName=='' ){
			if(shiftName==''){SetShiftNameError('Shift Name is required')}else{SetShiftNameError('')}
			if(shiftStartTime==''){SetStartTimeError('Shift Start Time is required')}else{SetStartTimeError('')}
			if(shiftEndtTime==''){SetEndTimeError('Shift End Time is required')}else{SetEndTimeError('')}
	
	
		}else{
		UpdateShift(requestBody2).then(res=>{
			if(res.status==200){
				props.refreshShift();

				SetShiftName('');

				SetStartTime('');
				SetEndTime('')
				SetTitle(<h4>Shift is updated</h4>)
				SetDescriptionMessage(res.message)
				SetModalSucess(true)
			}else{
				SetTitle(<h4>{res.message}</h4>)
				
				SetDescriptionMessage('Shift is not updated')
				SetModalError(true)
			}
		})
	}
	  }

	function handleCloseModalSucess(){
		SetModalSucess(false);
		props.handleCloseModal();
	}  

	function handleCloseModalError(){
		SetModalError(false);
	}





	return(<>
		{loader?<div className='loaderContainer loaderContainerSmall'><img src={LoaderSpinner}/></div>:<>
		<div className='modalHeader'>
				<h4>Update Shift</h4>
				<div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
			</div>
			<div className='modalBody'>
				<div className='field-row'>
				<label>Shift Name</label>
					<input type="text" value={shiftName} onChange={(e)=>{if(reg.test(e.target.value)){SetShiftName(e.target.value);}else if(e.target.value==''){SetShiftName(e.target.value)} }  } className='form-control'  />
					{shiftNameError!=''?<div className='errorMessage'style={{lineHeight:1}}>{shiftNameError}</div>:""}
				</div>
				<div className='field-row'>
				<label>Start Time</label>
						<LocalizationProvider dateAdapter={AdapterMoment}>
						<TimePicker

							value={shiftStartTime}
							onChange={(newValue) => {
								SetStartTime(newValue);
							}}
							ampm={false}
							renderInput={(params) => <TextField {...params} />}
						/>
						</LocalizationProvider>
						{shiftStartTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftStartTimeError}</div>:""}
				</div>
				<div className='field-row'>
				<label>End Time</label>
				<LocalizationProvider dateAdapter={AdapterMoment}>
						<TimePicker
							value={shiftEndtTime}
							onChange={(newValue) => {
								SetEndTime(newValue);
							}}
							ampm={false}
							renderInput={(params) => <TextField {...params} />}
						/>
						</LocalizationProvider>
						{shiftEndtTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftEndtTimeError}</div>:""}
				</div>
				<button className='btn btn-primary btn-100' onClick={CreateShiftHandler}>Update Shift</button>  
			</div>
			</>}
			<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage} key={'CreateShift'} />
			<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}  key={'CreateShiftError'} />

			</> 
    )

}


const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(EditShift))