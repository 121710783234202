import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreateProcess(requestBody) {
    return axios.post(prefixURL + `/process/create_process` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeProcess(requestBody) {
    return axios.post(prefixURL + `/process/remove_process` , requestBody).then(res=>res.data).catch(err=>err)
}
export function UpdateProcess(requestBody) {    
    return axios.post(prefixURL + `/process/edit_process` , requestBody).then(res=>res.data).catch(err=>err)
}
export function getSubDepartmentListByProductivity(session,org_id,sub_org_id) {

    return axios.get(prefixURL + `/department/get_sub_department_productivity_mapping_list?session=${session}&org_id=${org_id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
}
export function getProcessList(session,org_id,sub_org_id) {

    return axios.get(prefixURL + `/process/get_process_list?session=${session}&org_id=${org_id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
}
