import React, { useState, useEffect } from 'react'
import { Container, Row, Col,Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/settingstyle.scss'
import '../styles/styles.scss'
import {ReactComponent as Back} from '../../assets/images/angle-left.svg'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import AddSetting from './addsettings';
import { useHistory } from 'react-router-dom'
// const customStyle = {
//   overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(255, 255, 255, 0.75)'
//   },
//   content: {
//       position: 'absolute',
//       width: '25rem',
//       height: '13rem',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       border: '1px solid #ccc',
//       background: '#fff',
//       overflow: 'auto',
//       WebkitOverflowScrolling: 'touch',
//       borderRadius: '4px',
//       outline: 'none',
//       padding: '0'
//   }
// }
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function UserSetting(props){

    const [modalCreate,SetModalCreate]=useState(false);

    let history= useHistory();

    function handleCloseModalCreate(){
        SetModalCreate(false);
    }
    function tabshift(){
        history.push('/organization-management')  
        }

    return(
      
      <div className="main-content side-content pt-0">

      <div className="container-fluid">
          <div className="inner-body">
      
          <div className="page-header">
                  <Row>
                      <Col lg={6}>
                      {/* <Button className='btn btn-large btn-header btn-transparent' style={{marginLeft:0}} onClick={()=>tabshift()}>Go Back</Button> */}
                      </Col>
                      <Col lg={2}></Col>
                      <Col lg={4} style={{textAlign:"right"}}>
                      <button onClick={(e)=>(SetModalCreate(true))} className="btn btn-large btn-header btn-transparent btn-icon" ><PlusIcon/>Create User Settings</button>
                      </Col>
                      </Row>
                      
          </div>
      
        <ReactModal
                        isOpen={modalCreate}
                        style={customStyles}
                        
                       
                    >
                           
                      <AddSetting
                            handleCloseModal={handleCloseModalCreate}
                           orgid={props.orgid}
                        />
                    </ReactModal>
          

            </div>
</div>
</div>    
         
    )
    

}
export default UserSetting;