import axios from "axios";

let prefixURL = process.env.REACT_APP_URL_LOGIN

let token = localStorage.getItem('tokenAuthAdmin')?localStorage.getItem('tokenAuthAdmin'):'tituw3958589'

export function userLogin(requestBody) {
   
   
    requestBody.type='admin'
   
    return axios.post(prefixURL+`/user/login` , requestBody).then(res=>res.data).catch(err=>err)
}



export function forgotPassword(requestBody) {
    requestBody.token = token
    return axios.post(prefixURL + `/user/forgot_password` , requestBody).then(res=>res.data).catch(err=>err)
}

export function logoutUser(requestBody) {
   
    return axios.post(prefixURL+`/user/logout` , requestBody).then(res=>res.data).catch(err=>err)
}

