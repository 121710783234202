import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import  './detail.scss';
import {ReactComponent as AddIcon} from '../../../assets/images/square-plus.svg'
import {ReactComponent as MinusIcon} from '../../../assets/images/square-minus.svg'
import {ReactComponent as Check} from '../../../assets/images/check.svg'
import {ReactComponent as UnCheck} from '../../../assets/images/xmark.svg'
import {ReactComponent as EditIcon} from '../../../assets/images/pen-to-square.svg'
import {ReactComponent as Back} from '../../../assets/images/angle-left.svg'
 import  '../../styles/style.scss';
import  './detail.scss';
import moment from 'moment';
import {Checkbox, Divider,AutoComplete,Select  } from 'antd';
import {getRoleDetail} from '../../actionMethods/actionMethods'
import LoaderSpinner from '../../../assets/images/Spinner Loader.gif'

function RoleDetail(props){
    const { Option } = Select;
    let count100 = [];
    for(let i= 1;i<101;i++){
      count100.push(i);
    }
    const [activeAccord,SetActiveAccord]=useState(count100)
    const [role_name,Setrole_name]=useState('');
    const [roleDetail,SetRoleDetail]=useState([]);
    const [user_management,Setuser_management]=useState("True");
    const [site_management,Setsite_management] =useState("True")
    const [device_management,Setdevice_management] =useState("True")
    const [group_management,Setgroup_management] =useState("True")
    const [role_management,Setrole_management] =useState("True")
    const [visitor,Setvisitor] =useState({})
    const [employee,Setemployee] =useState({})
    const [device,Setdevice] =useState({})
    const [group,Setgroup] =useState({})
    const [role,Setrole] =useState({})
    const [category,Setcategory] =useState({})
    const [location,Setlocation] =useState({})
    const [dashboard,Setdashboard] =useState({})
    const [asset,Setasset] =useState({})
    const [mobile_app,Setmobile_app] =useState({})
    const [webapp_dashboard,Setwebapp_dashboard] =useState({})
    const [webapp_sitemanagement,Setwebapp_sitemanagement] =useState({})
    const [webapp_manpower_management,Setwebapp_manpower_management] =useState({})
    const [webapp_visitor_management,Setwebapp_visitor_management] =useState({})
    const [webapp_policy_management,Setwebapp_policy_management] =useState({})

    const [productivity,Setproductivity] =useState({})
    const [productive_management,Setproductive_management] = useState("True");

    const [webapp_productivity_management,Setwebapp_productivity_management] =useState({})

    const [UserAll,SetUserALL] =useState({})
    const [SiteAll,SetSiteAll] =useState({})
    const [DeviceAll,SetDeviceAll] =useState({})
    const [GroupAll,SetGroupAll] =useState({})
    const [RoleAll,SetRoleAll] =useState({})
    const [WebAll,SetWebAll] =useState({})
    const [geo_map_web_all,Setgeo_map_web_all]=useState("True");
    const [geo_map_admin_all,Setgeo_map_admin_all]=useState("True");
    const [geo_map_web,Setgeo_map_web]=useState();
    const [geo_map_admin,Setgeo_map_admin]=useState({});


    const [reportAll,SetReportAll] =useState("True");
    const [report,Setreport]=useState({});
    const [reportLocation,SetReportLocation]= useState({})
    const [reportWorkingHour,SetReportWorkingHour]= useState({})

    const [adminapp_shift_condition,Setadminapp_shift_condition] =useState("True");
    const [adminapp_shift_permissions,Setadminapp_shift_permissions]= useState({"View": "False", "edit": "False", "Create":"False", "remove":"False"});
    const [team_management,SetTeamManagement]= useState({"View": "False", "edit": "False", "Create":"False", "remove":"False"});
    
    const [adminapp_mass_condition,Setadminapp_mass_condition] =useState("False");
    // const [adminapp_mass_permissions,Setadminapp_mass_permissions]= useState({"bulk_shift": "False", "daily_employee_bulk": "False", "employee_tag_changing_bulk": "False"});
    const [adminapp_mass_permissions,Setadminapp_mass_permissions]= useState({"bulk_shift": "False", "daily_employee_bulk": "False", "employee_tag_changing_bulk": "False","cycles_count_bulk": "False","cycles_count_bulk": "False"});
    const [cycle_count_permissions,Setcycle_count_permissions]=useState({"View":"True","export":"True"});
    const [web_app_cycle_count_condition,Setweb_app_cycle_count_condition] =useState("True");
    const [reportCycleCount,SetReportCycleCount]= useState({"View":"True","export":"True"})
    const [web_app_cycles,Setweb_app_cycles] =useState({"View": "True", "edit": "True", "Create":"True", "remove":"True"})
    const [loader,SetLoader]=useState(true);
    
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    let idVal = props.match.params.id.replace(":", "");
    let org_id = userDetails.org_id?userDetails.org_id:""


    
    useEffect(()=>{

      props.ToggleProfile();
      let requestBody={}
      requestBody.session=session
      requestBody.role_id=idVal
      requestBody.org_id= org_id

      getRoleDetail(requestBody).then(res=>{
        if(res.status==200 || res.code==200){
          let data= res.data[0];
          SetRoleDetail(res.data);

          let categoryJson = JSON.parse(res.data[0].category);
          let groupJson = JSON.parse(res.data[0].group);
          let visitorJson = JSON.parse(res.data[0].visitor);
          let employeeJson = JSON.parse(res.data[0].employee);
          let locationJson = JSON.parse(res.data[0].location);
          let deviceJson = JSON.parse(res.data[0].device);
          let roleJson = JSON.parse(res.data[0].role);
          let assetJson = JSON.parse(res.data[0].asset);
          let adminJson = JSON.parse(res.data[0].dashboard);
          let dashboardJson = JSON.parse(res.data[0].health_management);
          let mobileJson = JSON.parse(res.data[0].mobile_app);
          let site_managementJson = JSON.parse(res.data[0].web_app_sitemanagement);
          let manpower_managementJson = JSON.parse(res.data[0].manpower_management);
          let visitor_managementJson = JSON.parse(res.data[0].visitor_management);
          let policy_managementJson = JSON.parse(res.data[0].policy_management);
          let ProductiveJson = res.data[0].admin_productive_permissions?JSON.parse(res.data[0].admin_productive_permissions):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}
          let webappProdJson = res.data[0].productivity_management?JSON.parse(res.data[0].productivity_management):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}
          let GeoMapAdminJson = res.data[0].geo_map_admin_permissions?JSON.parse(res.data[0].geo_map_admin_permissions):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}
          let teamPerm = res.data[0].teams?JSON.parse(res.data[0].teams):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}
          let GeoMapWeb = res.data[0].geo_map_permissions?JSON.parse(res.data[0].geo_map_permissions):{"facility_tracker": "False", "employee_tracker": "False"}
          let locationPermission = res.data[0].location_permissions?JSON.parse(res.data[0].location_permissions):{"View": "False", "export": "False"} 
          let workingPermission = res.data[0].working_hour_permissions?JSON.parse(res.data[0].working_hour_permissions):{"View": "False", "export": "False"} 

          let AdminShift = res.data[0].shift_permissions?JSON.parse(res.data[0].shift_permissions):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}
          let AdminMassUpload = res.data[0].bulk_permissions?JSON.parse(res.data[0].bulk_permissions):{"bulk_shift": "False", "daily_employee_bulk": "False", "employee_tag_changing_bulk":"False","cycles_count_bulk": "False"}
          let ReportcyclePerm = res.data[0].cycle_count_permissions?JSON.parse(res.data[0].cycle_count_permissions):{"View": "False", "export": "False"}
          let WebbcyclePerm = res.data[0].web_app_cycles?JSON.parse(res.data[0].web_app_cycles):{"View": "False", "edit": "False", "Create":"False", "remove":"False"}


          SetReportCycleCount(ReportcyclePerm);
          Setweb_app_cycles(WebbcyclePerm);

          Setadminapp_mass_condition(res.data[0].bulk_management_page)
          Setadminapp_shift_condition(res.data[0].shift_management_page)

          Setadminapp_mass_permissions(AdminMassUpload);
          Setadminapp_shift_permissions(AdminShift);
          SetTeamManagement(teamPerm)

          Setrole_name(data.name);
          Setgroup(groupJson)
          Setcategory(categoryJson)
          Setvisitor(visitorJson)
          Setemployee(employeeJson)
          Setlocation(locationJson)
          Setdevice(deviceJson)
          Setrole(roleJson)
          Setasset(assetJson)
          Setmobile_app(mobileJson)
          Setwebapp_dashboard(dashboardJson)
          Setdashboard(adminJson)
          Setwebapp_sitemanagement(site_managementJson)
          Setwebapp_manpower_management(manpower_managementJson)
          Setwebapp_visitor_management(visitor_managementJson)
          Setwebapp_policy_management(policy_managementJson)
          Setwebapp_productivity_management(webappProdJson)
          Setproductivity(ProductiveJson)
          Setgeo_map_admin(GeoMapAdminJson);
          Setgeo_map_web(GeoMapWeb);
          SetReportWorkingHour(workingPermission);
          SetReportLocation(locationPermission);
          setTimeout(function(){

            SetLoader(false)
          },500)
        }
        if(res.status==300){
          localStorage.removeItem('isLoggedInGatewayTraceAdmin')
          localStorage.removeItem('userLoginDetailsTraceAdmin')
          props.history.push(`/login`)
      }
      }).catch()


    },[])

    function AccordionHandler(val){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
        }   
        else {  
            ActivList.push(val);
        }
        SetActiveAccord(ActivList);
    }
    return(
      
<div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">
    <div className='br-seperator-row'>                
      
    <Row>
          <Col lg={6}>
          
          <label>Role Name</label>
          <h4 style={{marginBottom:"0px"}}>{role_name}</h4>
          

          </Col>
          <Col lg={6} style={{textAlign:"right"}}>
              <button onClick={()=>props.history.push(`/role-management`)} className="btn btn-transparent btn-icon " style={{marginRight:"10px"}}><Back className='svgSmall'/> Go Back</button>
              <button onClick={()=>props.history.push(`/edit-role/${idVal}`)} className="btn btn-transparent btn-icon"><EditIcon/> Edit Role</button>
          </Col>

        </Row>
</div>

{loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:<>



            <div className='br-seperator-row'>                

          <div className='field-row'>
              <h4>Trace Admin System</h4>
          </div>



          <div className={activeAccord.indexOf(1)!==-1?'bg-background-accord active':'bg-background-accord'}>
              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>User Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(1)} >
                 { activeAccord.indexOf(1)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
<div className={activeAccord.indexOf(1)!==-1?'accord-role active':'accord-role'} >
              
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Department</Col>
              <Col lg={2} className='label-check'><span className={group.View=='True'?"checked":"unchecked"}>{group.View=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={group.Create=='True'?"checked":"unchecked"}>{group.Create=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={group.edit=='True'?"checked":"unchecked"}>{group.edit=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={group.remove=='True'?"checked":"unchecked"}>{group.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'> Visitor </Col>

              <Col lg={2} className='label-check'><span className={visitor.View=='True'?"checked":"unchecked"}>{visitor.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={visitor.Create=='True'?"checked":"unchecked"}>{visitor.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={visitor.edit=='True'?"checked":"unchecked"}>{visitor.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={visitor.remove=='True'?"checked":"unchecked"}>{visitor.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Employee</Col>
              <Col lg={2} className='label-check'><span className={employee.View=='True'?"checked":"unchecked"}>{employee.View=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={employee.Create=='True'?"checked":"unchecked"}>{employee.Create=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={employee.edit=='True'?"checked":"unchecked"}>{employee.edit=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={employee.remove=='True'?"checked":"unchecked"}>{employee.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 

           </div>  

           <div  className={activeAccord.indexOf(2)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Site Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(2)}>
                 { activeAccord.indexOf(2)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
<div className={activeAccord.indexOf(2)!==-1?'accord-role active':'accord-role'} >
              
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Category</Col>
              <Col lg={2} className='label-check'><span className={category.View=='True'?"checked":"unchecked"}>{category.View=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={category.Create=='True'?"checked":"unchecked"}>{category.Create=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={category.edit=='True'?"checked":"unchecked"}>{category.edit=='True'?<Check />:<UnCheck />}</span></Col>
              <Col lg={2} className='label-check'><span className={category.remove=='True'?"checked":"unchecked"}>{category.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'> Location </Col>
              <Col lg={2} className='label-check'><span className={location.View=='True'?"checked":"unchecked"}>{location.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={location.Create=='True'?"checked":"unchecked"}>{location.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={location.edit=='True'?"checked":"unchecked"}>{location.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={location.remove=='True'?"checked":"unchecked"}>{location.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div> 

           <div  className={activeAccord.indexOf(3)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Device Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row> 
                  <span className='icon-right' onClick={()=>AccordionHandler(3)}>
                 { activeAccord.indexOf(3)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
<div className={activeAccord.indexOf(3)!==-1?'accord-role active':'accord-role'} >
              
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Device</Col>
              <Col lg={2} className='label-check'><span className={device.View=='True'?"checked":"unchecked"}>{device.View=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={device.Create=='True'?"checked":"unchecked"}>{device.Create=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={device.edit=='True'?"checked":"unchecked"}>{device.edit=='True'?<Check />:<UnCheck />}</span></Col>
    <Col lg={2} className='label-check'><span className={device.remove=='True'?"checked":"unchecked"}>{device.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div> 

           
        
           {/* <div  className={activeAccord.indexOf(9)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Asset Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>AccordionHandler(9)}>
                 { activeAccord.indexOf(9)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>

          </div> */}

{/* 
<div className={activeAccord.indexOf(9)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Asset</Col>
              <Col lg={2} className='label-check'><span className={asset.View=='True'?"checked":"unchecked"}>{asset.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={asset.Create=='True'?"checked":"unchecked"}>{asset.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={asset.edit=='True'?"checked":"unchecked"}>{asset.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={asset.remove=='True'?"checked":"unchecked"}>{asset.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div>  */}



           <div  className={activeAccord.indexOf(5)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Role Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>AccordionHandler(5)}>
                 { activeAccord.indexOf(5)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>



          <div className={activeAccord.indexOf(5)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Role </Col>
              <Col lg={2} className='label-check'><span className={role.View=='True'?"checked":"unchecked"}>{role.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={role.Create=='True'?"checked":"unchecked"}>{role.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={productivity.edit=='True'?"checked":"unchecked"}>{role.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={role.remove=='True'?"checked":"unchecked"}>{role.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div> 

           <div  className={activeAccord.indexOf(11)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Productivity Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>{AccordionHandler(11); }}>
                 { activeAccord.indexOf(11)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>

<div className={activeAccord.indexOf(11)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Productivity</Col>
              <Col lg={2} className='label-check'><span className={productivity.View=='True'?"checked":"unchecked"}>{productivity.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={productivity.Create=='True'?"checked":"unchecked"}>{productivity.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={productivity.edit=='True'?"checked":"unchecked"}>{productivity.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={productivity.remove=='True'?"checked":"unchecked"}>{productivity.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div>


           <div  className={activeAccord.indexOf(21)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Shift Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>{AccordionHandler(21); }}>
                 { activeAccord.indexOf(21)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>

<div className={activeAccord.indexOf(21)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Shift</Col>
              <Col lg={2} className='label-check'><span className={adminapp_shift_permissions.View=='True'?"checked":"unchecked"}>{adminapp_shift_permissions.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={adminapp_shift_permissions.Create=='True'?"checked":"unchecked"}>{adminapp_shift_permissions.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={adminapp_shift_permissions.edit=='True'?"checked":"unchecked"}>{adminapp_shift_permissions.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={adminapp_shift_permissions.remove=='True'?"checked":"unchecked"}>{adminapp_shift_permissions.remove=='True'?<Check />:<UnCheck />}</span></Col>
            </Row> 
           </div>


           <div  className={activeAccord.indexOf(7)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Geo Map Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>{AccordionHandler(7); }}>
                 { activeAccord.indexOf(7)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>

<div className={activeAccord.indexOf(7)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Geo Map</Col>
              <Col lg={2} className='label-check'><span className={geo_map_admin.View=='True'?"checked":"unchecked"}>{geo_map_admin.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={geo_map_admin.Create=='True'?"checked":"unchecked"}>{geo_map_admin.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={geo_map_admin.edit=='True'?"checked":"unchecked"}>{geo_map_admin.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={geo_map_admin.remove=='True'?"checked":"unchecked"}>{geo_map_admin.remove=='True'?<Check />:<UnCheck />}</span></Col>

            </Row> 
           </div>



           <div  className={activeAccord.indexOf(20)!==-1?'bg-background-accord active':'bg-background-accord'}>
                    
           <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Team Management</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>  

                  <span className='icon-right' onClick={()=>{AccordionHandler(7); }}>
                 { activeAccord.indexOf(7)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>

<div className={activeAccord.indexOf(20)!==-1?'accord-role active':'accord-role'} >
            <Row className='cotnent-table'>
              <Col lg={4} className='label-disp'>Team Management</Col>
              <Col lg={2} className='label-check'><span className={team_management.View=='True'?"checked":"unchecked"}>{team_management.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={team_management.Create=='True'?"checked":"unchecked"}>{team_management.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={team_management.edit=='True'?"checked":"unchecked"}>{team_management.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={team_management.remove=='True'?"checked":"unchecked"}>{team_management.remove=='True'?<Check />:<UnCheck />}</span></Col>

            </Row> 
           </div>







           

          <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
           <div className='field-row'>
              <h4>Admin Dashboard</h4>
            </div>  
              <div  className={activeAccord.indexOf(12)!==-1?'bg-background-accord active':'bg-background-accord'}>              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Dashboard</Col>
              <Col lg={3} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Import</Col>
              <Col lg={3} className='label-check'>Export</Col>
              
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(12)} >
                 { activeAccord.indexOf(12)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
          <div className={activeAccord.indexOf(12)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Dashboard</Col>
                <Col lg={3} className='label-check'><span className={dashboard.View=='True'?"checked":"unchecked"}>{dashboard.View=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={2} className='label-check'><span className={dashboard.Bulk_import=='True'?"checked":"unchecked"}>{dashboard.Bulk_import=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={3} className='label-check'><span className={dashboard.Bulk_export=='True'?"checked":"unchecked"}>{dashboard.Bulk_export=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 

          </div>

          <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
           <div className='field-row'>
              <h4>Mass Upload</h4>
            </div>  
              <div  className={activeAccord.indexOf(21)!==-1?'bg-background-accord active':'bg-background-accord'}>              
              <Row className='header-table-2 header-table-3 header-table-4'>
              <Col lg={4} className='label-disp'>Mass Upload</Col>
              <Col lg={2} className='label-check'><span className="smallFont">Regular Employee</span><span className='biggerFont'>Update Shift</span></Col>
              <Col lg={2} className='label-check'><span className="smallFont">Daily Employee</span><span className='biggerFont'>Register Worker</span></Col>
              <Col lg={2} className='label-check'><span className="smallFont">Regular Employee</span><span className='biggerFont'>Update Tag</span></Col>
              <Col lg={2} className='label-check'><span className="smallFont"></span><span className='biggerFont'>Cycle Count</span></Col>
              
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(21)} >
                 { activeAccord.indexOf(21)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
          <div className={activeAccord.indexOf(21)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Mass Upload</Col>
                <Col lg={2} className='label-check'><span className={adminapp_mass_permissions.bulk_shift=='True'?"checked":"unchecked"}>{adminapp_mass_permissions.bulk_shift=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={2} className='label-check'><span className={adminapp_mass_permissions.daily_employee_bulk=='True'?"checked":"unchecked"}>{adminapp_mass_permissions.daily_employee_bulk=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={2} className='label-check'><span className={adminapp_mass_permissions.employee_tag_changing_bulk=='True'?"checked":"unchecked"}>{adminapp_mass_permissions.employee_tag_changing_bulk=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={2} className='label-check'><span className={adminapp_mass_permissions.cycles_count_bulk=='True'?"checked":"unchecked"}>{adminapp_mass_permissions.employee_tag_changing_bulk=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 

          </div>


           <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
           <div className='field-row'>
              <h4>Mobile App</h4>
            </div>  
              <div  className={activeAccord.indexOf(13)!==-1?'bg-background-accord active':'bg-background-accord'}>              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Module</Col>
              <Col lg={3} className='label-check'>Manager</Col>
              <Col lg={2} className='label-check'>Admin</Col>
              <Col lg={3} className='label-check'>Employee</Col>
              
            </Row>
                  <span className='icon-right'  onClick={()=>AccordionHandler(13)}>
                 { activeAccord.indexOf(13)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>


          <div className={activeAccord.indexOf(13)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Mobile App</Col>
                <Col lg={3} className='label-check'><span className={mobile_app.policy=='True'?"checked":"unchecked"}>{mobile_app.policy=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={2} className='label-check'><span className={mobile_app.visitor=='True'?"checked":"unchecked"}>{mobile_app.visitor=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={3} className='label-check'><span className={mobile_app.default=='True'?"checked":"unchecked"}>{mobile_app.default=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
          </div>


          <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
           <div className='field-row'>
              <h4>Geo Map Web App</h4>
            </div>  
              <div  className={activeAccord.indexOf(15)!==-1?'bg-background-accord active':'bg-background-accord'}>              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Geo Map</Col>
              <Col lg={4} className='label-check'>Employee Tracker</Col>
               <Col lg={4} className='label-check'>Facilities Tracker</Col>
              
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(15)}>
                 { activeAccord.indexOf(11)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
          <div className={activeAccord.indexOf(15)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Geo Map</Col>
                <Col lg={4} className='label-check'><span className={geo_map_web.employee_tracker=='True'?"checked":"unchecked"}>{geo_map_web.employee_tracker=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={4} className='label-check'><span className={geo_map_web.facility_tracker=='True'?"checked":"unchecked"}>{geo_map_web.facility_tracker=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 

          </div>

          <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
           <div className='field-row'>
              <h4>Report</h4>
            </div>  
              <div  className={activeAccord.indexOf(16)!==-1?'bg-background-accord active':'bg-background-accord'}>              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Report</Col>
              <Col lg={4} className='label-check'>View</Col>
               <Col lg={4} className='label-check'>Export</Col>
              
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(16)}>
                 { activeAccord.indexOf(16)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>
          <div className={activeAccord.indexOf(16)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Location</Col>
                <Col lg={4} className='label-check'><span className={reportLocation.View=='True'?"checked":"unchecked"}>{reportLocation.View=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={4} className='label-check'><span className={reportLocation.export=='True'?"checked":"unchecked"}>{reportLocation.export=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Working Hour</Col>
                <Col lg={4} className='label-check'><span className={reportWorkingHour.View=='True'?"checked":"unchecked"}>{reportWorkingHour.View=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={4} className='label-check'><span className={reportWorkingHour.export=='True'?"checked":"unchecked"}>{reportWorkingHour.export=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Cycle Count</Col>
                <Col lg={4} className='label-check'><span className={reportCycleCount.View=='True'?"checked":"unchecked"}>{reportCycleCount.View=='True'?<Check />:<UnCheck />}</span></Col>
                <Col lg={4} className='label-check'><span className={reportCycleCount.export=='True'?"checked":"unchecked"}>{reportCycleCount.export=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
          </div>



           <div className='br-seperator' style={{margin:"30px 0px 20px"}}></div>                
          <div className='field-row'>
              <h4>Web APP</h4>
          </div>
          
          <div  className={activeAccord.indexOf(14)!==-1?'bg-background-accord active':'bg-background-accord'}>
              
              <Row className='header-table-2'>
              <Col lg={4} className='label-disp'>Module</Col>
              <Col lg={2} className='label-check'>View</Col>
              <Col lg={2} className='label-check'>Create</Col>
              <Col lg={2} className='label-check'>Edit</Col>
              <Col lg={2} className='label-check'>Remove</Col>
            </Row>
                  <span className='icon-right' onClick={()=>AccordionHandler(14)} >
                 { activeAccord.indexOf(14)!==-1?<MinusIcon/>:<AddIcon/>} 
                </span>
          </div>


          <div className={activeAccord.indexOf(14)!==-1?'accord-role active':'accord-role'} >
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Dashboard</Col>
                <Col lg={2} className='label-check'><span className={webapp_dashboard.View=='True'?"checked":"unchecked"}>{webapp_dashboard.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_dashboard.Create=='True'?"checked":"unchecked"}>{webapp_dashboard.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_dashboard.edit=='True'?"checked":"unchecked"}>{webapp_dashboard.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_dashboard.remove=='True'?"checked":"unchecked"}>{webapp_dashboard.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'> Site Management </Col>
                <Col lg={2} className='label-check'><span className={webapp_sitemanagement.View=='True'?"checked":"unchecked"}>{webapp_sitemanagement.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_sitemanagement.Create=='True'?"checked":"unchecked"}>{webapp_sitemanagement.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_sitemanagement.edit=='True'?"checked":"unchecked"}>{webapp_sitemanagement.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_sitemanagement.remove=='True'?"checked":"unchecked"}>{webapp_sitemanagement.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Manpower Management</Col>
                <Col lg={2} className='label-check'><span className={webapp_manpower_management.View=='True'?"checked":"unchecked"}>{webapp_manpower_management.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_manpower_management.Create=='True'?"checked":"unchecked"}>{webapp_manpower_management.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_manpower_management.edit=='True'?"checked":"unchecked"}>{webapp_manpower_management.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_manpower_management.remove=='True'?"checked":"unchecked"}>{webapp_manpower_management.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row> 
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Visitor Management</Col>
                <Col lg={2} className='label-check'><span className={webapp_visitor_management.View=='True'?"checked":"unchecked"}>{webapp_visitor_management.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_visitor_management.Create=='True'?"checked":"unchecked"}>{webapp_visitor_management.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_visitor_management.edit=='True'?"checked":"unchecked"}>{webapp_visitor_management.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_visitor_management.remove=='True'?"checked":"unchecked"}>{webapp_visitor_management.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row>
              
              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Policy Management</Col>
                <Col lg={2} className='label-check'><span className={webapp_policy_management.View=='True'?"checked":"unchecked"}>{webapp_policy_management.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_policy_management.Create=='True'?"checked":"unchecked"}>{webapp_policy_management.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_policy_management.edit=='True'?"checked":"unchecked"}>{webapp_policy_management.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_policy_management.remove=='True'?"checked":"unchecked"}>{webapp_policy_management.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row>

              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Productivity Management</Col>
                <Col lg={2} className='label-check'><span className={webapp_productivity_management.View=='True'?"checked":"unchecked"}>{webapp_policy_management.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_productivity_management.Create=='True'?"checked":"unchecked"}>{webapp_policy_management.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_productivity_management.edit=='True'?"checked":"unchecked"}>{webapp_policy_management.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={webapp_productivity_management.remove=='True'?"checked":"unchecked"}>{webapp_policy_management.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row>

              <Row className='cotnent-table'>
                <Col lg={4} className='label-disp'>Cycle Count </Col>
                <Col lg={2} className='label-check'><span className={web_app_cycles.View=='True'?"checked":"unchecked"}>{web_app_cycles.View=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={web_app_cycles.Create=='True'?"checked":"unchecked"}>{web_app_cycles.Create=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={web_app_cycles.edit=='True'?"checked":"unchecked"}>{web_app_cycles.edit=='True'?<Check />:<UnCheck />}</span></Col>
<Col lg={2} className='label-check'><span className={web_app_cycles.remove=='True'?"checked":"unchecked"}>{web_app_cycles.remove=='True'?<Check />:<UnCheck />}</span></Col>
              </Row>
             </div>  
             </div>
             </>}
</div>       
 
</div>        
</div>
         
    )
    

}
export default RoleDetail;