import React, { cloneElement } from "react";
import moment from "moment-timezone";
import './styles.scss'
const timezonesRaw = require("./timezones.json");
const timezones = Object.values(timezonesRaw.zones);




export default class TimezonePicker extends React.PureComponent {
  
  
  constructor(props) {
    super(props);
    
    
    this.state = {
      centers: timezones.map(val => ({
        x: (180 + val.long) / 360,
        y: (90 - val.lat) / 180,
        name: val.name
      })),
      lastCenter: {},
      mapAxisX: "",
      mapAxisY: "",
      timeLocale: "",
      zoom: 1
    };
    // this.onMouseMove.bind(this);
    // this.onClick.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
this.onClick = this.onClick.bind(this);
  this.mapRef = React.createRef();
    this.select = this.select.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    }
    renderDropdown() {
      return (
        <select onChange={(event) => this.select(event.target.value)}>
          {timezones.map(tz => (
            <option key={tz.name} value={tz.name}>
              {tz.name}{tz.timeLocale}
            </option>
          ))}
        </select>
      );
    }
     onClick(value) {
      const tz = timezones.find(tz => tz.name === value);
      this.activate(tz);
    }
    select(value) {
      var now = moment.utc();
      const tz = timezones.find(tz => tz.name === value);
      var tz_offset = moment(now).tz(tz.name).format('Z');
      const selectedCenter = this.state.centers.find(center => center.name === tz.name);
    this.setState({
  mapAxisX: `calc(${selectedCenter.x * 100}% + 3px)`,
  mapAxisY: `calc(${selectedCenter.y * 100}% + 3px)`
    });
      this.props.onSelect(value,tz_offset);
    }
   
  
  renderZones() {
    return this.state.centers.map(val => {
      return (
        <span
        
          style={{
            position: "absolute",
            top: `${val.y * 100}%`,
            left: `${val.x * 100}%`,
            background: "palevioletred",
            border: "0.5px grey solid",
            width: "4px",
            height: "4px",
            borderRadius: "50%"
          }}
          onClick={() => this.select(val)}
          key={val.name}
        />
      );
    });
  }
  

  select(tz) {
    var now = moment.utc();
    // console.log(tz);
    // var tz_offset = moment.tz.zone(tz.name).utcOffset(now);
    //var tz_offset = moment(now).tz(tz.name).utcOffset() ;
    var tz_offset = moment(now).tz(tz.name).format('Z');

    const m = moment().tz(tz.name);
    //  this.props.onSelect(tz.name, `${m.format("hh:mm a ")} ${m.zoneAbbr()}`);
    
    this.props.onSelect(tz.name, tz_offset);
  }

  distSqr(x, y, px, py) {
    const dx = x - px;
    const dy = y - py;
    return dx * dx + dy * dy;
  }

  changeCenter(center) {
    const lastCenter = this.state.lastCenter;
    if (center === lastCenter) {
      return;
    }
    if (lastCenter) {
      this.deactivate(lastCenter);
    }

    this.activate(center);
  }

  activate(tz) {
    const m = moment().tz(tz.name);
   
    this.setState({
      name: tz.name,
      timeLocale: `${m.format("hh:mm a ")} ${m.zoneAbbr()}`,
      lastCenter: tz,
      mapAxisX: `calc(${tz.x * 100}% + 3px)`,
      mapAxisY: `calc(${tz.y * 100}% + 3px)`
    
    });
  }
 


  deactivate(tz) {}

  onMouseMove(e) {
    const rect = this.mapRef.current.getBoundingClientRect();
    const win = this.mapRef.current.ownerDocument.defaultView;
    const offsetTop = rect.top + win.pageYOffset;
    const offsetLeft = rect.left + win.pageXOffset;
    const x = e.pageX - offsetLeft;
    const y = e.pageY - offsetTop;
    const px = x / this.mapRef.current.offsetWidth;
    const py = y / this.mapRef.current.offsetHeight;
    let dist;
    let closestDist = 100;
    let closestCenter;

    // console.log(x, e.pageX, rect.left, offsetLeft, win.pageXOffset, e.screenX);

    this.state.centers.forEach(tz => {
      dist = this.distSqr(tz.x, tz.y, px, py);
      if (dist < closestDist) {
        closestCenter = tz;
        closestDist = dist;
      }
    });

    if (closestCenter) {
      this.changeCenter(closestCenter);
    }
  }

  onClick(e) {}
 
  zoomIn() {
    if (this.state.zoom >= 2) return;
    this.setState({
    zoom: this.state.zoom + 0.2
    });
    }
    
    zoomOut() {
    if (this.state.zoom <= 0.2) return;
    this.setState({
    zoom: this.state.zoom - 0.2
    });
    }

  zoomIn() {
    this.setState({
      zoom: this.state.zoom + 1,
      mapAxisX: this.state.mapAxisX * (this.state.zoom + 1),
      mapAxisY: this.state.mapAxisY * (this.state.zoom + 1)
    });
  }
  
  zoomOut() {
    if (this.state.zoom > 1) {
      this.setState({
        zoom: this.state.zoom - 1,
        mapAxisX: this.state.mapAxisX / (this.state.zoom),
        mapAxisY: this.state.mapAxisY / (this.state.zoom)
      });
    }
  }
  
  
  render() {
    
    
    return (
      <>
      {this.renderDropdown()}
      <div className="zoom">
      <button onClick={this.zoomIn}>+</button>
          <button onClick={this.zoomOut}>-</button>
   </div>
   <span className="set">
           { this.state.name} {this.state.timeLocale}
        </span>
        <div className="timezonePicker" ref={this.mapRef}>
        
          <div
            className="worldMap"
            onMouseMove={e => this.onMouseMove(e)}
            onClick={e => this.onClick(e)}
            style={{
              transform: `scale(${this.state.zoom})`,
              transformOrigin: `${this.state.mapAxisX} ${this.state.mapAxisY}`,
             
              }}
            // viewBox="0 0 1 1"
           
          >
            
            {/* <div className="zoom-buttons" 
        
        >
          <button onClick={this.zoomIn}>+</button>
          <button onClick={this.zoomOut}>-</button>
        </div> */}
            <div className="map-axis-x" style={{ left: this.state.mapAxisX  }} />
            <div className="map-axis-y" style={{ top: this.state.mapAxisY }} />
            {this.renderZones()}
            <span
          style={{
            position: "absolute",
            top: this.state.mapAxisY,
            left: this.state.mapAxisX,
            background: "palevioletred",
            border: "0.5px grey solid",
            width: "4px",
            height: "4px",
            borderRadius: "50%"
          }}
        />
          </div>
        </div>
        
        
       
      </>
    );
  }
}
