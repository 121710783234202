import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'
import '../styles/dashboard.scss'


import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';
import BoxStats from './BoxStats';
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { userInfo,roleInfo, groupInfo,categoryInfo,locationInfo,deviceInfo,exportData,importData } from '../actionMethods/actionMethods';
import LoaderSpinner from  '../../assets/images/Spinner Loader.gif'

import {ReactComponent as FileDownload} from '../../assets/images/file-excel.svg'
import {ReactComponent as FileImport} from '../../assets/images/file-import.svg'
import {ReactComponent as FileExport} from '../../assets/images/file-export.svg'
import ExcelTemplate from '../../assets/working_data_template.xlsm'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import ExcelJs from "exceljs";
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};

function Dashboard(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let sub_org_id =userDetails.sub_org_id?userDetails.sub_org_id:""

    const [currentLocation,SetCurrentLocation]=useState(null)

    const[lat, SetLat]=useState(null);
    const[long, SetLong]=useState(null);
    const [activeMac, SetActiveMac]=useState(1);
    const [temperature,SetTemperature]=useState('')
    const [macData,SetMacData]=useState([])
    const [locationinfoData,Setlocationinfo]=useState([])
    const [osinfoData,Setosinfo]=useState([])
    const [systemperformanceData,Setsystemperformance]=useState([])
    const [memoryinfoData,Setmemoryinfo]=useState([])
    const [storageinfoData,Setstorageinfo]=useState([])
    const [deviceData,SetDeviceData]=useState([])
    const [addonData,SetAddonData]=useState([])
    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [user_info,Setuser_info]= useState([])
    const [role_info,Setrole_info]= useState([])
    const [group_info,Setgroup_info]= useState([])
    const [category_info,Setcategory_info]= useState([])
    const [location_info,Setlocation_info]= useState([])
    const [device_info,Setdevice_info]= useState([])
    const [inc,SetInc]=useState(0);
    const [loader,Setloader]= useState(true);
    const [modalImport,SetModalImport]=useState(false);
    const [file, setFile] = React.useState("");
    const [fileError,SetFileError] =useState('');
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')
    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [loaderExport,SetLoaderExport] =useState(false);

    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].dashboard))
                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()
        
    }, []);


    function handleCloseModalSucess(){
      SetModalImport(false);
      SetModalSucess(false);
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }
    

    const [statsData,SetStatsData]= useState(["#000000",'#3fb995','#000000',"#ef835c","#fbcf31","#f16b95"]);

    useEffect(()=>{
        if(permission.View=='True'){
            let requestBody={}
            requestBody.session=session;
            requestBody.org_id=userDetails.org_id;
                       
            userInfo(requestBody).then(res=>{
                if(res.status==200){
                    Setuser_info(res.data)
                }
            })
            roleInfo(requestBody).then(res=>{
                if(res.status==200){
                    Setrole_info(res.data)
                }
            })
          
            groupInfo(requestBody,sub_org_id).then(res=>{
                if(res.status==200){
                    Setgroup_info(res.data)
                }
            })
            categoryInfo(requestBody).then(res=>{
                if(res.status==200){
                    Setcategory_info(res.data)
                }
            })
            locationInfo(requestBody).then(res=>{
                if(res.status==200){
                    Setlocation_info(res.data)
                }
            })
            deviceInfo(requestBody).then(res=>{
                if(res.status==200){
                    Setdevice_info(res.data)
                    SetInc(6)
                }
            })            
        }

    },[permission])
    useEffect(()=>{
    if(inc==6){
        Setloader(false);
    }
    },[inc])

    function loadFile(event){

        let arry = event.target.files[0].name.split('.');
        let lastElement = arry[arry.length - 1];
           
        if(lastElement=='xls' || lastElement=='xlsx' || lastElement=='xlsm' ){    
            setFile(event.target.files[0]);    
        }else{
            SetFileError('Only Support Excel file')
            setFile('');    
            setTimeout(function(){
               SetFileError('')
            },3000)
        }
    
    }
    
    

    function importFileHandler(){

      SetLoaderImport(true);
      
      if(file && file.type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type =="application/vnd.ms-excel.sheet.macroEnabled.12"){
        let requestBody={}
       
        requestBody.session= session
        importData(requestBody,file).then(res=>{
          if(res.status==200){
             
              SetTitle(<h4>File is imported</h4>);
              SetDescription(res.message)
              SetModalSucess(true)            
          }else{
            SetTitle(res.message);
            SetDescription('Fail to import')
            SetModalError(true)
          }
          SetLoaderImport(false);
        })

      }else{
        SetFileError('File is required')
      }

    }


    const ExportEccel = () => {

      let requestBody ={}
      requestBody.session= session
      requestBody.org_id=  userDetails.org_id
      SetLoaderExport(true);
      exportData(requestBody).then(res=>{

        if(res.status==200){
          const workbook = new ExcelJs.Workbook();
       
          const filename = "Export.xlsx";
          const sheetNames = ["organization", "location", "employee", "role"];
          
          const orgExcel = [res.data.organization_data];
          const employeeExcel = res.data.employee_data;
          const locationExcel= res.data.location_data;
          const roleExcel = res.data.roles_data;
          
  
          sheetNames.forEach(sheetName => {
              let worksheet = workbook.addWorksheet(sheetName);
              // I believe this needs to be set to show in LibreOffice.
              if(sheetName=='organization'){
  
              
  
              worksheet.addTable({
                  name: sheetName,
                  ref: "A1", 
                  headerRow: true,
                  totalsRow: false,
                  style: {
                    theme: "TableStyleMedium2",
                    showRowStripes: false,
                    width: 200
                  },
                  columns : [
                    {name:'NAME', header: 'NAME', key: 'org_name'},
                    {name:'DESCRIPTION',header: 'DESCRIPTION', key: 'description'},
                    {name:'LOGO URL',header: 'LOGO URL', key: 'url'},
                  ],
                  rows: orgExcel.map((e) => {
                    let arr = [e.org_name,e.description,e.url];      
                    return arr;
                  })
                })
                worksheet.state = 'visible';
                const table = worksheet.getTable(sheetName);
                for (let i = 0; i < table.table.columns.length; i++) {
                    worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                    worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "d0cfcf" }
                  };
              
        
                }
                worksheet.columns.forEach(column => {
                  const lengths = column.values.map(v => v.toString().length);
                  const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                  column.width = maxLength+2;
                });
  
                table.commit();
  
              
              }
  
              if(sheetName=='location'){
                  worksheet.addTable({
                      name: sheetName,
                      ref: "A1", 
                      headerRow: true,
                      totalsRow: false,
                      style: {
                        theme: "TableStyleMedium2",
                        showRowStripes: false,
                        width: 200
                      },
                      columns : [
                        {name:'NAME', header: 'NAME', key: 'location_name'},
                        {name:'DESCRIPTION',header: 'DESCRIPTION', key: 'description'},
                        {name:'TAG SERIAL',header: 'TAG SERIAL', key: 'tag_serial'},
                        {name:'CATEGORY',header: 'CATEGORY', key: 'categories'},
                        {name:'CAPACITY',header: 'CAPACITY', key: 'capacity'},
                        {name:'AREA SIZE',header: 'AREA SIZE', key: 'area_size'},
                       
                      ],
                      rows: locationExcel.map((e) => {
                        let arr = [e.location_name ,e.description,e.tag_serial,e.categories,e.capacity,e.area_size];      
                        return arr;
                      })
                    })
  
                    worksheet.state = 'visible';
                    const table = worksheet.getTable(sheetName);
                    for (let i = 0; i < table.table.columns.length; i++) {
                        worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                        worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "d0cfcf" }
                      };
                  
            
                    }
                    worksheet.columns.forEach(column => {
                      const lengths = column.values.map(v => v.toString().length);
                      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                      column.width = maxLength+2;
                    });
                    
                    table.commit();
                    
                  
              }
              if(sheetName=='employee'){
                  worksheet.addTable({
                      name: sheetName,
                      ref: "A1", 
                      headerRow: true,
                      totalsRow: false,
                      style: {
                        theme: "TableStyleMedium2",
                        showRowStripes: false,
                        width: 200
                      },
                      columns : [
                        {name:'NAME',header: 'NAME', key: 'name'},
                        {name:'EMAIL', header: 'EMAIL', key: 'emp_email'},
                        {name:'DESIGNATION',header: 'DESIGNATION', key: 'description'},
                        {name:'TAG SERIAL',header: 'TAG SERIAL', key: 'tag_serial'},
                        {name:'ROLE',header: 'ROLE', key: 'role_name'},
                        {name:'DEPARTMENT',header: 'DEPARTMENT', key: 'department_name'},
                        {name:'SUB DEPARTMENT',header: 'SUB DEPARTMENT', key: 'sub_dept_name'},
                        {name:'EMPLOYEE ID',header: 'EMPLOYEE ID', key: 'emp_id'},
                        {name:'SHIFT',header: 'SHIFT', key: 'shift'},
                        {name:'SHIFT TIMINGS',header: 'SHIFT TIMINGS', key: 'shift_timings'},
                        {name:'PRODUCTIVE LOCATIONS',header: 'PRODUCTIVE LOCATIONS', key: 'productive'},
                        {name:'LESS PRODUCTIVE LOCATIONS',header: 'LESS PRODUCTIVE LOCATIONS', key: 'productive'},
                        {name:'NON PRODUCTIVE LOCATIONS',header: 'NON PRODUCTIVE LOCATIONS', key: 'productive'},
                      ],
                      rows: employeeExcel.map((e) => {
 
                        let productive= e.productivity ;
                        let arr = [e.name,e.emp_email,e.description,e.tag_serial,e.role_name,e.department_name,e.sub_dept_name,e.emp_id,e.shift,e.shift_start_time.split(':')[0]+':'+e.shift_start_time.split(':')[1] +" - "+e.shift_end_time.split(':')[0]+':'+e.shift_end_time.split(':')[1] ,productive.productive.toString(),productive.less_productive.toString(),productive.non_productive.toString()];      
                        return arr;
                      })
                    })
                    worksheet.state = 'visible';
                    const table = worksheet.getTable(sheetName);
                    for (let i = 0; i < table.table.columns.length; i++) {
                        worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
                        worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "d0cfcf" }
                      };
                  
            
                    }
  
                    worksheet.columns.forEach(column => {
                      const lengths = column.values.map(v => v.toString().length);
                      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                      column.width = maxLength +2;
                    });
  
                    table.commit();                
  
              }
      
           if(sheetName=='role'){
  
                let colPrint= 0;
                let boldCol = [];
  
                for (let role = 0; role<roleExcel.length;role++ ){
                  let element = roleExcel[role][0]?roleExcel[role][0]:roleExcel[role];
  
                  let categoryJson = JSON.parse(element.category);
                  let groupJson = JSON.parse(element.group);
                  let visitorJson = JSON.parse(element.visitor);
                  let employeeJson = JSON.parse(element.employee);
                  let locationJson = JSON.parse(element.location);
                  let deviceJson = JSON.parse(element.device);
                  let roleJson = JSON.parse(element.role);
                  let assetJson = JSON.parse(element.asset);
                  let dashboardJson = JSON.parse(element.dashboard);
                  let mobileJson = JSON.parse(element.mobile_app);
                  let site_managementJson = JSON.parse(element.web_app_sitemanagement);
                  let manpower_managementJson = JSON.parse(element.manpower_management);
                  let visitor_managementJson = JSON.parse(element.visitor_management);
                  let policy_managementJson = JSON.parse(element.policy_management);
                 
                  if(colPrint> 0){
                    worksheet.addRow([]);  
                    worksheet.addRow([]);
                  }
  
                  /*Role Name*/
                  worksheet.addRow(["ROLE NAME", element.name]);
                  worksheet.mergeCells('B'+(colPrint*27+1)+':E'+(colPrint*27+1));
                  /*Role Header*/
                  worksheet.addRow(["", "VIEW","EDIT","CREATE","REMOVE"]);
  
                  /*USER MANAGEMENT*/
                  worksheet.addRow(["USER MANAGEMENT",element.user_management.toUpperCase()]);                
                  worksheet.mergeCells('B'+(colPrint*27+3)+':E'+(colPrint*27+3));
                  worksheet.addRow(["Visitor", visitorJson.View.toUpperCase(),visitorJson.edit.toUpperCase(),visitorJson.Create.toUpperCase(),visitorJson.remove.toUpperCase()]);
                  worksheet.addRow(["Employee", employeeJson.View.toUpperCase(),employeeJson.edit.toUpperCase(),employeeJson.Create.toUpperCase(),employeeJson.remove.toUpperCase()]);
                  
                  /*SITE MANAGEMENT*/
                  worksheet.addRow(["SITE MANAGEMENT",element.site_management.toUpperCase()]);
                  worksheet.mergeCells('B'+(colPrint*27+6)+':E'+(colPrint*27+6));
                  worksheet.addRow(["Category", categoryJson.View.toUpperCase(),categoryJson.edit.toUpperCase(),categoryJson.Create.toUpperCase(),categoryJson.remove.toUpperCase()]);
                  worksheet.addRow(["Location", locationJson.View.toUpperCase(),locationJson.edit.toUpperCase(),locationJson.Create.toUpperCase(),locationJson.remove.toUpperCase()]);
  
                  /*DEVICE MANAGEMENT*/
                  worksheet.addRow(["DEVICE MANAGEMENT",element.device_management.toUpperCase()]);
                  worksheet.mergeCells('B'+(colPrint*27+9)+':E'+(colPrint*27+9));
                  worksheet.addRow(["Device", deviceJson.View.toUpperCase(),deviceJson.edit.toUpperCase(),deviceJson.Create.toUpperCase(),deviceJson.remove.toUpperCase()]);
  
                  /*ROLE MANAGEMENT*/
                  worksheet.addRow(["ROLE MANAGEMENT",element.role_management.toUpperCase()]);
                  worksheet.mergeCells('B'+(colPrint*27+11)+':E'+(colPrint*27+11));
                  worksheet.addRow(["Role", roleJson.View.toUpperCase(),roleJson.edit.toUpperCase(),roleJson.Create.toUpperCase(),roleJson.remove.toUpperCase()]);
                  
                  /*ASSET MANAGEMENT*/
                  worksheet.addRow(["ASSET MANAGEMENT",element.asset_management.toUpperCase()]);
                  worksheet.mergeCells('B'+(colPrint*27+13)+':E'+(colPrint*27+13));
                  worksheet.addRow(["Asset", assetJson.View.toUpperCase(),assetJson.edit.toUpperCase(),assetJson.Create.toUpperCase(),assetJson.remove.toUpperCase()]);
                  
                  /*GROUP MANAGEMENT*/
                  worksheet.addRow(["GROUP MANAGEMENT",element.group_management.toUpperCase()]);
                  worksheet.mergeCells('B'+(colPrint*27+15)+':E'+(colPrint*27+15));
                  worksheet.addRow(["Group", groupJson.View.toUpperCase(),groupJson.edit.toUpperCase(),groupJson.Create.toUpperCase(),groupJson.remove.toUpperCase()]);
                   
                  /*WEB APP PERMISSIONS*/ 
                  worksheet.addRow(["WEB APP PERMISSIONS",'']);
                  worksheet.mergeCells('B'+(colPrint*27+17)+':E'+(colPrint*27+17));
                  worksheet.addRow(["Dashboard", dashboardJson.View.toUpperCase(),dashboardJson.edit.toUpperCase(),dashboardJson.Create.toUpperCase(),dashboardJson.remove.toUpperCase()]);
                  worksheet.addRow(["Site management", site_managementJson.View.toUpperCase(),site_managementJson.edit.toUpperCase(),site_managementJson.Create.toUpperCase(),site_managementJson.remove.toUpperCase()]);
                  worksheet.addRow(["Manpower management", manpower_managementJson.View.toUpperCase(),manpower_managementJson.edit.toUpperCase(),manpower_managementJson.Create.toUpperCase(),manpower_managementJson.remove.toUpperCase()]);
                  worksheet.addRow(["Visitor management", visitor_managementJson.View.toUpperCase(),visitor_managementJson.edit.toUpperCase(),visitor_managementJson.Create.toUpperCase(),visitor_managementJson.remove.toUpperCase()]);
                  worksheet.addRow(["Policy management", policy_managementJson.View.toUpperCase(),policy_managementJson.edit.toUpperCase(),policy_managementJson.Create.toUpperCase(),policy_managementJson.remove.toUpperCase()]);
  
                  /*Header */		
                  worksheet.addRow(["","POLICY","VISITOR","DEFAULT"]);
                  /*MOBILE APP PERMISSIONS */		
                  worksheet.addRow(["MOBILE APP PERMISSIONS",""]);
                  worksheet.mergeCells('B'+(colPrint*27+24)+':E'+(colPrint*27+24));
                  worksheet.addRow(["MOBILE APP", mobileJson.policy.toUpperCase(),mobileJson.visitor.toUpperCase(),mobileJson.default.toUpperCase()]);
                  
                  
  
  
  
                  ['A'+(colPrint*27+1),'A'+(colPrint*27+3),'A'+(colPrint*27+6),'A'+(colPrint*27+9),'A'+(colPrint*27+11),'A'+(colPrint*27+13),'A'+(colPrint*27+15),'A'+(colPrint*27+17),'A'+(colPrint*27+24)].map(key => {
                    boldCol.push(key);
                      worksheet.getCell(key).font = { size: 11,bold: true };
                      worksheet.getCell(key).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor: { argb: "d0cfcf" },
                        size:11,
                        Bold: true,   
                      };
                    });
                    colPrint++;
                }

                worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber){
                  row.eachCell(function(cell, colNumber){

                    if(boldCol.indexOf(cell._address) > -1 ){
                      cell.font = {
                        size: 11,
                        bold:true
                      };
                    }else{
                      cell.font = {
                        size: 11,
                        bold:false
                      };
                    }

                 });
                });
  
                worksheet.state = 'visible';
                
  
                worksheet.columns.forEach(column => {
                  if(column){

                    const lengths = column.values.map(v => v?v.toString().length:0);
                    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                    column.width = maxLength + 2;
                  }
                  
                });
                
                //table.commit();
              }
                  
          });
  
          const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(filename, buffer);
          });
  
        }
        SetLoaderExport(false);
        
      })


      };
      


    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">



        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
        <>
            {/* <div className="page-header">
            <Row>
                <Col lg={6}>
                </Col>
                <Col lg={6} className="header-btn">                    
                {permission.Bulk_import=="True"?<button className='btn btn-large btn-header btn-transparent' onClick={()=>SetModalImport(true) } style={{width:"110px"}}><FileImport/>Import</button>:<button className='btn btn-large btn-header btn-transparent disable hover-message' style={{width:"110px",opacity:".8"}} > <FileImport/><span className='messageTag'>Access Restricted</span>Import</button>}
                {permission.Bulk_export=="True"?<button className='btn btn-large btn-header btn-transparent btn-icon' style={{width:"110px"}}onClick={ExportEccel}><FileExport/>Export</button>:<button className='btn btn-large btn-header btn-transparent btn-icon disable hover-message' style={{width:"110px",opacity:".8"}}><FileExport/><span className='messageTag'>Access Restricted</span>Export</button>}
                    <a className='btn btn-large btn-header btn-transparent btn-icon' href={ExcelTemplate} download="Template" style={{width:"210px"}}><FileDownload/>Download Template</a>
                </Col>
                </Row>
    </div> */}

        <Row className='box-stat-rows'>
         {permissionAPICall && permission.View=='True'?<>
         
        {/* {   
            user_info?<Col lg={4} className="box-space-bottom" key={"box-User"} >
            <BoxStats title={'User'} count={user_info.total_number_of_users?user_info.total_number_of_users:0} subStats={[{count:user_info.total_number_of_users,subtitle:"Active"},{count:0,subtitle:"InActive"}]} redirect={'/user-management'} color={statsData[0]} {...props} />
            </Col>:''}
 
            {
            group_info?<Col lg={4} className="box-space-bottom" key={"box-group"} >
            <BoxStats title={'Group'} count={group_info.total_number_of_departments?group_info.total_number_of_departments+group_info.total_number_of_sub_departments:0} subStats={[{count:group_info.total_number_of_departments,subtitle:"Total Department"},{count:group_info.total_number_of_sub_departments,subtitle:"Total Sub Department"}]} color={statsData[1]} {...props} redirect={'/user-management'} />
            </Col>:''}

            {
            role_info?<Col lg={4} className="box-space-bottom" key={"box-Role"} >
            <BoxStats title={'Role'} count={role_info.total_number_of_roles?role_info.total_number_of_roles:0} subStats={[{count:role_info.total_number_of_roles,subtitle:"Active"},{count:0,subtitle:"InActive"}]} color={statsData[2]}  {...props} redirect={'/role-management'} />
            </Col>:''} */}


            {
            location_info?<Col lg={4} className="box-space-bottom" key={"box-location"} >
            <BoxStats title={'Location'} count={location_info.total_number_of_locations?location_info.total_number_of_locations:0} subStats={[{count:location_info.total_number_of_locations,subtitle:"Active"},{count:0,subtitle:"InActive"}]} color={statsData[4]} {...props} redirect={'/site-management'}/>
            </Col>:''}

            {
            category_info?<Col lg={4} className="box-space-bottom" key={"box-Categories"} >
            <BoxStats title={'Location Categories'} count={category_info.total_number_of_categories?category_info.total_number_of_categories:0} subStats={[{count:category_info.total_number_of_categories,subtitle:"Active"},{count:0,subtitle:"InActive"}]} color={statsData[5]}  {...props} redirect={'/site-management'}/>
            </Col>:''}
            {
            device_info ?
            <Col lg={4} className="box-space-bottom" key={"box-device"} >

             <div className='boxDashboardStats boxDashboardStatsDevice'>
                <div  className='headerStats' onClick={()=>props.history.push('/device-management')}>
                    <div className='biggerFont' style={{color:statsData[3]!=null?statsData[3]:"#000" }}>{device_info.total_number_of_devices?device_info.total_number_of_devices:0}</div>
                    <div className='smallerFont'>{'Device'}</div>
                </div>
                <div className='contentAreaStats'>
                <div className="rowStats"><span className="titleLeft"></span><span className="subCount subCountTitleInUse">In Use</span><span className="subCount subCountTitleAvailable">Available</span> <span className="subCount subCountTitleAvailable">Low Battery</span> </div>
                <div className="rowStats"><span className="titleLeft"> Regular Worker Tag</span><span className="subCount subCountTitleInUse">{device_info.active_employee_count?device_info.active_employee_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.available_employee_count?device_info.available_employee_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.employee_low_battery_devices?device_info.employee_low_battery_devices:0}</span> </div>
                <div className="rowStats"><span className="titleLeft">Location Tag </span><span className="subCount subCountTitleInUse">{device_info.active_location_count}</span><span className="subCount subCountTitleAvailable">{device_info.available_location_count}</span><span className="subCount subCountTitleAvailable">{device_info.location_low_battery_devices}</span></div>
                <div className="rowStats"><span className="titleLeft">Gateway </span><span className="subCount subCountTitleInUse">{device_info.active_gateway_count}</span><span className="subCount subCountTitleAvailable">{device_info.inactive_gateway_count}</span><span className="subCount subCountTitleAvailable">0</span></div>
                <div className="rowStats"><span className="titleLeft">Asset </span><span className="subCount subCountTitleInUse">{device_info.active_asset_count?device_info.active_asset_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.inactive_asset_count?device_info.inactive_asset_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.asset_low_battery_devices?device_info.asset_low_battery_devices:0}</span></div>
                <div className="rowStats"><span className="titleLeft">Visitor </span><span className="subCount subCountTitleInUse">{device_info.active_visitor_count?device_info.active_visitor_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.inactive_visitor_count?device_info.inactive_visitor_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.visitor_low_battery_devices?device_info.visitor_low_battery_devices:0}</span></div>
                <div className="rowStats"><span className="titleLeft" style={{lineHeight:1}}>Daily Worker Tag </span><span className="subCount subCountTitleInUse">{device_info.active_temporary_employee_count?device_info.active_temporary_employee_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.inactive_temporary_employee_count?device_info.inactive_temporary_employee_count:0}</span><span className="subCount subCountTitleAvailable">{device_info.temporary_employee_low_battery_devices?device_info.temporary_employee_low_battery_devices:0}</span></div>
                
                    <Row>
                        
                    </Row>
                                    </div>
            </div>


            {/* <BoxStats title={'Device '} count={device_info.total_number_of_devices?device_info.total_number_of_devices:0}
             subStats={[{count:device_info.active_employee_count,subtitle:"Active Employee Tag"},{count:device_info.available_employee_count,subtitle:"Available Employee Tag"}
        ,{count:device_info.active_location_count,subtitle:"Active Location Tag"},{count:device_info.available_location_count,subtitle:"Available Location Tag"},
        {count:device_info.gateway_count,subtitle:"Gateway "},{count:device_info.low_battery_devices,subtitle:"Low batteries "},{count:0,subtitle:"Stock"},
        ]} color={statsData[3]} /> */}
            </Col>
            :''}
            </>:"" }
 
                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}

    
        </Row></>}


    </div>
</div>

<ReactModal
                isOpen={modalImport}
                style={customStyles}
                key={'mapLocation'}
              //  onRequestClose={handleCloseModal}
            >
                    <div className={loaderImport?'loaderActive disable':""}>
            <div className='modalHeader'>
                    <h4>Import</h4>
                    <div className='closeMark' onClick={()=>SetModalImport(false)}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                     <label>Import File* <div className='posRel'><HelpIcon /><span className='helpSmall'>Please select you want to import</span></div></label>
                     <input type="file"  id="file" placeholder="browse" accept=".xlsx,.xlsm,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>loadFile(e) } />
                            <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                       </div>
                    {loaderImport?<button className='btn btn-primary btn-100' onClick={importFileHandler} ><img src={Loader} />Importing...</button>:<button className='btn btn-primary btn-100' onClick={importFileHandler} > Start Import</button>  }  
                </div>
        </div>
            </ReactModal>



            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Dashboard))
