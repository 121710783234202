
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory, withRouter } from "react-router-dom";
import '../../assets/styles/common.scss'
import '../styles/styles.scss'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import Createorg from './create'
import Editorg from './editorg';
import ReactModal from 'react-modal';
import OrgList from './OrgList';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {getorgDetail,CreteOrg, removeOrganization} from '../actionMethods/actionMethods'
import UserSetting from './usersettings';
import WarningModal from '../../components/WarningModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};
  
function OrganizationManagement(props) {
    console.log("props",props)
    const [modalCreate,SetModalCreate]=useState(false);
    const [modalEdit,SetModalEdit]=useState(false);
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');
    const [modalSucess,SetModalSucess]= useState(false);
    const [modalError,SetModalError]= useState(false);
    const [modalDelete,SetModalDelete]= useState(false); 
const [modalWarning,SetModalWarning]= useState(false);
const [searchorg,SetSearchorg] = useState([]);
const [deleteID,SetDeleteID] =useState('');
const [inc,SetInc]=useState(1);
let history = useHistory();
const [modalUpdate,SetModalUpdate]=useState(false);
const [modalCategoryEdit,SetModalCategoryEdit]= useState(false); 
const [orgDetail,SetorgDetail]=useState([]);

let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	 let org_id = userDetails.org_id?userDetails.org_id:"";

useEffect(()=>{
  
    let requestBody={}
    requestBody.session=session
    requestBody.org_id= org_id


    getorgDetail(requestBody).then(res=>{
      // console.log(res.data);
      if(res.status==200 || res.code== 200){
        let data= res.data[0];
      //  SetorgDetail(res);
        
      }
    //   if(res.status==300){
    //     localStorage.removeItem('isLoggedInGatewayTraceAdmin')
    //     localStorage.removeItem('userLoginDetailsTraceAdmin')
    //     props.history.push(`/login`)
    // }
    }).catch()


  },[])
function Reload(){
    SetInc((prev)=>prev+1);
}

function modalSucessHandler(titleShow,descriptionShow){
    SetTitle(titleShow)
    SetDescription(descriptionShow)
    SetModalSucess(true);
    
}
function handleCloseModalSucess(){
    SetModalCreate(false);
    SetModalUpdate(false)
    SetModalSucess(false)
    SetModalEdit(false);
    SetInc((prev)=>prev +1);
 
}
function handleCloseModalError(){
    SetModalError(false)
}

function modalErrorHandler(titleShow,descriptionShow){
    SetTitle(titleShow)
    SetDescription(descriptionShow)
    SetModalError(true);
}
function handleCloseModalCreate(){
    SetModalCreate(false);
}
function handleCloseModalUpdate(){
    SetModalUpdate(false);
}

function handleCloseModalEdit(){
    SetModalEdit(false);
}

function handleCloseModalWarning(){
    SetModalWarning(false)
}
const modalOpenCategoryEdit = (obj) => {
    SetorgDetail(obj)
 
    SetModalEdit(true);

}
function modalDeleteModal(data){
    SetDeleteID(data.id) 
  
    SetModalDelete(true)
}
function handleCloseModalWarning(){
    SetModalDelete(false);
}
function  SetOrgDetailHandler(obj){


}

function handleDeleteConfirm(){

    let requestBody={};
    requestBody.removing_org_id=deleteID
    requestBody.org_id = userDetails.org_id
    // requestBody.sub_org_id=sub_org_id
    requestBody.session = userDetails.session
    console.log("requestbody",requestBody)
    removeOrganization(requestBody).then(res=>{
        if(res.status==200){
            SetInc((prev)=>prev +1)
            SetTitle(title);
            SetDescription(res.message?res.message:'');
            SetModalSucess(true);
            SetModalDelete(false);    
            
        }else{
            SetTitle("Failed to delete");
            SetDescription(res.message?res.message:'');
            SetModalError(true);
            //SetModalDelete(false);
        }
    })
  //  SetModalDelete(false);
}
const[user,SetUser] = useState(false)
const[user1,SetUser1] =useState("")


    function UserHandler(orgdata){
        SetUser(true)
        SetUser1(orgdata)
       
    history.push('/user-setting');
    }
    

    if(user){
        return (
        <UserSetting
                    orgid={user1.id} 
                       
                    />
        )
        }
        

   


return(
    
        <div className="main-content side-content pt-0">
        <div className="container-fluid">
            <div className="inner-body">
                <div className="page-header page-header2">
                    <Row>
                
                        <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6} className="header-btn">
                        <input type="text" className='form-control' placeholder="Search Org . . ." value={searchorg} onChange={(e)=>SetSearchorg(e.target.value) } style={{width:200,display:"inline-block",}} />
                            {/* <button className='btn btn-large btn-header btn-transparent btn-icon btn-event-create'  onClick={(e)=>(SetModalCreate(true))} ><PlusIcon/>create organization</button> */}
                        </Col>
                    </Row>
                </div>

               
                <div>
                    <OrgList   modalOpenCategoryEdit={modalOpenCategoryEdit}  SetOrgDetailHandler={ SetOrgDetailHandler}
                     searchorg={searchorg}
                     UserHandler={UserHandler}
                     {...props}
                     inc={inc}
                     Reload={Reload}
                     modalDeleteModal={modalDeleteModal}
                     />
                </div>
            </div>   
        </div>
       
                <ReactModal
                        isOpen={modalCreate}
                        style={customStyles}
                        // onRequestClose={handleclosemodal}
                        className={'create-form-user'}
                    >
                           <Createorg 
                            
                           handleCloseModal={handleCloseModalCreate}
                           modalSucessHandler={modalSucessHandler}
                           modalErrorHandler={modalErrorHandler}
                           Reload={Reload}
                        />
                    </ReactModal>
                   
                    <ReactModal
                        isOpen={modalEdit}
                        style={customStyles}
                        // onRequestClose={handleclosemodal}
                        className={'create-form-user'}
                    >
                           <Editorg 
                            modalOpenCategoryEdit={modalOpenCategoryEdit}
                           handleCloseModal={handleCloseModalEdit}
                           modalSucessHandler={modalSucessHandler}
                           modalErrorHandler={modalErrorHandler}
                           Reload={Reload}
                           orgDetail={orgDetail}
                        />
                    </ReactModal>
      
                   
                    <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
                    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />    
    </div>  

    )}
    const mapStateToProps = (state) => ({
        language: state.dashboard.selectedLangaugeValue
    })
export default connect(mapStateToProps, {  })(withRouter(OrganizationManagement))