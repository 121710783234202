import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import CategoryList from './Category/CategoryList';
import CreateCategory from './Category/CreateCategory';
import EditCategory from './Category/EditCategory';
import AddLocation from './Location/AddLocation';
import EditLocation from './Location/EditLocation ';
import { getCategoryList,getlocationDetail, getlocationType,removeCategory,removeLocation } from '../actionMethods/actionMethods';
import {getAvailableTag} from '../../usermanagement/actionMethods/actionMethods'
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { remove } from 'ramda';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};
const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxHeight: '90%'
    },
};


function SiteManagement(props) {

  let requestBody={};
  const [modalCategory,SetModalCategory]= useState(false);  
  const [loader,SetLoader] =useState(false); 
  const [modalCategoryEdit,SetModalCategoryEdit]= useState(false);   
  const [modalLocationEdit,SetModalLocationEdit]= useState(false);   
  const [modalRole,SetModalRole]= useState(false);   
  const [modalRoleEdit,SetModalRoleEdit]= useState(false);   
  const [modalAddEmp,SetModalAddEmp]= useState(false);   
  const [modalAddEmpEdit,SetModalAddEmpEdit]= useState(false);   
  const [modalDelete,SetModalDelete]= useState(false);   
  const [dataCategory,SetDataCategory] = useState([]); 
  const [searchLocation,SetSearchLocation] = useState([]); 
  const [cat_name,SetCatName]=useState('')
  const [categoryID,SetCategoryID]=useState('')
  const [inc,SetInc]=useState(1);
  const [locationID,SetLocationID] =useState('');
  const [locationDetail,SetLocationDetail] =useState([]);
  const [locationType,SetlocationType] = useState([])
  const [availableTag,SetAvailTag]=useState([]);
  const [permission,SetPermission] = useState([]);
  const [permissionCategory,SetPermissionCategory] = useState([]);
  const [permissionLocation,SetPermissionLocation] = useState([]);
  const[permissionAPICall,SetPermissionAPICall] = useState(true)

  const [title,SetTitle]=useState('');
  const [description,SetDescription]= useState('');
  const [modalSucess,SetModalSucess]= useState(false);
  const [modalError,SetModalError]= useState(false);
  const [type,SetType]=useState('');
  const [ID,SetID] =useState('');

  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:23
  


    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let arr=[];
        
        let requestBody2={};
        requestBody2.session=session
        requestBody2.org_id = org_id

        getCategoryList(requestBody2).then(res2=>{
           
            if(res2.status==200||res2.code==200){
                SetDataCategory( res2.data );
            }else{
                SetDataCategory([]);
            }
        })
        getlocationType(requestBody2).then(res=>{
            if(res.status==200){
                SetlocationType(res.data);
            }
        })
        requestBody2.tag_type='location'
        
        getAvailableTag(requestBody2).then(res=>{
         	if(res.status=200){
				SetAvailTag(res.data);
			}
		})
        
        requestBody2.org_id = org_id;
        requestBody2.session= session;
        requestBody2.role_id= userDetails.role
        getRoleDetail(requestBody2).then(res=>{
            if(res.status==200|| res.code==200){
                SetPermission(res.data);
                {console.log(res.data)}
                SetPermissionCategory(JSON.parse(res.data[0].category))
                SetPermissionLocation(JSON.parse(res.data[0].location))
                SetPermissionAPICall(true);
            }
            if(res.status == 300 ){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }


        }).catch()

        
    }, [inc]);

    const [statsData,SetStatsData]=useState([
      {title:"User",color:"#000000",count:180,subStats:[{count:140,subtitle:"Active"},{count:40,subtitle:"InActive"}]},
      {title:"Categorys",color:"#3fb995",count:120,subStats:[{count:140,subtitle:"Active"},{count:40,subtitle:"InActive"}]},
      {title:"Roles",color:"#000000",count:150,subStats:[{count:140,subtitle:"Active"},{count:40,subtitle:"InActive"}]},
      {title:"Devices",color:"#ef835c",count:90,subStats:[{count:40,subtitle:"Active Emp Tag"},{count:10,subtitle:"Active LOC Tag"},{count:10,subtitle:"Gateway"},{count:10,subtitle:"Low batteries"},{count:10,subtitle:"Stock"}]},
      {title:"Location",color:"#fbcf31",count:110,subStats:[{count:140,subtitle:"Active"},{count:40,subtitle:"InActive"}]},
      {title:"Categories",color:"#183153",count:110,subStats:[{count:140,subtitle:"Active"},{count:40,subtitle:"InActive"}]}     
    ])
    

    function handleCloseModal(){
        SetModalRole(false)
        SetModalCategory(false)
        SetModalAddEmp(false)
        SetModalCategoryEdit(false)
        SetModalLocationEdit(false);
        SetModalRoleEdit(false)
    }

    function modalOpenAddEmp(id,name){
        SetModalAddEmp(true)
       
        SetCategoryID(id)
        SetCatName(name)
    }

    function Reload(){
    SetInc(inc+1);    
    }
    function modalOpenCategoryEdit(id,name){
        SetCatName(name);
        SetCategoryID(id)
        SetModalCategoryEdit(true)
    }
    function modalOpenRoleEdit(){
        SetModalRoleEdit(true)
    }
    function modalDeleteModal(type,id,name){

        SetTitle(<><strong>{name}</strong>  {type}</>)
        SetID(id)
        SetType(type);
        SetModalDelete(true)
    }

    function modalLocationEditModal(data,id){
        SetModalLocationEdit(true)
        let requestBody={};
        requestBody.session=session
        requestBody.org_id= org_id
        requestBody.location_id=data.id
        getlocationDetail(requestBody).then(res=>{
            SetLocationDetail(res.data)
        })
    }

    function handleCloseModalError(){
        SetModalError(false)
    }
    function handleCloseModalSucess(){
        SetModalSucess(false)
    }
    function handleCloseModalWarning(){
        SetModalDelete(false)
    }
    function handleDeleteConfirm(){
        let requestBody={}
        requestBody.session =session;
        requestBody.org_id=org_id.toString();

        if(type=='Location'){
            requestBody.location_id = ID.toString()
            removeLocation(requestBody).then(res=>{
                if(res.status==200){
                    SetTitle(<h4>Location is Deleted</h4>)
                    SetDescription(res.message);
                    SetModalSucess(true)
                    Reload()
                    SetModalDelete(false)
                }else{
                    SetTitle(<h4>Fail to delete Location</h4>)
                    SetDescription(res.message);
                    SetModalError(true)
                    SetModalDelete(false)
                }
            })

        }else if(type=='Category'){
            requestBody.category_id = ID
            removeCategory(requestBody).then(res=>{
                if(res.status==200){
                    SetTitle(<h4>Category is Deleted</h4>)
                    SetDescription(res.message);
                    SetModalSucess(true)
                    Reload()
                    SetModalDelete(false)
                }else{
                    SetTitle(<h4>Fail to delete Category</h4>)
                    SetDescription(res.message);
                    SetModalError(true)
                    SetModalDelete(false)
                }
            })
        }
    }


    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
   
   
    {permissionCategory &&permissionCategory.View=='True'&&permissionAPICall?
    <div className="inner-body">


    <div className="page-header">
            <Row>
                <Col lg={6}>
                </Col>
                <Col lg={2} className={"tagAvailStyle tagAvailStyle-"+availableTag.length.toString().length}>Tag Available <strong>{availableTag.length}</strong></Col>
                <Col lg={4} className="header-btn">
                <input type="text" className='form-control' placeholder="Search Location" value={searchLocation} onChange={(e)=>SetSearchLocation(e.target.value) } style={{width:200,display:"inline-block"}} />
                    {permissionCategory && permissionCategory.Create=='True'? <button className='btn btn-large btn-header btn-transparent btn-icon' onClick={(e)=>SetModalCategory(true)} ><PlusIcon/> Add Category</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled' disabled ><PlusIcon/> Add Category <span className='messageTag'>No Permission</span></button>}</Col>
                </Row>
    </div>

    <CategoryList 
        dataCategory={dataCategory}
        modalOpenAddEmp={modalOpenAddEmp}
        modalOpenCategoryEdit={modalOpenCategoryEdit}
        modalDeleteModal={modalDeleteModal}
        modalLocationEditModal={modalLocationEditModal}
        searchLocation={searchLocation}
        Reload={Reload}
        inc={inc}
        availableTag={availableTag}
        permission={permission}
        permissionLocation={permissionLocation}
    />        
        

    </div>:''}
    {permissionCategory.View=='False'&&permissionAPICall?<Col lg={12}><div className='errorPermission'>Access Restricted to acess this page please contact to administrator</div></Col>:""}
</div>

            <ReactModal
                isOpen={modalCategory}
                style={customStyles}
              //  onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                   
              <CreateCategory handleCloseModal={handleCloseModal} Reload={Reload} />
            </ReactModal>

            <ReactModal
                isOpen={modalCategoryEdit}
                style={customStyles}
               // onRequestClose={handleCloseModal}
            >
                   
              <EditCategory handleCloseModal={handleCloseModal} categoryID={categoryID} cat_name={cat_name} Reload={Reload} availableTag={availableTag} />
            </ReactModal>

            <ReactModal
                isOpen={modalLocationEdit}
                style={customStyles}
                //onRequestClose={handleCloseModal}
                className={'create-form-user create-form-user-less-step'}
            >
                   
              <EditLocation handleCloseModal={handleCloseModal} locationType={locationType} locationID={locationID}  locationDetail={locationDetail}  Reload={Reload}     dataCategory={dataCategory} availableTag={availableTag}/>
            </ReactModal>

            <ReactModal
                isOpen={modalAddEmp}
                style={customStyles2}
               // onRequestClose={handleCloseModal}
                className={'create-form-user create-form-user-less-step'}
            >
                   
              <AddLocation handleCloseModal={handleCloseModal}  Reload={Reload} locationType={locationType} categoryID={categoryID} cat_name={cat_name} dataCategory={dataCategory} availableTag={availableTag}/>
            </ReactModal>

            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
        
        <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>


</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(SiteManagement))
