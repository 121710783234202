import axios from "axios";

let prefixURL = process.env.REACT_APP_URL
let prefixURL2 = process.env.REACT_APP_URL_2

export function getLocationCounter( requestBody) {
    return axios.get(prefixURL + `/location/get_facility_count_list?org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}&session=${requestBody.session}`)
        .then(res => res.data).catch(err => err)     
}
//https://api.traceplus.ai/trace/api/v1/get_cycle_count_plan?session=8f72ea326b2b4239956beeba29b11a8c&org_id=28&sub_org_id=0&date=2023-06-09
export function getCycleCount(requestBody){
    return axios.get(prefixURL2 + `/get_cycle_count_plan?session=${requestBody.session}&org_id=${requestBody.org_id}&sub_org_id=${requestBody.sub_org_id}&date=${requestBody.date}`)
        .then(res => res.data).catch(err => err)     
} 
export function createFacilityCount(requestBody){
    
        return axios.post(prefixURL + `/location/create_facility_count`,requestBody)
        .then(res => res.data).catch(err => err)     

}
export function updateFacilityCount(requestBody){
    
        return axios.post(prefixURL + `/location/edit_facility_count`,requestBody)
        .then(res => res.data).catch(err => err)     

}
