import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import GroupList from './Group/GroupList';
import CreateGroup from './Group/CreateGroup';
import EditGroup from './Group/EditGroup';
import AddEmployee from './Employee/AddEmployee';
import EditEmployee from './Employee/EditEmployee'
import {getRoleList} from '../../rolemanagement/actionMethods/actionMethods';
import SubGroup from './Group/SubGroup';
import EditSubGroup from './Group/EditSubGroup';
import {getEmployeeInfo,getAvailableTag,getShifttList,getsuborganizationlist} from '../actionMethods/actionMethods'
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import {getlocationType} from '../../sitemanagement/actionMethods/actionMethods'
import { getClientDBList } from '../../clientdb/actionMethods/actionMethods';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};



function UserManagement(props) {

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:""

  const [modalGroup,SetModalGroup]= useState(false);   
  const [modalGroupEdit,SetModalGroupEdit]= useState(false);   
  const [modalRole,SetModalRole]= useState(false);   
  const [modalRoleEdit,SetModalRoleEdit]= useState(false);   
  const [modalAddEmp,SetModalAddEmp]= useState(false);   
  const [modalAddEmpSucess,SetModalAddEmpSucess]= useState(false);   
  const [modalEmpEdit,SetModalEmpEdit]= useState(false);   
  const [modalSubGroup,SetmodalSubGroup]=useState(false)
  const [modalSubGroupEdit,SetmodalSubGroupEdit]=useState(false)
  const [modalDelete,SetModalDelete]= useState(false);   
  const [dataGroup,SetDataGroup] = useState([]);  
  const [roleListData,SetRoleListData] = useState([]);
  const [group_id,SetGroupID]=useState('');  
  const [group_name,SetGroupName]=useState('');  
  const [employeeID,SetEmployeeID]=useState('');
  const [inc,SetInc]=useState(1)
  const [subgroup_name,SetSubGroupName]=useState('');
  const [subGroupID,SetSubGroupID] = useState('');
  const [parentGroup,SetParentGroup]=useState([]);
  const [searchUser,SetSearchUser] =useState([]);
  const [employeeDetail,SetEmployeeDetail] = useState([]);
  const [availableTag,SetAvailTag]=useState([])   
  const [permission,SetPermission] =useState([]);
  const [permissionAPICall,SetPermissionAPICall] =useState(false);
  const [permissionDepartment,SetPermissionDepartment]=useState([])
  const [shift,SetShift]=useState([]);
  const [locationType,SetLocationType] = useState([]); 
  const[suborglist,Setsuborglist] =useState([])   
  const [clientdb,SetClientDb] = useState([]);


  useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody =[];
        requestBody.session=session
        requestBody.org_id=org_id

        getClientDBList(userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{
            if(res.status==200){
                SetClientDb(res.data);
            }
        })

        getRoleList(requestBody).then(res=>{
            if(res.status==200){
                let data = res.data;
                let index2 = 1;
                let dataFormat =[];
                 
                Object.keys(data).map(function(key){
                    
                    dataFormat.push({id:data[key].role_id,role_name:key,active:data[key].active,inactive:data[key].inactive,total:data[key]['total users']})
                    index2++;
                });
                SetRoleListData(dataFormat);
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)
            }
        }).catch();
        requestBody.tag_type='personal'
        getAvailableTag(requestBody).then(res=>{
            if(res.status=200){
				SetAvailTag(res.data);
			}
		})
        getShifttList(requestBody).then(res=>{
            if(res.status==200){
               SetShift(res.data);
            }
        })
        getlocationType(requestBody).then(res=>{
            if(res.status==200){
                SetLocationType(res.data);
            }
        })

    }, [inc]);
    useEffect(()=>{
    
        if(permissionDepartment.View=='True'){

            let requestBody={}
            requestBody.session=session
            requestBody.org_id= org_id    

        getsuborganizationlist(requestBody).then(res=>{
            
            if(res.status==200){
                Setsuborglist(res.data);

            }

        })
    }
    },[permission])

    useEffect(()=>{

        let requestBody={}
        requestBody.session=session
        requestBody.org_id= org_id
        requestBody.role_id=userDetails.role
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200|| res.code==200){
                
                SetPermissionDepartment(JSON.parse(res.data[0].group));
                SetPermission(res.data);
                SetPermissionAPICall(true);
            }
            if(res.status == 300 ){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }

        }).catch();

    },[])

    function Reload(){
        SetInc(inc+1);
    }  

    function handleCloseModal(){
        SetModalRole(false)
        SetModalGroup(false)
        SetModalAddEmp(false)
        SetModalEmpEdit(false)
        SetModalGroupEdit(false)
        SetModalRoleEdit(false)
        SetmodalSubGroup(false)
        SetModalAddEmpSucess(false)
        SetModalEmpEdit(false);
    }

    function handleCloseModalEditGroup(){
        SetmodalSubGroupEdit(false);
    }

    function modalOpenAddEmp(id,name,sub_dept_id=''){
        SetModalAddEmp(true)
        SetGroupID(id)
        SetGroupName(name);
        SetSubGroupID(sub_dept_id)
        
    }

    function modalOpenEmpSucess(){
        SetModalAddEmpSucess(true)
    }
    function modalOpenEditEmployee(data){
        SetEmployeeID(data.emp_id);
        console.log("data",data)
        let requestBody={}
        requestBody.org_id= org_id
        requestBody.employee_id = data.emp_id
        requestBody.session = session;
        console.log("user APi Hits");
        getEmployeeInfo(requestBody).then(res=>{
            if(res.status==200){
               console.log("user APi response",res);
                SetEmployeeDetail(res.data.data);                
            }
        })       
        SetModalEmpEdit(true);
    }

    function refreshShift(){
        let requestBody={}
        requestBody.org_id= org_id
        requestBody.session = session;
        getShifttList(requestBody).then(res=>{
            if(res.status==200){
               SetShift(res.data);
            }
        })
    }
    
    function modalOpenSubGroup(name,id){
        SetGroupName(name)
        SetGroupID(id);
        SetmodalSubGroup(true);

    }

    function modalOpenGroupEdit(id,val){
        SetModalGroupEdit(true)
        SetGroupID(id);
        SetGroupName(val)
    }

    

    function modalOpenRoleEdit(){
        SetModalRoleEdit(true)
    }
    function modalDeleteModal(){
        SetModalDelete(true)
    }

    function modalSubGroupEditOpen(id,name,parent_id,parentList){
        SetSubGroupName(name)
        SetGroupID(parent_id)
        SetSubGroupID(id)
        SetmodalSubGroupEdit(true)   
        SetParentGroup(parentList)
    }
    


    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    
    {permissionAPICall&&permissionDepartment.View=='True'?
    
    <div className="inner-body">


    <div className="page-header">
            <Row>
                <Col lg={4}>
                </Col>
                <Col lg={2} className={"tagAvailStyle tagAvailStyle-"+availableTag.length.toString().length}>Tag Available <strong>{availableTag.length}</strong></Col>
                <Col lg={6} className="header-btn">
                    <input type="text" className='form-control' placeholder="Search User" value={searchUser} onChange={(e)=>SetSearchUser(e.target.value) } style={{width:200,display:"inline-block"}} />
                    {permissionDepartment && permissionDepartment.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={(e)=>SetModalGroup(true)} ><PlusIcon/> Add Department</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled btn-delete' disabled><PlusIcon/> Add Department <span className='messageTag'>Access Restricted</span></button>}</Col>
                </Row>
    </div>

    <GroupList 
        dataGroup={dataGroup}
         modalOpenAddEmp={modalOpenAddEmp}
         modalOpenRoleEdit={modalOpenRoleEdit}
         modalOpenGroupEdit={modalOpenGroupEdit}
         modalDeleteModal={modalDeleteModal}
         modalOpenSubGroup={modalOpenSubGroup}
         modalOpenEditEmployee={modalOpenEditEmployee}
         modalSubGroupEditOpen={modalSubGroupEditOpen}
         Reload={Reload}
         searchUser={searchUser}
         inc={inc}
         availableTag={availableTag}
         permission={permission}
    />        
        

    </div>:permissionAPICall && permissionDepartment.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}
</div>

            <ReactModal
                isOpen={modalGroup}
                style={customStyles}
                // onRequestClose={handleCloseModal}
              >
                   
              <CreateGroup handleCloseModal={handleCloseModal} Reload={Reload}  />
            </ReactModal>

            <ReactModal
                isOpen={modalSubGroup}
                style={customStyles}
               // onRequestClose={handleCloseModal}
            >                   
              <SubGroup handleCloseModal={handleCloseModal} Reload={Reload}  group_name={group_name}  group_id={group_id} />
            </ReactModal>

            <ReactModal
                isOpen={modalSubGroupEdit}
                style={customStyles}
               // onRequestClose={handleCloseModal}
            >                   
              <EditSubGroup handleCloseModal={handleCloseModalEditGroup} Reload={Reload}   group_id={group_id} subgroup_name={subgroup_name} subGroupID={subGroupID} parentGroup={parentGroup} />
            </ReactModal>

            <ReactModal
                isOpen={modalGroupEdit}
                style={customStyles}
               // onRequestClose={handleCloseModal}
            >
                   
              <EditGroup handleCloseModal={handleCloseModal} modalOpenGroupEdit={modalOpenGroupEdit}   group_id={group_id} group_name={group_name} Reload={Reload}  inc={inc} />
            </ReactModal>


            <ReactModal
                isOpen={modalDelete}
                style={customStyles}
              //  onRequestClose={handleCloseModal}
            >
                <div className='dangerDeleteZone'>
                   <div className='deleteZoneHeader'>
                        {/* <DeleteIcon/> */}
                         <h2>Delete</h2>
                </div>
                <div className='deleteZoneContent'>
                    Are you sure you wan't to delete <br/> <span style={{color:"#c52f2f"}}>Name of delete item</span>
                </div>
                <div className='deleteZoneAction'>
                    <button className='btn btn-transparent' onClick={()=>SetModalDelete(false)}>No</button>
                    <button className='btn btn-primary' onClick={()=>SetModalDelete(false)}>Yes, Delete</button>
                </div>
                </div>
            </ReactModal>


            <ReactModal
                isOpen={modalAddEmp}
                style={customStyles}
                //onRequestClose={handleCloseModal}
                className={'create-form-user'}
            >
                   
              <AddEmployee 
                    handleCloseModal={handleCloseModal}
                    subGroupID={subGroupID} 
                    roleListData={roleListData} 
                    group_name={group_name} 
                    availableTag={availableTag} 
                    group_id={group_id} 
                    modalOpenEmpSucess={modalOpenEmpSucess} 
                    Reload={Reload}
                    shift={shift}
                    refreshShift={refreshShift}
                    locationType={locationType}
                    clientdb={clientdb}
                />
            </ReactModal>


            <ReactModal
                isOpen={modalEmpEdit}
                style={customStyles}
             //   onRequestClose={handleCloseModal}
                className={'create-form-user'}
            >
                   
              <EditEmployee
                    subGroupID={subGroupID} 
                    handleCloseModal={handleCloseModal} 
                    Reload={Reload} 
                    employeeID={employeeID} 
                    availableTag={availableTag} 
                    roleListData={roleListData} 
                    modalOpenEmpSucess={modalOpenEmpSucess} 
                    employeeDetail={employeeDetail}
                    shift={shift}
                    refreshShift={refreshShift}
                    locationType={locationType}
                    suborglist={suborglist}
                    clientdb={clientdb}
                    />
            </ReactModal>


            

           


</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(UserManagement))
