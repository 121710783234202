import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function getDeviceReport(requestBody) {
    
    return axios.get(prefixURL + `/device/get_device_report?org_id=${requestBody.org_id}&session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
}


export function getAllEmployeeList(requestBody) {
    
    return axios.get(prefixURL + `/employee/get_all_employees?org_id=${requestBody.org_id}&session=${requestBody.session}&employee_type=${requestBody.employee_type}`).then(res=>res.data).catch(err=>err)
}

export function getAllLocationList(requestBody) {
    
    return axios.get(prefixURL + `/location/get_all_locations?org_id=${requestBody.org_id}&session=${requestBody.session}`).then(res=>res.data).catch(err=>err)
}


export function unAssignLocationTag(requestBody) {    
    return axios.post(prefixURL + `/location/unassign_location_tag`,requestBody).then(res=>res.data).catch(err=>err)
}

export function unAssignEmployeeTag(requestBody) {    
    return axios.post(prefixURL + `/employee/unassign_employee_tag`,requestBody).then(res=>res.data).catch(err=>err)
}
export function unAssignTemporaryTag(requestBody) {    
    return axios.post(prefixURL + `/employee/unassign_temporary_employee_tag`,requestBody).then(res=>res.data).catch(err=>err)
}

export function assignTag(requestBody) {    
    if(requestBody.type=='personal' || requestBody.type=='asset'  ){
        return axios.post(prefixURL + `/employee/assign_employee_tag`,requestBody).then(res=>res.data).catch(err=>err)
    }else if(requestBody.type=='temporary'){
        return axios.post(prefixURL + `/employee/assign_temporary_employee_tag`,requestBody).then(res=>res.data).catch(err=>err)
    }else{
        return axios.post(prefixURL + `/location/assign_location_tag`,requestBody).then(res=>res.data).catch(err=>err)
    }
 
}



