import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import RoleList from './RoleList';
import { useHistory } from 'react-router-dom';
import { getRoleDetail, removeRole } from '../actionMethods/actionMethods'
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};


function RoleManagement(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

  const [modalGroup,SetModalGroup]= useState(false);   
  const [modalGroupEdit,SetModalGroupEdit]= useState(false);   
  const [modalRole,SetModalRole]= useState(false);   
  const [modalRoleEdit,SetModalRoleEdit]= useState(false);   
  const [modalAddEmp,SetModalAddEmp]= useState(false);   
  const [modalAddEmpEdit,SetModalAddEmpEdit]= useState(false);   
  const [modalDelete,SetModalDelete]= useState(false);   
  const [dataGroup,SetDataGroup] = useState([]);  
  const [searchRole,SetSearchRole] = useState([]);
  const [permission,SetPermission]=useState([])
  const [permissionAPICall,SetPermissionAPICall]=useState(false);
  const [deleteID,SetDeleteID] =useState('');
  const [title,SetTitle]=useState('');
  const [description,SetDescription]= useState('');

  const [modalSucess,SetModalSucess]= useState(false);
  const [modalError,SetModalError]= useState(false);
  const [inc,setInc]=useState(0);

  
    
  useEffect(() => {

        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].role))

                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()


    }, []);


    function handleCloseModal(){
        SetModalRole(false)
        SetModalGroup(false)
        SetModalAddEmp(false)
        SetModalGroupEdit(false)
        SetModalRoleEdit(false)
    }

    function modalOpenAddEmp(){
        SetModalAddEmp(true)
    }

    function modalOpenGroupEdit(){
        SetModalGroupEdit(true)
    }
    function modalOpenRoleEdit(){
        SetModalRoleEdit(true)
    }

    function modalDeleteModal(title,id){
        SetDeleteID(id)
        SetTitle(<><strong>{title}</strong> Role</>);     
        SetModalDelete(true)
    }
    
    function handleCloseModalWarning(){
        SetModalDelete(false);
    }

function AddCreateHandler(){

    history.push('/add-role');
}
    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.role_id=deleteID
        requestBody.org_id = userDetails.org_id
        requestBody.session = userDetails.session
      
        removeRole(requestBody).then(res=>{
            if(res.status==200){
                setInc((prev)=>prev +1)
                SetTitle(title);
                SetDescription(res.message?res.message:'');
                SetModalSucess(true);
                SetModalDelete(false);    
                
            }else{
                SetTitle("Failed to delete");
                SetDescription(res.message?res.message:'');
                SetModalError(true);
                //SetModalDelete(false);
            }
            
        })
      //  SetModalDelete(false);


    }
    function handleCloseModalSucess(){
        SetModalSucess(false);
    }
    function handleCloseModalError(){
        SetModalError(false);
    }
    


    return (
         <div className="main-content side-content pt-0">

<div className="container-fluid">
{permissionAPICall && permission.View=='True'?
    <div className="inner-body">


    <div className="page-header">
            <Row>
            <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                
                <Col lg={6} className="header-btn">
                <input type="text" className='form-control' placeholder="Search Role" value={searchRole} onChange={(e)=>SetSearchRole(e.target.value) } style={{width:200,display:"inline-block"}} />
               
                    {permission && permission.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={AddCreateHandler}><PlusIcon/> Add Role</button>:<button className='btn btn-large btn-header btn-transparent btn-icon disabled hover-message disabled' disabled><PlusIcon/> Add Role<span className='messageTag'>Access Restricted</span></button>}
                    
                </Col>
                </Row>
    </div>

    <RoleList 
        dataGroup={dataGroup}
         modalOpenAddEmp={modalOpenAddEmp}
         modalOpenRoleEdit={modalOpenRoleEdit}
         modalOpenGroupEdit={modalOpenGroupEdit}
         modalDeleteModal={modalDeleteModal}
         searchRole={searchRole}
         permission={permission}
         {...props}
         inc={inc}
         
    />        
        

    </div>:permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}   
</div>

           
           

            {/* <ReactModal
                isOpen={modalDelete}
                style={customStyles}
             >
                <div className='dangerDeleteZone'>
                   <div className='deleteZoneHeader'>
                 
                         <h2>Delete</h2>
                </div>
                <div className='deleteZoneContent'>
                    Are you sure you wan't to delete <br/> <span style={{color:"#c52f2f"}}>Name of delete item</span>
                </div>
                <div className='deleteZoneAction'>
                    <button className='btn btn-transparent' onClick={()=>SetModalDelete(false)}>No</button>
                    <button className='btn btn-primary' onClick={()=>SetModalDelete(false)}>Yes, Delete</button>
                </div>
                </div>
            </ReactModal> */}


            <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
           
           
           

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(RoleManagement))
