import axios from "axios";
let prefixURL = process.env.REACT_APP_URL

let token = 'ezrsrdfghawtsetrrAZSXCxfa'


export function getClientDBList(session,org_id,sub_org_id) {    
    return axios.get(prefixURL + `/employee/get_client_db_list?session=${session}&org_id=${org_id}`).then(res=>res.data).catch(err=>err)
}
 
