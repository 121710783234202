import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import  '../styles/style.scss';
import moment from 'moment';
import {Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as MinusIcon} from '../../assets/images/square-minus.svg'
import {ReactComponent as Back} from '../../assets/images/angle-left.svg'
import LoaderSpinner from '../../assets/images/Spinner Loader.gif'
import { Select, Radio,Switch } from 'antd';
import {CreatePage,CreateSubPage} from '../actionMethods/actionMethods'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import {ReactComponent as HelpIcon} from '../../assets/images/circle-info.svg'

const customStyle = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      width: '25rem',
      height: '13rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}

function AddPage(props){
    const { Option } = Select;  
    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    let count100 = [];
    for(let i= 1;i<101;i++){
      count100.push(i);
    }
    const [activeAccord,SetActiveAccord]=useState(count100)
    
    const [page_id,SetPageID] = useState(props.page_id);
    const [page_name,SetPageName]= useState(props.page_name);
    const [sub_page_name,SetSubPageName]= useState(props.sub_page_name);
    const [version,SetVersion] = useState("");
    const [versionURL,SetVersionUrl] = useState("");
    

    const [page_nameError,SetPageNameError]=useState('');
    const [sub_page_nameError,SetSubPageNameError]=useState('');
    const [versionError,SetVersionError]=useState('');
    

    const [loader,SetLoader]=useState("False");
    const reg = /^\S.*$/;
  
    function AccordionHandler(val){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
        }   
        else {  
            ActivList.push(val);
        }
        SetActiveAccord(ActivList);
    }



    function handleCloseModalSucess(){
      SetModalSucess(false)
      props.handleCloseModal();
      props.reload();
      // props.history.push(`/role-management`);
    }
    function handleCloseModalError(){
      SetModalError(false) 
    }


    function AddPagehandler(event){

      event.preventDefault();

      console.log("page_name",page_name,page_name=='', page_name.trim().length==0)
      
      if(page_name==''|| page_name.trim().length==0){
       SetLoader("False");
       SetPageNameError('Required');
      }
      else if(props.tyepModal=='Sub Page' && sub_page_name.trim().length==0){
         SetPageNameError('Required');
      }else if(version==''|| version.trim().length==0){
       SetLoader("False");
        SetVersionError('Required');
      }

      else{
        
         let requestBody={
          "page_name": page_name,
          "version": version,
          "url": versionURL,
          "session" : userDetails.session
      }
      if(props.tyepModal=="Sub Page" || props.tyepModal=="Sub Page Version"){
        requestBody={
            "sub_page_name": sub_page_name,
            "version": version,
            "url": versionURL,
            "page_id": page_id,
            "session" : userDetails.session
        }
        CreateSubPage(requestBody).then(res=>{
          if(res.status == 200 || res.code == 200){
            //props.handleCloseModal();
            SetTitle(`${props.tyepModal?props.tyepModal:"Page"} is created`);
            SetModalSucess(true);
          }else{
            SetTitle("Failed to create page");
            SetModalError(true);
          }

        })
      }else{
        CreatePage(requestBody).then(res=>{
          if(res.status == 200 || res.code == 200){
            
            SetTitle(`${props.tyepModal?props.tyepModal:"Page"} is created`);
            SetModalSucess(true);
          }else{
            SetTitle("Failed to create page");
            SetModalError(true);
          }
        })
      }


      }

    }

  


 

    return(
   <>
   		<div className='policyTypeTeam'>

			<div className='headerAreaTeam'>
				<h4>Add {props.tyepModal!=""?props.tyepModal:"Page"}</h4>

				 <button className='btn btn-close' onClick={()=>props.handleCloseModal()}>x</button></div>
			<div className='bodyAreaTeam'>
			<Row>
        <Col lg={12}>
          {props.tyepModal=="Sub Page Version"?"":<div className='form-field'>
						<label>Page Name <span className='required'>*</span></label>
						<input type="text"  className='form-control border-single' readOnly={props.tyepModal!=""} value={page_name} onChange={(e)=>SetPageName(e.target.value)}/>
						{page_nameError!=''?<div className='error'>{page_nameError}</div>:""}
					</div>}
          {props.tyepModal=="Sub Page" || props.tyepModal=="Sub Page Version" ?
          <div className='form-field'>
						<label>Sub Page Name <span className='required'>*</span></label>
						<input type="text"  className='form-control border-single' value={sub_page_name} onChange={(e)=>SetSubPageName(e.target.value)}/>
						{sub_page_nameError!=''?<div className='error'>{sub_page_nameError}</div>:""}
					</div>:""}
          <div className='form-field'>
						<label>Version Name <span className='required'>*</span></label>
						<input type="text" className='form-control border-single' value={version} onChange={(e)=>SetVersion(e.target.value)}/>
						{/* {page_nameError!=''?<div className='error'>{page_namaeError}</div>:""} */}
					</div>
            <div className='form-field'>
						<label>Version URL <span className='required'>*</span></label>
						<input type="text" className='form-control border-single' value={versionURL} onChange={(e)=>SetVersionUrl(e.target.value)}/>
						{versionError!=''?<div className='error'>{versionError}</div>:""}
					</div>
        </Col>
			</Row>
			
			</div>
			<div className='footerAreaTeam'>
				<button type="submit"  className='btn btn-submit' style={{width:"100%"}} onClick={AddPagehandler} > Add {props.tyepModal!=""?props.tyepModal:"Page"}</button>
			</div>


	
		</div>
   

                
                {loader=="True"?<div className='loaderArea'><img src={LoaderSpinner}/></div>:""}
                <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />

</>
    )
    

}
export default AddPage;