import React,{useState,useEffect} from "react";
import Scrollbars from "react-custom-scrollbars";
import ReactModal from "react-modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};
function ErrorModal(props){

    const [modalError,SetModalError] =useState(false);
    useEffect(()=>{

        SetModalError(props.modalError)
    },[props])

return (
    
    <ReactModal
    isOpen={modalError}
    style={customStyles}
    className={props.errorMessage && props.errorMessage!=''?"sucessModal with-errorMessage":props.description&&props.description!=null?'sucessModal with-description':'sucessModal without-description'}
    >
        {props.errorMessage==undefined ||props.errorMessage.length == 0 ?<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
    <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
    <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
    <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
    </svg>:""}
        <h4 style={{textAlign:props.errorMessage==''|| props.errorMessage==undefined ?"center":"left"}}>{props.title}</h4>
        <p>{props.description}</p>
        {props.errorMessage&& props.errorMessage!=''?<div className="someHieght" style={{height:"150px",marginBottom:"10px"}}><Scrollbars style={{height:"150px"}}>
        {props.errorMessage}
        </Scrollbars></div>:""}
            <button onClick={()=>props.handleCloseModalError()} className="btn btn-close"> Close</button>
    </ReactModal>



)
}

export default ErrorModal;