import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../../rolemanagement/styles/style.scss';
import '../style/styles.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/circle-xmark.svg'
import RoleList from '../../rolemanagement/components/RoleList'
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import DeviceList from './device/DeviceList';
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};



function DeviceManagement(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:"";

  const [dataGroup,SetDataGroup] = useState([]);  
  const [activeTab,SetActiveTab] =useState(0);
  const [deviceSearch,SetDeviceSearch] =useState([]);
  const [permission,SetPermission]=useState([]);
  const[permissionAPICall,SetPermissionAPICall] =useState(false);
  const [availableEmployee,SetAvailableEmployee] = useState([
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"80%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"100%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"20%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"50%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"90%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"30%"},
  ])
  const [activeEmployee,SetActiveEmployee] = useState([
    {tag_id:'01FAF6ACC38A4F',name:"B. Siva Sankar",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"B. Srinu",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"G. Suresh Babu",battery:"80%"},
    {tag_id:'01FAF6ACC38A4F',name:"V. Krishna Sumanth",battery:"100%"},
    {tag_id:'01FAF6ACC38A4F',name:"B. Vijay Kumar",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"D. Rama Mohan",battery:"20%"},
    {tag_id:'01FAF6ACC38A4F',name:"G. Jayaram",battery:"50%"},
    {tag_id:'01FAF6ACC38A4F',name:"P. Appala Swamy",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"N Sandeep",battery:"90%"},
    {tag_id:'01FAF6ACC38A4F',name:"Panasa. Ramesh",battery:"30%"},
  ])

  const [activeVisitor,SetActiveVisitor] = useState([
    {tag_id:'01FAF6ACC38A4F',name:"B. Siva Sankar",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"B. Srinu",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"G. Suresh Babu",battery:"80%"},
  ])
  const [availableVisitor,SetAvailableVisitor] = useState([
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"40%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"60%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"80%"},
    {tag_id:'01FAF6ACC38A4F',name:"",battery:"100%"},
  ])

    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id= org_id
        requestBody.role_id = userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].device));

                SetPermissionAPICall(true)
            }
            if(res.status==300){
              localStorage.removeItem('isLoggedInGatewayTraceAdmin')
              localStorage.removeItem('userLoginDetailsTraceAdmin')
              props.history.push(`/login`)        
          }
        }).catch()

    }, []);




    


    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    {permission.View=='True' && permissionAPICall?<div className="inner-body">




    <DeviceList 
        dataGroup={dataGroup}
         deviceSearch={deviceSearch}
         permission={permission}
         {...props}
    />        
        

    </div>:""}
    {permission.View=='False' && permissionAPICall?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}
</div>

           

           
           
           

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(DeviceManagement))
