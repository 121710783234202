import axios from "axios";
let prefixURL = process.env.REACT_APP_URL_2


// /api/v1/get_location_offline_status?session=trace123456789&org_id=28
// export const getOfflineStatus (sessionID, orgId, sub_org_id) => {
//     try {
//         let res = await axios.get(`${prefixURL}/get_location_offline_status?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
//         return res.data
//     } catch (err) {
//         return err
//     }
// }

// export const getEmployeesTagDetails (sessionID, orgId, sub_org_id) => {
//     try {
//         let res = await axios.get(`${prefixURL}/get_employees_tag_details?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
//         return res.data
//     } catch (err) {
//         return err
//     }
// }

export async function getLocationStatus (sessionID, orgId, sub_org_id) {
    try {
        let res = await axios.get(`${prefixURL}/get_location_status?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}
export async function getTagStatus (sessionID, orgId, sub_org_id) {
    try {
        let res = await axios.get(`${prefixURL}/get_tag_status?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}
// /api/v1/get_gateway_status?session=trace123456789&org_id=31
export function getGatewayStatus(  sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL + `/get_gateway_status?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
// Gateway History
// /api/v1/get_gateway_status_history?session=trace123456789&org_id=31&gateway_macid=E4:5F:01:43:4B:2A&start_time=2023-11-07 00:00:00&end_time=2023-11-08 00:00:00
export function getGatewayHistory(  sessionID,gateway_macid,start_time,end_time,org_id,sub_org_id) {
    return axios.get(prefixURL + `/get_gateway_status_history?session=${sessionID}&gateway_macid=${gateway_macid}&start_time=${start_time}&end_time=${end_time}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getLocationHistory(  sessionID,tag_serial,start_time,end_time,org_id,sub_org_id) {
    return axios.get(prefixURL + `/get_location_status_history?session=${sessionID}&tag_serial=${tag_serial}&start_time=${start_time}&end_time=${end_time}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
